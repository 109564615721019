import { Box, Breadcrumbs, Button, ButtonBase, FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import React, { useEffect, useState } from "react";
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "./ExportMenu";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import moment from "moment-timezone";
import { Link, useNavigate, useParams } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Grid } from "@material-ui/core";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import CustomSearchInput from "./CustomSearchInput";
import CustomSelectField from "./CustomSelectField";





const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Student Report
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Student Transactions
  </Typography>

];
const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);

const baseUrl = process.env.REACT_APP_BASE_URL;
const StudentPaymentHistory = () => {
  const { studentID } = useParams()
  const [studentDataData, setStudentDetails] = useState([])
  const [studentTranscations, setStudentTranscations] = useState([])
  const [StudentAllData, setStudentAllData] = useState()
  const [pageSize, setPageSize] = React.useState(10);
  const [status, setStatus] = useState('')
  const [grade, setGrade] = useState('')
  const [stream, setStream] = useState('')
  const [coursesOptions, setCoursesOptions] = useState([]);
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)


  
  const [term, setTerm] = useState('')
  const [searchValue, setSearchValue] = useState('')
  console.log(term, 'Term');

  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 10, });

  // console.log('student ID', studentID);

  const navigate = useNavigate()
  const { userId, X_Authorization } = useSelector((store) => store.user);

  //         const response = await fetch(baseUrl + `/api/v1/student_invoice_status?admissionNumber=${studentID}?pageSize=${pageState.page}?limit=${pageState.pageSize}`, {

  const studentDetails = async () => {
    const response = await fetch(baseUrl + `/api/v1/student_invoice_status?admissionNumber=${studentID}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });

    if (response.status === 200) {
      const studentData = await response.json();
      console.log('studentData', studentData);
      setStudentDetails(studentData.data)
      setStudentAllData(studentData)
      setStudentTranscations(studentData.studentInvoices)
    }
  };
  // console.log(StudentAllData, 'studentDataData===============>');
  useEffect(() => {
    studentDetails()
  }, [])

  const getCourses = async () => {
    const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
      method: "GET",
    });
    const data = await response.json();
    console.log(data, "Get Courses");
    if (response.status === 200) {
      // setCoursesOptions(data.data);
      setCoursesOptions(data.data.map((courses) => {
        return { value: courses.courseName, label: courses.courseName }
      }
      ))
    }
  };

  useEffect(() => {
    getCourses();
  }, []);

  const firstName = studentDataData?.studentFirstName
  const secondName = studentDataData?.studentSecondName
  const studentGrade = studentDataData?.studentGrade
  const studentTerm = studentDataData?.term

  const firstNameLetter = firstName?.slice(0, 1);
  const secondNameLetter = secondName?.slice(0, 1);
  // 

  const columns = [
    {
      field: "invoiceNumber", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice No</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "510" }}>
            <Link to={`/school/invoice/${params.value}`}>
              {params.value}
            </Link>

          </div>
        );
      }
    },
    {
      field: "grade", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Grade</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "term", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Term</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "stream", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Stream</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "invoiceAmount", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Amount</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "invoiceDiscountAmount", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Discount Amount</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "amountPaid", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Amount Paid</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.row.invoiceAmountPaid  || 0}
          </div>
        );
      }
    },
    {
      field: "invoiceBalance", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Balance</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "invoiceOverPayment", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Over Payment</span>, renderCell: (params) => {
        return (
          <div style={{ fontSize: "1rem", fontWeight: "500" }}>
            {params.value}
          </div>
        );
      }
    },
    {
      field: "invoiceStatus", flex: 1, headerName: "Status",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">

              {
                params.row.invoiceOverPayment > 0 ? <span style={{ color: "#032541", fontWeight: "bold" }}>{'Over Payment'}</span>
                  : params.value == 'Paid' ? <span style={{ color: "#00B87C", fontWeight: "bold" }}>{params.value}</span>
                    : params.value === 'Unpaid' ? <span style={{ color: "#FF0000", fontWeight: "bold" }}>{params.value}</span>
                      : params.value === 'Partially Paid' ? <span style={{ color: "#F79029", fontWeight: "bold" }}>{params.value}</span>

                        : <span style={{ color: "#FF0000", fontWeight: "bold" }}>{params.value}</span>}

            </Typography>
          </div>
        );
      }
    },



  ]

  const rows = studentTranscations.filter((item) => {
    return grade === '' ? item : grade === 'ALL' ? item : item?.grade?.includes(grade)

  })
    .filter((item) => {
      return term === '' ? item : term === 'ALL' ? item : item?.term?.includes(term)

    })
    .filter((item) => {
      return status == '' ? item : status === 'ALL' ? item : status === 'Paid' ? item?.invoiceBalance === 0 && item?.invoiceOverPayStatus === false : item?.invoiceStatus?.includes(status)

      // new Date(item.dateOfAdmission).getFullYear() === year
    }).filter((item) => {
      return searchValue.toLowerCase() == '' ? item : item.invoiceNumber?.toLowerCase().includes(searchValue.toLocaleLowerCase())
    }).map((item) => {
      return {
        id: item._id,
        invoiceNumber: item.invoiceNumber,
        grade: item?.grade,
        term: item?.term,
        stream: item?.stream,
        boardingStatus: item?.boardingStatus,
        invoiceAmount: item.invoiceAmount,
        invoiceStatus: item.invoiceStatus,
        invoiceBalance: item?.invoiceBalance,
        invoiceOverPayment: item?.invoiceOverPayment,
        invoiceOverPayStatus: item.invoiceOverPayStatus,
        invoiceDiscountAmount:item?.invoiceDiscountAmount,
        invoiceAmountPaid: item?.invoiceAmountPaid
      }
    })


  const [gradeOptions, setGradeOptions] = useState([])
  const [streamsOptions, setStreamesOptions] = useState([]);
  const [termOptions, setTermOptions] = useState([]);
  console.log('School Type Id', schoolTypeId);
  const GetGrades = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Grades");

    setGradeOptions(data.data.map((itemGrade) => {
      return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
    }
    ))
  };
  const GetStreams = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_streams`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setStreamesOptions(data.data.map((itemStream) => {
        return { value: itemStream.streamName, label: itemStream.streamName }
      }
      ))
    }
  };

  const GetStreamsTerms = async () => {
    const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    console.log(data, "Streams");
    if (response.status === 201) {
      setTermOptions(data.data.map((terms) => {
        return { value: terms.schoolGrades, label: terms.schoolGrades }
      }
      ))
    }
  };
  useEffect(() => {
    if (schoolTypeId) {
      GetGrades();
      GetStreams();
      GetStreamsTerms();
    }

  }, [schoolTypeId]);
  const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
  const getBoardingStatus = async () => {
    const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
      headers: {
        "Content-Type": "application/json",
        "X-Authorization": X_Authorization,
      },
    });
    const data = await response.json();
    // console.log("here store is data",data);
    if (data.status === "SUCCESS") {
      console.log("here Boarding Status", data);
      setBoardingStatusOptions(data.data.map((boardingStatus) => {
        return { value: boardingStatus, label: boardingStatus }
      }
      ))
    } else {
      console.error("Error setting info")
      setErrorShow({ state: true, message: "Error setting info" })
    }

  }
  useEffect(() => {
    getBoardingStatus()
  }, [])

  let totalPartials = 0
  studentTranscations?.map((item) => {
    let invoiceBalance = item?.invoiceBalance
    let invoiceAmount = item?.invoiceAmount

    if (invoiceBalance === 0) {
      totalPartials = 0
    }else if(invoiceBalance !== invoiceAmount && invoiceBalance !== 0){
      let amountPaid = invoiceAmount - invoiceBalance
      
      totalPartials = totalPartials + parseFloat(amountPaid)
    }
  })
  // console.log('totalPartials', totalPartials);








  return (
    <div>
      <div>
        <div>
          <div>
            <div className="d-flex">
              <ArrowBackIosNewIcon style={{ cursor: "pointer" }} onClick={() => { navigate('/school/reports') }} /> <h4>Student Transactions</h4>
            </div>
            <Box component="div" sx={{ marginY: 2, display: "flex", justifyContent: "space-between" }}>
              <Breadcrumbs
                separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                aria-label="breadcrumb">
                {breadcrumbs}
              </Breadcrumbs>
              <Button style={{ backgroundColor: "#f5f6f7", width: "125px", height: "45px", color: "#032541", marginTop: "-25px" }}>View Parent</Button>
            </Box>
          </div>

          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div className="user">
                <div style={{ display: "flex" }}>
                  <div className="profile_name" style={{ width: "80px", height: "80px", padding: "19px 21px 18px 22px", borderRadius: "5px", border: "solid 1px rgba(145, 158, 171, 0.5)", color: "#032541", fontSize: "20px" }}>
                    {`${firstNameLetter}${secondNameLetter}`}
                  </div>
                  <div className="profile_details ml-2" style={{}}>
                    <h5 style={{ fontSize: "24px", fontFamily: "Poppins", color: "#032541", webkitTextStrokeWidth: "1px rgba(0, 0, 0, 0)" }}> {`${firstName} ${secondName}`} </h5>
                    <h6 style={{ fontSize: "14px", fontStyle: "normal", color: "#707070" }}>Admission No: {`${studentID}`}</h6>
                    <h6 style={{ fontSize: "14px", fontStyle: "normal", color: "#707070" }}>Current: {studentGrade} {studentTerm}</h6>
                  </div>
                </div>
              </div>
              <div className="payments">
                <div className="total_payments" style={{ display: 'flex', justifyContent: "space-between", fontSize: "14px" }}>
                  <div className="total mr-5">
                    <h4 style={{ fontSize: "18px" }}>Total Due </h4>
                    <h4 style={{ fontSize: "20px", color: "#dc3545", fontWeight: "bold" }}> {numberFormat(StudentAllData?.totalAmountUnpaid + StudentAllData?.totalAmountPartial)}</h4>
                    <h6 style={{ fontSize: "14px", marginLeft: "18px" }}>{StudentAllData?.countTotalAmountPartial + StudentAllData?.countTotalAmountUnpaid} invoices</h6>
                  </div>
                  <div className="total mr-5">
                    <h4 style={{ fontSize: "18px" }}>UNPAID </h4>
                    <h4 style={{ fontSize: "20px", color: "#111927", fontWeight: "bold" }}> {numberFormat(StudentAllData?.totalAmountUnpaid)}</h4>
                    <h6 style={{ fontSize: "14px", marginLeft: "18px" }}>{StudentAllData?.countTotalAmountUnpaid} invoices</h6>
                  </div>
                  <div className="total mr-5">
                    <h4 style={{ fontSize: "18px" }}>Partially Paid </h4>
                    <h4 style={{ fontSize: "20px", color: "#f79009", fontWeight: "bold" }}> {numberFormat(StudentAllData?.totalAmountPartial)}</h4>
                    <h6 style={{ fontSize: "14px", marginLeft: "18px" }}>{StudentAllData?.countTotalAmountPartial} invoices</h6>
                  </div>
                  <div className="total mr-5">
                    <h4 style={{ fontSize: "18px" }}>Paid </h4>
                    <h4 style={{ fontSize: "20px", color: "#10b981", fontWeight: "bold" }}> {numberFormat(StudentAllData?.totalAmountPaid)}</h4>
                    <h6 style={{ fontSize: "14px", marginLeft: "18px" }}>{StudentAllData?.countTotalAmountPaid} invoices</h6>
                  </div>
                </div>
              </div>

            </div>
            <div className="mt-2" style={{ display: "flex" }}>
              <div className=" mr-2">
                <FormControl style={{ width: "190px", marginTop:"10px", height: "35px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                  <InputLabel id="Invoice Status">Invoice Status</InputLabel>
                  <Select
                    // style={{ height: "60px", width: "140px", border: "solid 1px #bec5d1", backgroundColor: "#fff" }}
                    labelId="Invoice Status"
                    id="year-select"
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}

                    label="Year"
                  >
                    <MenuItem value={"Invoice Status"} disabled>
                      Invoice Status
                    </MenuItem>
                    <MenuItem value={"ALL"}>All</MenuItem>
                    <MenuItem value={"Paid"}>Paid</MenuItem>
                    <MenuItem value={"Partially Paid"}>Partially Paid</MenuItem>
                    <MenuItem value={"Unpaid"}>Unpaid</MenuItem>

                  </Select>
                </FormControl>
              </div>
              <div className="mr-2 ">
                {/* <FormControl style={{ width: "145px", height: "35px", border: "solid 1px #cdd39d9", color: "#fff" }} >
                                    <InputLabel id="Select Grade">Select Grade</InputLabel>
                                    <Select
                                        // style={{ height: "60px", width: "140px", border: "solid 1px #bec5d1", backgroundColor: "#fff" }}
                                        labelId="Select Grade"
                                        id="year-select"
                                        value={grade}
                                        onChange={(e) => setGrade(e.target.value)}

                                        label="Select Grade"
                                    >
                                        <MenuItem value={"Select Grade"} disabled>
                                            Select Grade
                                        </MenuItem>
                                        <MenuItem value={"ALL"}>All</MenuItem>
                                        {Fetchedgrades.map((item) => {
                                                return (
                                                <MenuItem value={item}>{item}</MenuItem>
                                                )
                                        })}


                                    </Select>
                                </FormControl> */}
                <Box component="div" sx={{ marginX: 1 }} >
                  <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={schoolTypeName?.includes("University") ? "Select Year" :"Select Grade"} options={gradeOptions} />

                </Box>


              </div>
              <div className="mr-2">
                {/* <FormControl style={{ width: "135px", height: "35px", border: "solid 1px #cdd39d9", color: "#fff" }}>
                  <InputLabel id="Select Term">Select Term</InputLabel>
                  <Select
                    // style={{ height: "60px", width: "140px", border: "solid 1px #bec5d1", backgroundColor: "#fff" }}
                    labelId="Invoice Status"
                    id="year-select"

                    label="Year"
                    value={term}
                    onChange={(e) => setTerm(e.target.value)}
                  >
                    <MenuItem value={"Select Term"} disabled>
                      Select Term
                    </MenuItem>
                    <MenuItem value={"ALL"}>All</MenuItem>
                    <MenuItem value={"TERM 1"}>Term 1</MenuItem>
                    <MenuItem value={"TERM 2"}>Term 2</MenuItem>
                    <MenuItem value={"TERM 3"}>Term 3</MenuItem>


                  </Select>
                </FormControl> */}
                <Box component="div" sx={{ marginX: 1 }}>
                        <CustomSelectField value={term} onChange={(e) => setTerm(e.target.value)} name={"term"} placeholder={schoolTypeName?.includes("University") ? "Semester" :"Terms"} options={termOptions} />
                        {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                    </Box>
              </div>

              <div className="">
                {/* <Paper
                  component="form"
                  sx={{ p: '6px 8px', marginTop: "5px", display: 'flex', alignItems: 'center', width: 295, height: 45 }}
                >

                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                    placeholder="Search ....."
                    inputProps={{ 'aria-label': 'search .....' }}
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>

                </Paper> */}
                <CustomSearchInput value={searchValue} placeholder={"search invoice number"} onChange={(e) => setSearchValue(e.target.value)} />

              </div>
            </div>

          </div>
        </div>

        {/* Data grid */}
        <Box component={'div'} className="mt-5">
          <DataGrid style={{ height: "500px", width: "auto", overflow: "visible", }}
            components={{ NoRowsOverlay: NoRowsOverlay }}

            rows={rows}
            rowCount={pageState.total}
            loading={pageState.isLoading}
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            pagination
            page={pageState.page - 1}
            pageSize={pageState.pageSize}
            paginationMode="server"
            columns={columns}
            onPageChange={(newPage) => {
              setPageState((old) => ({
                ...old,
                page: newPage + 1,
                pageSize: pageSize,
              }));
            }}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}


          />
        </Box>


      </div>
    </div>
  )
}

export default StudentPaymentHistory