import { useNavigate , useLocation } from 'react-router';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { ExpandLess } from '@material-ui/icons';
import { ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import List from '@material-ui/core/List';
import { Link } from 'react-router-dom';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Groups2Icon from '@mui/icons-material/Groups2';

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function Users(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const userGroup = localStorage.getItem('group');
  const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"


  return (
    <div>
      {/*Users*/}
      <ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
        <div style={{ marginRight: '5%' }}>
          <Groups2Icon />
        </div>
        <ListItemText primary="Users" style={{ fontFamily: 'Poppins',color: open ? '#04f2fc' : '#fff' }} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      {/*Collapsed Users*/}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {userGroup !== 'Admin' && userGroup === 'Merchant' || userGroup === "Owner" ? (
            <>
            <Link style={{ textDecoration: 'none', color: isActiveLink("/users") ? activeColor : '#fff', }} to='/users'>
              <ListItem button className={classes.nested}>
                <ListItemText primary="User Groups" />
              </ListItem>
            </Link>
            <Link style={{ textDecoration: 'none', color: isActiveLink("/users") ? activeColor : '#fff', }} to='/users'>
            <ListItem button className={classes.nested}>
              <ListItemText primary="Users" />
            </ListItem>
          </Link>
          </>
          ) : null}

          {userGroup === 'Admin' ? (
            <Link style={{ color: isActiveLink("/groups") ? activeColor : '#fff', textDecoration: 'none' }}to="/groups">
              <ListItem button className={props.nestedClass}>
                  <ListItemText primary="Groups" />
              </ListItem>
            </Link>
          ) : null}
        </List>
      </Collapse>
    </div>
  );
}
