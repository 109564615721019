import React, { useState, useEffect } from "react";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Dash from "../../../common/dash";
import { Grid, Button, Checkbox, Modal, Tab, styled, TextField, Box, ListItemText, ListItemIcon, ListItemButton, ListItem, List, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper, Alert } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import removeitemdetailedbill from "../../../common/images/removeitemdetailedbill.svg";
import { CheckBox, ArrowBack, Close, FiberManualRecord } from "@mui/icons-material";
import mpesasvg from "../../../common/images/mpesa.svg";
import equitelsvg from "../../../common/images/equitel.svg";
import banksvg from "../../../common/images/bank.svg";
import cardsvg from "../../../common/images/card.svg";
import cardpay from "../../../common/images/cardpay.svg";
import cashsvg from "../../../common/images/cash.svg";
import cashpayment from "../../../common/images/cashpayment.svg";
import emptyState from "../../../common/images/emptystate.svg";
// import equitelsvg from "../../../common/images/equitel.svg";
// import mpesasvg from "../../../common/images/mpesa.svg";
import possvg from "../../../common/images/pos.svg";
import powericon from "../../../common/images/powericon.svg";
import printersvg from "../../../common/images/printer.svg";
import vouchersvg from "../../../common/images/voucher-icn.svg";
import CloseIcon from "@mui/icons-material/Close";
// import removeitemdetailedbill from "../../../common/images/removeitemdetailedbill.svg";
import sendpayrequest from "../../../common/images/sendpayrequest.svg";
import voomasvg from "../../../common/images/vooma.svg";
import approvetick from "../../../common/images/approvetick.svg";
import { MpesaPaymentModal, BankPayModal, CancelVoidTransactionModal, ApproveVoidTransactionModal, ErrorAlertRightAligned } from "../startorderModals/modals";
import { useNavigate, useParams } from "react-router-dom";
import { CreditnotepayModal, ConfirmPayCreditNote } from "../startorderModals/modals";
import HttpComponent from "../../School/MakeRequest";
import creditnote from "../../../common/images/creditnotepay.svg";
import { Requestpayment } from "../requestpayment/requestpayment";
import { SuccessAlertRightAligned } from "../startorderModals/modals";
import VoidPayment from "./voidPayment";
import startOrderFunction from "./startOrderEndpoints";
import { universalStyles } from "../startorderuniversalstyles/styles";

const baseUrl = process.env.REACT_APP_BASE_URL;

const AntTabs = styled(TabList)({
  borderBottom: "3px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#dc3545",
  },
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "13px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const AddProductModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "477px",
  height: "400px",
};
const style = {
  borderColor: "transparent",
  borderRadius: "36px",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "300px",
  height: "300px",
};

const Detailedbill = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [showReturnBill, setShowReturnBill] = useState(true);
  const startOrderMethods = new startOrderFunction();
  // CHANGE TAB VALUE
  const [tabvalue, setNewValue] = useState("requestfund");

  const setNewTabValue = (prev, newvalue) => {
    setNewValue(newvalue);
  };

  // get query params!;
  const queryParams = decodeURIComponent(window.location.search);
  const queryParamValue = queryParams?.slice(1);

  // components toggle;

  const [detailedBillComponent, setDetailedBillComponent] = useState(true);
  const [requestPaymentComponent, setRequestOrder] = useState(false);

  useEffect(() => {
    if (queryParamValue === "voidPayment") {
      setDetailedBillComponent(false);
    } else {
      setDetailedBillComponent(true);
    }
  }, [queryParamValue]);

  // MPESA PAY QUERY
  const [mobilePayQuery, setMobilePayQuery] = useState("");
  const [bankPayQuery, setBankPayQuery] = useState("");
  // SET IF ORDER HAS BEEN PAID;
  const [orderPaid, setIfOrderPaid] = useState("");

  // OPEN PAYMENT MODALS;
  const [openPayModal, setOpenPayModal] = useState(false);

  // SENDING REQUEST TO PHONE;
  const [sendRequest, setSendRequest] = useState(false);

  // SUCCESS REQUEST
  const [successfullyPaid, setSuccessModal] = useState(false);

  // SUCCESS RECIEVED
  const [successfullpay, setsuccessPay] = useState(false);

  // MPESA PAYMENT;
  const [stkpush, setStkPush] = useState(false);

  // BANK PAYMENT;
  const [bankPay, setBankPay] = useState(false);

  // CARD MODAL DETAILS POP UP;
  const [cardModal, setCardModalOpen] = useState(false);
  const [confirmPayDetails, setConfirmPatDetails] = useState(false);

  const [cardTypes, setCardTypes] = useState([]);

  const [getAcquirers, setCardAcquirers] = useState([]);

  const [getCardType, setCardType] = useState("");
  const [refNo, setRefNo] = useState("");
  const [getAcquirer, setAcquirer] = useState("");
  // CARD DISOCUNT
  const [cardDiscount, setToggleCardDiscount] = useState(false);
  const [cardCustomer, setCardCustomerName] = useState("");
  const [cardDiscountAmount, setCardDiscountAmount] = useState(0);

  // CARD PAYMENTS;
  const [togglebankPay, setToggleBankPay] = useState(false);

  // CASH PAYMENT MODAL;
  const [openCashModal, setOpenCashPayment] = useState(false);

  // CONFIRM PAY MODAL;
  const [payDetails, setPayDetails] = useState(false);

  // toggle check box
  const [discount, setToggleDiscount] = useState(false);

  // CONFRIM CARD PAYMENT;
  const [confirmPay, setConfirmPay] = useState(false);

  const [voomapay, setVoomaPay] = useState(false);

  // MPESA PAY
  const [mpesapay, setMpesapay] = useState(false);

  // CASH MODAL
  const [cashModalPayment, setCashModalOpen] = useState(false);

  // PAYMENTDETAILS
  const [cashPaymentDetails, setCashPaymentDetails] = useState(false);
  // CASH DISCOUNT MOIDAL;

  // DSCOUNT CHECK BOX TOGGLE;
  const [checkedDiscount, setCheckedDiscount] = useState(false);
  const setCheckledStatus = () => {
    setCheckedDiscount(!checkedDiscount);
  };
  // GET POS MODAL
  const [posPayment, setPospayment] = useState(false);
  const [showPrinter, setShowPrinter] = useState(false);

  // CASH DISCOUNT;
  const [itemDiscount, setItemDiscount] = useState(0);

  // CASH RECIEVED;
  const [cashRecieved, setCashRecieved] = useState(0);
  // SUCCESFUL RETURN;
  const [successfullreturn, setSuccessfullReturn] = useState(false);
  const [voidDetails, setVoidDetails] = useState(false);

  // CHECK IF CAN PAY;
  const [canPay, setCanPayOrder] = useState(false);

  // EXCESS PAYMENT MODAL
  const [mpesaAmountModal, setMpesaAmountModal] = useState(false);

  // FETCHING MESSAGES
  const [messagesTobeFetched, setMessagesToBeFetched] = useState("");
  const [messagesCollection, setMessagesCollection] = useState([]);
  const [paymentMessageModal, setPaymentMessageModal] = useState(false);
  const [confrimPayModal, setConfirmPayModal] = useState(false);
  const [togglePaymentDiscount, setTogglePaymentDiscount] = useState(false);
  const [allPaymentDiscount, setAllPaymentDiscount] = useState(0);

  //  SET PAYMENT DETAILS;
  const [customerName, setCustomerName] = useState("");
  const [payAmount, setPayAmount] = useState(0);
  const [payNum, setPayNum] = useState("");
  const [payDate, setPayDate] = useState("");
  const [payRef, setPayRef] = useState("");

  const [paymentStatus, setPaymentStatus] = useState("");

  // SET ORDERED ITEMS;
  const [order, setOrder] = useState([]);
  const [orderDetails, setOrderDetails] = useState({
    amount: 0,
    transdate: "",
    cashier: "",
    subtotal: 0,
    vat: 0,
    discAmount: 0,
    deficit: 0,
    status: "",
    customerType: "",
    transactionStatus: "",
    voidedBy: "",
    dateVoided: "",
    dateVoidRequested: "",
    voidRequestedBy: "",
  });

  // console.log('orderDetails  ====<><>', orderDetails?.transactionStatus);

  const [openVoucherPay, setopenVoucherPay] = useState(false);
  const closeVoucherPay = () => setopenVoucherPay(false);

  // SUCCESS BILL MODAL
  const [paySuccess, setpaySuccess] = useState(false);

  // SET POS SERIAL NO;
  const [pos, setPos] = useState([]);
  const [posSerialNo, setPosSerialNo] = useState("");

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  // ADD TO BE DELETED ARRAY;
  const [tobedeletedArray, setTobedeletedArray] = useState([]);
  const [tobedeletedAmount, setAmount] = useState([0]);

  // RETURN ARRAY
  const [returnArray, setReturnArray] = useState([]);
  const [returnedAmount, setReturnedAmount] = useState([]);

  const [cancelorderPrompt, setCancelOrderPrompt] = useState(false);

  const toBeRemovedItem = (item) => {
    const newToBeDeletedArray = [...tobedeletedArray];
    const currentIndex = newToBeDeletedArray.indexOf(item);
    if (currentIndex === -1) {
      newToBeDeletedArray.push(item);
    }

    setTobedeletedArray(newToBeDeletedArray);

    // add to array totals;
    const newToBeletedAmount = [...tobedeletedAmount];

    if (currentIndex === -1) {
      newToBeletedAmount.push(item.itemAmount * item.itemCount);
    }
    setAmount(newToBeletedAmount);
  };

  const handleTobeReturnedItems = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // console.log(name, value);

    setTobedeletedArray(
      tobedeletedArray.map((item) => ({
        ...item,
        ...(name === item.productId ? { itemCount: value, totalAmount: item.itemAmount * value } : {}),
      }))
    );
  };

  const totalsToDelete = tobedeletedAmount.reduce((curr, total) => {
    return (curr += total);
  });

  // console.log(totalsToDelete);

  const returnItems = (item) => {
    const newReturnArray = [...returnArray];
    const productId = item.productId;
    const quantity = parseInt(item.itemCount);
    const itemDelete = { productId, quantity };

    // console.log(productId);
    // const currentIndex = newChecked.findIndex(
    //   (Object) => Object.tobecarriedId === newObject.tobecarriedId
    // );

    const currentIndex = newReturnArray.findIndex((itemDelete) => item.productId === itemDelete.productId);
    if (currentIndex === -1) {
      newReturnArray.push(itemDelete);
    } else {
      newReturnArray.splice(currentIndex, 1);
    }

    setReturnArray(newReturnArray);

    const newReturnAmount = [...returnedAmount];

    const newItemAmount = parseInt(item.itemAmount);
    const newItemId = item.productId;
    const newItemCount = parseInt(item.itemCount);

    const getTotals = { newItemAmount, newItemId, newItemCount };
    const getIndex = newReturnAmount.findIndex((getTotals) => item.productId === getTotals.newItemId);
    if (getIndex === -1) {
      newReturnAmount.push(getTotals);
    } else {
      newReturnAmount.splice(getIndex, 1);
    }
    setReturnedAmount(newReturnAmount);
  };

  let returnedTotal =
    returnedAmount.length > 0 &&
    returnedAmount.reduce((total, curr) => {
      const newTotalAmount = curr.newItemAmount * curr.newItemCount;
      return (total += newTotalAmount);
    }, 0);

  returnedTotal === false ? (returnedTotal = 0) : returnedTotal;

  // set overallTotal;
  const [overallTotal, setOverallTotals] = useState(0);

  // function to set overallTotal;

  const getOverallTotal = () => {
    if (orderDetails.status === "unpaid") {
      setOverallTotals(orderDetails.amount);
    } else if (orderDetails.status === "partial") {
      setOverallTotals(orderDetails.deficit - orderDetails.discAmount);
    }
  };
  useEffect(() => {
    let isCalculated = false;

    if (!isCalculated) {
      getOverallTotal();
    }
    return () => {
      isCalculated = true;
    };
  }, [orderDetails.amount, orderDetails.deficit, orderDetails.discAmount]);

  // console.log(overallTotal);

  const balance = parseInt(cashRecieved) - parseInt(overallTotal - itemDiscount);

  const newCashTotal = parseInt(overallTotal - itemDiscount);

  // console.log(newCashTotal);
  const [mpesaPayModal, setMpesaPayModal] = useState(false);
  const allPayTotals = parseInt(overallTotal) - parseInt(allPaymentDiscount);
  const [allPayAmount, setAllPayAmount] = useState(0);
  const [allPayCustomerName, setAllPayCustomerName] = useState("");
  const [allPayCustomerNumber, setAllPayCustomerNumber] = useState("");
  const [allPayTransId, setAllPayTransId] = useState("");

  var cashtransamount = 0;

  if (Math.sign(balance) === -1) {
    cashtransamount = parseInt(cashRecieved);
  } else if (Math.sign(balance) === 1) {
    cashtransamount = parseInt(newCashTotal);
  } else if (Math.sign(balance) === 0) {
    cashtransamount = parseInt(newCashTotal);
  }

  // OTHER PAYMENT METHODS;
  // MOBILE PAY METNODS;

  const allPaymentsTotal = overallTotal - allPaymentDiscount;
  const allPayBalance = parseInt(allPayAmount) - parseInt(overallTotal - allPaymentDiscount);

  var paytransamount = 0;
  var newPayTransBalance = 0;
  if (Math.sign(allPayBalance) === -1) {
    paytransamount = parseInt(allPayAmount);
    newPayTransBalance = allPayBalance * -1;
  } else if (Math.sign(allPayBalance)) {
    paytransamount = parseInt(allPayTotals);
  } else if (Math.sign(allPayBalance) === 0) {
    paytransamount = parseInt(allPayTotals);
  }

  // CARD PAYMENT;
  const cardTotal = parseInt(overallTotal) - cardDiscountAmount;

  // BANK PAYMENT DETAILS;
  const [bankPayModal, setBankPayModal] = useState(false);
  const [detailedBill, setDetailedBill] = useState(true);

  // const [bankPay, setNewBankPayModal] = useState(false);
  const [bankDetailsDropDown, setBanDetailsDropDown] = useState(true);
  const [bankDetailsMessages, setBankDetailsMessages] = useState(false);

  const [bankList, setBankLists] = useState(true);
  const [bankMessages, setBankMessages] = useState(false);

  const [bankCustomerName, setNewBankCustomerName] = useState("");
  const [bankPayDiscount, setBankpayDiscount] = useState(0);
  const [bankPayName, setBankCustomerName] = useState("");
  const [bankPayAmount, setPayBankAmount] = useState(0);
  const [bankPayNum, setBankPayNum] = useState("");
  const [bankPayDate, setpayBankDate] = useState("");
  const [bankPayRef, setPayBankId] = useState("");

  // BANK PAYMENT DETAILS;
  const bankTotal = overallTotal - bankPayDiscount;
  const bankPayTotal = parseInt(overallTotal) - parseInt(bankPayDiscount);
  const bankPayBalance = parseInt(bankPayAmount) - parseInt(overallTotal - bankPayDiscount);

  // console.log(bankPayBalance);
  // console.log(bankPayAmount);
  var bankAmount = 0;
  var newBankBalance = 0;
  if (Math.sign(bankPayBalance) === -1) {
    bankAmount = parseInt(bankPayAmount);
    newBankBalance = bankPayBalance * -1;
  } else if (Math.sign(bankPayBalance) === 1) {
    bankAmount = parseInt(bankPayTotal);
  } else if (Math.sign(bankPayBalance) === 0) {
    bankAmount = parseInt(bankPayTotal);
  }

  // console.log(bankAmount);
  // console.log(bankPayAmount);

  // console.log('orderDetails.status =====<><><>',  orderDetails.status);

  const businessNo = localStorage.getItem("businessId");

  // CREDIT NOTE PAY;
  // CREDIT NOTE PAYMENT;
  const [openCreditNote, setOpenCreditNote] = useState(false);
  const [creditnotesearch, setCreditnotesearch] = useState("");
  const [creditNoteData, setCreditNoteData] = useState("");
  const [creditNoteError, setCreditNoteError] = useState("");
  const [openConfirmCredit, setopenConfirmCredit] = useState(false);
  const [creditNotedetails, setCreditNoteDetails] = useState({
    amount: 0,
    no: "",
  });
  // get creditnotes ;
  const [creditNoteId, setCreditNoteId] = useState("");
  const [creditNotePayId, setCreditNotePayId] = useState("");

  const searchCreditUrl = () => {
    if (creditnotesearch)
      HttpComponent({
        method: "GET",
        url: `/api/search_credit_note_voucher?voucherNumber=${creditnotesearch}`,
        token: localStorage.getItem("X-Authorization"),
        body: null,
      })
        .then((data) => {
          console.log(data);
          if (data.status === 200) {
            setCreditNoteData(data.response.data);
          }

          if (data.status === 400) {
            setCreditNoteError(data.response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };

  useEffect(() => {
    searchCreditUrl();
  }, [creditnotesearch]);

  // GET DETAILED BILL
  const getDetailedBill = async () => {
    try {
      const getDetailResp = await fetch(baseUrl + "/api/order_payment_status", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          pushyTransactionId: id,
          businessNo: localStorage.getItem("businessId"),
        }),
      });
      await getDetailResp.json().then((data) => {
        const getOrderData = data.order;
        console.log(getOrderData);

        if (getOrderData.status) {
          setIfOrderPaid("true");
          setPaymentStatus(getOrderData.status);
        }

        if (getOrderData.parentOrderId === "") {
          setCanPayOrder(true);
        }
        setOrder(getOrderData.items);

        const { cashier, transamount, subTotal, vat, createdAt, discountAmount, status, customerType, transactionStatus } = getOrderData;
        const { deficit } = data.data;
        const newDate = new Date(createdAt).toLocaleString();

        setOrderDetails({
          ...orderDetails,
          amount: transamount,
          transdate: newDate,
          cashier: cashier,
          subtotal: subTotal.toFixed(2),
          vat: vat.toFixed(2),
          discAmount: discountAmount,
          deficit: deficit,
          status: status,
          customerType: customerType,
          transactionStatus: transactionStatus,
        });
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (id?.length === 24) getDetailedBill();
  }, [id, queryParamValue]);

  // GET TRANSACTION BY TRANSID;

  const getDetailedBillByTransID = () => {

    if (id.length !== 24)
    startOrderMethods?.getBillByTransactionID(id).then((data) => {
      if (data?.status === 200) {
        // console.log("data?.response?.data", data?.response?.data);
        const { items, transamount, discountAmount, voidStatus, voidDeclinedBy, subTotal, vat, cashier, transactionType, transactionCreated, transactionID, status, voidedBy, dateVoided, dateVoidRequested, voidRequestedBy, dateVoidDeclined } = data?.response?.data;

        const newDate = new Date(transactionCreated).toLocaleString();

        setOrder(items);
        setOrderDetails({
          ...orderDetails,
          amount: transamount,
          transdate: newDate,
          cashier: cashier,
          subtotal: subTotal.toFixed(2),
          vat: vat.toFixed(2),
          discAmount: discountAmount,
          // deficit: deficit,
          status: voidStatus || status,
          customerType: transactionType,
          transactionStatus: voidStatus === "REQUEST" ? "AWAITING_VOID_APPROVAL" : voidStatus === "VOIDED" ? "VOIDED" : "PAID",
          voidedBy: voidStatus ===  "DECLINED" ? voidDeclinedBy  : voidedBy,
          dateVoided: voidStatus === "VOIDED" ? new Date(dateVoided).toLocaleString() : new Date(dateVoidDeclined).toLocaleString(),
          dateVoidRequested: new Date(dateVoidRequested).toLocaleString(),
          voidRequestedBy: voidRequestedBy,
          transactionID: transactionID,
          transactionType:transactionType,
          voidDeclinedBy:voidDeclinedBy
        });
      }
    });
  }
  useEffect(() => {
    getDetailedBillByTransID();
  }, [id, queryParamValue]);

  // console.log("orderDetails?.status =====<>?<><><", orderDetails?.status);
  // void details
  useEffect(() => {
    if (orderDetails?.status === "VOIDED") {
      setVoidDetails(true);
    } else if (orderDetails?.status === "DECLINED") {
      setVoidDetails(true);
    } else if (orderDetails?.status === "REQUEST") {
      setVoidDetails(true);
    }
  }, [orderDetails?.status]);

  // FETCH PAYMENT MESSGAES;
  // GET UNUSED MPESA MESSAGES;
  const getUnusedMpesaMessage = async () => {
    try {
      const messagesResp = await fetch(baseUrl + `/api/getAllSms?type=${messagesTobeFetched}&status=Unused`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (messagesResp.status === 200) {
        const messagesData = await messagesResp.json();
        // console.log(messagesData);
        setMessagesCollection(messagesData.sms);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    getUnusedMpesaMessage();
  }, [messagesTobeFetched]);

  // clear used messages;
  const unUseMessage = async (sms) => {
    // console.log(sms.sms);
    try {
      const unUseMessoResp = await fetch(baseUrl + "/api/updatePosSms", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          sms: sms.sms,
        }),
      });
      // const unUseMessoRespData = await unUseMessoResp.json();
    } catch (error) {
      console.log(error.message);
    }
  };

  // CANCEL A BILL
  const cancelBill = async () => {
    try {
      const cancelBillResp = await fetch(baseUrl + `/api/cancelPushyTransaction/?_id=${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      // console.log(cancelBillResp);
      navigate("/orders/cancelledbills");
    } catch (error) {
      console.log(error.message);
    }
  };

  // RETURN A BILL;
  const returnBill = async () => {
    try {
      const returnBillResp = await fetch(baseUrl + "/api/create_order_return", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          billId: id,
          itemIds: returnArray,
        }),
      });

      if (returnBillResp.status === 201) {
        setSuccessfullReturn(true);

        setTimeout(() => {
          setSuccessfullReturn(false);
          navigate("/orders");
        }, 2000);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // GET POS;
  const getPos = async () => {
    try {
      const getPosResp = await fetch(baseUrl + "/api/allTerminals/Active", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (getPosResp.status === 200) {
        const posS = await getPosResp.json();

        setPos(posS.data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getPos();
  }, []);

  // SEND DATA TO PRINTER

  const sendToPrinter = async () => {
    const sendToPrinterResp = await fetch(baseUrl + "/api/sendPushy", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": " *",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
      body: JSON.stringify({
        pushTransactionId: id,
        serialNo: posSerialNo,
      }),
    });

    if (sendToPrinterResp.status === 409) {
      setSendRequest(false);
      setSuccessModal(true);
    }
  };

  // GET CARD TYPES;
  const getCardTypes = async () => {
    try {
      const getCardsResp = await fetch(baseUrl + "/api/getCards", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      const cardData = await getCardsResp.json();
      setCardTypes(cardData.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    getCardTypes();
  }, []);

  // GET ACQUIRER;
  const getAcquirersCard = async () => {
    const getAcquirersResp = await fetch(baseUrl + "/api/get_acquirer_networks", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    const acquirerData = await getAcquirersResp.json();

    setCardAcquirers(acquirerData.data);
  };

  useEffect(() => {
    getAcquirersCard();
  }, []);

  // PAY UNPAID BILLS;

  const payBills = async () => {
    try {
      const executePayResp = await fetch(baseUrl + "/api/transactions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          appBuildTime: "N/A",
          billRefNo: Date.now(),
          businessName: localStorage.getItem("businessName"),
          businessNo: localStorage.getItem("businessId"),
          businessShortCode: "0000",
          cashier: localStorage.getItem("username"),
          customerFirstName: messagesTobeFetched === "COOP" ? bankPayName : messagesTobeFetched === "ABSA" ? bankPayName : messagesTobeFetched === "DTB" ? bankPayName : messagesTobeFetched === "MPESA" ? allPayCustomerName : messagesTobeFetched === "VOOMA" ? allPayCustomerName : messagesTobeFetched === "EQUITEL" ? allPayCustomerName : messagesTobeFetched === "CARD" ? cardCustomer : " ",
          customerMiddleName: "N/A",
          customerPhone: "N/A",
          customerSecondName: "N/A",
          items: order,
          paybillBalance: 0,
          discountAmount: messagesTobeFetched === "COOP" ? parseInt(bankPayDiscount) : messagesTobeFetched === "ABSA" ? parseInt(bankPayDiscount) : messagesTobeFetched === "DTB" ? parseInt(bankPayDiscount) : messagesTobeFetched === "MPESA" ? parseInt(allPaymentDiscount) : messagesTobeFetched === "VOOMA" ? parseInt(allPaymentDiscount) : messagesTobeFetched === "EQUITEL" ? parseInt(allPaymentDiscount) : messagesTobeFetched === "CARD" ? parseInt(cardDiscountAmount) : parseInt(itemDiscount),
          paymentChanel: "Web",
          productCategory: "--",
          productName: "--",
          pushyTransactionId: [id],
          receiptNumber: Date.now(),
          requestType: "N/A",
          serialNo: " N/A",
          transactionID: Date.now(),
          transactionType: messagesTobeFetched === "COOP" ? "COOP" : messagesTobeFetched === "ABSA" ? "ABSA" : messagesTobeFetched === "DTB" ? "DTB Payment" : messagesTobeFetched === "MPESA" ? "Mpesa Payment" : messagesTobeFetched === "VOOMA" ? "Vooma Payment" : messagesTobeFetched === "EQUITEL" ? "Equity Payment" : messagesTobeFetched === "CARD" ? "Card Payment" : "Cash Payment",
          transamount: messagesTobeFetched === "COOP" ? parseInt(bankAmount) : messagesTobeFetched === "ABSA" ? parseInt(bankAmount) : messagesTobeFetched === "DTB" ? parseInt(bankAmount) : messagesTobeFetched === "MPESA" ? paytransamount : messagesTobeFetched === "VOOMA" ? paytransamount : messagesTobeFetched === "EQUITEL" ? paytransamount : messagesTobeFetched === "CARD" ? cardTotal : parseInt(cashtransamount),
          transtime: new Date(Date.now()),
          uploadTime: new Date(Date.now()),
          userId: localStorage.getItem("userId"),
          versionCode: "webv1",
          versionName: "webv1",
        }),
      });

      // console.log(executePayResp);
      if (executePayResp.status === 201) {
        setCustomerName("");
        setPayAmount(0);
        setPayNum("");
        setPayDate("");
        setPayRef("");
        setpaySuccess(true);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  // search voucher7
  const [searchedVoucher, setSearchedVoucher] = useState();
  const [resultSearch, setresultSearch] = useState(false);
  const [searchVoucherMessage, setsearchVoucherMessage] = useState("");
  const [VoucherInfomessage, setVoucherInfomessage] = useState("");
  const [titleMessage, settitleorderDetailsMessage] = useState("Confirmation Message");
  const [voucherValidationOpen, setvoucherValidationOpen] = React.useState(false);
  const ClosevoucherValidationClose = () => setvoucherValidationOpen(false);

  const [confirmationVoucherOPen, setconfirmationVoucherOPen] = React.useState(false);
  const closeconfirmationVoucher = () => setconfirmationVoucherOPen(false);
  const [voucherNumber, setvoucherNumber] = useState("");

  const searchVoucher = async () => {
    try {
      const res = await fetch(`${baseUrl}/api/search_voucher_by_voucher_number?voucherNumber=${voucherNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await res.json();
      // console.log(data);
      // console.log(data.status, "Status");
      if (data.status == "Success") {
        setresultSearch(true);
        setSearchedVoucher(data.data);
      } else {
        setsearchVoucherMessage(data.message);
        setresultSearch(false);
      }
      //  console.log(data.data, 'data.............search')
    } catch {}
  };
  useEffect(() => {
    if (voucherNumber != "") {
      searchVoucher();
    }
  }, [voucherNumber]);

  const executePayVoucher = async () => {
    const url = window.location.href;
    const url_parts = url.split("/");
    const last_item_id = url_parts[url_parts.length - 1];
    const OrderIds = last_item_id;

    const voucherIds = creditNoteId ? "" : searchedVoucher._id;

    const voucherBody = {
      transactionType: "Voucher",
      appBuildTime: new Date(Date.now()),
      orderIds: [OrderIds],
      versionName: "webv1",
      voucherIds: [voucherIds],
      versionCode: "webv1",
      paymentChanel: "Web",
      serialNo: " N/A",
    };

    const creditNotebody = {
      transactionType: "CreditNote",
      appBuildTime: new Date(Date.now()),
      orderIds: [id],
      versionName: "webv1",
      creditNoteId: creditNoteId,
      versionCode: "webv1",
      paymentChanel: "Web",
      serialNo: " N/A",
    };

    try {
      const savePayment = await fetch(baseUrl + "/api/v1/payments/pay", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify(creditNoteId ? creditNotebody : voucherBody),
      });

      if (savePayment.status === 200) {
        setpaySuccess(true);
        setopenVoucherPay(false);
        setconfirmationVoucherOPen(false);
        const paidData = await savePayment.json();
      }
    } catch (error) {
      console.log(error.message);
    }
  };

    // HANDLE PRINT BUTTONS;
    const showPrintButtton = orderDetails?.status === "paid" || orderDetails?.status === "partial" || orderDetails?.status === "unpaid";

      // approve decline modal;
  const [cancelVoidModal, setCancelPayModal] = useState(false);
  const [cancelVoidDetails, setCancelVoidDetails] = useState({amount: 0, transType:'', voidId:''});

  // console.log('cancelVoidDetails ====<><<><>', cancelVoidDetails);
  const [sucess, setSucess] = useState({ state: false, message: "" });
  const [error, setShowError] = useState({state: false, message:''})
  const approveCancel = () => {
    setCancelVoidDetails({...cancelVoidDetails, amount: orderDetails?.amount, transType: orderDetails?.transactionType, voidId: orderDetails?.transactionID });
    setCancelPayModal(true)

  }

  const doDeclineVoidPayment = () => {
    if(cancelVoidDetails?.voidId)
    startOrderMethods.cancelVoidTransactions(cancelVoidDetails?.voidId).then((data) => {
      if(data?.status === 200 || data?.status === 201){
        setCancelVoidDetails({...cancelVoidDetails , amount: 0, transType:'', voidId:''});
        setCancelPayModal(false)

        setSucess({ ...sucess, state: true, message: "Void transaction request approved successfully!" });

        setTimeout(() => {
          setSucess({ ...sucess, state: false, message: "" });
        }, 3000);

        getDetailedBillByTransID()
      }else if(data?.status === 400){

        setShowError({state: true, message: data?.response?.message});
        setCancelPayModal(false)
        setTimeout(() => {
          setShowError({state: false})
        }, 3000);
      }
    })
  }

    // approve void modal;
    const [approveVoidModal, setApproveVoidModal] = useState(false);
    const [newVoidDetails, setNewVoidDetails] = useState({ amount: 0, transType: "", voidId: "" });
    const approveVoidPay = (trans) => {
      setNewVoidDetails({ ...newVoidDetails, amount: orderDetails?.amount, transType: orderDetails?.transactionType, voidId: orderDetails?.transactionID  });
  
      setApproveVoidModal(true);
    };
  
    const doApproveVoidPayments = () => {
      if (newVoidDetails?.voidId)
        startOrderMethods.voidTransactions(newVoidDetails?.voidId).then((data) => {
          if (data?.status === 200 || data?.status === 201) {
            setApproveVoidModal(false);
            setVoidDetails({ ...newVoidDetails, amount: 0, transType: "", voidId: "" });
  
            setSucess({ ...sucess, state: true, message: "Void transaction request approved successfully!" });
  
            setTimeout(() => {
              setSucess({ ...sucess, state: false, message: "" });
            }, 3000);
  
            getDetailedBillByTransID();
          }else if(data?.status === 400){

            setShowError({state: true, message: data?.response?.message});
            setApproveVoidModal(false);
            setTimeout(() => {
              setShowError({state: false})
            }, 3000);
          }
        });
    };

    const businessCategory = localStorage.getItem("businessCategory");

    const showBillsBar = businessCategory && businessCategory !== 'School'

    console.log('orderDetails?.voidedBy ===<><', orderDetails?.voidedBy);

  return (
    <div>
      {queryParamValue === "voidPayment" && <VoidPayment />}

      {detailedBillComponent && (
        <>

        
          <div className="container-fluid content" style={{ padding: "20px" }}>

            {
              showBillsBar  ?             <Grid
              container
              style={{
                width: "100%",
                margin: "0px 0px 20px 0px",
                backgroundColor: "#f9f9f8",
                padding: "7px",
              }}
              alignItems="center"
              direction={"row"}
              justifyContent={"space-between"}
            >
              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/orders/unpaidbills`);
                  }}
                >
                  Unpaid Bills
                </span>
              </Grid>
              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/orders/paidbills`);
                  }}
                >
                  Paid Bills
                </span>
              </Grid>

              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/orders/partialpayment`);
                  }}
                >
                  Partial Payments
                </span>
              </Grid>

              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/orders/cancelledbills`);
                  }}
                >
                  Cancelled Bills
                </span>
              </Grid>

              <Grid item>
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/orders/report`);
                  }}
                >
                  Reports
                </span>
              </Grid>
            </Grid>
  : null
            }

            <Grid container direction={"row"} alignItems={"center"} gap={1} margin={"0px 0px 3% 0px"}>
              <Grid item xs={6}>
                <Grid container direction={"row"} style={{ width: "100%" }} justifyContent={"space-between"} margin="0px 0px 2% 0px">
                  <Grid item>
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#dc3545",
                      }}
                    >
                      Detailed Bill
                    </span>
                  </Grid>

                  {localStorage.getItem("group") !== "Cashier" && (
                    <>
                      {paymentStatus === "unpaid" && orderDetails.customerType !== "customeronaccounts" ? (
                        <Grid>
                          <Button
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              cursor: "pointer",
                              backgroundColor: "#dc3545",
                              color: "#ffffff",
                            }}
                            onClick={() => setCancelOrderPrompt(true)}
                          >
                            {" "}
                            Cancel Order
                          </Button>
                        </Grid>
                      ) : paymentStatus === "partial" ? (
                        <Grid>
                          <Button
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "500",
                              cursor: "pointer",
                              backgroundColor: "#dc3545",
                              color: "#ffffff",
                            }}
                            onClick={cancelBill}
                          >
                            {" "}
                            Cancel Order
                          </Button>
                        </Grid>
                      ) : null}
                    </>
                  )}
                </Grid>

                <Grid container padding={"10px 0px 10px 20px"} style={orderDetails.transactionStatus === "AWAITING_VOID_APPROVAL" ? { width: "100%", backgroundColor: "#f9f9f8", color: "#000000", fontWeight: "bold" } : orderDetails?.transactionStatus === "VOIDED" ? { width: "100%", backgroundColor: "rgba(220, 53, 69, 0.1)", color: "#dc3545", fontWeight: "bold" } : orderDetails.transactionStatus === "NORMAL" && orderDetails?.status === "unpaid" ? { width: "100%", backgroundColor: "rgba(220, 53, 69, 0.1)", color: "#dc3545", fontWeight: "bold" } : { backgroundColor: "rgba(23, 174, 123, 0.1)", color: "#17ae7b", fontWeight: "bold" }}>
                  <Grid item>
                    {orderDetails.transactionStatus === "AWAITING_VOID_APPROVAL" ? <span>Status: Void Request Pending Approval </span> : orderDetails.transactionStatus === "VOIDED" ? "Voided" : orderDetails.transactionStatus === "NORMAL" && orderDetails?.status === "paid" ? "Paid" : orderDetails.transactionStatus === "NORMAL" && orderDetails?.status === "unpaid" ? "unPaid" : 'Paid'}
                    {/* <span>Status: Voided </span> */}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={5.8}>
                <Grid container direction="row" display={"flex"} justifyContent={"flex-end"} gap={"20px"} alignItems={"center"}>
                  {paymentStatus === "unpaid" && orderDetails.customerType !== "customeronaccounts" ? (
                    <>
                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#032541",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                          }}
                          onClick={() => setOpenPayModal(true)}
                        >
                          PAY NOW
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#032541",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                          }}
                          onClick={() => navigate(`/orders/requestPayment?startOrderTag?${id}?${overallTotal}`)}
                        >
                          Request Payment
                        </Button>
                      </Grid>
                    </>
                  ) : paymentStatus === "partial" ? (
                    <>
                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#032541",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                          }}
                          onClick={() => setOpenPayModal(true)}
                        >
                          PAY NOW
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#032541",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                          }}
                          onClick={() => navigate(`/orders/requestPayment?startOrderTag?${id}?${overallTotal}`)}
                        >
                          Request Payment
                        </Button>
                      </Grid>
                    </>
                  ) : paymentStatus === "partial" || orderDetails?.status === "ACTIVE" || (paymentStatus === "paid" && orderDetails.transactionStatus !== "AWAITING_VOID_APPROVAL") ? (
                    <Grid item>
                      <Button
                        style={{
                          borderRadius: "4px",
                          backgroundColor: "#fffff",
                          color: "#dc3545",
                          fontFamily: "Poppins",
                          border: "1px solid #dc3545",
                        }}
                        onClick={() => navigate(`/detailedbill/${id}?voidPayment`)}
                      >
                        Void Transaction
                      </Button>
                    </Grid>
                  ) : orderDetails?.status === "REQUEST" ? (
                    <>
                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#dc3545",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                            border: "1px solid #dc3545",
                          }}
                          onClick={() => approveCancel()}
                        >
                          Decline
                        </Button>
                      </Grid>

                      <Grid item>
                        <Button
                          style={{
                            borderRadius: "4px",
                            backgroundColor: "#17ae7b",
                            color: "#ffffff",
                            fontFamily: "Poppins",
                            
                          }}
                          onClick={() => approveVoidPay()}
                        >
                          Approve
                        </Button>
                      </Grid>
                    </>
                  ) : null}

                  {showPrintButtton &&  <Grid item>
                    <Button
                      style={{
                        borderRadius: "4px",
                        backgroundColor: "#032541",
                        color: "#ffffff",
                        fontFamily: "Poppins",
                      }}
                      onClick={() => setPospayment(true)}
                    >
                      Print Bill
                    </Button>
                  </Grid> }
      
                </Grid>
              </Grid>
            </Grid>

            <Grid container>
              {/* LEFT SIDE GRID */}
              <Grid item xs={6} style={{ margin: "0px 30px 0px 0px" }}>
                <Grid
                  container
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    boxShadow: "0 3px 6px 0 #d9f6ec",
                  }}
                >
                  <Grid
                    container
                    style={{
                      width: "100%",
                      padding: "10px",
                      margin: "0px 0px 10px 0px",
                    }}
                    justifyContent={"space-between"}
                  >
                    <Grid item>
                      {" "}
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                        }}
                      >
                        Transaction Date:
                      </span>{" "}
                    </Grid>

                    <Grid item>
                      {" "}
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: "500",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                        }}
                      >
                        {orderDetails.transdate.split(",")}
                      </span>{" "}
                    </Grid>
                  </Grid>

                  {/* lopp through to display Items on list */}
                  <Grid container>
                    <TableContainer>
                      <Table sx={{ width: "100%" }}>
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                            {/* <TableCell>Category</TableCell> */}
                            <TableCell style={{ fontWeight: "bold" }}>Amount(KES)</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Total(KES)</TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {order?.map((item, index) => {
                            const { itemName, itemCategory, itemAmount, itemCount, productId, reciptNumber, status, totalAmount } = item;

                            if (Math.sign(itemCount) === -1) {
                              return (
                                <TableRow
                                  style={{
                                    backgroundColor: "rgba(220,53,69,.4)",
                                  }}
                                  key={index}
                                >
                                  <TableCell>{itemName}</TableCell>
                                  {/* <TableCell>{product.productCategory}</TableCell> */}
                                  <TableCell> {itemAmount} </TableCell>
                                  <TableCell>{itemCount}</TableCell>
                                  <TableCell>{totalAmount}</TableCell>
                                  <TableCell>
                                    {/* {" "}
                                {paymentStatus === "unpaid" && (
                                  <span onClick={() => toBeRemovedItem(item)}>
                                    <img style={{ cursor: "pointer" }} src={removeitemdetailedbill} />
                                  </span>
                                )} */}
                                  </TableCell>
                                </TableRow>
                              );
                            } else {
                              return (
                                <TableRow key={index}>
                                  <TableCell>{itemName}</TableCell>
                                  {/* <TableCell>{product.productCategory}</TableCell> */}
                                  <TableCell> {itemAmount} </TableCell>
                                  <TableCell>{itemCount}</TableCell>
                                  <TableCell>{totalAmount}</TableCell>
                                  <TableCell>
                                    {localStorage.getItem("group") !== "Cashier" && paymentStatus === "unpaid" && (
                                      <span onClick={() => toBeRemovedItem(item)}>
                                        <img style={{ cursor: "pointer" }} src={removeitemdetailedbill} />
                                      </span>
                                    )}
                                  </TableCell>
                                </TableRow>
                              );
                            }
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Grid>

                  <Grid container style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        SubTotal
                      </span>
                    </Grid>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        {numberFormat(orderDetails.subtotal)}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container direction={"row"} style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        Discount:
                      </span>
                    </Grid>

                    <Grid item>
                      {" "}
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        {numberFormat(orderDetails.discAmount)}
                      </span>{" "}
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#dc3545",
                        }}
                      >
                        VAT 16%{" "}
                      </span>
                    </Grid>

                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#dc3545",
                        }}
                      >
                        {numberFormat(orderDetails.vat)}
                      </span>
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        Total Amount
                      </span>
                    </Grid>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        {numberFormat(orderDetails.amount - orderDetails.discAmount)}
                      </span>
                    </Grid>
                  </Grid>

                  {orderDetails.status === "partial" && (
                    <>
                      {" "}
                      <Grid container style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                        <Grid item>
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "17px",
                              fontWeight: "bold",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "1",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#032541",
                            }}
                          >
                            Deficit
                          </span>
                        </Grid>
                        <Grid item>
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "17px",
                              fontWeight: "bold",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "1",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#032541",
                            }}
                          >
                            {numberFormat(orderDetails.deficit - orderDetails.discAmount)}
                          </span>
                        </Grid>
                      </Grid>{" "}
                    </>
                  )}

                  <Grid container style={{ width: "100%", padding: "10px" }} justifyContent={"space-between"}>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        Served By:
                      </span>
                    </Grid>

                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "17px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#032541",
                        }}
                      >
                        {orderDetails.cashier}
                      </span>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              {/*  RIGHT SIDE GRID REMOVE ITEMS*/}

              {voidDetails && (
                <>
                  <Grid item xs={5}>
                    <Grid container width={"100%"}>
                      <Grid item width={"100%"} margin={"0 0 2% 0"}>
                        <span style={{ color: "red", fontWeight: "bold" }}> Void Details</span>
                      </Grid>

                      <Grid item width={"100%"} margin={"0 0 2% 0"} display={"flex"} color={"#707070"} alignItems={"center"} justifyContent={"space-between"}>
                        <span>Voided By</span>
                        <span>{orderDetails?.voidRequestedBy}</span>
                      </Grid>

                      <Grid item width={"100%"} margin={"0 0 2% 0"} display={"flex"} color={"#707070"} alignItems={"center"} justifyContent={"space-between"}>
                        <span>Voided On</span>
                        <span> {orderDetails?.dateVoidRequested} </span>
                      </Grid>

                      {orderDetails?.status !== "REQUEST" && (
                        <Grid item width={"100%"} margin={"0 0 2% 0"} display={"flex"} color={"#707070"} alignItems={"center"} justifyContent={"space-between"}>
                          <span> {orderDetails?.status === "VOIDED" ? "Approved By" : "Declined By"}</span>
                          <span>  {orderDetails?.voidedBy} </span>
                        </Grid>
                      )}

                      {orderDetails?.status !== "REQUEST" && (
                        <Grid item width={"100%"} margin={"0 0 2% 0"} display={"flex"} color={"#707070"} alignItems={"center"} justifyContent={"space-between"}>
                          <span> {orderDetails?.status === "VOIDED" ? "Approved On" : "Declined On"} </span>
                          <span> {orderDetails?.dateVoided} </span>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}

              {showReturnBill && (
                <>
                  {tobedeletedArray.length > 0 ? (
                    <Grid item xs={5}>
                      <TableContainer>
                        <Table sx={{ width: "100%" }}>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ fontWeight: "bold" }}>Product Name</TableCell>
                              {/* <TableCell>Category</TableCell> */}
                              <TableCell style={{ fontWeight: "bold" }}>Amount(KES)</TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>Quantity</TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>Total(KES)</TableCell>
                              <TableCell style={{ fontWeight: "bold" }}>Action</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            {tobedeletedArray.map((item, index) => {
                              const { itemName, itemCategory, itemAmount, itemCount, productId, reciptNumber, status, totalAmount } = item;
                              return (
                                <TableRow key={index}>
                                  <TableCell>{itemName}</TableCell>
                                  {/* <TableCell>{product.productCategory}</TableCell> */}
                                  <TableCell> {itemAmount} </TableCell>
                                  <TableCell>
                                    {" "}
                                    <input
                                      onChange={handleTobeReturnedItems}
                                      value={itemCount}
                                      name={productId}
                                      id={productId}
                                      type="number"
                                      style={{
                                        border: "",
                                        color: "#9c9ea4",
                                        outline: "none",
                                        fontSize: "12px",
                                        width: "100%",
                                        margin: "0px 10px 0px 0px",
                                      }}
                                    />
                                  </TableCell>
                                  <TableCell>{totalAmount}</TableCell>
                                  <TableCell>
                                    <Checkbox onClick={() => returnItems(item)} />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </TableContainer>

                      <Grid
                        container
                        style={{
                          boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                          backgroundColor: "#fff",
                          margin: "0px 0px 20px 0px",
                        }}
                      >
                        <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ padding: "10px" }}>
                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#6e7074",
                              }}
                            >
                              Total Amount
                            </span>
                          </Grid>

                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#032541",
                              }}
                            >
                              {numberFormat(totalsToDelete)}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ padding: "10px" }}>
                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#6e7074",
                              }}
                            >
                              Number of Items
                            </span>
                          </Grid>

                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#032541",
                              }}
                            >
                              {tobedeletedArray.length}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ padding: "10px" }}>
                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#6e7074",
                              }}
                            >
                              Returned Items
                            </span>
                          </Grid>

                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#032541",
                              }}
                            >
                              {returnArray.length}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ padding: "10px" }}>
                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#6e7074",
                              }}
                            >
                              Current Amount
                            </span>
                          </Grid>

                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "20px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#032541",
                              }}
                            >
                              {numberFormat(totalsToDelete - returnedTotal)}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>

                      {returnArray.length > 0 && (
                        <Grid container justifyContent={"center"} alignItems={"center"} margin="0px 0px 20px 0px">
                          <Grid item>
                            <Button
                              style={{
                                borderRadius: "5px",
                                border: "solid 1px #dc3545",
                                color: "#dc3545",
                                margin: "0px 20px 0px 0px",
                              }}
                              onClick={() => setTobedeletedArray([])}
                            >
                              Cancel
                            </Button>
                          </Grid>

                          <Grid item>
                            <Button
                              style={{
                                borderRadius: "5px",
                                backgroundColor: "#032541",
                                color: "#ffffff",
                                margin: "0px 0px 0px 20px",
                              }}
                              onClick={returnBill}
                            >
                              Return
                            </Button>
                          </Grid>
                        </Grid>
                      )}

                      {successfullreturn && (
                        <Grid container justifyContent={"center"}>
                          <Grid item>
                            <Alert severity="success">Changes saved successfully!!</Alert>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ) : null}
                </>
              )}

              {/* STK PUISH PAYMENTS */}
              {stkpush && (
                <Grid xs={5}>
                  <Grid container style={{ width: "100%", paddingLeft: "20px" }}>
                    <Grid item style={{ width: "100%" }}>
                      <TabContext value={tabvalue}>
                        <AntTabs onChange={setNewTabValue}>
                          <AntTab label="Request Fund" value="requestfund" />
                          <AntTab label="Payment Done" value="paymentdone" />
                        </AntTabs>

                        <TabPanel value="requestfund">
                          <Box
                            component="form"
                            sx={{
                              "& > :not(style)": { width: "100%" },
                            }}
                            noValidate
                            autoComplete="off"
                            style={{ margin: "0px 0px 20px 0px" }}
                          >
                            <TextField id="outlined-basic" label="Customer's Phone No" variant="outlined" placeholder="Enter customer's phone number" />
                          </Box>

                          <Grid container justifyContent={"center"} alignItems={"center"} margin="0px 0px 20px 0px">
                            <Grid item>
                              <Button
                                style={{
                                  borderRadius: "5px",
                                  border: "solid 1px #dc3545",
                                  color: "#dc3545",
                                  margin: "0px 20px 0px 0px",
                                }}
                              >
                                Close
                              </Button>
                            </Grid>

                            {/* trigger push notification */}
                            <Grid item>
                              <Button
                                style={{
                                  borderRadius: "5px",
                                  backgroundColor: "#032541",
                                  color: "#ffffff",
                                  margin: "0px 0px 0px 20px",
                                }}
                              >
                                Pay Now
                              </Button>
                            </Grid>
                          </Grid>
                        </TabPanel>

                        <TabPanel value="paymentdone">
                          <Grid container style={{ width: "100%" }} justifyContent="flex-end">
                            <Grid item style={{ width: "100%" }}>
                              <span style={{ textAlign: "right" }}>
                                <Checkbox /> Discount
                              </span>
                            </Grid>
                          </Grid>

                          <Grid container style={{ width: "100%" }}>
                            <Grid item></Grid>
                          </Grid>

                          {/* list items */}
                          <Grid container>
                            <Grid item>
                              <List
                                sx={{
                                  width: "100%",
                                  bgcolor: "background.paper",
                                }}
                              >
                                <ListItem
                                  // key={bill._id}
                                  sx={{
                                    // border: "1px solid",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "flex-start",
                                    width: "100%",
                                  }}
                                  component="div"
                                  // value={listItemValue}
                                >
                                  <ListItemButton
                                    sx={{
                                      border: "1.5px solid #e1d6d6",
                                      borderRadius: "6px",
                                      width: "100%",
                                    }}
                                  >
                                    <ListItemIcon
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "0px 10px 0px 0px",
                                      }}
                                    >
                                      <FiberManualRecord
                                        edge="start"
                                        style={{
                                          color: "#17ae7b",
                                          fontSize: "10px",
                                        }}
                                      />
                                    </ListItemIcon>

                                    <Checkbox />

                                    <ListItemText
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "2.2",
                                        letterSpacing: "normal",
                                        textAlign: "left",
                                        color: "#6e7074",
                                      }}
                                      primary={``}
                                    />

                                    <ListItemText
                                      style={{
                                        fontFamily: "Poppins",
                                        fontSize: "15px",
                                        fontWeight: "500",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "2.2",
                                        letterSpacing: "normal",
                                        textAlign: "right",
                                        color: "#6e7074",
                                      }}
                                      primary={``}
                                    />
                                  </ListItemButton>
                                </ListItem>
                              </List>
                            </Grid>
                          </Grid>
                        </TabPanel>
                      </TabContext>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* STK PUISH PAYMENTS */}
              {mpesapay && (
                <Grid xs={5} style={{ height: "65vh" }}>
                  <Grid container style={{ width: "100%", paddingLeft: "20px" }}>
                    <Grid item style={{ width: "100%" }}>
                      {/* <Grid
                    container
                    style={{ width: "100%" }}
                    justifyContent="flex-end"
                  >
                    <Grid item style={{ width: "100%" }}>
                      <span style={{ textAlign: "right" }}>
                        <Checkbox /> Discount
                      </span>
                    </Grid>
                  </Grid> */}

                      <Grid
                        container
                        style={{
                          paddingLeft: "20px",
                          width: "100%",
                          paddingBottom: "20px",
                        }}
                        direction="row"
                        justifyContent="space-between"
                      >
                        <Grid
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "bold",
                              width: "50%",
                            }}
                          >
                            Total = {numberFormat(overallTotal - allPaymentDiscount)}
                          </span>
                          <span>
                            Discount <Checkbox onClick={() => setTogglePaymentDiscount(!togglePaymentDiscount)} />
                          </span>
                        </Grid>
                        {messagesTobeFetched === "MPESA" && (
                          <Grid item style={{ margin: "0px 0px 20px 0px" }}>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "15px",
                                fontWeight: "600",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                // textAlign: "left",
                                color: "#dc3545",
                              }}
                            >
                              Mpesa Payment
                            </span>
                          </Grid>
                        )}

                        {messagesTobeFetched === "EQUITEL" && (
                          <Grid item>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "15px",
                                fontWeight: "600",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                // textAlign: "left",
                                color: "#dc3545",
                              }}
                            >
                              Equitel Payment
                            </span>
                          </Grid>
                        )}

                        {messagesTobeFetched === "VOOMA" && (
                          <Grid item style={{ margin: "0px 0px 20px 0px" }}>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "15px",
                                fontWeight: "600",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                // textAlign: "left",
                                color: "#dc3545",
                              }}
                            >
                              Vooma Payment
                            </span>
                          </Grid>
                        )}

                        <Grid item>
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "600",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal", // textAlign: "left",
                              // color: "#dc3545",
                              margin: "0px 10px 0px 0px",
                            }}
                          >
                            Search:
                          </span>
                          <input style={{ outline: "none" }} placeholder="Search..." onChange={(e) => setMobilePayQuery(e.target.value)} />
                        </Grid>

                        {togglePaymentDiscount && (
                          <Grid item style={{ width: "100%" }}>
                            <TextField style={{ outline: "none", width: "100%" }} type="number" placeholder="" helperText="Discount" onChange={(e) => setAllPaymentDiscount(e.target.value)} />
                          </Grid>
                        )}
                      </Grid>

                      {/* list items */}
                      <Grid container style={{ width: "100%" }}>
                        <Grid
                          item
                          style={{
                            width: "100%",
                            height: "70vh",
                            overflow: "auto",
                          }}
                        >
                          <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                            {/* LOOP THROUGH MESSAGES TO MAP */}
                            {messagesCollection
                              ?.filter((messo) => {
                                if (mobilePayQuery.toLocaleLowerCase() === "") {
                                  return messo;
                                } else if (messo.transactionID.toLocaleLowerCase().includes(mobilePayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(mobilePayQuery)) {
                                  return messo;
                                }
                              })
                              .map((messo) => {
                                const { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;

                                function replaceAtMultipleIndexes(str, indexes, newValue) {
                                  let result = str;
                                  for (let i = 0; i < indexes.length; i++) {
                                    const index = indexes[i];
                                    result = result.substr(0, index) + newValue + result.substr(index + newValue.length);
                                  }
                                  return result;
                                }
                                let myString = phoneNumber;
                                let indexes = [6, 7, 8];
                                let newValue = "*";
                                let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                                return (
                                  <ListItem
                                    // key={bill._id}
                                    sx={{
                                      // border: "1px solid",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      width: "100%",
                                    }}
                                    component="div"
                                    // value={listItemValue}
                                    onClick={() => {
                                      const date = ` ${day}/${month}/${year}`;
                                      setCustomerName(customerName);
                                      setPayAmount(amount);
                                      setPayNum(phoneNumber);
                                      setPayDate(date);
                                      setPayRef(transactionID);

                                      if (amount > overallTotal - allPaymentDiscount) {
                                        setPaymentMessageModal(false);
                                        setMpesaAmountModal(true);
                                      } else {
                                        setPaymentMessageModal(false);
                                        setConfirmPayModal(true);
                                        unUseMessage(messo);
                                      }
                                    }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        border: "1.5px solid #e1d6d6",
                                        borderRadius: "6px",
                                        width: "100%",
                                      }}
                                    >
                                      <ListItemText
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "15px",
                                          fontWeight: "500",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "2.2",
                                          letterSpacing: "normal",
                                          textAlign: "left",
                                          color: "#6e7074",
                                        }}
                                        primary={``}
                                      />

                                      <ListItemText
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "15px",
                                          fontWeight: "500",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "2.2",
                                          letterSpacing: "normal",
                                          textAlign: "left",
                                          color: "#6e7074",
                                        }}
                                        primary={`${transactionID}  ${customerName} ${newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })}
                          </List>
                          {messagesCollection.length === 0 && <span>No Messages to show</span>}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* RIGHT SIDE BANK PAYMENT */}
              {bankPay && (
                <Grid item xs={5} style={{ height: "65vh" }}>
                  <Grid container>
                    <Grid
                      item
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                      }}
                    >
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontWeight: "bold",
                          textAlign: "left",
                          width: "50%",
                        }}
                      >
                        Total KES = {numberFormat(overallTotal - bankPayDiscount)}
                      </span>
                      <span>
                        Discount <Checkbox onClick={() => setToggleBankPay(!togglebankPay)} />
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    style={{
                      paddingLeft: "20px",
                      width: "100%",
                      paddingBottom: "20px",
                      // border:"2px solid red"
                    }}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal", // textAlign: "left",
                          color: "#dc3545",
                        }}
                      >
                        Bank Payment
                      </span>
                    </Grid>

                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal", // textAlign: "left",
                          // color: "#dc3545",
                          margin: "0px 10px 0px 0px",
                        }}
                      >
                        Search:
                      </span>
                      <input style={{ outline: "none" }} onChange={(e) => setBankPayQuery(e.target.value)} placeholder="Search Bank" />
                    </Grid>
                  </Grid>

                  <Grid container style={{ width: "100%", padding: "0px 0px 0px 20px" }}>
                    {togglebankPay && (
                      <Grid item style={{ width: "100%" }}>
                        <TextField style={{ width: "100%" }} helperText="discount" defaultValue={bankPayDiscount} onChange={(e) => setBankpayDiscount(e.target.value)} type="number" />
                      </Grid>
                    )}
                  </Grid>

                  <Grid container style={{ paddingLeft: "20px", width: "100%" }}>
                    {bankDetailsDropDowidth && (
                      <>
                        <Grid
                          item
                          style={{
                            backgroundColor: "#deedfa",
                            width: "100%",
                            padding: "20px",
                            margin: "0px 0px 10px 0px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setMessagesToBeFetched("DTB");
                            setBankDetailsMessages(true);
                            setBanDetailsDropDown(false);
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",

                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#032541",
                            }}
                          >
                            DTB
                          </span>
                        </Grid>

                        <Grid
                          item
                          style={{
                            backgroundColor: "#deedfa",
                            width: "100%",
                            padding: "20px",
                            margin: "0px 0px 10px 0px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setMessagesToBeFetched("COOP");
                            setBankDetailsMessages(true);
                            setBanDetailsDropDown(false);
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",

                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#032541",
                            }}
                          >
                            COOP-BANK
                          </span>
                        </Grid>

                        <Grid
                          item
                          style={{
                            backgroundColor: "#deedfa",
                            width: "100%",
                            padding: "20px",
                            margin: "0px 0px 10px 0px",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            setMessagesToBeFetched("ABSA");
                            setBankDetailsMessages(true);
                            setBanDetailsDropDown(false);
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "14px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",

                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#032541",
                            }}
                          >
                            ABSA
                          </span>
                        </Grid>
                      </>
                    )}

                    {bankDetailsMessages && (
                      <Grid item style={{ height: "60vh", overflow: "auto" }}>
                        <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                          {/* LOOP THROUGH MESSAGES TO MAP */}
                          {
                            // messagesCollection.length > 0 &&
                            messagesCollection
                              ?.filter((messo) => {
                                if (bankPayQuery.toLocaleLowerCase() === "") {
                                  return messo;
                                } else if (messo.transactionID.toLocaleLowerCase().includes(bankPayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(bankPayQuery)) {
                                  return messo;
                                }
                              })
                              .map((messo) => {
                                const { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;

                                function replaceAtMultipleIndexes(str, indexes, newValue) {
                                  let result = str;
                                  for (let i = 0; i < indexes.length; i++) {
                                    const index = indexes[i];
                                    result = result.substr(0, index) + newValue + result.substr(index + newValue.length);
                                  }
                                  return result;
                                }
                                let myString = phoneNumber;
                                let indexes = [6, 7, 8];
                                let newValue = "*";
                                let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                                return (
                                  <ListItem
                                    // key={bill._id}
                                    sx={{
                                      // border: "1px solid",
                                      display: "flex",
                                      flexDirection: "column",
                                      alignItems: "flex-start",
                                      width: "100%",
                                    }}
                                    component="div"
                                    // value={listItemValue}
                                    onClick={() => {
                                      const date = ` ${day}/${month}/${year}`;
                                      setBankPayName(customerName);
                                      setBankPayAmount(amount);
                                      setBankPayNum(phoneNumber);
                                      setBankPayDate(date);
                                      setBankPayRef(transactionID);

                                      if (amount > overallTotal - bankPayDiscount) {
                                        setPaymentMessageModal(false);
                                        setMpesaAmountModal(true);
                                      } else {
                                        setPaymentMessageModal(false);
                                        setConfirmPayModal(true);
                                        unUseMessage(messo);
                                      }
                                    }}
                                  >
                                    <ListItemButton
                                      sx={{
                                        border: "1.5px solid #e1d6d6",
                                        borderRadius: "6px",
                                        width: "100%",
                                      }}
                                    >
                                      <ListItemText
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "15px",
                                          fontWeight: "500",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "2.2",
                                          letterSpacing: "normal",
                                          textAlign: "left",
                                          color: "#6e7074",
                                        }}
                                        primary={``}
                                      />

                                      <ListItemText
                                        style={{
                                          fontFamily: "Poppins",
                                          fontSize: "15px",
                                          fontWeight: "500",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "2.2",
                                          letterSpacing: "normal",
                                          textAlign: "left",
                                          color: "#6e7074",
                                        }}
                                        primary={`${transactionID}  ${customerName} ${newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                                      />
                                    </ListItemButton>
                                  </ListItem>
                                );
                              })
                          }
                        </List>
                        ` {/* empty state transaction */}
                        {messagesCollection.length === 0 && (
                          <Grid item style={{ width: "100%" }}>
                            <p style={{ textAlign: "center" }}>
                              <img
                                style={{
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "fit-content",
                                  margin: "0px",
                                }}
                                src={emptyState}
                              />
                            </p>
                            <p style={{ textAlign: "center", margin: "0px" }}>No tranasctions added transactions will appear here</p>
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              )}

              {/* VOOMA PAYMENT */}
              {voomapay && (
                <Grid item xs={6}>
                  <Grid
                    container
                    style={{
                      paddingLeft: "20px",
                      width: "100%",
                      paddingBottom: "20px",
                    }}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal", // textAlign: "left",
                          color: "#dc3545",
                        }}
                      >
                        Vooma Payment
                      </span>
                    </Grid>

                    <Grid item>
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "15px",
                          fontWeight: "600",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal", // textAlign: "left",
                          // color: "#dc3545",
                          margin: "0px 10px 0px 0px",
                        }}
                      >
                        Search:
                      </span>
                      <input style={{ outline: "none" }} placeholder="Search Bank" />
                    </Grid>
                  </Grid>

                  {/* empty state transaction */}
                  {/* <Grid
                container
                justifyContent={"center"}
                alignItems={"center"}
                style={{ paddingLeft: "20px", width: "100%" }}
              >
                <Grid item style={{ width: "100%" }}>
                  <p style={{ textAlign: "center" }}>
                    <img
                      style={{
                        width: "80px",
                        height: "80px",
                        objectFit: "fit-content",
                        margin: "0px",
                      }}
                      src={emptyState}
                    />
                  </p>
                  <p style={{ textAlign: "center", margin: "0px" }}>
                    No tranasctions added transactions will appear here
                  </p>
                </Grid>
              </Grid> */}
                </Grid>
              )}
            </Grid>
          </div>

          {/* <====== MODE OF PAYMENT SELECTION =======> */}
          <Modal
            open={openPayModal}
            //  onClose={() => setOpenPayModal(false)}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "30%",
                height: "fit-content",
              }}
            >
              <Grid container justifyContent="space-around" direction="row" style={{ width: "100%", padding: "20px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.5",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#041920",
                    }}
                  >
                    Choose Payment Method
                  </span>
                </Grid>

                <Grid item>
                  <span>
                    <Close
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setOpenPayModal(false);
                      }}
                    />
                  </span>
                </Grid>
              </Grid>

              <Grid container direction="column" style={{ width: "100%", padding: "20px" }}>
                <Grid
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("MPESA");
                    setOpenPayModal(false);
                    setMpesaPayModal(true);

                    setShowReturnBill(false);
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={mpesasvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Mpesa
                  </span>
                </Grid>
                <Grid
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // closePaymentModal();
                    setOpenCashPayment(true);
                    setPayDetails(false);
                    setOpenPayModal(false);

                    setBankPay(false);
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={cashsvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Cash
                  </span>
                </Grid>

                <Grid
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("VOOMA");

                    setMpesaPayModal(true);
                    setOpenPayModal(false);
                    setShowReturnBill(false);
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={voomasvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Vooma
                  </span>
                </Grid>

                <Grid
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("EQUITEL");

                    setMpesaPayModal(true);
                    setOpenPayModal(false);
                    setShowReturnBill(false);
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={equitelsvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Equitel
                  </span>
                </Grid>

                <Grid
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("CARD");

                    setOpenPayModal(false);
                    setCardModalOpen(true);
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={cardsvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Card
                  </span>
                </Grid>

                <Grid
                  onClick={() => {
                    setOpenPayModal(false);
                    setBankPayModal(true);
                    // setPayOpenModal(false);
                    setShowReturnBill(false);
                  }}
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px" }} src={banksvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Banks
                  </span>
                </Grid>
                <Grid
                  onClick={() => {
                    setOpenPayModal(false);

                    setopenVoucherPay(true);
                  }}
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px", height: "53px" }} src={vouchersvg} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Voucher
                  </span>
                </Grid>

                <Grid
                  onClick={() => {
                    setOpenPayModal(false);
                    setOpenCreditNote(true);
                  }}
                  item
                  style={{
                    boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                    backgroundColor: "#fff",
                    margin: "0px 0px 5px 0px",
                    padding: "5px 0px 5px 5px",
                    cursor: "pointer",
                  }}
                >
                  <img style={{ padding: "5px 20px 5px 15px", height: "53px" }} src={creditnote} />{" "}
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "0.71",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    Credit Note
                  </span>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* SENDING REQUEST MODAL */}

          <Modal open={sendRequest} onClose={() => setSendRequest(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "20%",
                height: "200px",
              }}
            >
              <Box style={{ width: "100%", textAlign: "right", padding: "10px" }}>
                <span>
                  <Close onClick={() => setSendRequest(false)} style={{ cursor: "pointer" }} />
                </span>
              </Box>

              <Box style={{ width: "100%" }}>
                <p style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "80px",
                      heigth: "80px",
                      objectFit: "fit-content",
                    }}
                    src={sendpayrequest}
                  />
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    // lineHeight: "0.71",
                    letterSpacing: "normal",
                  }}
                >
                  Sending the request...
                  <br />
                  Please Wait
                </p>
              </Box>
            </div>
          </Modal>

          {/* SUCCESS MODAL */}
          {/* SAVE BILL MODAL */}
          <Modal open={successfullyPaid} onClose={() => setSuccessModal(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "300px",
                height: "250px",
              }}
            >
              <div style={{ width: "100%", padding: "20px" }}>
                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                    }}
                    src={approvetick}
                  />
                </p>

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Success
                </p>

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "17px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                    margin: "0px 0px 10px 0px",
                  }}
                >
                  Request Sent successfully!
                </p>

                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0px 20px 0px 0px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                    }}
                    onClick={() => setSuccessModal(false)}
                  >
                    ok
                  </Button>
                </p>
              </div>
            </div>
          </Modal>
          <Modal open={voucherValidationOpen} onClose={ClosevoucherValidationClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "100px",
                  padding: "12px 30px 80px",
                  objectFit: "contain",
                }}
              >
                <img
                  style={{
                    width: "82px",
                    height: "82px",
                    margin: "4px 0px 0px -15px",
                  }}
                  src={powericon}
                  alt="img"
                />
              </div>
              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: " #032541",
                }}
              ></h4>

              <h4
                style={{
                  fontSize: "18px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "green",
                }}
              >
                {titleMessage}
              </h4>
              <h4
                style={{
                  marginTop: "16px",
                  fontSize: "16px",
                  textAlign: "center",
                }}
              >
                {VoucherInfomessage}
              </h4>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "240px",
                    height: "40px",
                    margin: "20px 10px 0 30px",
                    backgroundColor: "#032541",
                  }}
                  onClick={ClosevoucherValidationClose}
                >
                  ok
                </Button>
              </div>
            </Box>
          </Modal>
          <Modal open={openVoucherPay} onClose={closeVoucherPay} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={AddProductModalstyle}>
              <Box
                sx={{
                  width: "100%",
                  padding: "33.8px 55.6px 0.8px 43.6px",
                }}
              >
                <div className="topbar-voucher d-flex" style={{ display: "flex", justifyContent: "space-between" }}>
                  <ArrowBack onClick={closeVoucherPay} />
                  <h6>Voucher</h6>
                  <CloseIcon onClick={closeVoucherPay} />
                </div>
                <div className="billDate">
                  <div
                    className="d-flex "
                    style={{
                      width: "407px",
                      height: " 54px",
                      margin: " 28px 0 20px 0px",
                      padding: "17px 10px 16px",
                      display: "flex",
                      justifyContent: "space-between",

                      borderRadius: "6px",
                      backgroundColor: "#d8eaf5",
                    }}
                  >
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: " normal",
                        fontStyle: "normal",
                        lineHeight: "1.67",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Total Bill Amount
                    </h4>
                    <h4
                      style={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        fontStretch: " normal",
                        fontStyle: "normal",
                        lineHeight: "1.67",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#17ae7b",
                      }}
                    >
                      {numberFormat(overallTotal)}
                    </h4>
                  </div>
                </div>
                <div className="search-holder">
                  {/* <Paper
                  component="form"
                  sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                 
                  <InputBase
                    sx={{ ml: 1, flex: 1 }}
                   
                    
                    placeholder="Search voucher number or scan QR Code"
                    inputProps={{ 'aria-label': 'Search voucher number or scan QR Code' }}
                  />
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon />
                  </IconButton>
                  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </Paper> */}
                  <input
                    onChange={(e) => setvoucherNumber(e.target.value)}
                    style={{
                      width: "400px",
                      padding: "17px 10px 16px",
                      height: " 54px",
                      fontSize: "13px",
                      fontWeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b4bcc4",
                      outline: "none",
                      border: "solid grey 1px",
                      borderRadius: "5px",
                    }}
                    placeholder="Search voucher number or Scan code"
                    type="text"
                    name=""
                    id=""
                  />
                </div>
                {resultSearch === false ? (
                  <span
                    style={{
                      fontSize: "14px",
                      marginTop: "20px",
                      color: "red",
                    }}
                  >
                    {searchVoucherMessage}
                  </span>
                ) : (
                  <>
                    <div
                      className="voucher"
                      style={{
                        margin: " 25px 14px 85px 15px",
                        padding: "17px 18px 16px",
                        borderRadius: "6px",
                        border: "solid 1px #707070",
                        backgroundColor: "#fff",
                      }}
                    >
                      {console.log("resultSearch VOUCHERSSSSS", resultSearch)}

                      <div
                        className="voucher-component"
                        onClick={() => {
                          if (searchedVoucher.usage_status == "USED") {
                            setVoucherInfomessage("The voucher have already been used");
                            settitleMessage("Voucher Already used");
                            setvoucherValidationOpen(true);
                            //{numberFormat(orderDetails.amount - orderDetails.discAmount)}
                          } else if (orderDetails.amount - orderDetails.discAmount < searchedVoucher.amount) {
                            setVoucherInfomessage("The voucher amount exceeds total bill kindly add some item");
                            settitleMessage("Voucher Bill");
                            setvoucherValidationOpen(true);
                          } else if (searchedVoucher.usage_status == "EXPIRED") {
                            setVoucherInfomessage("The voucher has already expired");
                            settitleMessage("Voucher Expired");
                            setvoucherValidationOpen(true);
                          } else {
                            setconfirmationVoucherOPen(true);
                            setvoucherValidationOpen(false);
                            // setopenVoucherPay(false)
                          }
                        }}
                      >
                        <div
                          className="d-flex"
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="voucher-details">
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "14px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              Voucher No. {searchedVoucher?.voucherNo}
                            </div>
                            <div
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "11px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              Voucher Amount: <span className="amount">{numberFormat(searchedVoucher?.amount)}</span>
                            </div>
                            <div
                              style={{
                                fontSize: "11px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#032541",
                              }}
                            >
                              {" "}
                              Expiry Date <span> {moment(searchedVoucher?.expiryDate).format("DD/MM/YYYY:HH:MM:SS")}</span>{" "}
                            </div>
                          </div>
                          <div className="voucher-status">
                            <span
                              style={{
                                fontSize: "16px",
                                marginTop: "10px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                color: "#17ae7b",
                              }}
                            >
                              {searchedVoucher?.usage_status}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </Box>
            </Box>
          </Modal>
          <Modal open={confirmationVoucherOPen} onClose={closeconfirmationVoucher} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                borderColor: "transparent",
                borderRadius: "36px",
                backgroundColor: "#fff",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "387px",
                height: "382px",
              }}
            >
              <div
                style={{
                  width: "50px",
                  height: "50px",
                  marginLeft: "100px",
                  padding: "12px 30px 80px",
                  objectFit: "contain",
                }}
              >
                <img
                  style={{
                    width: "82px",
                    height: "82px",
                    display: "flex",
                    marginTop: "20px",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  src={powericon}
                  alt="img"
                />
              </div>
              <h4
                style={{
                  fontSize: "22px",
                  fontWeight: " bold",
                  fontStretch: "normal",
                  fontStyle: " normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: " #032541",
                  marginTop: "24px",
                  color: "#032541",
                }}
              >
                Confirm Payment
              </h4>
              <h4 style={{ padding: "0 60px 10px 60px", fontSize: "18px" }}>
                Are you sure you want to pay with Voucher No. <strong className="text-success">{searchedVoucher?.voucherNo}</strong>
              </h4>
              <h4 style={{ textAlign: "center", fontSize: "18px" }}>
                Amount <strong className="text-success">{numberFormat(searchedVoucher?.amount)}</strong>
              </h4>
              <div
                className="button-row"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#dc3445",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "140px",
                    height: "40px",
                    margin: "20px 0px 0 0px",
                    border: "solid 1px  #dc3545",
                  }}
                  onClick={closeconfirmationVoucher}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="standard"
                  style={{
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    width: "140px",
                    height: "40px",
                    margin: "20px 0px 0 0px",
                    backgroundColor: "#032541",
                  }}
                  onClick={() => {
                    console.log("i Was clicked");

                    executePayVoucher();
                    ClosevoucherValidationClose();
                  }}
                >
                  Confirm
                </Button>
              </div>
            </Box>
          </Modal>

          {mpesaPayModal && <MpesaPaymentModal allPaymentDiscount={allPaymentDiscount} setAllPaymentDiscount={setAllPaymentDiscount} detailedBill={detailedBill} mpesaPayModal={mpesaPayModal} setMpesaPayModal={setMpesaPayModal} messagesCollection={messagesCollection} allPayTotals={allPayTotals} setAllPayAmount={setAllPayAmount} setAllPayCustomerName={setAllPayCustomerName} setAllPayCustomerNumber={setAllPayCustomerNumber} setAllPayTransId={setAllPayTransId} setMpesaAmountModal={setMpesaAmountModal} unUseMessage={unUseMessage} setConfirmPatDetails={setConfirmPatDetails} setConfirmPayModal={setConfirmPayModal} />}

          {bankPayModal && <BankPayModal bankPayDiscount={bankPayDiscount} detailedBill={detailedBill} setBankpayDiscount={setBankpayDiscount} setBankCustomerName={setBankCustomerName} setPayBankAmount={setPayBankAmount} setBankPayNum={setBankPayNum} setpayBankDate={setpayBankDate} setPayBankId={setPayBankId} bankPayModal={bankPayModal} setBankPayModal={setBankPayModal} bankTotal={bankTotal} setMessagesToBeFetched={setMessagesToBeFetched} messagesCollection={messagesCollection} setMpesaPayModal={setMpesaPayModal} setBankLists={setBankLists} bankMessages={bankMessages} setBankMessages={setBankMessages} bankList={bankList} setMpesaAmountModal={setMpesaAmountModal} setConfirmPayModal={setConfirmPayModal} unUseMessage={unUseMessage} />}
          {/* CARD PAY MODAL */}
          {/* CARD PAYMENT MODAL */}
          {/* <Modal open={cardModal} onClose={() => setCardModalOpen(false)}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "15px",
            backgroundColor: "#fff",
            width: "30%",
            height: "fit-content",
          }}
        >
          <Box style={{ width: "100%", padding: "10px" }}>
            <p style={{ textAlign: "right" }}>
              <Checkbox value={cardDiscount} onChange={(e) => setToggleCardDiscount(!cardDiscount)} /> Discount
            </p>
          </Box>

          <Grid container direction="row" style={{ width: "100%" }}>
            <Grid item style={{ width: "100%" }}>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  margin: "0",
                  lineHeight: "1.7",
                }}
              >
                <img
                  style={{
                    width: "70px",
                    height: "70px",
                    objectFit: "contain",
                  }}
                  src={cardpay}
                />
              </p>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.7",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#032541",
                  margin: "0",
                }}
              >
                Card Payment
              </p>
              <p
                style={{
                  width: "100%",
                  textAlign: "center",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  // lineHeight: "1.8",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#032541",
                  // margin:"0"
                }}
              >
                Total = {numberFormat(cardTotal)}
              </p>
            </Grid>

            <Grid container style={{ width: "100%", padding: "20px" }}>
              <Grid item style={{ width: "100%" }}>
                {cardDiscount && (
                  <Grid item style={{ width: "100%" }}>
                    <TextField
                      id="outlined-select-meals"
                      label="Discount"
                      style={{ width: "100%" }}
                      defaultValue={cardDiscountAmount}
                      helperText={"Enter discount"}
                      type="number"
                      onChange={(e) => setCardDiscountAmount(e.target.value)}
                    />
                  </Grid>
                )}

                <TextField id="outlined-select-meals" select label="Card Type" style={{ width: "100%" }} defaultValue={getCardType} helperText={"Select Card Type"} onChange={(e) => setCardType(e.target.value)}>
                  {cardTypes.length > 0 &&
                    cardTypes.map((card) => {
                      return (
                        <MenuItem key={card._id} value={card.cardType}>
                          {card.cardType}
                        </MenuItem>
                      );
                    })}
                </TextField>
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <TextField id="outlined-select-meals" label="Reference number" style={{ width: "100%" }} defaultValue={refNo} helperText={"Select Reference Number"} onChange={(e) => setRefNo(e.target.value)} />
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <TextField id="outlined-select-meals" select label="Reference No" style={{ width: "100%" }} defaultValue={getAcquirer} helperText={"Select Card Type"} onChange={(e) => setAcquirer(e.target.value)}>
                  {getAcquirers.length > 0 &&
                    getAcquirers.map((acquirer) => {
                      return <MenuItem value={acquirer.name}>{acquirer.name}</MenuItem>;
                    })}
                </TextField>
              </Grid>

              <Grid item style={{ width: "100%" }}>
                <TextField id="outlined-select-meals" label="Customer's Name" style={{ width: "100%" }} defaultValue={cardCustomer} helperText={"Enter name of the  Customer's Name"} onChange={(e) => setCardCustomerName(e.target.value)} />
              </Grid>
            </Grid>

            <Grid container style={{ width: "100%", padding: "10px" }} direction="row" justifyContent={"center"} alignItems="center">
              <Grid item>
                <Button
                  style={{
                    fontSize: "15px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                    border: "1px solid #dc3545",
                    margin: "0px 20px 0px 0px",
                  }}
                  onClick={() => setCardModalOpen(false)}
                >
                  Cancel
                </Button>
              </Grid>

              <Grid item>
                <Button
                  style={{
                    objectFit: "contain",
                    borderRadius: "5px",
                    backgroundColor: "#032541",
                    color: "#ffffff",
                    margin: "0px 0px 0px 20px",
                  }}
                  onClick={() => {
                    setConfirmPatDetails(true);
                    setCardModalOpen(false);
                  }}
                >
                  Pay Now
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal> */}

          {/* CONFIRM CARD PAY */}
          <Modal open={confirmPayDetails} onClose={() => setConfirmPatDetails(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "20%",
                height: "fit-content",
              }}
            >
              <Box>
                <p style={{ textAlign: "center" }}>
                  <img style={{ objectFit: "contain" }} src={powericon} />
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Confirm Payment
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Are you sure you want to pay with
                </p>

                <br />

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  {getCardType}?
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  For {cardCustomer} Amount {numberFormat(cardTotal)}
                </p>

                <p style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                      border: "1px solid #dc3545",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => setConfirmPatDetails(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={() => {
                      setpaySuccess(false);
                      navigate("/orders");
                      //
                    }}
                  >
                    Confirm
                  </Button>
                </p>
              </Box>
            </div>
          </Modal>

          {/* CASH PAYMENT MODAL */}
          <Modal
            open={openCashModal}
            // onClose={() => setOpenCashPayment(false)}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "30%",
                height: "450px",
              }}
            >
              <Grid container style={{ padding: "20px" }}>
                <Grid item style={{ width: "100%" }}>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      fontWeight: "500",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "2.08",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    {" "}
                    <Checkbox checked={checkedDiscount} onChange={setCheckledStatus} inputProps={{ "aria-label": "controlled" }} />
                    Discount
                  </p>
                </Grid>

                <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
                  <Grid item>
                    <p style={{ margin: "0", textAlign: "center" }}>
                      <img src={cashpayment} />
                    </p>
                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#032541",
                        margin: "0px",
                      }}
                    >
                      Cash Payment
                    </p>

                    <p
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "20px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#032541",
                        margin: "0px",
                      }}
                    >
                      Sub Total = KES {overallTotal - itemDiscount}
                    </p>
                  </Grid>
                </Grid>

                <Grid container>
                  <Grid item style={{ width: "100%" }}>
                    <Box
                      style={{ display: "flex", flexDirection: "column" }}
                      component="form"
                      sx={{
                        "& > :not(style)": { m: 1, width: "90%" },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      {checkedDiscount && <TextField id="outlined-basic" label="Enter Cash Discount" variant="outlined" value={itemDiscount} type="number" onChange={(e) => setItemDiscount(e.target.value)} />}
                      width={"100%"}
                      <TextField id="outlined-basic" label="Enter Cash Recieved" variant="outlined" value={cashRecieved} type="number" onChange={(e) => setCashRecieved(e.target.value)} />
                    </Box>
                  </Grid>
                </Grid>

                <Grid container style={{ width: "100%" }} justifyContent="center" alignItems="center">
                  <Button
                    style={{
                      borderRadius: "5px",
                      border: "solid 1px #dc3545",
                      color: "#dc3545",
                      padding: "10px 40px 10px 40px",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => setOpenCashPayment(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      padding: "10px 40px 10px 40px",
                      margin: "0px 0px 0px 20px",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      setOpenCashPayment(false);
                      setPayDetails(true);
                    }}
                  >
                    Pay
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* CONFROM PAY MODAL */}
          <Modal open={payDetails} onClose={() => setPayDetails(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "25%",
                height: "400px",
              }}
            >
              <Grid container style={{ width: "100%", padding: "20px" }} alignItems="center" justifyContent="center">
                <Grid item>
                  <p style={{ margin: "0", textAlign: "center" }}>
                    <img src={cashpayment} />
                  </p>
                  <p
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                      margin: "0px",
                    }}
                  >
                    Cash Payment
                  </p>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b2b4bb",
                    }}
                  >
                    Bill Amount:
                  </span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    {overallTotal}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b2b4bb",
                    }}
                  >
                    Amount Recieved:
                  </span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    {overallTotal}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b2b4bb",
                    }}
                  >
                    Discount:
                  </span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    {itemDiscount}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b2b4bb",
                    }}
                  >
                    Total:
                  </span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    KES {overallTotal - itemDiscount}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 20px 0px",
                }}
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#b2b4bb",
                    }}
                  >
                    Balance:
                  </span>
                </Grid>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "right",
                      color: "#032541",
                    }}
                  >
                    KES {0}
                  </span>
                </Grid>
              </Grid>

              <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
                <Grid item>
                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      border: "solid 1px #dc3545",
                      color: "#dc3545",
                      padding: "10px 20px 10px 20px",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={() => {
                      setPayDetails(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      padding: "10px 20px 10px 20px",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={() => {
                      // if (pay) {
                      //   saveOrderToBd();
                      // }
                      setPayDetails(false);

                      payBills();
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* PRINT BILL POP UP */}
          <Modal
            open={posPayment}
            // onClose={() => setPospayment(false)}
          >
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "30%",
                height: "fit-content",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "20px",
                  backgroundColor: "#dc3545",
                  width: "100%",
                  borderRadius: "15px 15px 0px 0px",
                }}
              >
                <span
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                  onClick={() => {
                    if (posArray.length > 0) {
                      // posPayment(true);
                    }
                  }}
                ></span>
              </Box>

              <Grid container justifyContent="space-around" style={{ width: "100%", padding: "20px" }} alignItems="center">
                <Grid item onClick={() => setShowPrinter(false)}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)",
                      // border: "solid 3px #032541",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={possvg} />
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Send to POS
                    </span>
                  </Box>
                </Grid>

                <Grid item onClick={() => setShowPrinter(true)}>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      padding: "5px",
                      boxShadow: "0 3px 6px 0 rgba(0, 0, 0, 0.16)", // border: "solid 3px #032541",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <img src={printersvg} />
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "15px",
                        fontWeight: "500",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#032541",
                      }}
                    >
                      Send to Printer
                    </span>
                  </Box>
                </Grid>
              </Grid>

              {!showPrinter && (
                <Grid container style={{ width: "100%", padding: "20px" }}>
                  <Grid item style={{ width: "100%" }}>
                    <TextField style={{ width: "100%" }} id="outlined-select-meals" select label="Pos" defaultValue={posSerialNo} helperText="POS" onChange={(e) => setPosSerialNo(e.target.value)}>
                      {pos.length > 0 &&
                        pos.map((pos) => {
                          return (
                            <MenuItem key={pos._id} value={pos.terminalSerialNumber}>
                              {pos.terminalSerialNumber}
                            </MenuItem>
                          );
                        })}
                    </TextField>

                    {pos.length === 0 && <p style={{ textAlign: "right" }}>You do not have any POS</p>}
                  </Grid>
                </Grid>
              )}

              {showPrinter && (
                <Grid container style={{ width: "100%", padding: "20px" }}>
                  <Grid item style={{ width: "100%" }}>
                    <TextField
                      style={{ width: "100%" }}
                      id="outlined-select-meals"
                      select
                      label="Printer"
                      // defaultValue={customerNote}
                      helperText="Select Printer"
                      // onChange={(e) => setCustomerNote(e.target.value)}
                    >
                      {/* {printers.length > 0 &&
                  printers.printers.map((printer) => {
                    return (
                      <MenuItem value="HJFY875GCUYTR">HJFY875GCUYTR</MenuItem>
                    );
                  })} */}
                    </TextField>
                  </Grid>
                </Grid>
              )}

              <Grid container justifyContent="space-around" alignItems="center" style={{ width: "100%", padding: "20px" }} direction="row">
                <Grid item>
                  <Button
                    style={{
                      border: "solid 1px #dc3545",
                      color: "#dc3545",
                      // margin: "0px 20px 0px 0px",
                      padding: "8px 20px 8px 20px",
                    }}
                    onClick={() => setPospayment(false)}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      sendToPrinter();
                      setSendRequest(true);
                      setPospayment(false);
                    }}
                    style={{
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      // margin: "0px 0px 0px 20px",
                      padding: "8px 30px 8px 30px",
                    }}
                  >
                    Send
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* more payment display */}
          <Modal open={mpesaAmountModal} onClose={() => setMpesaAmountModal(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "15%",
                height: "fit-content",
              }}
            >
              <Box style={{ width: "100%", padding: "10px" }}>
                <p style={{ textAlign: "center" }}>
                  <img src={powericon} />
                </p>
                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "2.2",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  The amount selected is greater than the total bill
                </p>
              </Box>

              <Box
                style={{
                  width: "100%",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button onClick={() => setMpesaAmountModal(false)} style={{ backgroundColor: "#032541", color: "#ffffff" }}>
                  ok
                </Button>
              </Box>
            </div>
          </Modal>

          <Modal open={confrimPayModal} onClose={() => setConfirmPayModal(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "25%",
                height: "450px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <p style={{ textAlign: "center" }}>
                  <img src={powericon} />
                </p>
              </Box>
              <Box style={{ width: "100%" }}>
                <p
                  style={{
                    margin: "0",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "17px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Confirm Payment
                </p>
                <p
                  style={{
                    margin: "0",
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Are you sure you want to confirm this payment
                </p>
              </Box>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Amount
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? numberFormat(allPaymentsTotal - bankPayDiscount) : numberFormat(allPayTotals)}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Customer Name
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? bankPayName : allPayCustomerName}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Phone Number
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? bankPayNum : allPayCustomerNumber}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Transaction Date
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? bankPayDate : "payDate"}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Reference No.
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? bankPayRef : allPayTransId}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Type
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {messagesTobeFetched}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Total Amount
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? numberFormat(allPaymentsTotal - bankPayDiscount) : numberFormat(allPayTotals)}
                  </span>
                </Grid>
              </Grid>

              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                style={{
                  width: "100%",
                  padding: "0px 30px 0px 30px",
                  margin: "0px 0px 10px 0px",
                }}
              >
                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    Balance
                  </span>
                </Grid>

                <Grid item>
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                    }}
                  >
                    {bankPayName !== "" ? numberFormat(newBankBalance) : numberFormat(newPayTransBalance)}
                  </span>
                </Grid>
              </Grid>

              <Grid container justifyContent={"space-between"} alignItems={"center"} style={{ width: "100%", padding: "0px 30px 0px 30px" }}>
                <Grid item>
                  <Button onClick={() => setConfirmPayModal(false)} style={{ border: "solid 1px #dc3545", color: "#dc3545" }}>
                    Cancel
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    onClick={() => {
                      setConfirmPayModal();
                      payBills();
                    }}
                    style={{ color: "#ffffff", backgroundColor: "#032541" }}
                  >
                    PAY NOW
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* SAVE BILL MODAL */}
          <Modal open={paySuccess} onClose={() => setpaySuccess(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "300px",
                height: "250px",
              }}
            >
              <div style={{ width: "100%", padding: "20px" }}>
                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <img
                    style={{
                      height: "50px",
                      width: "50px",
                      objectFit: "contain",
                    }}
                    src={approvetick}
                  />
                </p>

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Success
                </p>

                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "17px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                    margin: "0px 0px 10px 0px",
                  }}
                >
                  Payment Recieved successfully!
                </p>

                <p style={{ textAlign: "center", margin: "0px 0px 5px 0px" }}>
                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0px 20px 0px 0px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                    }}
                    onClick={() => {
                      setpaySuccess(false);
                      navigate("/orders");
                    }}
                  >
                    ok
                  </Button>

                  <Button
                    style={{
                      textAlign: "center",
                      margin: "0",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 20px 0px 0px",
                    }}
                    // onClick={() => {
                    //   closeStoreBill();
                    //   navigate("/orders");
                    // }}
                  >
                    share
                  </Button>
                </p>
              </div>
            </div>
          </Modal>

          {/* CARD PAY MODAL */}
          {/* CARD PAYMENT MODAL */}
          <Modal open={cardModal} onClose={() => setCardModalOpen(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "30%",
                height: "fit-content",
              }}
            >
              <Box style={{ width: "100%", padding: "10px" }}>
                <p style={{ textAlign: "right" }}>
                  <Checkbox value={cardDiscount} onChange={(e) => setToggleCardDiscount(!cardDiscount)} /> Discount
                </p>
              </Box>

              <Grid container direction="row" style={{ width: "100%" }}>
                <Grid item style={{ width: "100%" }}>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      margin: "0",
                      lineHeight: "1.7",
                    }}
                  >
                    <img
                      style={{
                        width: "70px",
                        height: "70px",
                        objectFit: "contain",
                      }}
                      src={cardpay}
                    />
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "1.7",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                      margin: "0",
                    }}
                  >
                    Card Payment
                  </p>
                  <p
                    style={{
                      width: "100%",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "20px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      // lineHeight: "1.8",
                      letterSpacing: "normal",
                      textAlign: "center",
                      color: "#032541",
                      // margin:"0"
                    }}
                  >
                    Total = {numberFormat(cardTotal)}
                  </p>
                </Grid>

                <Grid container style={{ width: "100%", padding: "20px" }}>
                  <Grid item style={{ width: "100%" }}>
                    {cardDiscount && (
                      <Grid item style={{ width: "100%" }}>
                        <TextField id="outlined-select-meals" label="Discount" style={{ width: "100%" }} defaultValue={cardDiscountAmount} helperText={"Enter discount"} onChange={(e) => setCardDiscountAmount(e.target.value)} />
                      </Grid>
                    )}

                    <TextField id="outlined-select-meals" select label="Card Type" style={{ width: "100%" }} defaultValue={getCardType} helperText={"Select Card Type"} onChange={(e) => setCardType(e.target.value)}>
                      {cardTypes.length > 0 &&
                        cardTypes.map((card) => {
                          return (
                            <MenuItem key={card._id} value={card.cardType}>
                              {card.cardType}
                            </MenuItem>
                          );
                        })}
                    </TextField>
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" label="Reference number" style={{ width: "100%" }} defaultValue={refNo} helperText={"Select Reference Number"} onChange={(e) => setRefNo(e.target.value)} />
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" select label="Reference No" style={{ width: "100%" }} defaultValue={getAcquirer} helperText={"Select Card Type"} onChange={(e) => setAcquirer(e.target.value)}>
                      {getAcquirers.length > 0 &&
                        getAcquirers.map((acquirer) => {
                          return <MenuItem value={acquirer.name}>{acquirer.name}</MenuItem>;
                        })}
                    </TextField>
                  </Grid>

                  <Grid item style={{ width: "100%" }}>
                    <TextField id="outlined-select-meals" label="Customer's Name" style={{ width: "100%" }} defaultValue={cardCustomer} helperText={"Enter name of the  Customer's Name"} onChange={(e) => setCardCustomerName(e.target.value)} />
                  </Grid>
                </Grid>

                <Grid container style={{ width: "100%", padding: "10px" }} direction="row" justifyContent={"center"} alignItems="center">
                  <Grid item>
                    <Button
                      style={{
                        fontSize: "15px",
                        fontWeight: "600",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "left",
                        color: "#dc3545",
                        border: "1px solid #dc3545",
                        margin: "0px 20px 0px 0px",
                      }}
                      onClick={() => setCardModalOpen(false)}
                    >
                      Cancel
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        objectFit: "contain",
                        borderRadius: "5px",
                        backgroundColor: "#032541",
                        color: "#ffffff",
                        margin: "0px 0px 0px 20px",
                      }}
                      onClick={() => {
                        setConfirmPatDetails(true);
                        setCardModalOpen(false);
                      }}
                    >
                      Pay Now
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </Modal>

          {/* CONFIRM CARD PAY */}
          <Modal open={confirmPayDetails} onClose={() => setConfirmPatDetails(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "20%",
                height: "fit-content",
              }}
            >
              <Box>
                <p style={{ textAlign: "center" }}>
                  <img style={{ objectFit: "contain" }} src={powericon} />
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Confirm Payment
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Are you sure you want to pay
                </p>

                <br />

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  {numberFormat(allPayAmount)}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  with {messagesTobeFetched}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  For {allPayCustomerName}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Amount {numberFormat(allPayTotals)}
                </p>

                <p
                  style={{
                    textAlign: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "center",
                    color: "#032541",
                  }}
                >
                  Balance {numberFormat(allPayTotals - allPayAmount)}
                </p>

                <p style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                      border: "1px solid #dc3545",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => setConfirmPatDetails(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={() => {
                      setConfirmPatDetails(false);
                      payBills();
                      //
                    }}
                  >
                    Confirm
                  </Button>
                </p>
              </Box>
            </div>
          </Modal>

          <Modal open={cancelorderPrompt} onClose={() => setCancelOrderPrompt(false)}>
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                borderRadius: "15px",
                backgroundColor: "#fff",
                width: "20%",
                height: "fit-content",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  padding: "10px",
                  fontFamily: "Poppins",
                  color: "#032541",
                }}
              >
                <p style={{ textAlign: "center" }}>
                  <img src={powericon} />
                </p>

                <p style={{ textAlign: "center" }}>Are you sure you want to cancel this order?</p>

                <p style={{ textAlign: "center" }}>
                  <Button
                    style={{
                      fontSize: "15px",
                      fontWeight: "600",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#dc3545",
                      border: "1px solid #dc3545",
                      margin: "0px 20px 0px 0px",
                    }}
                    onClick={() => setCancelOrderPrompt(false)}
                  >
                    Cancel
                  </Button>

                  <Button
                    style={{
                      objectFit: "contain",
                      borderRadius: "5px",
                      backgroundColor: "#032541",
                      color: "#ffffff",
                      margin: "0px 0px 0px 20px",
                    }}
                    onClick={cancelBill}
                  >
                    Ok
                  </Button>
                </p>
              </Box>
            </div>
          </Modal>

          {/* VOUCHER PAYMENTS */}
          {openCreditNote && <CreditnotepayModal billTotal={allPaymentsTotal} openCreditNote={openCreditNote} setCreditnotesearch={setCreditnotesearch} creditNoteData={creditNoteData} creditnotesearch={creditnotesearch} creditNoteError={creditNoteError} setCreditNotePayId={setCreditNotePayId} setOpenCreditNote={setOpenCreditNote} setCreditNoteId={setCreditNoteId} setopenConfirmCredit={setopenConfirmCredit} creditNotedetails={creditNotedetails} setCreditNoteDetails={setCreditNoteDetails} />}
          {openConfirmCredit && <ConfirmPayCreditNote openConfirmCredit={openConfirmCredit} setopenConfirmCredit={setopenConfirmCredit} creditNoteAmount={creditNotedetails.amount} creditNoteNumber={creditNotedetails.no} executePayVoucher={executePayVoucher} />}
        </>
      )}

      {/* {requestPaymentComponent && <Requestpayment billTotal={overallTotal}   requestPayId={id}  backToUnsettledBills={backToUnsettledBills} setpaySuccess={setpaySuccess} />} */}

      {/* void payments modals */};

      <CancelVoidTransactionModal  cancelVoidModal={cancelVoidModal} setCancelPayModal={setCancelPayModal} doDeclineVoidPayment={doDeclineVoidPayment} billAmount={cancelVoidDetails?.amount} paymentMethod={cancelVoidDetails?.transType} />
      <ApproveVoidTransactionModal approveVoidModal={approveVoidModal} setApproveVoidModal={setApproveVoidModal} billAmount={newVoidDetails?.amount} paymentMethod={newVoidDetails?.transType} doApproveVoidPayments={doApproveVoidPayments}  />
      <ErrorAlertRightAligned error={error.state} message={error.message} />
      <SuccessAlertRightAligned sucess={sucess?.state} message={sucess?.message} />
    </div>
  );
};

export default Detailedbill;
