import React, {useState} from "react";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, Input } from "@material-ui/core";
import ShareIcon from "@mui/icons-material/Share";
import AddIcon from "@mui/icons-material/Add";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, Button, TextField, Tab, Modal, Tabs } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { alpha, styled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect } from "react";
import moment from "moment-timezone";
import Typography from "@mui/material/Typography";
import { ArrowBack } from "@material-ui/icons";
import CloseIcon from "@mui/icons-material/Close";
import dayjs from "dayjs";
import { SuccessVoucher } from "./successalert";
import { Link } from "react-router-dom";
import { ShareGenerateMOdal } from "./shareVoucher";
import { ValidationAlertMessage } from "./validation";

// Custom Tab Styling
const AntTabs = styled(TabList)({
  borderBottom: "0px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#ffffff00",
  },
});
const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);


const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none",
  minWidth: 0,
  [theme.breakpoints.up("sm")]: {
    minWidth: 0,
  },
  fontWeight: theme.typography.fontWeightRegular,
  marginRight: theme.spacing(1),
  fontSize: "12px",
  fontStretch: "normal",
  fontStyle: "normal",
  lineHeight: "2.75",
  letterSpacing: "normal",
  textAlign: "left",
  color: "#6e7074",
  fontFamily: ["Poppins"].join(","),
  "&:hover": {
    color: "#032541",
    opacity: 1,
  },
  "&.Mui-selected": {
    color: "#dc3545",
    fontWeight: 600,
  },
  "&.Mui-focusVisible": {
    backgroundColor: "blue",
  },
}));

const AddProductModalstyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "665px",
  height: "650px",
};

const VoucherDetails = () => {
  // handle tab Changes
  const [tabValue, setTabValue] = React.useState("ALL");
  console.log(tabValue);
  const handleTabChange = (event, newValue) => {
    console.log("TabValue", newValue);
    setTabValue(newValue);
  };
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 1,
    pageSize: 1000,
    // search : searchValue
  });
  // Get id from url
  const url = window.location.href;
  const batchId = url.substring(url.lastIndexOf("/") + 1);
  console.log(batchId);

  const [approved, setApproved] = useState([]);
  const [allTab, setAllTabs] = useState([]);
  const [usedTabs, setUsedTabs] = useState([]);
  const [unusedTabs, setUnusedTabs] = useState([]);
  const [expiredTabs, setExpiredTabs] = useState([]);
  const [status, setStatus] = useState("ALL");
  const [changeDate, setChangeDate] = useState("");


  // open modal

  const [shareModalOpen, setShareModalOpen] = useState(false);
  const ShareModalHandleOpen = () => setShareModalOpen(true);
  const ShareModalHandleClose = () => setShareModalOpen(false);

  // submit details
  const [NoOfVouchers, setNoOfVouchers] = useState("");
  const [VoucherAmount, SetVoucherAmount] = useState("");
  const [startDate, setstartDate] = useState("");
  const [expiryDate, setexpiryDate] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [searchValue, setSearchValue] = useState("");
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [selectedRows, setSelectedRows] = useState([]);




  const [voucherNumber, setvoucherNumber] = useState('')
  const [amount, setAmount] = useState('')
  const [expiryDategenerate, setexpiryDateGenerate] = useState('')
  const [voucherId, setvoucherId] = useState('')
  const [updatedAtgenerate, setupdatedAtDateGenerate] = useState('')


  const [validateModalOPen, setvalidateModalOPen] = useState(false);
  const validateModalClose = () => setvalidateModalOPen(false);
  const [message, setMessage] = useState("Successfully...");
  const [validate_message, setvalidate_message] = useState("");
  const [titleMessage, settitleMessage]= useState('Decline')



  



  const addIds = (newId, row) => {
    const newIdArray = [...selectedRows];
    console.log(row, 'rowsssssssssssssssss');

    const index = newIdArray.indexOf(newId);

    if (index === -1) {
      newIdArray.push(newId);
      setvoucherNumber(row.voucherNo)
      setAmount(row.amount)
        setexpiryDateGenerate(row.expiryDate)
      setupdatedAtDateGenerate(row.updatedAt)
      setvoucherId(row.id)


    } else {
      newIdArray.splice(index, 1);
    }
    setSelectedRows(newIdArray);
  };
 
  console.log(selectedRows, 'Selected ROWS');
  
  const handleDateChange = (value) => {
    setChangeDate(value);
  };
  const changeDateObj = dayjs(changeDate);
  const ddd =  new Date(changeDate)
  // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
  const formattedChangeDate = changeDateObj.format("YYYY-MM-DDTHH:mm:ssZ");
  const filterDate = moment(formattedChangeDate).format("DD/MM/YYY")
  console.log(ddd, 'Change Date')



  const SubmitForGeneration = async() => {
   if(selectedRows.length > 1){
    alert('You can generate one voucher at time')

   }else{
    try {
      console.log(voucherNumber, 'voucherNumber');
      console.log(amount, 'amount');
      console.log(expiryDategenerate, 'expiryDategenerate');
      console.log(voucherId, 'voucherId');
      console.log(updatedAtgenerate, 'updatedAtgenerate');

      const generateCode = await fetch(`${baseUrl}/api/generate_voucher`,{
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body:JSON.stringify({
          voucherNumber:voucherNumber,
          amount:amount,
          expiryDate:expiryDategenerate,
          voucherId:voucherId ,
          startDate:updatedAtgenerate,
          voucherid:voucherId})
      });

      await generateCode.json().then((data) => {
        setShareModalOpen(true)

      })      
    } catch (error) {
      
    }
   }
    



  }

  // Currency Formatter
  const AllColumns = [
    {
      field: "voucherNo",
      headerName: "Voucher NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Voucher NO
            </Typography>
          </div>
        );
      },

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount (KES)",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Amount 
            </Typography>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {numberFormat(params.value)}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "expiryDate",
      headerName: "Expires On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {moment(params.value).format("DD/MM/YYYY")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Date Used On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.row.usage_status == "USED" ? moment(params.row.updatedAt).format("DD/MM/YYYY")   : '-'  }
            </Typography>
          </div>
        );
      },
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },
   
   

    {
      field: "usage_status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        // #17ae7b

        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value == "UNUSED" ? (
                <span style={{ color: "#6e7074" }}>{params.value}</span>
              ) : params.value == "USED" ? (
                <span style={{ color: "#17ae7b" }}>{params.value}</span>
              ) : params.value == "EXPIRED" ? (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              ) : (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              )}
            </Typography>
          </div>
        );
      },
    },
    {
        field: "share",
        headerName: "Action",
        flex: 1,
        headerAlign: "center",
        align: "center",
        editable: false,
        headerClassName: "super-app-theme--header",
        renderCell: (params) => {
          return (
            <div>
              <Typography variant="body2" color="textSecondary">
                {params.row.usage_status == "USED" || params.row.usage_status == "EXPIRED" ? '' : 
                 <input
                 type="checkbox"
                 onClick={() => {
                  addIds(params.row.id, params.row)}}/>                
                
                   }
              </Typography>
            </div>
          );
        },
        valueFormatter: (params) => {
          // console.log("params", params);
          return moment(params.value);
        },
      },
  ];
  const UsedColumns = [
    {
      field: "voucherNo",
      headerName: "Voucher NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Voucher NO
            </Typography>
          </div>
        );
      },

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount (KES)",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Amount
            </Typography>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {numberFormat(params.value)}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "expiryDate",
      headerName: "Expires On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {moment(params.value).format("DD/MM/YYYY")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Date Used On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.row.status == "UNSED" ? "-" : moment(params.row.updatedAt).format("DD/MM/YYYY")}
            </Typography>
          </div>
        );
      },
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },
   

    {
      field: "usage_status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        // #17ae7b

        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value == "UNUSED" ? (
                <span style={{ color: "#6e7074" }}>{params.value}</span>
              ) : params.value == "USED" ? (
                <span style={{ color: "#17ae7b" }}>{params.value}</span>
              ) : params.value == "EXPIRED" ? (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              ) : (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              )}
            </Typography>
          </div>
        );
      },
    },
  ];
  
  const UnusedColumns = [
    {
      field: "voucherNo",
      headerName: "Voucher No",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Voucher NO
            </Typography>
          </div>
        );
      },

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount (KES)",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Amount
            </Typography>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {numberFormat(params.value)}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "expiryDate",
      headerName: "Expires On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {moment(params.value).format("DD/MM/YYYY")}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "updatedAt",
      headerName: "Date Used On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.row.usage_status == "USED" ? moment(params.row.updatedAt).format("DD/MM/YYYY")   : '-'  }
            </Typography>
          </div>
        );
      },
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },

    {
      field: "usage_status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value == "UNUSED" ? (
                <span style={{ color: "#6e7074" }}>{params.value}</span>
              ) : params.value == "USED" ? (
                <span style={{ color: "#17ae7b" }}>{params.value}</span>
              ) : params.value == "EXPIRED" ? (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              ) : (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              )}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "share",
      headerName: "Action",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.row.usage_status == "USED" || params.row.usage_status == "EXPIRED" ? '' : 
               <input
               type="checkbox"
               onClick={() => {
                addIds(params.row.id, params.row)}}/>                
              
                 }
            </Typography>
          </div>
        );
      },
      valueFormatter: (params) => {
        // console.log("params", params);
        return moment(params.value);
      },
    },
  ];

  const ExpiredColumns = [
    {
      field: "voucherNo",
      headerName: "Voucher NO",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Voucher NO
            </Typography>
          </div>
        );
      },

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "amount",
      headerName: "Amount (KES)",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderHeader: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary" style={{ fontWeight: "bold" }}>
              Amount
            </Typography>
          </div>
        );
      },
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {numberFormat(params.value)}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "expiryDate",
      headerName: "Expired On",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {moment(params.value).format("DD/MM/YYYY")}
            </Typography>
          </div>
        );
      },
    },
   

    {
      field: "usage_status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      align: "center",
      editable: false,
      headerClassName: "super-app-theme--header",

      renderCell: (params) => {
        return (
          <div>
            <Typography variant="body2" color="textSecondary">
              {params.value == "UNUSED" ? (
                <span style={{ color: "#6e7074" }}>{params.value}</span>
              ) : params.value == "USED" ? (
                <span style={{ color: "#17ae7b" }}>{params.value}</span>
              ) : params.value == "EXPIRED" ? (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              ) : (
                <span style={{ color: "#dc3545" }}>{params.value}</span>
              )}
            </Typography>
          </div>
        );
      },
    },
  ];

  const ativeRow = allTab
    .filter((item) => {
      return searchValue?.toLowerCase() === ""
        ? item
        : item?.voucherNo?.toLowerCase().includes(searchValue?.toLowerCase());
    })
    .filter((item) => {
        return status === "USED" ? item?.usage_status == 'USED' : status === "UNUSED" ? item?.usage_status  === 'UNUSED': item
        
    })
    // .filter((item) => {
    //   const convertDate = new Date(item?.expiryDate )
    //   const changeD = moment(convertDate).format("DD/MM/YYY")
    //   console.log(filterDate, 'filterDate','changeDj', changeD );
    //   return filterDate == changeD ? changeD : item


    // })
    .map((item, index) => ({
      id: item._id,
      voucherNo: item.voucherNo,
      amount: item.amount,
      status: item.status,
      usage_status: item.usage_status,
      expiryDate: item.expiryDate,
      updatedAt: item.updatedAt,
    }));
  console.log("unusedTabs", unusedTabs);
  
  const UnusedRows = unusedTabs
    .filter((item) => {
      return searchValue?.toLowerCase() === ""
        ? item
        : item?.voucherNo?.toLowerCase().includes(searchValue?.toLowerCase());
    }) .filter((item) => {
      return status === "USED" ? item?.usage_status == 'USED' : status === "UNUSED" ? item?.usage_status  === 'UNUSED': item
      
  })
    .map((item, index) => ({
      id: item._id,
      voucherNo: item.voucherNo,
      amount: item.amount,
      status: item.status,
      usage_status: item.usage_status,
      expiryDate: item.expiryDate,
      updatedAt: item.updatedAt,
    }));
  const usedRows = usedTabs
    .filter((item) => {
      return searchValue?.toLowerCase() === ""
        ? item
        : item?.voucherNo?.toLowerCase().includes(searchValue?.toLowerCase());
    }) .filter((item) => {
      return status === "USED" ? item?.usage_status == 'USED' : status === "UNUSED" ? item?.usage_status  === 'UNUSED': item
      
  })
    .map((item, index) => ({
      id: item._id,
      voucherNo: item.voucherNo,
      amount: item.amount,
      status: item.status,
      usage_status: item.usage_status,
      expiryDate: item.expiryDate,
      updatedAt: item.updatedAt,
    }));

  const expiredRows = expiredTabs
    .filter((item) => {
      return searchValue?.toLowerCase() === ""
        ? item
        : item?.voucherNo?.toLowerCase().includes(searchValue?.toLowerCase());
    }).filter((item) => {
      return status === "USED" ? item?.usage_status == 'USED' : status === "UNUSED" ? item?.usage_status  === 'UNUSED': item
      
  })
    .map((item, index) => ({
      id: item._id,
      voucherNo: item.voucherNo,
      amount: item.amount,

      usage_status: item.usage_status,
      expiryDate: item.expiryDate,
      updatedAt: item.updatedAt,
    }));

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
    console.log(searchValue, "hello search.........................................");
  };
  useEffect(() => {
    get_All_batch_vouchers();
    get_unused_batches();
    get_used_batches();
    get_Expired_batch_vouchers();
  }, [tabValue, pageState.page, pageState.pageSize]);

  const get_All_batch_vouchers = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(
        `${baseUrl}/api/get_vouchers_by_batch?limit=${pageState.pageSize}&page=${pageState.page}&batchId=${batchId}&status=ALL`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await ActiveResponse.json();
      setAllTabs(data.data);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data,
      }));
      if (ActiveResponse.status === 200) {
        setAllTabs(data.data);
        console.log("ALL Data", data.data);
        localStorage.setItem("tabValue", "ALL");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };




  const get_used_batches = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(
        `${baseUrl}/api/get_vouchers_by_batch?limit=${pageState.pageSize}&page=${pageState.page}&batchId=${batchId}&status=USED`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await ActiveResponse.json();
      setUsedTabs(data.data);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data,
      }));
      if (ActiveResponse.status === 200) {
        setUsedTabs(data.data);
        console.log("USED DATA", data.data);
        localStorage.setItem("tabValue", "USED");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const get_unused_batches = async () => {
    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(
        `${baseUrl}/api/get_vouchers_by_batch?limit=${pageState.pageSize}&page=${pageState.page}&batchId=${batchId}&status=UNUSED`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await ActiveResponse.json();
      setUnusedTabs(data.data);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data,
      }));
      if (ActiveResponse.status === 200) {
        setUnusedTabs(data.data);
        console.log("UNUSED Data", data.data);
        localStorage.setItem("tabValue", "UNUSED");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };
  const get_Expired_batch_vouchers = async () => {                          

    try {
      // console.log("ON");
      setPageState((old) => ({ ...old, isLoading: true }));
      const ActiveResponse = await fetch(
        `${baseUrl}/api/get_vouchers_by_batch?limit=${pageState.pageSize}&page=${pageState.page}&batchId=${batchId}&status=EXPIRED`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        }
      );
      const data = await ActiveResponse.json();
      setExpiredTabs(data.data);
      setPageState((old) => ({
        ...old,
        isLoading: false,
        data: data.data,
      }));
      if (ActiveResponse.status === 200) {
        setExpiredTabs(data.data);
        console.log("setExpiredTabs", data.data);
        localStorage.setItem("tabValue", "EXPIRED");
      } else if (ActiveResponse.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handlExpiryDateChange = (value) => {
    setexpiryDate(value);
  };

  const handleStartDateChange = (value) => {
    setstartDate(value);
  };
  const handleSubmut = async (e) => {
    e.preventDefault();
    console.log("NoOfVouchers", NoOfVouchers);
    console.log("VoucherAmount", VoucherAmount);
    console.log("startDate", startDate);
    console.log("expiryDate", expiryDate);

    const expiryDateObj = dayjs(expiryDate);
    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedExpiryDate = expiryDateObj.format("YYYY-MM-DDTHH:mm:ssZ");
    // Add one year to the date and format it again
    const nextYearExpiryDate = expiryDateObj.add(1, "year").format("YYYY-MM-DDTHH:mm:ssZ");
    console.log("formattedExpiryDate", formattedExpiryDate);

    const startDateObj = dayjs(startDate);
    // Format the date as "YYYY-MM-DDTHH:mm:ssZ"
    const formattedStartDate = startDateObj.format("YYYY-MM-DDTHH:mm:ssZ");
    // Add one year to the date and format it again
    const nextYearStartDate = startDateObj.add(1, "year").format("YYYY-MM-DDTHH:mm:ssZ");
    console.log("formattedStartDate", formattedStartDate);

    try {
      const response = await fetch(`${baseUrl}/api/create_voucher_batch`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
        body: JSON.stringify({
          noOfVouchers: NoOfVouchers,
          eachVoucherAmount: VoucherAmount,
          startDate: formattedStartDate,
          expiryDate: formattedExpiryDate,
        }),
      });
      const data = await response.json();
      console.log("data", data);
      if (response.status === 200) {
        console.log("data", data);
        setDeactivateModalOpen(true);
        handleClose();
      }
    } catch {}
  };

  const [deactivateModalOpen, setDeactivateModalOpen] = useState(false);
  const DeactivateModalHandleClose = () => setDeactivateModalOpen(false);
console.log('STATUS', status);
  return (
    <div>
      <div>
        <div>
          <div>
            <Grid container spacing={2} justifyContent="space-between" direction={"row"} alignItems="center">
              <Grid item column={6}>
                <span
                  style={{
                    margin: "0px 90px 21px 0",

                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: " normal",
                    fontStyle: " normal",
                    lineHeight: " 1.32",
                    letterSpacing: " normal",
                    textAlign: " left",
                    color: "#dc3545",
                  }}
                >
                  {" "}
                  vouchers
                </span>
              </Grid>
              <Grid item column={6}>
                <Grid container spacing={2} justifyContent="space-between" direction={"row"} alignItems="center">
                <Grid item column={6}>
                      {tabValue == 'UNUSED' || tabValue == 'ALL' ? <><Button
                        style={{
                          width: " 146px",
                          height: "55px",
                          margin: "0px 20px 24px 6px",
                          padding: "16px 30px 14px",
                          borderRadius: " 4px",
                          backgroundColor: "#032541",
                          color: "#fff",
                          textAlign: "#center",
                        }}
                        onClick={() => {
                          if(selectedRows == '' ){
                            setvalidateModalOPen(true)
                            settitleMessage('Generate Voucher') 
                            setMessage('Kindly select the voucher you want generate')
                            setvalidate_message('Kindly select the voucher you want generate')
                            

                          } else{
                            SubmitForGeneration();
                          }
                         
                          
                        }}
                      >
                        <ShareIcon style={{ marginRight: "2px" }} /> share
                      </Button></> : ''}
                      
                    </Grid>
                  <Grid item column={6}>
                    <Button
                      onClick={handleOpen}
                      style={{
                        width: " 250px",
                        height: "55px",
                        margin: "0px 0px 24px 6px",
                        padding: "18px 25.7px 17px 25px",
                        borderRadius: " 5px",
                        backgroundColor: "#032541",
                        color: "#fff",
                        textAlign: "#center",
                      }}
                    >
                      {" "}
                      <AddIcon style={{ marginRight: "3px" }} />
                      Create Voucher
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <div className="bread-crumbs">
              <span
                style={{
                  height: "28px",
                  margin: "21px 0px 39px 12px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#6e7074",
                }}
              >
                Dashboard
              </span>
              <span
                style={{
                  height: "28px",
                  margin: "21px 0px 39px 12px",
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#6e7074",
                }}
              >
                \
              </span>
              <span
                style={{
                  fontFamily: "Poppins",
                  fontSize: "20px",
                  fontWeight: "600",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.65",
                  letterSpacing: "normal",
                  textAlign: "left",
                  color: "#032541",
                }}
              >
                {tabValue == 'ALL' ? 'ACTIVE' : tabValue}
              </span>
            </div>
            <div></div>
            <TabContext value={tabValue}>
              <Box>
                <div className="filter-tabs">
                  <div>
                    <Grid container justifyContent="space-between" direction={"row"} alignItems="center">
                      <div style={{ display: "flex" }}>
                      <div className="voucherBack pt-4" >
                        <Link to='/vouchers'>
                        <ArrowBack style={{fontSize:"30px"}} />
                        </Link>
                          
                          </div>
                        <AntTabs
                          onChange={handleTabChange}
                          aria-label="Expenses tabs"
                          TabIndicatorProps={{ hidden: false }}
                          textColor="primary"
                        
                        >
                          <AntTab   style={{fontSize:"20px"}} label="Active" value="ALL" />
                          <AntTab   style={{fontSize:"20px"}} label="Unused" value="UNUSED" />
                          <AntTab   style={{fontSize:"20px"}} label="Used" value="USED" />
                          <AntTab   style={{fontSize:"20px"}} label="Expired" value="EXPIRED" />
                        </AntTabs>
                      </div>

                      <Grid item>
                        <label
                          style={{
                            height: "12px",
                            fontFamily: "Poppins",
                            fontSize: "9px",
                            fontWeight: "bold",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "2.78",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#032541",
                          }}
                        >
                          Search :{" "}
                        </label>
                        <Input
                          startDecorator={<SearchIcon />}
                          size="sm"
                          variant="plain"
                          value={searchValue}
                          onChange={handleSearchBox}
                          style={{
                            fontSize: "12px",
                            color: "#272d3b",
                            borderRadius: "6px",
                            width: "238px",
                            height: "36px",
                            margin: "0 0 0 20.4px",
                            padding: "7px 107.7px 6.4px 11.1px",
                            objectFit: "contain",
                            border: "solid 1px #032541",
                          }}
                          placeholder="search here"
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <Grid container justifyContent="space-between" direction={"row"} alignItems="center">
                  {/*SubCategories Label*/}
                  <Grid item>
                    <Grid item justifyContent="space-between" style={{ marginTop: "34px" }}>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          margin: "0 7px 2px 0",

                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginRight: "18px",
                        }}
                      >
                        {" "}
                        show{" "}
                      </label>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          sx={{
                            height: "40px",
                            borderRadius: "6pxchangeDate",
                            fontFamily: "Poppins",
                            border: "solid 1px #707070",
                            width: "80px",
                            marginRight: "18px",
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          defaultValue={10}
                        >
                          <MenuItem value={5}>5</MenuItem>
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={20}>20</MenuItem>
                        </Select>
                      </FormControl>
                      <label
                        style={{
                          fontFamily: "Poppins",
                          margin: "0 7px 2px 0",

                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "1.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginRight: "18px",
                        }}
                      >
                        {" "}
                        Entries{" "}
                      </label>

                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginLeft: "25px",
                        }}
                      >
                        {" "}
                        Filter by :{" "}
                      </label>
                      <FormControl sx={{ m: 1 }} size="small">
                        <Select
                          sx={{
                            height: "40px",
                            borderRadius: "6px",
                            fontFamily: "Poppins",
                            border: "solid 1px #707070",
                            width: "120px",
                            marginRight: "18px",
                          }}
                          labelId="demo-select-small"
                          id="demo-select-small"
                          defaultValue={10}
                          value={status}
                          onChange={(e) => setStatus(e.target.value)}
                        >
                          <MenuItem value="ALL">All</MenuItem>
                          <MenuItem value="USED">Used</MenuItem>
                          <MenuItem value="UNUSED">Unused</MenuItem>
                          <MenuItem value="EXPIRED">"Expired</MenuItem>

                        </Select>
                      </FormControl>

                      <label
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "2.78",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#6e7074",
                          marginLeft: "25px",
                        }}
                      >
                        {" "}
                        Filter By Date{" "}
                      </label>
                      <FormControl style={{ marginLeft: "10px" }} size="small">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label="FIlter by Date"
                            renderInput={(params) => <TextField {...params} />}
                            // format="YYYY-MM-DD HH:MM:ss"
                            inputFormat="DD-MM-YYYY HH:mm:ss"
                            format="DD-MM-YYYY HH:MM:ss"
                            value={changeDate}
                            onChange={handleDateChange}
                          />
                        </LocalizationProvider>
                      </FormControl>
                    </Grid>
                  </Grid>

                  {/*Export buttons*/}
                  <Grid item alignContent={"right"} justifyContent="space-between" style={{ marginTop: "34px" }}>
                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#05d8e1",
                      }}
                    >
                      CSV
                    </Button>

                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 12px",
                        padding: "9px 7px 8px",
                        backgroundColor: "#032541",
                      }}
                    >
                      Excel
                    </Button>

                    <Button
                      sx={{
                        fontWeight: 600,
                        color: "#fff",
                        width: "50px",
                        height: "37px",
                        margin: "0 0 0 12px",
                        padding: "9px 12px 8px",
                        backgroundColor: "#dc3545",
                      }}
                    >
                      PDF
                    </Button>
                  </Grid>
                </Grid>
              </Box>
              <TabPanel value="ALL">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={ativeRow}
                  columns={AllColumns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
              <TabPanel value="UNUSED">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={UnusedRows}
                  // rows={ativeRow}
                  columns={UnusedColumns}
                  // columns={[]}

                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
              <TabPanel value="USED">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={usedRows}
                  columns={UsedColumns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
              <TabPanel value="EXPIRED">
                <DataGrid
                  style={{ height: "500px", width: "auto" }}
                  rows={expiredRows}
                  columns={ExpiredColumns}
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "10px",
                    color: "#272d3b",
                  }}
                />
              </TabPanel>
            </TabContext>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={AddProductModalstyle}>
          <Box
            sx={{
              width: "665px",
              height: "90px",
              margin: "0 0 9px",
              padding: "35px 30px 34px",
              borderRadius: "16px",
              backgroundColor: "#dc3545",
            }}
          >
            <Typography
              style={{
                width: "100%",
                height: "21px",
                margin: "0 423px 0 0",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "normal",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#fff",
              }}
            >
              <div className="top-modal-bar" style={{ display: "flex", justifyContent: "space-around" }}>
                <ArrowBack onClick={handleClose} />
                <span>CREATE A VOUCHER</span>
                <CloseIcon onClick={handleClose} />
              </div>
            </Typography>
          </Box>
          <Box
            sx={{
              width: "605.2px",
              height: "494.5px",
              margin: "18.2px 29.4px 6.2px 30.4px",
              padding: "33.8px 55.6px 0.8px 43.6px",
              backgroundColor: "#f5f7ff",
            }}
          >
            <form onSubmit={handleSubmut}>
              <FormControl
                variant="standard"
                style={{
                  width: "100%",
                  height: "100%",
                  margin: "0 0 9px",
                }}
              >
                <TextField
                  id="No of Vouchers"
                  label="No of Vouchers"
                  required
                  style={{
                    marginTop: "20px",
                  }}
                  value={NoOfVouchers}
                  type="number"
                  onChange={(e) => {
                    setNoOfVouchers(e.target.value);
                  }}
                />
                <TextField
                  style={{
                    marginTop: "20px",
                  }}
                  value={VoucherAmount}
                  required
                  id="outlined-multiline-static"
                  label="Each Voucher Amount"
                  defaultValue="Each Voucher Amount"
                  type="number"
                  placeholder="Each Voucher Amount"
                  onChange={(e) => {
                    SetVoucherAmount(e.target.value);
                  }}
                />
                <FormControl style={{ marginTop: "20px" }} size="small">
                  {/* <label htmlFor=""> Start Date</label>
                                   <input type="datetime-local" name=""  value={startDate}
                                    onChange={(e) => setstartDate(e.target.value)} style={{height:"60px", width:"100%", outline:"none",fontSize:"20x", color:"#676888",  border:"none", border:"1px solid #676888" ,paddingLeft:"3px" }}  />
                                     */}
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Start Date"
                      renderInput={(params) => <TextField {...params} />}
                      // format="YYYY-MM-DD HH:MM:ss"
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      value={startDate}
                      onChange={handleStartDateChange}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl style={{ marginTop: "20px" }} size="small">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Expiry Date"
                      renderInput={(params) => <TextField {...params} />}
                      // format="YYYY-MM-DD HH:MM:ss"
                      inputFormat="DD-MM-YYYY HH:mm:ss"
                      value={expiryDate}
                      onChange={handlExpiryDateChange}
                    />
                  </LocalizationProvider>

                  {/* <label htmlFor=""> Expiry Date</label>
                                   
                                   <input type="datetime-local" name=""  value={startDate}
                                    onChange={(e) => setstartDate(e.target.value)} style={{height:"60px", width:"100%", outline:"none",fontSize:"20x", color:"#676888",  border:"none", border:"1px solid #676888" ,paddingLeft:"3px" }}  /> */}
                </FormControl>

                <div sx={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{
                      margin: "20px 0px 0",
                      borderRadius: "4px",
                      backgroundColor: "#9BA6B3",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "3.14",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#fff",
                      width: "502px",
                      height: "50px",
                    }}
                  >
                    Submit For Approval
                  </Button>
                </div>
              </FormControl>
            </form>
          </Box>
        </Box>
      </Modal>
      <ValidationAlertMessage
        deactivateModalOpen={validateModalOPen}
        onClose={validateModalClose}
        message={validate_message}
        titleMessage={titleMessage}
        
        
      />
      <SuccessVoucher deactivateModalOpen={deactivateModalOpen} onClose={DeactivateModalHandleClose} />
      <ShareGenerateMOdal shareModalOpen={shareModalOpen} onClose={ShareModalHandleClose} voucherNumber={voucherNumber} />
      
    </div>
  );
};

export default VoucherDetails;
