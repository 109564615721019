import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField, Button, Grid } from "@mui/material";
import { customerstyles } from "./styles";
import { removeInvoiceItems } from "../../../features/startorderSlice";
import { useDispatch, useSelector } from "react-redux";

export const CustomDate = (props) => {
  const dispatch = useDispatch();
  const setDateFrom = props?.setDateFrom;
  const setDateTo = props?.setDateTo;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const gridFormatDate = (inputDate) => {
    // const today = new Date();
    const yyyy = new Date(inputDate)?.getFullYear();
    let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
    let dd = new Date(inputDate)?.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    return formattedToday;
  };

  useEffect(() => {
    const newStartDate = gridFormatDate(startDate);
    setDateFrom(newStartDate);
    const newEndDate = gridFormatDate(endDate);
    setDateTo(newEndDate);
  }, [startDate, endDate]);

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        {/* <DatePicker label="Uncontrolled picker" defaultValue={dayjs(new Date())} /> */}

        <Grid container>
          <Grid item width={"50%"}>
            <DatePicker label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ margin: "0px 15px 0px 15px" }} />} />
          </Grid>

          <Grid item width={"50%"}>
            <DatePicker label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} />} />
          </Grid>
        </Grid>
      </LocalizationProvider>
    </div>
  );
};

export const CustomizedDate = ({ style, setDateFrom, setDateTo }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  
  const gridFormatDate = (inputDate) => {
    // const today = new Date();
    const yyyy = new Date(inputDate)?.getFullYear();
    let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
    let dd = new Date(inputDate)?.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = yyyy + "-" + mm + "-" + dd;

    return formattedToday;
  };

  useEffect(() => {
    const newStartDate = gridFormatDate(startDate);
    startDate ? setDateFrom(newStartDate) : setDateFrom("")
    const newEndDate = gridFormatDate(endDate);
    endDate ? setDateTo(newEndDate) : setDateTo("")
  }, [startDate, endDate]);

  return (
    <div style={style}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker label="Start" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ margin: "0px 15px 0px 15px" }} />} />
        <DatePicker label="End" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} />} />
      </LocalizationProvider>
    </div>
  );
};

export const gridDateFormater = (inputDate) => {
  // const today = new Date();
  const yyyy = new Date(inputDate)?.getFullYear();
  let mm = new Date(inputDate)?.getMonth() + 1; // Months start at 0!
  let dd = new Date(inputDate)?.getDate();

  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday = dd + "/" + mm + "/" + yyyy;

  return formattedToday;
};

export const PreviewBtn = (props) => {
  const setInvoiceArray = props?.setInvoiceArray;
  const setPreviewInvoiceItemsShow = props?.setPreviewInvoiceItemsShow;
  const invoiceTotals = props?.invoiceTotals;
  const setError = props?.setError;
  const dispatch = useDispatch();
  return (
    <div>
      {/* <Button onClick={() => setInvoiceArray([])} style={customerstyles.cancelBtn}> */}
      <Button
        onClick={() => {
          dispatch(removeInvoiceItems());
          setInvoiceArray([]);
        }}
        style={customerstyles.cancelBtn}
      >
        Cancel
      </Button>
      <Button style={customerstyles.approvalButton} onClick={() => (invoiceTotals > 0 ? setPreviewInvoiceItemsShow(true) : setError({ state: true, message: "Check the invoice Amount" }))}>
        Preview Invoice
      </Button>
    </div>
  );
};
