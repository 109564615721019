import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);


function ConfirmationAlert(props) {
  // session Expired
  const style = {
    borderColor: "transparent",
    borderRadius: "36px",
    backgroundColor: "#fff",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "400px",
    height: "350px",
    p: 4,
  };

  

  return (
    <div>
      <Modal
        open={props.deactivateModalOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={props.formAction}>
            <div
              style={{
                width: "50px",
                height: "50px",
                marginLeft: "150px",
                padding: "12px 30px 127px",
                objectFit: "contain",
              }}
            >
              <img
                style={{ width: "95px", height: "95px", margin: "-24px 0px 0px -66px" }}
                src={props.actionImage}
                alt="img"
              />
            </div>
            <div
              style={{
                marginTop:"-30px",
                fontFamily: "Poppins",
                fontSize: "25px",
                fontWeight: "bold",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "normal",
                letterSpacing: "normal",
                textAlign: "center",
                color: "#323d52",
              }}
            >
              <span>{props.title}</span>
            </div>
            <div>
              <span 
               style={{
                textAlign:"center",
                fontSize:"18px"
               
              }}
              >
                Are you sure you want {props.action}
                Vouchers worth {props.action} <b style={{
                    color:"#323d52"
                }}>{numberFormat(props.amt)}</b>
              </span>
            </div>
            <div align="right">
              <Button
                onClick={props.onClose}
                variant="outlined"
                style={{
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  textAlign: "left",
                  color: "#dc3545",
                  width: "90px",
                  height: "40px",
                  margin: "40px 30px 0 0",
                  padding: "9px 29px 8.1px 30px",
                  border: "solid 1px #dc3545",
                }}
              >
                Cancel
              </Button>
              {/*  save  */}
              <Button
                type="submit"
                variant="standard"
                style={{
                  color: "#fff",
                  fontFamily: "Poppins",
                  fontSize: "12px",
                  width: "90px",
                  height: "40px",
                  margin: "40px 50px 0 30px",
                  padding: "9px 32px 8.1px 31px",
                  backgroundColor: "#032541",
                }}
              >
                {props.processName}
              </Button>
            </div>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
export { ConfirmationAlert };
