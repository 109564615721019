import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess } from "@material-ui/icons";
import { ExpandMore } from "@mui/icons-material";
import { Collapse } from "@mui/material";
import List from "@material-ui/core/List";
import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import InventoryIcon from '@mui/icons-material/Inventory';
import reportIcon from '../../components/School/Images/report.svg';
import HttpComponent from "../../components/School/MakeRequest";
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function SchoolReport(props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);
	const [status, setStatus] = useState()
	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"
	const [reportsOpen, setReportsOpen] = useState(false)
	const handleReportsOpen = () => { setReportsOpen(!reportsOpen) }

	const [payitStatus, setPayitStatus] = useState()

	function getPayitStatus() {
		try {
			HttpComponent({
				method: "GET",
				url: '/api/v1/getZedPayItStatus',
				token: localStorage.getItem('X-Authorization'),
			}).then((data) => {
				//console.log(data , 'payit status')
				if (data.status === 200) {
					setPayitStatus(data?.response?.data)
				}
			})
		} catch (error) {
		}
	}

	useEffect(() => {
		getPayitStatus()
	}, [])


	const handleClick = () => { setOpen(!open); };

	const userGroup = localStorage.getItem('group')
	const businessCat = localStorage.getItem('businessCategory')
	// useEffect(()=>{
	// 	console.log("category in inventory", props.businessCat)
	// },[props.businessCat])

	const getWareHouseStatus = async () => {
		try {
			const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					"X-Authorization": localStorage.getItem("X-Authorization"),

				},
			})
			const data = response.json()
				.then((response) => {
					// console.log('status getWareHouseStatus ', response.data.enabled);
					setStatus(response?.data?.enabled)
				})

		} catch (error) {


		}


	}

	useEffect(() => {
		getWareHouseStatus()

	}, [])
	return (
		<div>

			{/*Users*/}
			{businessCat === 'School' ?
				<>
					<ListItem button onClick={handleClick} style={{ color: open ? '#04f2fc' : '#fff' }}>
						<div style={{ marginRight: '5%' }}>
							<img src={reportIcon} alt="" />
						</div>

						<ListItemText primary="Reports" style={{ fontFamily: 'Poppins' }} />



						{open ? <ExpandLess /> : <ExpandMore />}
					</ListItem>

					{/*Collapsed Users*/}
					<Collapse in={open} timeout="auto" unmountOnExit>
						<List component="div" disablePadding>

							{userGroup !== 'Admin' && userGroup === 'Merchant' || userGroup === "Director" || userGroup === 'Accountant' ?
								<div>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/voidtransactionreport") ? activeColor : '#fff' }} to="/voidtransactionreport">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Void Transactions" />

										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/reports") ? activeColor : '#fff' }} to="/school/reports">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Student Report" />

										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/payment-reports") ? activeColor : '#fff' }} to="/payment-reports">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Payment Report" />
										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/studentspaidinvoices") ? activeColor : '#fff' }} to="/school/studentspaidinvoices">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Paid  Invoices Report" />
										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/unpaidinvoices") ? activeColor : '#fff' }} to="/school/unpaidinvoices">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Unpaid invoices Report" />
										</ListItem>
									</Link>
									<Link style={{ textDecoration: 'none', color: isActiveLink("/school/partiallypaidinvoices") ? activeColor : '#fff' }} to="/school/partiallypaidinvoices">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Partially Paid invoices Report" />
										</ListItem>
									</Link>
									{/* <Link style={{ textDecoration: 'none', color: isActiveLink("/school/failedtransactions") ? activeColor : '#fff' }} to="/school/failedtransactions">
										<ListItem button className={classes.nested}>
											<ListItemText primary="Failed Transactions" />
										</ListItem>
									</Link> */}


									{status === true ? <>
										<Link style={{ textDecoration: 'none', color: isActiveLink("/reports") ? activeColor : '#fff' }} to="/reports">
											<ListItem button className={classes.nested}>
												<ListItemText primary="Product Sales Report" />
											</ListItem>
										</Link>
										<Link style={{ textDecoration: 'none', color: isActiveLink("/get_total_sales_by_item_by_payments") ? activeColor : '#fff' }} to="/get_total_sales_by_item_by_payments">
											<ListItem button className={classes.nested}>
												<ListItemText primary="Sales Report" />
											</ListItem>
										</Link> </>
										: null}
									{userGroup !== "Customer" && userGroup !== 'User' && userGroup !== "Cashier" && userGroup !== 'Accountant' && businessCat === "School" && payitStatus === true &&
										<ListItem button onClick={handleReportsOpen} style={{ marginLeft: '5%', color: reportsOpen ? '#04f2fc' : '#fff' }}>
											<ListItemText primary="Zed-Pocket Money Reports" style={{ fontFamily: 'Poppins' }} />
											{reportsOpen ? <ExpandLess /> : <ExpandMore />}
										</ListItem>
									}
								</div> : null}

						</List>
					</Collapse>
					<Collapse in={reportsOpen} timeout="auto" unmountOnExit>
						<div style={{ marginLeft: '5%' }}>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/school/allpayitTransactions") ? activeColor : '#fff' }} to="/school/allpayitTransactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="All Transactions" />
								</ListItem>
							</Link>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/school/cardtransactions") ? activeColor : '#fff' }} to="/school/cardtransactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Card Transactions" />
								</ListItem>
							</Link>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/school/biotransactions") ? activeColor : '#fff' }} to="/school/biotransactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Bio Transactions" />
								</ListItem>
							</Link>
							<Link style={{ textDecoration: 'none', color: isActiveLink("/school/failedtransactions") ? activeColor : '#fff' }} to="/school/failedtransactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="Failed Transactions" />
								</ListItem>
							</Link>
						</div>
					</Collapse>
				</> : null}
		</div>
	)
}
