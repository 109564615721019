import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../MakeRequest";

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Bank Statements</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Upload File</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Add Bank Account</Typography>
]

export default function AddBankDetails() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [formData, setFormData] = useState({ accountName: '', accountNumber: '', bankName: '' })
    const [selectedBankName, setSelectedBankName] = useState('')
    const [banks, setBanks] = useState([])

    //handle on change

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })

    }
    // fetch banks

    const fetchAllBanks = ()=>{
        try{
            HttpComponent({
                method:'GET',
                url:`/api/get_banks_list`,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 201){
                    setBanks(data?.response?.data)
                }
            })

        }catch(error){
            console.log(error)

        }
    }

    useEffect(()=>{
        fetchAllBanks()
    },[])

    // add bank detail

    const addBankDetails = () => {
        try {
            HttpComponent({
                method: 'POST',
                url: `/api/v1/addBankstatementAccounts`,
                body: {
                    bankName: selectedBankName,
                    accountNumber: formData.accountNumber
                },
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setSuccessShow({ state: true, message: data?.response?.message })
                    setTimeout(() => {
                        navigate(-1)
                    }, 2000)
                }
            })
        } catch (error) {
            setErrorShow({ state: true, message: error.message })
        }
    }

    const navigate = useNavigate();

    return (
        <Grid direction={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Add Bank Account</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">Bank</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedBankName}
                        label="Bank"
                        onChange={(e) => setSelectedBankName(e.target.value)}
                        name="bankName"
                    >
                        {banks?.map((item) => (
                            <MenuItem name="outlet" key={item?._id} value={item?.name}>{item?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid mt={2} item width={'50%'}>
                <TextField onChange={handleOnChange} value={formData.accountName} style={{ marginTop: '5px', width: '100%' }} id="outlined-basic" label="Account Name" name="accountName" variant="outlined" />
            </Grid>
            <Grid mt={2} item width={'50%'}>
                <TextField onChange={handleOnChange} value={formData.accountNumber} style={{ marginTop: '5px', width: '100%' }} id="outlined-basic" label="Account Number" name="accountNumber" variant="outlined" />
            </Grid>
            <Grid mt={2} item width={'50%'} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)}  style={{ border:'1px solid #dc3545', color: '#dc3545', textTransform: 'inherit', width: '124px' }}> Cancel</Button>
                <Button onClick={addBankDetails}  style={{ backgroundColor: '#032541', marginLeft:'10px', color: '#fff', textTransform: 'inherit', width: '124px' }}> Add</Button>
            </Grid>
        </Grid>
    )
}