import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ParentPayOptions from "./components/customerAccounts/customerComponents/parentPay";
import TenantDetails from "./components/customerAccounts/tenantComponents/tenantDetails";
import TenantInvoicePreview from "./components/customerAccounts/tenantComponents/tenantInvoice";
import TenantPayInvoice from "./components/customerAccounts/tenantComponents/tenantPayInvoice";
import TenantPaymentOption from "./components/customerAccounts/tenantComponents/tenantPaymentOptions";
import Tenants from "./components/customerAccounts/tenants";
import NewUserDashboard from "./components/Dashboard/newUserDashboard";
import Login from "./components/login/login";

import Signup from "./components/login/signup";
import Dashboard from "./components/Dashboard/pageDashboard";
import CreateBusiness from "./components/new user/createBusiness";
import DefineServices from "./components/Rental/components/defineServices";
import AddServiceFromInvoice from "./components/Rental/components/invoice/addServiceFromInvoice";
import DefineUnits from "./components/Rental/components/Units/defineUnits";
import PreviewTenantInvoice from "./components/Rental/components/invoice/previewTenantInvoice";
import RentalMultiSetup from "./components/Rental/rentalBusinessSetup";
import Transactions from "./components/transactions/transactions";
import Terminal from "./components/terminals/terminals";
import Otp from "./components/otp/otp";
import ResetPassword from "./components/passwordReset/reset";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Unauthorised from "./components/unauthorised/unauthorised";
import Logout from "./components/logout/logout";
import Shops from "./components/shops/shops";
import BusinessTerminals from "./components/businessTerminals/businessTerminals";
import Users from "./components/users/users";
import Reports from "./components/reports/reports";
import Groups from "./components/groups/groups";
import Products from "./components/products/product";
import Categories from "./components/products/categories";
import Stock from "./components/products/stock";
import ForgotPass from "./components/login/forgotPass";
import ResetSuccess from "./components/passwordReset/successReset";
import SentMail from "./components/passwordReset/sentMail";
import ForgotReset from "./components/passwordReset/forgotReset";
import ResetExpPass from "./components/passwordReset/resetExpiredPass";
import Configuration from "./components/configuration/config";
import Reasons from "./components/configuration/reasons";
import BusinessCategories from "./components/configuration/businessCategories";
import ReportsByCashier from "./components/reports/reportsByCashier";
import ReportsUnpaidOrdersByCashier from "./components/reports/reportsUnpaidOrdersByCashier";
import ReportsDetailedSalesByCashier from "./components/reports/reportsDetailedSalesByCashier";
import ReportSalesTotalByProduct from "./components/reports/reportSalesTotalByProduct";
import ReportSalesTotalByProductByPayment from "./components/reports/reportSalesTotalByProductByPayment";
import ReportFailedSales from "./components/configuration/reportFailedSales";
import Customers from "./components/customerAccounts/customers";
import Customerdetails from "./components/customerAccounts/customerComponents/customerdetails";
import InvoicePreview from "./components/customerAccounts/customerComponents/invoicePreview";
import InvoiceItems from "./components/customerAccounts/customerComponents/invoiceItems";
import Invoices from "./components/shops/invoices";
// import Bills from "./components/bills/bills";
// import Startorder from "./components/bills/startorder";
import Bills from "./components/bills/bills";
import Startorder from "./components/bills/startorder";
import DetailedBills from "./components/bills/billscomponents/DetailedBills";
import Invoice from "./components/customerAccounts/customerComponents/invoice";
import { StockConfig } from "./components/configuration/stockConfigs";
import { BusinessConfig } from "./components/configuration/businessConfigs";
import Detailedbill from "./components/bills/billscomponents/detailedbill";
import { NotFound } from "./common/404NotFound";
import "./common/font/Poppins-Regular.ttf";
import CssBaseline from "@material-ui/core/CssBaseline";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import CustomerAccountDash from "./components/customerAccounts/customerAccountDash";
import PrivateRoutes from "./components/layouts/PrivateRoutes";
import PublicRoutes from "./components/layouts/PublicRoutes";
import WithBranchRoutes from "./components/layouts/WithBranchRoutes";
import { SchoolCustomers } from "./components/customerAccounts/customerComponents/schoolCustomers";
import { ParentProfile } from "./components/customerAccounts/customerComponents/parentProfile";
import { StudentProfile } from "./components/customerAccounts/customerComponents/studentProfile";
import { SchoolStudents } from "./components/customerAccounts/customerComponents/schoolStudents";
import CreateEmergency from './components/customerAccounts/customerComponents/addContacts';
import EditProfile from './components/customerAccounts/customerComponents/editStudentProfile';
import { StudentDash } from "./components/customerAccounts/studentDash";
import SchoolInvoicePreview from "./components/customerAccounts/customerComponents/schoolInvoicePreview";
import Receipts from "./components/customerAccounts/customerComponents/receipts";
import Receipt from "./components/customerAccounts/customerComponents/receipt";
import Expenditure from "./components/expenditure/expenditure";
import Cashier from "./components/cashier/cashier";
import SettleCashier from "./components/cashier/settlecashier";
import SchoolSetup from "./components/School/SchoolSetup";
import SchoolCustomerAccount from "./components/customerAccounts/customerComponents/schoolCutomerAccount";
import SchoolInvoiceBatches from "./components/School/InvoiceBatches";
import InvoicesInBatch from "./components/School/InvoicesInBatch";
import GenerateSchoolInvoices from "./components/School/GenerateSchoolInvoices";
import CreditNotePage from "./components/creditnote/creditnote";
import VouchersPage from "./components/vouchers/vouchers";
import ReportAllCashiersSettle from "./components/cashier/allcashiers";
import SchoolListParents from "./components/School/SchoolListParents";
import SchoolAddParent from "./components/School/SchoolAddParent";
import SchoolListStudents from "./components/School/SchoolListStudents";
import VoucherDetails from "./components/vouchers/voucherdetails";
import VoucherGiftPreview from "./components/vouchers/vouchergift";
import UploadParentsStudents from "./components/School/UploadParentsStudents";
import EditSchoolInvoice from "./components/School/editSchoolInvoice";
import UnauthInvoice from "./components/School/UnauthInvoice";
import UnauthPayment from "./components/School/UnauthPayment";
import PaymentSetup from "./components/PaymentSetup/paymentSetup";
import TransactionSummary from "./components/Rental/Dashboard/calenderDrillDown";
import SchoolAddService from "./components/School/SchooAddService";
import Createcreditnote from "./components/creditnote/creditnotecomponents/createcreditnote";
import Units from "./components/customerAccounts/tenantComponents/units";
import TenantInvoice from "./components/customerAccounts/tenantComponents/Invoice & Receipts/invoice";
import TenantReceipt from "./components/customerAccounts/tenantComponents/Invoice & Receipts/receipts";
import Previewcreditnote from "./components/creditnote/creditnotecomponents/previewcreditNote";
import PaymentSettingUp from "./components/paymentmodule/paymentsettingup";
import TenantStatement from "./components/customerAccounts/tenantComponents/tenantDetails/tenantStatement";
import TenantReport from "./components/customerAccounts/tenantComponents/tenantReport";
import SchoolPaymentSetup from "./components/School/PaymentSetup";
import TenantPreviewInvoice from "./components/customerAccounts/tenantComponents/Invoice & Receipts/tenantPreviewInvoice";
import HousingHistory from "./components/customerAccounts/tenantComponents/houseHistory/unitHistory";
import AddStudentExistingParent from "./components/School/addstudentexistingparent";
import ParentEdit from "./components/customerAccounts/customerComponents/parentEdit";
import Suppliers from './components/warehouse/suppliers';
import { Addsupplier } from "./components/warehouse/supplierscomponents/createsupplier";
import ServiceForm from "./components/School/ServiceForm";
import ParentPayMakePayment from "./components/School/parentmakepayment";
import SchoolReports from './components/School/studentsreports';

import Warehouses from "./components/warehouse/warehouse";
import Addwarehouse from "./components/warehouse/warehousecomponents/addwarehouse";
import Substores from "./components/warehouse/substores";
import Addsubstore from "./components/warehouse/substorecomponents/addsubstore";
import Mainstores from "./components/warehouse/mainstore";
import MainStoreEdit from "./components/warehouse/mainstorecomponents/mainstoreEdit";
import Createcustomer from "./components/customerAccounts/customerComponents/createcustomer";
import Customerlist from "./components/customerAccounts/customerlist";
import SchoolTranscations from "./components/School/schooltranscations";
import NewSignUp from "./components/login/SignInOptions";
import { CreditAccount } from "./components/customerAccounts/customerComponents/creditaccount";
import { CreateInvoice } from "./components/Modals/Invoice/createInvoice";
import EmailSignIn from "./components/login/emailsignin";
import GeneralBusinessSetup from "./components/GenaralBusiness/generalBusinessSetup";
import NoticePdf from "./components/customerAccounts/tenantComponents/tenantsExit/NoticePdf";
import StudentPaymentHistory from "./components/School/studentpayments";
import EditTenantInfo from "./components/Rental/components/Tenant/EditTenant";
import SignInOptions from "./components/login/SignInOptions";
import SignUpOptions from "./components/login/SignUpOptions";
import BusinessInvoice from "./components/customerAccounts/customerComponents/businessinvoices";
import CustomerReceipts from "./components/customerAccounts/customerComponents/customerreceipts";
import Paymentreceipt from "./components/customerAccounts/customerComponents/paymentreceipt";
import TenantConfig from "./components/configuration/tenantConfig";

import StatementPreveiw from "./components/customerAccounts/customerComponents/statementpreview";
import Statements from "./components/customerAccounts/customerComponents/statement";
import AllStudents from "./components/School/allstudents";
import PreviewTenantInvoicePage from "./components/Rental/components/invoice/previewInvoicePage";
import NewStudents from "./components/School/newStudents";
import StudentWithBalances from "./components/School/studentwithbalances";
import StudentPaidInvoices from "./components/School/studentspaidinvoices";
import StudentUnpaidInvoices from "./components/School/studentsunpaidinvoice";
import SchoolConfigurationEnable from "./components/School/SchoolConfigurationEnable";
import ProductSetUp from "./components/products/productsetup/Productsetup";
import ServiceSetUp from "./components/products/servicesetup/ServiceSetUp";
import EditProductService from "./components/products/productsetup/editproductcategory";
import EditSingleProduct from "./components/products/servicesetup/editProduct";
import PaymentReport from "./components/School/PaymentReport";
import ReceiptInvoice from "./components/School/receiptsinvoice";

import Activestock from "./components/products/stockComponents/activeStock";
import { ADDSTOCK } from "./components/products/stockComponents/addStock";
import Transferstock from "./components/products/stockComponents/transferstock";
import { APPROVAL } from "./components/products/stockComponents/approval";
import ViewMoreStock from "./components/products/stockComponents/viewMoreStock";
import {STOCKTAKE} from "./components/products/stockComponents/stockTake";
import StockOrder from "./components/products/stockComponents/stockOrder";
import StockOrders from "./components/products/stockComponents/stockOrders";
import ReceiveStock from "./components/products/stockComponents/receiveStock";
import { REPORT } from "./components/products/stockComponents/report";
import StudentsPartiallyPaid from "./components/School/studentPartiallyPaid";
import FailedPayitTransactions from "./components/School/payitfailedtransaction";
import MainBankSetUp from "./common/user Dashboard Components/banks/mainbanksetup";
import { AccountingConfig } from "./components/configuration/accountingconfig";
import SchoolGradesForm from "./components/School/schoolGradesForm";
import AllcustomersTransactions from "./components/customerAccounts/customerComponents/allTransaction";
import UploadParentsStudentsBalances from "./components/School/uploadstudentBalances";
import TerminalUser from "./components/payit/components/terminalusers/terminaluser";
import TerminalOutlets from "./components/payit/components/outlets/outlets";
import TerminalDevices from "./components/payit/components/devicemodels/devicemodel";
import TerminalMgnt from "./components/payit/components/terminalmngnt/terminalmgnt";
import RequestService from "./components/School/requestService";
import PreviewRequestableService from "./components/School/previewRequestableInvoice";
import EditbS from "./components/shops/editBusiness";
import BusinessInfo from "./components/shops/businessinfo";
import RequestJoinBs from "./components/shops/requestjoinbs";
import ApproveJoinBs from "./components/shops/approvejoinbs";
import CardBins from "./components/payit/components/cardbins/cardbins";
import PreviewRequestServiceInvoice from "./components/customerAccounts/customerComponents/previewRequestserviceInvoice";
import ConveninienceFee from "./common/user Dashboard Components/convenienceFee/conviniencemain";
import ConveninienceFeeOtherBiz from "./components/School/convinienceset";
import VoidTransaction from "./components/reports/voidTransaction";
import PayModesLanding from "./components/configuration/zedPayItConfigs/payModeLanding";
import MainBankStatementsSetup from "./components/School/bankstatements/mainBankStatement";
import SchoolAdminPayitDashboard from "./components/payit/components/schoolAdminDashboard/schooladminpayitdashboard";
import GeneralLedgerSetUp from "./common/user Dashboard Components/generalLedgerAcc/mainGlsetUp";
import AllPayitTransactions from "./components/payit/components/payitschoolReports/allpayittransaction";
import AllBioTransactions from "./components/payit/components/payitschoolReports/biotransactions";
import AllPayitCardTransactions from "./components/payit/components/payitschoolReports/payitcardtransactions";
import PromoteStudentSetup from "./components/School/studentpromotion/promotestude";
// function Redirect(props: { to: { pathname: string } }) {
//   return null;
// }


const theme = createTheme({ typography: { fontFamily: ["Poppins"].join(",") } });

const App = () => {
  const { group, branchId } = useSelector((store) => store.user);
  const {category} = useSelector((store)=> store.category)
  
  const branchID = localStorage.getItem("BranchID");
  const Group = localStorage.getItem("group");


  const businessCat = localStorage.getItem('businessCategory');
  const noBranchAndIsAdmin = !branchID && Group && Group == "Admin";
 
  const isStoreOperator = Group && Group.toLocaleLowerCase().includes("storeoperator")
  const isStoreManger = Group && Group.toLocaleLowerCase().includes("storemanager")
  const isGoogleUser = localStorage.getItem('group') === 'User' && !localStorage.getItem('businessCategory')
  const isCashier = branchID && Group && Group == "Cashier" || branchID && isStoreOperator
  const isBusinessUser = Group && Group === 'Customer' && businessCat !== 'Rental' || businessCat !== 'School'
  const isAdmin = Group && Group == "Admin";
  const isSupervisor = Group && Group == "Supervisor";
  const isMerchant = (Group && Group == "Merchant") || Group == "Owner" || Group === "Customer" ;
  const isCashierOrAdminOrSupervisorOrMerchant = isCashier || isAdmin || isSupervisor || isMerchant || Group === "Director";
  const canViewProducts = Group === "Accountant" || Group === "Director" || Group === "Caretaker"
  const noBranchAndIsAdminOrMerchant = noBranchAndIsAdmin || isMerchant || isAdmin || canViewProducts || isCashier || isStoreManger || isStoreOperator || isSupervisor;


// remove all consoles on test and production!
if(process.env.REACT_APP_BASE_URL !== "https://api.dev.zed.business"){
  console.log = function (){}

  // prevent right click on test and production!
window.addEventListener('contextmenu', (e) => {
  e.preventDefault();
});
}



  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route element={<PublicRoutes />}>
            <Route path="*" element={<NotFound />} />
            {/* <Route path="/newsignup" element={<NewSignUp />} />
            <Route path="/" element={<Login />} /> */}

            <Route path="/" element={<SignInOptions />} />
            <Route path="/signup" element={< SignUpOptions />} />
            <Route path="/login/phone" element={<Login />} />
            <Route path="/login/email" element={<EmailSignIn />} />
            <Route path="/signup/email" element={<Signup/>} />
            <Route path="/401" element={<Unauthorised />} />
            <Route path="/forgot" element={<ForgotPass />} />
            <Route path={"/resetSuccess"} element={<ResetSuccess />} />
            <Route path={"/sentMail"} element={<SentMail />} />
            <Route path="/reset" element={<ResetPassword />} />
            <Route path="/forgotReset" element={<ForgotReset />} />
          </Route>

          <Route element={<PrivateRoutes />}>
            <Route path="/userDashboard" element={<NewUserDashboard />} />
            {/* <Route path="/dashboard" element={isCashier ? <Navigate to="/orders/startorder" /> : <Dashboard />} /> */}
            <Route path="/dashboard" element={ <Dashboard />} />
            <Route path="/createBusiness" element={<CreateBusiness />} />
            <Route path={"/sc_preview_invoice"} element={<SchoolInvoicePreview />} />
            <Route path="/transactions" element={<Transactions />} />
            <Route path="/customers" element={<Customerlist />} />
            <Route path="/allcustomerstransaction" element={<AllcustomersTransactions />} />
            <Route path="/customerreceipts" element={<CustomerReceipts />} />
            <Route path="/paymentreceipt/:id/:path" element={<Paymentreceipt />} />
            <Route path="/statementReview" element={<StatementPreveiw />} />
            <Route path="/viewstatement/:id" element={<Statements />} />
            <Route path="/createcustomer/:id" element={<Createcustomer />} />
            <Route path="/creditcustomer/:id/:payType" element={<CreditAccount />} />
            <Route path="/tenants" element={<Tenants />} />
            <Route path="/tenant_invoice_preview" element={<TenantInvoicePreview />} />
            <Route path="/tenants/notice/:noticeNumber" element={<NoticePdf />} />
            <Route path="/invoice/:invoiceNumber" element={<Invoice />} />
            <Route path="/parent/pay/:invoiceNumber" element={<ParentPayMakePayment />} />
            <Route path="/terminal/users" element={<TerminalUser />} />
            <Route path="/outlets" element={<TerminalOutlets />} />
            <Route path="/terminal/devices" element={<TerminalDevices />} />
            <Route path="/terminal/mngt" element={<TerminalMgnt />} />
            <Route path="/cards/cardbins" element={<CardBins/>} />
            <Route path="/tenantInvoice/:invoiceNumber" element={<TenantPayInvoice />} />
            <Route path="/tenantPay/:invoiceNumber" element={<TenantPaymentOption />} />
            {/*<Route path="/tenantReceipt/:invoiceNumber" element={<TenantPaymentOption />} />*/}
            <Route path={"/parent/:customerId"} element={<ParentProfile />} />
            <Route path={"/sc_preview_invoice"} element={<SchoolInvoicePreview />} />
            <Route path={"/request/service/invoice"} element={<PreviewRequestableService />} />
            <Route path="/invoices" element={<Invoices />} />
            <Route path={"/receipts"} element={<Receipts />} />
            <Route path={"/receipt/:receiptNumber/:itemNumber"} element={<Receipt />} />
            <Route path={"/cashier/:id"} element={<Cashier />} />
            <Route path={"/cashier/cash/:id"} element={<SettleCashier />} />
            <Route path={"/creditnote"} element={<CreditNotePage />} />
            <Route path={"/vouchers"} element={<VouchersPage />} />
            <Route path={"/createcreditnote"} element={<Createcreditnote />} />
            <Route path={"/previewcreditnote/:id"} element={<Previewcreditnote />} />
            <Route path={"/receipt/:receiptNumber/:itemNumber"} element={<Receipt/>} />
            <Route path={"/cashier/:id"} element={<Cashier/>} />
            <Route path={"/cashier/cash/:id"} element={<SettleCashier/>} />
            <Route path={"/creditnote"} element={<CreditNotePage/>} />
            <Route path={"/vouchers"} element={<VouchersPage/>} />
            <Route path={"/createcreditnote"} element={<Createcreditnote/>} />
            <Route path={"/previewcreditnote/:id"} element={<Previewcreditnote/>} />
            <Route path={"/suppliers"} element={<Suppliers/>} />
            <Route path={"/addsupplier/:id"} element={<Addsupplier/>} />
            <Route path={"/addwarehouse/:id"} element={<Addwarehouse/>} />
            <Route path={"/warehouses"} element={<Warehouses/>} />
            <Route path={"/substores"} element={<Substores/>} />
            <Route path={"/addsubstore/:id"} element={<Addsubstore/>} />
            <Route path={"/mainstores"} element={<Mainstores/>} />
            <Route path={"/mainstores/:id"} element={<MainStoreEdit/>} />
            {/*Rental*/}
            <Route path="/userDashboard" element={<NewUserDashboard />} />
            <Route path="/setup/rental/:businessName" element={<RentalMultiSetup />} />
            <Route path="/defineUnits" element={<DefineUnits />} />
            <Route path="/tenantPreviewInvoice" element={<PreviewTenantInvoice />} />
            <Route path="/tenantPreviewInvoices" element={<PreviewTenantInvoicePage />} />
            <Route path="/addServiceFromInvoice" element={<AddServiceFromInvoice />} />
            <Route path="/tenantDetails/:id" element={<TenantDetails />} />
            <Route path="/tenant/edit/:customerId" element={<EditTenantInfo />} />
            <Route path="/paymentSetup" element={<PaymentSetup />} />
            <Route path="/transactionSummary/:month/:year" element={<TransactionSummary />} />
            <Route path="/school/transcations/:month/:year" element={<SchoolTranscations />} />
            <Route path="/school/studentpayments/:studentID" element={<StudentPaymentHistory />} />


            <Route path="/units" element={<Units />} />
            <Route path="/tenantInvoices" element={<TenantInvoice />} />
            <Route path="/tenantReceipts" element={<TenantReceipt />} />
            <Route path="/paymentsettingup" element={<PaymentSettingUp />} />
            <Route path="/tenantInvoicePreview/:invoiceNumber" element={<TenantPreviewInvoice />} />
            <Route path="/tenantStatement/:id" element={<TenantStatement />} />
            <Route path="/tenantsReport" element={<TenantReport />} />
            <Route path="/housingHistory" element={<HousingHistory />} />

            {/*School*/}
            <Route path="/parentPay/:invoiceNumber" element={<ParentPayOptions />} />
            <Route path="/setup/school/:businessName" element={<SchoolSetup />} />
            <Route path="/school/service/create" element={<ServiceForm />} />
            <Route path="/school/grades" element={<SchoolGradesForm />} />
            <Route path={"/school/generate/invoice"} element={<GenerateSchoolInvoices />} />
            <Route path={"/school/invoices/batch"} element={<SchoolInvoiceBatches />} />
            <Route path={"/school/upload/studentbalance"} element={<UploadParentsStudentsBalances />} />
            <Route path={"/school/invoices/batch/:batchId"} element={<InvoicesInBatch />} />
            <Route path={"/school/invoice/:invoiceNumber"} element={<Invoice />} />
            <Route path={"/school/student/:customerId/:itemNo"} element={<StudentProfile />} />
            <Route path={"/editprofile/:customerId/:itemNo"} element={<EditProfile/>}/>
            <Route path="/school/parents" element={<SchoolListParents />} />
            <Route path="/school/students" element={<SchoolListStudents />} />
            <Route path="/school/bankstatements" element={<MainBankStatementsSetup />} />
            <Route path="/school/studentpromotion" element={<PromoteStudentSetup />} />
            <Route path="/school/reports" element={<SchoolReports />} />
            <Route path="/payment-reports" element={<PaymentReport />} />
            <Route path={"/school/parent/add"} element={<SchoolAddParent />} />
            <Route path={"/school/student/add/:customerId"} element={<AddStudentExistingParent />} />
            <Route path={"/school/parent/:customerId"} element={<ParentProfile />} />
            <Route path={"/school/parent/add/upload"} element={<UploadParentsStudents />} />
            <Route path={"/school/invoice/edit/:invoiceNumber"} element={<EditSchoolInvoice />} />
            <Route path={"/school/services/add"} element={<SchoolAddService />} />
            <Route path={"/school/parent/edit/:customerId"} element={<ParentEdit />} />
            <Route path={"/school/request/service"} element={<RequestService />} />
            <Route path={"/school/allstudents"} element={<AllStudents />} />
            <Route path={"/school/newstudents"} element={<NewStudents />} />
            <Route path={"/school/studentswithbalances"} element={<StudentWithBalances />} />
            <Route path={"/school/studentspaidinvoices"} element={<StudentPaidInvoices />} />
            <Route path={"/school/unpaidinvoices"} element={< StudentUnpaidInvoices/>} />
            <Route path={"/school/partiallypaidinvoices"} element={< StudentsPartiallyPaid/>} />
            {/* <Route path={"/school/partiallypaidinvoices"} element={< StudentUnpaidInvoices/>} /> */}
            <Route path={"/school/failedtransactions"} element={<FailedPayitTransactions/>} />
            <Route path={"/school/payitshooladmin"} element={<SchoolAdminPayitDashboard/>} />
            <Route path={"/school/configuration"} element={<SchoolConfigurationEnable />} />
            <Route path={"/school/parent/preview"} element={<SchoolConfigurationEnable />} />
            <Route path={"/school/request/preview/invoice"} element={<PreviewRequestServiceInvoice/>} />
            <Route path={"/conviniencefee/otherbusiness"} element={<ConveninienceFeeOtherBiz/>}/>
            <Route path={"/ledgersetup"} element={<GeneralLedgerSetUp/>}/>
            <Route path={"/school/allpayitTransactions"} element={<AllPayitTransactions/>}/>
            <Route path={"/school/biotransactions"} element={<AllBioTransactions/>}/>
            <Route path={"/school/cardtransactions"} element={<AllPayitCardTransactions/>}/>

            {/*<Route path="/school/customer" element={<SchoolCustomerAccount/>}/>*/}
            {/* general business setup route */}
            <Route path="/setup/:category" element={<GeneralBusinessSetup />} />
            <Route path="/shops/edit/:id" element={<EditbS/>} />
            <Route path="/shop/businessinfo" element={<BusinessInfo/>} />
            <Route path="/requestjoinbusiness" element={<RequestJoinBs/>}/>
            <Route path="/approverequests" element={<ApproveJoinBs/>}/>
            <Route path="/addcontacts/:customerId" element={<CreateEmergency/>}/>
            {/* end of hotel routes */}

            {/*<Route path="/defineServices" element={<DefineServices />} />*/}
            {isAdmin && <Route path="/adminbanksetup" element={<MainBankSetUp />} /> }
            {isAdmin && <Route path="/conviniencefee" element={<ConveninienceFee />} /> }
            {isAdmin && <Route path="/payItMode" element={<PayModesLanding />} /> }
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/orders/:tabValue" element={<Bills />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/detailedbill/:id" element={<Detailedbill />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/startorder/:parentId" element={<Startorder />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/detailedunpaidbill/:businessNo/:billId" element={<DetailedBills />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/detailedpaidbill/:businessNo/:billId" element={<DetailedBills />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/detailedpartialbill/:businessNo/:billId" element={<DetailedBills />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/detailedcancelledbill/:businessNo/:billId" element={<DetailedBills />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/voucher/:id" element={<VoucherDetails />} />}
            {isCashierOrAdminOrSupervisorOrMerchant && <Route path="/giftvoucher/:id" element={<VoucherGiftPreview />} />}

            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/terminals" element={<Terminal />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/otp" element={<Otp />} />}
            {noBranchAndIsAdminOrMerchant  && <Route path="/shops" element={<Shops />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/businessTerminals" element={<BusinessTerminals />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/users" element={<Users />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/reports" element={<Reports />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/voidtransactionreport" element={<VoidTransaction />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/reports-by-cashier" element={<ReportsByCashier />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/get_unpaid_orders_by_cashier" element={<ReportsUnpaidOrdersByCashier />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/get_detailed_sales_report" element={<ReportsDetailedSalesByCashier />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/get_sales_total_by_product" element={<ReportSalesTotalByProduct />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/customerDetails/:id" element={<Customerdetails />} />}
            {/*{  noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/tenantDetails/:id" element={<TenantDetails />} />}*/}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/get_total_sales_by_item_by_payments" element={<ReportSalesTotalByProductByPayment />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/get_all_cashier_to_settle" element={<ReportAllCashiersSettle />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/groups" element={<Groups />} />}
            {/* {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/products" element={<Products />} />} */}
            {noBranchAndIsAdminOrMerchant && <Route path="/products" element={<ServiceSetUp/>} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/products/edit/:id" element={<EditSingleProduct/>} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/expenditure" element={<Expenditure />} />}

            {/* {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/categories" element={<Categories />} />} */}
            {noBranchAndIsAdminOrMerchant && <Route path="/categories" element={<ProductSetUp/>} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/categories/edit/:id" element={<EditProductService/>} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/stock" element={<Stock />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/activestock" element={<Activestock />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/transferstock/:state" element={<Transferstock />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/approvestock" element={<APPROVAL />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/viewmoreStock/:status/:id" element={<ViewMoreStock />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/addstock/:state" element={<ADDSTOCK />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/stockOrder/:state" element={<StockOrder />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/stockOrder" element={<StockOrders />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/receiveStock/:state" element={<ReceiveStock />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/reports/:state" element={<REPORT />} />}
            {noBranchAndIsAdminOrMerchant && <Route path="/stockTake" element={<STOCKTAKE/>} /> }
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/stockConfig" element={<StockConfig />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/accountConfig" element={<AccountingConfig />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/businessConfig" element={<BusinessConfig />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/tenantConfig" element={<TenantConfig />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/expPass" element={<ResetExpPass />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/configuration" element={<Configuration />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/reasons" element={<Reasons />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/businessCategories" element={<BusinessCategories />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/reportFailedSales" element={<ReportFailedSales />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/createInvoice/:id" element={<CreateInvoice />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/businessinvoice" element={<BusinessInvoice />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path="/preview_invoice" element={<InvoicePreview />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path={"/test_coa_dash"} element={<CustomerAccountDash />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path={"/test_parent_display"} element={<SchoolCustomers />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path={"/test_student_profile"} element={<StudentProfile />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path={"/test_school_students"} element={<SchoolStudents />} />}
            {noBranchAndIsAdminOrMerchant && !isCashier && <Route path={"/test_student_dash"} element={<StudentDash />} />}
          </Route>

          <Route path="/logout" element={<Logout />} />
          {/* eceipt/REI4U97DSC/INV_46702 */}
          <Route path="/view/receipt/:receiptNo/:invoiceNumber/:businessNo" element={<ReceiptInvoice />} />
          <Route path="/view/purchaseorder/:PoNo/:businessNo" element={<UnauthInvoice />} />
          <Route path="/view/deliverynote/:Dnote/:businessNo" element={<UnauthInvoice />} />
          <Route path="/view/invoice/:invoiceNumber/:darajaConfigId" element={<UnauthInvoice />} />
          <Route path="/pay/invoice/:invoiceNumber/:darajaConfigId" element={<UnauthPayment />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
