import React, { useEffect, useState } from "react";
import HttpComponent from "../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { customerstyles } from "../customerAccounts/customerComponents/styles";
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../utils/dateFormatter";
import { CustomDate } from "../customerAccounts/customerComponents/customDate";
import SearchFilter from "../customerAccounts/customerComponents/searchFilter";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Grid, TextField } from "@mui/material";

const PaidInvoices = () => {
  const navigate = useNavigate();
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(5);
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  //const status = 'Paid'

    let url 

    if(startDate && endDate){
      url = `/api/get_business_invoices_by_status?limit=${dataGridPageSize}&page=${pageState.page}&status=Paid&startDate=${startDate}&endDate=${endDate}`
    }else{
      url = `/api/get_business_invoices_by_status?limit=${dataGridPageSize}&page=${pageState.page}&status=Paid`
    }


  

  // get paid invoices
  const fetchAllPaidInvoices = () => {
    HttpComponent({
      method: "GET",
      url: url,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    })
      .then((data) => {
        //console.log(data , 'paid invboicesssssssssss')
        if (data.status === 200) {
          console.log(data.response)
          setPageState({ ...pageState, isLoading: false, data: data.response.data, count: data.response.count });
        }
      })
      .catch((e) => {
        console.log(e.message);
      });
  };

  useEffect(() => {
    fetchAllPaidInvoices();
  }, [dataGridPageSize , pageState.page, startDate ,endDate]);

 const paidInvoicesColumn = [
  {
    field: "invoiceNumber",
    headerName: "Invoice No",
    flex: 1,
    renderCell: (params) => {
      return (
        <span style={{ color: "red", cursor: "pointer" }} onClick={() =>  navigate(`/invoice/${params.row.invoiceNumber}`)}>
          {params.row.invoiceNumber}
        </span>
      );
    },
  },
  { field: "sentTo", headerName: "To", flex: 1 },
  { field: "createdAt", headerName: "Date & Time", flex: 1 ,renderCell:(params)=><span>{DateFormatter(params?.row?.createdAt)}</span>},
  { field: "invoiceAmount", headerName: "Invoice Amount", flex: 1 },
  { field: "paidAmount", headerName: "Paid Amount", flex: 1  , renderCell:(params)=><span>{params?.row?.invoiceAmount} - {params?.row?.invoiceBalance}</span>},
  { field: "invoiceBalance", headerName: "Balance", flex: 1 },
  { field: "invoiceType", headerName: "Invoice Type", flex: 1 },
  { field: "invoiceStatus", headerName: "Status", flex: 1, renderCell:(params) => {
    return <span style={params?.row?.invoiceStatus === "Paid" ? {color:"green"} : params?.row?.invoiceStatus  === "Unpaid" ? {color:"red"} : {color:"orange"}} >{params?.row.invoiceStatus}</span>} }
 ]
  return (
    <Grid container>
     <Grid container direction={"row"} justifyContent={"space-between"} alignItems={"center"} width={"100%"} style={{margin:"10px 0px 0px 0px"}} >
          <Grid item style={customerstyles.searchDateFilter}  >
            <SearchFilter placeholder={'Search Invoice'} />
            <Grid item width={'50%'} ml={3} display={'flex'}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                        <Grid item width={'50%'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Start Date" value={startDate} onChange={(newValue) => setStartDate(newValue)} renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />} />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item width={'50%'}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="End Date" value={endDate} onChange={(newValue) => setEndDate(newValue)} renderInput={(params) => <TextField {...params} style={{ width: "100%" }} />} />
                            </LocalizationProvider>
                        </Grid>
                    </Grid>
                </Grid>
          </Grid>
        <Grid item>
                {/* <Exports/> */}
          </Grid>
        </Grid>
      <DataGrid
        style={customerstyles.dataGridStyling}
        rows={pageState?.data}
        columns={paidInvoicesColumn}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        rowCount={pageState?.count}
        loading={pageState.isLoading}
        pagination
        page={pageState.page - 1}
        pageSize={dataGridPageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize }));
        }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        getRowId={row => row?._id}
      />
     </Grid>
  );
}

export default PaidInvoices;