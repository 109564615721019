import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import {List, ListItemIcon} from "@mui/material";
import DashboardIcon from '@mui/icons-material/Dashboard';
import {useSelector} from "react-redux";
import LocalAtmIcon from '@mui/icons-material/LocalAtm';

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(2),
    },
}));

function SendIcon() {
    return null;
}

export default function PaymentSetup () {
    const classes = useStyles();

    const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

    let {businessCat} = useSelector((store)=>store.businessCategory)
    if(!businessCat) businessCat = localStorage.getItem("businessCategory")


    return (
        <div>
            <Link style={{textDecoration: 'none', color: isActiveLink("/paymentsettingup") ? activeColor : '#fff'}} to={"/paymentsettingup"}>
                <ListItem button className={classes.nested} disablePadding>
                    <div style={{marginRight : '5%'}}>
                        <LocalAtmIcon />
                    </div>
                    <div>
                        <ListItemText primary="Payment Set-up" />
                    </div>
                </ListItem>
            </Link>
        </div>
    )
}
