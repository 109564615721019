import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import loginImage from "../../common/images/login_image.svg";
import loginLogo from "../../images/zedLogo.png";
import { Button, Grid, TextField } from "@mui/material";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

const baseUrl = process.env.REACT_APP_BASE_URL;

const ForgotPass = () => {
  const navigate = useNavigate();
  const [email, setUserEmail] = useState("");

  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  // Submit Registered Email Address
  const submitHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(baseUrl + "/posForgotPinVersion2", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userEmail: email }),
      });

      const data = await response.json();
      console.log(data);
      if (response.status === 209) {
        setSuccessShow({ state: true, message: data.message });
        localStorage.clear();
        localStorage.setItem("email", email);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        setErrorShow({ state: true, message: data.message });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleNavigate = ()=> navigate('/')

  return (
    <Grid container style={{ fontFamily: "Poppins" }}>
      {/*snackBar*/}
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

      {/*Header logo Container*/}
      <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        {/*Logo image*/}
        <Grid item>
          <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
        </Grid>
      </Grid>

      {/*login image and Form container*/}
      <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
        {/*login image*/}
       
        <Grid item lg={6} sm={12} xs={12} xl={6} md={6}>
          <img src={loginImage} className={`img-fluid`} alt={"login image"} />
        </Grid>

        {/*Login Form*/}
        <Grid item style={{ marginTop: "10%" }} lg={6} sm={12} xs={12} xl={6} md={6}>
          {/*Login form*/}
          <Grid item display={'flex'} alignItems={'center'} mb={2}>
              <span onClick={handleNavigate} style={{ fontSize: "20px", cursor:"pointer", color: "#707070", marginLeft:'-10px', fontWeight: "600" }}>  <ArrowBackIosNewIcon style={{marginRight:'5px'}} /> Back</span>
            </Grid>
          <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>
            {/*login label*/}
            <Grid item>
              <span style={{ fontSize: "20px", color: "#032541", fontWeight: "bolder" }}>Forgot Pin?</span>
            </Grid>
          </Grid>
          {/*Phone*/}
          <Grid container>
            {/*login input label*/}
            <Grid item style={{ marginTop: "2%" }} lg={12} sm={12} xs={12} xl={12} md={12}>
              <span style={{ fontSize: "15px", color: "#032541", fontWeight: 500 }}>
                {" "}
                Please Enter your Registered Email Address
                <span style={{ color: "red" }}>*</span>
              </span>
            </Grid>

            {/*phone number input*/}
            <Grid item style={{ marginTop: "5%" }} pr={3} lg={12} sm={12} xs={12} xl={12} md={12}>
              <TextField fullWidth placeholder={"Enter Valid Email"} InputProps={{ disableUnderline: true }} style={{ height: "55px", textDecoration: "none" }} required type="email" value={email} onChange={(e) => setUserEmail(e.target.value)} />
            </Grid>
          </Grid>

          {/*Login button*/}
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "10%" }}>
            <Button onClick={submitHandler} style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
              Send New Pin
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ForgotPass;
