import React, { useEffect, useState } from "react";
import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, Menu, MenuItem, Modal, Select, Tab, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CustomSelectField from "./CustomSelectField";
import CustomSearchInput from "./CustomSearchInput";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { alpha, styled } from "@mui/material/styles";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";
import CustomTable from "./CustomTable";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import ReplayIcon from "@mui/icons-material/Replay";
import { useLocation, useNavigate } from "react-router-dom";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import ExportMenu from "./ExportMenu";
import DeleteIcon from '@mui/icons-material/Delete';
import WarningImg from "../../images/warning.png"
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";
import { DataGrid } from "@mui/x-data-grid";

const style = { borderRadius: "10px", bgcolor: 'background.paper', display: "flex", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "630px", height: "298px", p: 4, };


const breadcrumbs = [
  <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
    Dashboard
  </Typography>,
  <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
    Invoices
  </Typography>,
];
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#dc3545" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: "none", minWidth: 0, [theme.breakpoints.up("sm")]: { minWidth: 0 },
  fontWeight: theme.typography.fontWeightRegular, marginRight: theme.spacing(1),
  fontSize: "18px", fontStretch: "normal", fontStyle: "normal", lineHeight: "2.75",
  letterSpacing: "normal", textAlign: "left", color: "#6e7074", fontFamily: ["Poppins"].join(","),
  "&:hover": { color: "#032541", opacity: 1 }, "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
  "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
      frontFamily: "Poppins",
    }}
    {...props}
  />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


const localCurrency = localStorage.getItem('localCurrency')

const SchoolInvoiceBatches = () => {
  const [tabValue, setTabValue] = useState('APPROVED')
  const [tableData, setTableData] = useState([]);
  const userGroup = localStorage.getItem('group')
  const [selectedSchoolType, setSelectedSchoolType] = useState('')
  const { schoolTypeName, schoolTypeId } = useSelector((store) => store?.schoolType?.schoolTypeDetail)

  const [formData, setFormData] = useState({
    search: "",
    filter: "batchList",
  });

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue)
  };
  const filterOption = [
    { value: "batchList", label: "Batch List" },
    { value: "invoiceList", label: "Invoice List" },
  ];

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const { X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [dataToShow, setDataToShow] = useState([]);
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const [gridLoading, setGridLoading] = useState(false);

  //invoice tabs
  const [tabValueInvoices, setTabValueInvoices] = useState('APPROVED')
  const handleTabInvChange = (event, newValue) => { setTabValueInvoices(newValue) };
  const [dataGridPageSize, setPageSize] = useState(10);
  const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

  // console.log('data grid page size ======<><><>', dataGridPageSize);
   console.log('data grid page ======<><><>', pageState?.total);
  //modals

  const approve_delete_invoice = userGroup === 'Director' || userGroup === 'Accountant' || userGroup === 'Merchant'
  const [selectedStudentInvoiceName, setSelectedStudentInvoiceName] = useState(null)
  const [selectedStudentInvoiceAmount, setSelectedStudentInvoiceAmount] = useState(null)
  const [selectedInvoiceNumber, setSelectedInvoiceNumber] = useState(null)

  const [openDelete, setOpenDelete] = useState(false)
  const [openCancel, setOpenCancel] = useState(false)

  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  const handleOpenCancel = () => setOpenCancel(true)
  const handleCloseCancel = () => setOpenCancel(false)

  const [approveDelete, setApproveDelete] = useState(false)
  const [approveCancel, setApproveCancel] = useState(false)

  const handleOpenApproveDelete = () => setApproveDelete(true)
  const handleCloseApproveDelete = () => setApproveDelete(false)

  const handleOpenApproveCancel = () => setApproveCancel(true)
  const handleCloseApproveCancel = () => setApproveCancel(false)

  function handleIniateDeleteInvoice() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/invoice/delete`,
        body: { invoiceNumber: selectedInvoiceNumber },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: 'Invoice is successfully awaiting delete' })
          setTimeout(() => {
            handleCloseDelete();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 2000)
        } else {
          setErrorShow({ state: true, message: data?.response?.message })
          setTimeout(() => {
            handleCloseDelete();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 1500)
        }
      })
    } catch (error) {
      setErrorShow({ state: true, message: error })
    }
  }

  function handleIniateCancelInvoice() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/invoice/cancel`,
        body: { invoiceNumber: selectedInvoiceNumber },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: 'Invoice is successfully awaiting cancel' })
          setTimeout(() => {
            handleCloseCancel();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 2000)
        } else {
          setErrorShow({ state: true, message: data?.response?.message })
          setTimeout(() => {
            handleCloseCancel();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 1500)
        }
      })
    } catch (error) {
      setErrorShow({ state: true, message: error })
    }
  }

  function handleDeleteInvoice() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/invoice/delete`,
        body: { invoiceNumber: selectedInvoiceNumber, action: "APPROVE" },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: 'Invoice is successfully  deleted' })
          setTimeout(() => {
            handleCloseApproveDelete();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 2000)
        } else {
          setErrorShow({ state: true, message: data?.response?.message })
          setTimeout(() => {
            handleCloseApproveDelete();
            setSelectedInvoiceNumber(null)
          }, 1500)
        }
      })
    } catch (error) {
      setErrorShow({ state: true, message: error })
    }
  }

  function handleCancelInvoice() {
    try {
      HttpComponent({
        method: 'POST',
        url: `/api/v1/invoice/cancel`,
        body: { invoiceNumber: selectedInvoiceNumber, action: "APPROVE" },
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 200) {
          setSuccessShow({ state: true, message: 'Invoice is successfully  cancelled' })
          setTimeout(() => {
            handleCloseApproveCancel();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 2000)
        } else {
          setErrorShow({ state: true, message: data?.response?.message })
          setTimeout(() => {
            handleCloseApproveCancel();
            fetchInvoices();
            setSelectedInvoiceNumber(null)
          }, 1500)
        }
      })
    } catch (error) {
      setErrorShow({ state: true, message: error })
    }
  }

  // get school types
  const [allSchoolCategory, setAllSchoolCategory] = useState([])

  function GetType_school() {
    try {
      HttpComponent({
        method: 'GET',
        url: `/api/v1/config/school_classification`,
        token: localStorage.getItem('X-Authorization')
      }).then((data) => {
        if (data.status === 201) {
          setAllSchoolCategory(data?.response?.data)
        }
      })
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    GetType_school()
  }, [])



  //render cell column

  const RenderCell = (params) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const ActionOpen = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    };
    const handleActionClose = () => { setAnchorEl(null); };
    return (
      <div>
        <Modal
          open={openDelete}
          onClose={handleCloseDelete}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Grid container spacing={4}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Delete Invoice ?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you want to delete <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedInvoiceNumber}</span>  of  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedStudentInvoiceName}</span> <br></br> invoice of <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{localCurrency + selectedStudentInvoiceAmount}</span>
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseDelete} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleIniateDeleteInvoice} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Delete Invoice</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={openCancel}
          onClose={handleCloseCancel}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Grid container spacing={4}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Cancel Invoice ?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you need to cancel  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedInvoiceNumber}</span> of  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedStudentInvoiceName}</span> <br></br> invoice of <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{localCurrency + selectedStudentInvoiceAmount}</span>
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseCancel} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleIniateCancelInvoice} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Cancel Invoice</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={approveDelete}
          onClose={handleCloseApproveDelete}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Grid container spacing={4}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Approve Delete Invoice ?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you need to approve delete  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedInvoiceNumber}</span> of  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedStudentInvoiceName}</span> <br></br> invoice of <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{localCurrency + selectedStudentInvoiceAmount}</span>
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseApproveDelete} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleDeleteInvoice} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Approve Delete</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <Modal
          open={approveCancel}
          onClose={handleCloseApproveCancel}
          BackdropProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.2)' } }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Grid item display={'flex'} justifyContent={'center'} alignItems={'center'}>
              <Grid container spacing={4}>
                <Grid item>
                  <img src={WarningImg} alt='Warning' />
                </Grid>
                <Grid item>
                  <Typography id="modal-modal-title" variant="h6" style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }} component="h2">
                    Approve Cancel Invoice ?
                  </Typography>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                    Are you sure you need to approve cancel invoice  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedInvoiceNumber}</span> of  <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{selectedStudentInvoiceName}</span> <br></br> invoice of <span style={{ color: "#032541", fontWeight: 600, fontSize: "16px" }}>{localCurrency + selectedStudentInvoiceAmount}</span>
                  </Typography>
                  <Grid mt={3} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Button onClick={handleCloseApproveCancel} style={{ width: "145px", display: "flex", border: "1px solid #032541", alignItems: "center", color: "#032541", height: "45px", textTransform: "inherit" }}>Cancel</Button>
                    <Button onClick={handleCancelInvoice} style={{ width: "145px", display: "flex", alignItems: "center", color: "#fff", height: "45px", textTransform: "inherit", background: "#dc3545" }}>Approve Cancel</Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        <MoreVertIcon onClick={handleClick} />
        {params?.row?.invoiceStatus === 'Unpaid' && params?.row?.processStatus === 'Normal' ? (
          <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
            <MenuItem disableRipple onClick={() => { handleOpenDelete(); setSelectedStudentInvoiceName(params?.row?.studentName); setSelectedStudentInvoiceAmount(params?.row?.invoiceAmount); setSelectedInvoiceNumber(params?.row?.invoiceNumber); setAnchorEl(null) }}><DeleteIcon style={{ color: "#DC3545FF" }} /> Delete </MenuItem>
            <MenuItem disableRipple onClick={() => { handleOpenCancel(); setSelectedStudentInvoiceName(params?.row?.studentName); setSelectedStudentInvoiceAmount(params?.row?.invoiceAmount); setSelectedInvoiceNumber(params?.row?.invoiceNumber); setAnchorEl(null) }}><CloseIcon style={{ color: "#DC3545FF" }} /> Cancel</MenuItem>
          </StyledMenu>) : null
        }
        {
          params?.row?.invoiceStatus === 'Unpaid' && params?.row?.processStatus === 'AWAITING_DELETE' ? (
            <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
              {approve_delete_invoice ? <MenuItem disableRipple onClick={() => { handleOpenApproveDelete(); setSelectedStudentInvoiceName(params?.row?.studentName); setSelectedStudentInvoiceAmount(params?.row?.invoiceAmount); setSelectedInvoiceNumber(params?.row?.invoiceNumber); setAnchorEl(null) }}><DoneIcon style={{ color: "#17ae7b" }} /> Approve Delete </MenuItem> : null}

            </StyledMenu>) : null
        }
        {
          params?.row?.invoiceStatus === 'Unpaid' && params?.row?.processStatus === 'AWAITING_CANCEL' ? (
            <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
              {approve_delete_invoice ? <MenuItem disableRipple onClick={() => { handleOpenApproveCancel(); setSelectedStudentInvoiceName(params?.row?.studentName); setSelectedStudentInvoiceAmount(params?.row?.invoiceAmount); setSelectedInvoiceNumber(params?.row?.invoiceNumber); setAnchorEl(null) }}><DoneIcon style={{ color: "#17ae7b" }} /> Approve Cancel</MenuItem> : null}
            </StyledMenu>) : null
        }

      </div>
    )

  }

  const fetchBatches = () => {
    //setGridLoading(true);
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpComponent({
      method: "GET",
      url: `/api/get_invoice_batches?status=${tabValue}&classification=${selectedSchoolType}&page=${pageState?.page}&limit=${dataGridPageSize}`,
      body: null,
      token: localStorage.getItem('X-Authorization')
    })
      .then((data) => {
        // console.log("invoice batches data", data);
        if (data.status === 201) {
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: data.response.data,
            total: data.response.count,
          }));
          //setTableData(data.response.data);
          //setGridLoading(false);
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
          setGridLoading(false);
        }
      })
      .catch((error) => {
        console.error(error.message);
        setGridLoading(false);
      });
  };

  let urlInvoiceBatch = `/api/get_student_invoices_by_batch_status?page=${pageState.page}&limit=${dataGridPageSize}&status=${tabValueInvoices}&classification=${selectedSchoolType}`
  if (tabValueInvoices === 'DELETED' || tabValueInvoices === 'CANCELLED') {
    urlInvoiceBatch = `/api/v1/reports/student_payments?page=${pageState.page}&limit=${dataGridPageSize}&status=${tabValueInvoices}&classification=${selectedSchoolType}`
  }

  const fetchInvoices = () => {
    //setGridLoading(true);
    setPageState((old) => ({ ...old, isLoading: true }));
    HttpComponent({
      method: "GET",
      url: urlInvoiceBatch,
      body: null,
      token: localStorage.getItem('X-Authorization'),
    })
      .then((data) => {
         console.log(data, 'invoicesssssssssss')
        if (data.status === 200) {
          //setTableData(data.response.data);
          setPageState((old) => ({
            ...old,
            isLoading: false,
            data: data.response.data,
            total: data.response.count,
          }));
          //setGridLoading(false);
        } else {
          setErrorShow({ state: true, message: data.response.message });
          // setGridLoading(false);
        }
      })
      .catch((error) => {
        setGridLoading(false);
      });
  };

  const setData = () => {
    let searchable = [...pageState?.data];
   // console.log(searchable, 'searchable>>>>>>>>>>>>')
    let result;
    if (formData.search !== "") {
      if (formData.filter === "batchList") {
        result = searchable.filter((batch) =>
          batch.batchNo.toLowerCase().includes(formData.search)
        );
      } else if (formData.filter === "invoiceList") {
        result = searchable.filter((invoice) =>
          invoice.invoiceNumber.toLowerCase().includes(formData.search)
        );
      }
      setDataToShow(result);
    } else {
      setDataToShow(pageState.data);
    }
  };

  function formatDate(inputDate) {
    const dateObj = new Date(inputDate);
    const options = { year: "numeric", month: "short", day: "numeric" };
    return `${dateObj.toLocaleDateString(
      "en-US",
      options
    )} ${dateObj.toLocaleTimeString()}`;
  }

  const pendingColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" , cursor:'pointer' }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Created On",
      field: "createdAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.createdAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #17ae7b",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("approve", params.row._id)}
          >
            <DoneIcon
              sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #dc3545",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("decline", params.row._id)}
          >
            <CloseIcon
              sx={{ color: "#dc3545", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const approvedColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" ,cursor:'pointer' }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Approved On",
      field: "updatedAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.updatedAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1, align: 'center', fontSize: 16 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    { headerName: "Paid Invoices", field: "paidInvoices", flex: 1 },
    {
      headerName: "Paid Amount",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountPaid}</Typography>
      ),
    },
    { headerName: "Invoices Due", field: "invoicesDue", flex: 1, fontSize: 16 },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountDue}</Typography>
      ),
    },
  ];

  const declinedColumns = [
    {
      headerName: "Batch No",
      field: "batchNo",
      flex: 1,
      renderCell: (params) => (
        <Typography
          onClick={() => navigate(`/school/invoices/batch/${params.row._id}`)}
          sx={{ color: "#33699d", textDecoration: "underline" , cursor:'pointer' }}
        >
          {params.row.batchNo}
        </Typography>
      ),
    },
    {
      headerName: "Created On",
      field: "createdAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.createdAt)}</Typography>
      ),
    },
    {
      headerName: "Declined On",
      field: "updatedAt",
      flex: 1,
      renderCell: (params) => (
        <Typography>{formatDate(params.row.updatedAt)}</Typography>
      ),
    },
    { headerName: "Total Invoices ", field: "totalInvoices", flex: 1 },
    { headerName: "Total Amount", field: "totalAmount", flex: 1 },
    {
      field: "Action",
      headerName: "Action",
      renderCell: (params) => (
        <Box sx={{ display: "flex" }}>
          <Box
            sx={{
              width: "1.313rem",
              height: "1.313rem",
              borderRadius: "50%",
              border: "1px solid #17ae7b",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginX: 0.5,
              cursor: "pointer",
            }}
            onClick={() => actionBatch("restore", params.row._id)}
          >
            <ReplayIcon
              sx={{ color: "#17ae7b", alignSelf: "center", fontSize: "1rem" }}
            />
          </Box>
        </Box>
      ),
    },
  ];

  const approvedInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography onClick={() => navigate(`/school/invoice/${params.row.invoiceNumber}`)} sx={{ color: "#33699d", textDecoration: "underline" , cursor:'pointer' }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 1 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceAmount}</Typography>
      ),
    },

    {
      headerName: "Invoice Discount Amount",
      field: "invoiceDiscountAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params?.row?.invoiceDiscountAmount}</Typography>
      ),
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountPaid}</Typography>
      ),
    },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountDue}</Typography>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => <Typography style={params?.row?.invoiceStatus === 'Unpaid' ? { color: "#dc3545" } : params?.row?.invoiceStatus === 'Paid' ? { color: "#17ae7b" } : params?.row?.invoiceStatus === 'Partially Paid' ? { color: "#ff8503" } : params?.row?.invoiceStatus === 'CANCELLED' ? { color: '#333333', fontWeight: 700, fontSize: "14px" } : params?.row?.invoiceStatus === 'DELETED' ? { color: '#9C9EA4', fontWeight: 700, fontSize: "14px" } : null}>{params?.row?.invoiceStatus}</Typography>,
    },
    {
      headerName: "Delivery Status",
      field: "deliveryStatus",
      renderCell: (params) => <Typography>{params?.row?.deliveryStatus}</Typography>,
    },
    {
      headerName: "Action", field: "Action",
      renderCell: RenderCell
    }
  ];
  const cancelledInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography sx={{ color: "#33699d", textDecoration: "underline" }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 1 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceAmount}</Typography>
      ),
    },
    {
      headerName: "Discount Amount",
      field: "invoiceDiscountAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceDiscountAmount}</Typography>
      ),
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountPaid}</Typography>
      ),
    },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountDue}</Typography>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => <Typography style={{ color: "#333333", fontWeight: 700, fontSize: "14px" }}>{params?.row?.invoiceStatus}</Typography>,
    },
    // {
    //   headerName: "Delivery Status",
    //   field: "deliveryStatus",
    //   renderCell: (params) => <Typography>{params?.row?.deliveryStatus}</Typography>,
    // },
  ]

  const deletedInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography sx={{ color: "#33699d", textDecoration: "underline" , cursor:'pointer' }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 1 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceAmount}</Typography>
      ),
    },
    {
      headerName: "Discount Amount",
      field: "invoiceDiscountAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceDiscountAmount}</Typography>
      ),
    },
    {
      headerName: "Amount Paid",
      field: "amountPaid",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountPaid}</Typography>
      ),
    },
    {
      headerName: "Amount Due",
      field: "amountDue",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.amountDue}</Typography>
      ),
    },
    {
      headerName: "Status",
      field: "status",
      renderCell: (params) => <Typography style={{ color: "#9C9EA4", fontWeight: 700, fontSize: "14px" }}>{params?.row?.invoiceStatus}</Typography>,
    },
    // {
    //   headerName: "Delivery Status",
    //   field: "deliveryStatus",
    //   renderCell: (params) => <Typography>{params?.row?.deliveryStatus}</Typography>,
    // },
  ]
  const declinedInvoiceColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNumber",
      flex: 1,
      renderCell: (params) => (
        // onClick={()=>navigate(`/school/invoices/batch/${params.row._id}`)}
        <Typography sx={{ color: "#33699d", textDecoration: "underline" }}>
          {params.row.invoiceNumber}
        </Typography>
      ),
    },
    { headerName: "Admission No", field: "studentNumber", flex: 0.5 },
    { headerName: "Student Name", field: "studentName", flex: 1 },
    { headerName: "Grade", field: "grade", flex: 1 },
    { headerName: "Term", field: "term", flex: 1 },
    {
      headerName: "Invoice Amount",
      field: "invoiceAmount",
      flex: 1,
      renderCell: (params) => (
        <Typography>{localCurrency} {params.row.invoiceAmount}</Typography>
      ),
    },
  ];

  useEffect(() => {
    setData();
  }, [formData.search, tableData, dataGridPageSize, pageState?.page]);

  const actionBatch = (action, batchId) => {
    let url;
    if (action === "approve") {
      url = "/api/approve_invoice_batch";
    } else if (action === "decline") {
      url = "/api/decline_invoice_batch";
    } else if (action === "restore") {
      url = "/api/restore_invoice_batch";
    }
    HttpComponent({
      method: "POST",
      url: url,
      body: { batchId },
      token: localStorage.getItem('X-Authorization'),
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 202) {
          fetchBatches();
          if (action === "approve") {
            setSuccessShow({
              state: true,
              message: "Batch Approved Successfully, Invoice(s) Sent",
            });
          } else if (action === "decline") {
            setSuccessShow({
              state: true,
              message: "Batch Declined Successfully",
            });
          } else if (action === "restore") {
            setSuccessShow({
              state: true,
              message: "Batch Restored Successfully",
            });
          }
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const navigate = useNavigate();
  const location = useLocation();

  const checkSuccess = () => {
    const data = location?.state?.data;
    if (data === "created") {
      setSuccessShow({ state: true, message: "Batch Created successfully" });
    } else {
      return null
    }
  };

  // useEffect(() => {
  //   checkSuccess();
  // }, [location]);

  // renderHeader : () => {return (<strong style={{fontSize : '14px', fontWeight : '600'}}

  const [fileHeaders, setFileHeaders] = useState([]);
  const [csvColumns, setCsvColumns] = useState([]);
  // formData.filter

  const setDownloadData = () => {
    let baseColumns;
    let baseHeaders;
    if (formData.filter == "batchList") {
      baseColumns = [
        { label: "Batch No", key: "Batch No" },
        { label: "Created On", key: "Created On" },
        { label: "Total Invoices", key: "Total Invoices" },
        { label: "Total Amount", key: "Total Amount" },
      ];
      baseHeaders = [
        ["Batch No", "Created On", "Total Invoices", "Total Amount"],
      ];
      if (tabValue == "DECLINED") {
        baseColumns.push({
          label: "Declined On",
          key: "Declined On",
        });
        baseHeaders[0].push("Declined On");
      } else if (tabValue == "APPROVED") {
        baseColumns.push(
          { label: "Approved On", key: "Approved On" },
          { label: "Paid Invoices", key: "Paid Invoices" },
          { label: "Paid Amount", key: "Paid Amount" },
          { label: "Invoices Due", key: "Invoices Due" },
          { label: "Amount Due", key: "Amount Due" }
        );
        baseHeaders[0].push(
          "Approved On",
          "Paid Invoices",
          "Paid Amount",
          "Invoices Due",
          "Amount Due"
        );
      }
    } else if (formData.filter == "invoiceList") {
      baseColumns = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Admission No", key: "Admission No" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Term", key: "Term" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
        { label: "Amount Due", key: "Amount Due" },
      ];
      baseHeaders = [
        [
          "Invoice No",
          "Admission No",
          "Student Name",
          "Grade",
          "Term",
          "Invoice Amount",
          "Amount Paid",
          "Amount Due",
        ],
      ];
    }
    setCsvColumns(baseColumns);
    setFileHeaders(baseHeaders);
  };

  const fileData = dataToShow.map((data) => {
    let obj;
    if (formData.filter === "batchList") {
      obj = {
        "Batch No": data?.batchNo,
        "Created On": data?.createdAt,
        "Total Invoices": data?.totalInvoices,
        "Total Amount": data?.totalAmount,
      };
      if (tabValue == "DECLINED") {
        obj["Declined On"] = data?.updatedAt;
      } else if (tabValue == "APPROVED") {
        obj["Approved On"] = data?.updatedAt;
        obj["Paid Invoices"] = data?.paidInvoices;
        obj["Paid Amount"] = data?.amountPaid;
        obj["Invoices Due"] = data?.invoicesDue;
        obj["Amount Due"] = data?.amountDue;
      }
    } else if (formData.filter == "invoiceList") {
      obj = {
        "Invoice No": data?.invoiceNumber,
        "Admission No": data?.studentNumber,
        "Student Name": data?.studentName,
        Grade: data?.grade,
        Term: data?.term,
        "Invoice Amount": data?.invoiceAmount,
        "Amount Paid": data?.amountPaid,
        "Amount Due": data?.amountDue,
      };
    }
    return obj;
  });

  const fileDataPDF = dataToShow.map((data) => {
    let theData;
    if (formData.filter == "batchList") {
      theData = [
        data?.batchNo,
        data?.createdAt,
        data?.totalInvoices,
        data?.totalAmount,
      ];
      if (tabValue == "DECLINED") {
        theData.push(data?.updatedAt);
      } else if (tabValue == "APPROVED") {
        theData.push(
          data?.updatedAt,
          data?.paidInvoices,
          data?.amountPaid,
          data?.invoicesDue,
          data?.amountDue
        );
      }
    } else if (formData.filter == "invoiceList") {
      theData = [
        data?.invoiceNumber,
        data?.studentNumber,
        data?.studentName,
        data?.grade,
        data?.term,
        data?.invoiceAmount,
        data?.amountPaid,
        data?.amountDue,
      ];
    }
    return theData;
  });

  useEffect(() => {
    if (formData.filter === "batchList" || tabValue === "PENDING") {
      fetchBatches();
    } else if (formData.filter === "invoiceList") {
      fetchInvoices();
    } else {
      setDownloadData();
    }
  }, [tabValue, selectedSchoolType, tabValueInvoices, dataGridPageSize, urlInvoiceBatch, formData.filter, pageState?.page]);

  return (
    <Box component="div">
      <SuccessAlert
        vertical="top"
        horizontal="right"
        onClose={() => setSuccessShow({ ...successShow, state: false })}
        open={successShow.state}
        message={successShow.message}
      />
      <ErrorAlert
        vertical="top"
        horizontal="right"
        onClose={() => setErrorShow({ ...errorShow, state: false })}
        open={errorShow.state}
        message={errorShow.message}
      />
      <Box sx={{ display: "flex", margin: 1, justifyContent: "space-between" }}>
        <Box
          component="div"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography
            sx={{ color: "#032541", fontSize: "1.563rem", fontWeight: 600 }}
          >
            Invoices
          </Typography>
        </Box>
        <Box component="div" sx={{ display: "flex", justifyContent: "center", }} >
          <Button sx={{ backgroundColor: "#f5f6f7", marginRight: "10px", height: "45px", width: "155px", color: "#032541", "&:hover": { backgroundColor: "#032541", color: "white", }, }} onClick={() => navigate("/school/upload/studentbalance")} >Upload Balances</Button>
          <Button sx={{ backgroundColor: "#032541", height: "45px", width: "155px", color: "white", "&:hover": { backgroundColor: "#032541", color: "white", }, }} onClick={() => navigate("/school/generate/invoice")} > Create Invoice    </Button>
        </Box>
      </Box>
      <Box component="div" sx={{ margin: 1 }}>
        <Breadcrumbs
          separator={
            <FiberManualRecordIcon
              sx={{ fontSize: "0.625rem", color: "#e1e5e8" }}
            />
          }
          aria-label="breadcrumb"
        >
          {breadcrumbs}
        </Breadcrumbs>
      </Box>

      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleTabChange}
            TabIndicatorProps={{
              style: { display: "none" },
            }}
          >
            <Tab
              label="Approved"
              value="APPROVED"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
            <Tab
              label="Pending Approval"
              value="PENDING"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
            <Tab
              label="Declined"
              value="DECLINED"
              centered
              sx={{
                color: "#6e7074",
                "&.Mui-selected": {
                  color: "#dc3545",
                },
              }}
            />
          </TabList>
        </Box>

        {/*approved starts*/}

        <TabPanel value="APPROVED">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", alignItems: 'center', justifyContent: "space-between" }}
            >
              <Box component="div" sx={{ display: "flex", alignItems: "center" }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CustomSelectField
                    name={"filter"}
                    value={formData.filter}
                    onChange={handleInputChange}
                    options={filterOption}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 0.5,
                  }}
                >
                  <CustomSearchInput
                    name={"search"}
                    value={formData.search}
                    onChange={handleInputChange}
                    placeholder="Search:"
                  />
                </Box>
                {schoolTypeName === 'Kindergarten/Junior/High School' ?
                  <Box>
                    <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                      <InputLabel id="year-select-label">School Type</InputLabel>
                      <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedSchoolType}
                        label="School Type"
                        onChange={(e) => setSelectedSchoolType(e.target.value)}
                      >
                        <MenuItem value="">
                          Select School
                        </MenuItem>
                        {allSchoolCategory.map((school) => (
                          <MenuItem key={school} value={school}>
                            {school}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  : null}
              </Box>
              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Approved ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Approved-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            {formData.filter === "batchList" ? (
              <Grid item>
                <DataGrid
                  components={{ NoRowsOverlay: NoRowsOverlay }}
                  sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                  rowCount={pageState?.total}
                  loading={pageState?.isLoading}
                  pagination
                  page={pageState.page - 1}
                  pageSize={dataGridPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  columns={approvedColumns}
                  rows={pageState?.data}
                  getRowId={row => row?._id}
                />
              </Grid>

            ) : formData.filter === "invoiceList" ? (
              <Grid item>
                <TabContext value={tabValueInvoices}>
                  <AntTabs onChange={handleTabInvChange} textColor="primary" TabIndicatorProps={{ hidden: true }}>
                    <AntTab label="All" value="APPROVED" />
                    <AntTab label="Cancelled" value="CANCELLED" />
                    <AntTab label="Deleted" value="DELETED" />
                  </AntTabs>
                  <TabPanel value="APPROVED">
                    <Grid item>
                      <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={approvedInvoiceColumns}
                        rows={pageState?.data}
                        getRowId={row => row?._id ? row?._id: Math.floor((Math.random() * 100) + 1)}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="CANCELLED">
                    <Grid item>
                      <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={cancelledInvoiceColumns}
                        rows={pageState?.data}
                        getRowId={row => row?._id ? row?._id: Math.floor((Math.random() * 100) + 1)}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="DELETED">
                    <Grid item>
                      <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={deletedInvoiceColumns}
                        rows={pageState?.data}
                        getRowId={row => row?._id ? row?._id: Math.floor((Math.random() * 100) + 1)}
                      />
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            ) : null}
          </Box>
        </TabPanel>

        {/*pending starts*/}
        <TabPanel value="PENDING">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
            >
              <Box
                component="div"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CustomSearchInput
                  name={"search"}
                  value={formData.search}
                  onChange={handleInputChange}
                  placeholder="Search:"
                />

              </Box>
              {schoolTypeName === 'Kindergarten/Junior/High School' ?
                <Box>
                  <FormControl style={{ width: "250px", marginTop: "-10px", marginLeft: "10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                    <InputLabel id="year-select-label">School Type</InputLabel>
                    <Select
                      labelId="year-select-label"
                      id="year-select"
                      value={selectedSchoolType}
                      label="School Type"
                      onChange={(e) => setSelectedSchoolType(e.target.value)}
                    >
                      <MenuItem value="">
                        Select School
                      </MenuItem>
                      {allSchoolCategory.map((school) => (
                        <MenuItem key={school} value={school}>
                          {school}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box> : null
              }

              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Pending ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Pending-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            <Grid item>
              <DataGrid
                components={{ NoRowsOverlay: NoRowsOverlay }}
                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                rowCount={pageState?.total}
                loading={pageState?.isLoading}
                pagination
                page={pageState.page - 1}
                pageSize={dataGridPageSize}
                paginationMode="server"
                onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                columns={pendingColumns}
                rows={pageState?.data}
                getRowId={row => row?._id}
              />
            </Grid>
          </Box>
        </TabPanel>

        {/*declined starts*/}

        <TabPanel value="DECLINED">
          <Box
            component="div"
            sx={{ display: "flex", flexDirection: "column" }}
          >
            <Box
              component="div"
              sx={{ display: "flex", justifyContent: "space-between", alignItems: 'center' }}
            >
              <Box component="div" sx={{ display: "flex", alignItems: 'center' }}>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <CustomSelectField
                    name={"filter"}
                    value={formData.filter}
                    onChange={handleInputChange}
                    options={filterOption}
                  />
                </Box>
                <Box
                  component="div"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    marginLeft: 0.5,
                  }}
                >
                  <CustomSearchInput
                    name={"search"}
                    value={formData.search}
                    onChange={handleInputChange}
                    placeholder="Search:"
                  />
                </Box>
                {schoolTypeName === 'Kindergarten/Junior/High School' ?
                  <Box>
                    <FormControl style={{ width: "200px", marginTop: "-10px", marginLeft: "5px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                      <InputLabel id="year-select-label">School Type</InputLabel>
                      <Select
                        labelId="year-select-label"
                        id="year-select"
                        value={selectedSchoolType}
                        label="School Type"
                        onChange={(e) => setSelectedSchoolType(e.target.value)}
                      >
                        <MenuItem value="">
                          Select School
                        </MenuItem>
                        {allSchoolCategory.map((school) => (
                          <MenuItem key={school} value={school}>
                            {school}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box> : null}
              </Box>
              <ExportMenu
                csvColumns={csvColumns}
                fileData={fileData}
                fileHeaders={fileHeaders}
                fileDataPDF={fileDataPDF}
                title={`Declined ${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
                fileName={`Declined-${formData.filter == "batchList" ? "Batches" : "Invoices"
                  }`}
              />
            </Box>
            {/*declinedColumns*/}
            {/*<CustomTable loading={gridLoading} data={dataToShow} columns={declinedColumns} rowsPerPage={5}/>*/}
            {formData.filter == "batchList" ? (
              <Grid item>
                <DataGrid
                  components={{ NoRowsOverlay: NoRowsOverlay }}
                  sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                  rowCount={pageState?.total}
                  loading={pageState?.isLoading}
                  pagination
                  page={pageState.page - 1}
                  pageSize={dataGridPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  columns={declinedColumns}
                  rows={pageState?.data}
                  getRowId={row => row?._id}
                />
              </Grid>

            ) : formData.filter === "invoiceList" ? (
              <Grid item>
                <DataGrid
                  components={{ NoRowsOverlay: NoRowsOverlay }}
                  sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                  rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                  rowCount={pageState?.total}
                  loading={pageState?.isLoading}
                  pagination
                  page={pageState.page - 1}
                  pageSize={dataGridPageSize}
                  paginationMode="server"
                  onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  columns={declinedInvoiceColumns}
                  rows={pageState?.data}
                  getRowId={row => row?._id ? row?._id: Math.floor((Math.random() * 100) + 1)}
                />
              </Grid>
            ) : null}
          </Box>
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default SchoolInvoiceBatches;
