import HttpComponent from "../School/MakeRequest";

export default class ConfigurationFunctions {
  constructor() {}

  getAllBusiness(inputValue) {
    return HttpComponent({
      method: "GET",
      url: `/api/search_businesses?limit=1000&page=1&searchValue=${inputValue}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  setupTransactionMode(transMode, numberOfPrints, bsNumber){
    return HttpComponent({
        method: "POST",
        url: `/api/v1/enableZedPayIdentification`,
        body: {
            transactionMode: transMode,
            businessNumber:bsNumber,
            numberOfPrints:numberOfPrints
        },
        token: localStorage.getItem("X-Authorization"),
      });
  }

  getTransactionMode(page, limit, search){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/getZedPayBusinessConfigs?page=${page}&limit=${limit}&searchValue=${search}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }
}
