import { Grid, Breadcrumbs, Typography, Button, TextField} from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CustomSelect from "../../../School/CustomSelectField";
import CustomInputLabel from "../../../School/CustomInputLabel";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Card Bins</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Create Card Bin</Typography>
]


export default function CreateCard(){

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const navigate = useNavigate();

    const [formData ,setFormData] = useState({cardName:"" , cardType:"" , cardNo:"" , suspenseAccount:""})

    //handleOnchange

    const handleOnChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name]:e.target.value
        })
    }
    
    //card types

    const options = [{ label:"Prepaid", value:'prepaid'},{ label:"Debit", value:'debit'},{ label:"Credit",value:'credit'}]

    //handle create card

    const submitFormDataDetails = {
        cardName:formData.cardName,
        cardNo:formData.cardNo,
        cardType:formData.cardType,
        suspenseAccount:formData.suspenseAccount
    }

    const handleCreateCard = ()=>{
        try{
            HttpComponent({
                method:'POST',
                url:`/api/v1/createCardBins`,
                body:submitFormDataDetails,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 201){
                    setSuccessShow({state:true ,message:"Card bin successfully created"})

                    setTimeout(()=>{
                        navigate(-1)
                    },[1500])
                   
                }
                if(data.status === 400){
                    setErrorShow({state:true ,message:'Card with provided details exists!'})
                    setTimeout(()=>{
                    },[1500])
                }
            })

        }catch(error){
            setErrorShow({state:true , message:error?.message})
        }
    }

    return(
        <Grid container direction={'column'}>
           <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{cursor:"pointer"}} onClick={()=>navigate(-1)}/>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Create Card Bin</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={3}>
                <Typography style={{ fontWeight: 600, color: "#707070", fontSize: "18px" }}>Card Bin Details</Typography>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="cardName" onChange={handleOnChange} style={{ width: "100%" }} value={formData.cardName} label="Card Name" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="cardNo" onChange={handleOnChange} style={{ width: "100%" }} value={formData.cardNo} label="Card Bin No" variant="outlined" />
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <CustomSelect name={'cardType'} placeholder={'Card Type'} onChange={handleOnChange} value={formData.cardType} options={options} style={{ width:"100%"}}/>
            </Grid>
            <Grid item mt={2} style={{ width: "50%" }}>
                <TextField id="outlined-basic" name="suspenseAccount" onChange={handleOnChange} style={{ width: "100%" }} value={formData.suspenseAccount} label="Suspense Account" variant="outlined" />
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleCreateCard} style={!formData.cardName || !formData.cardType ||!formData.cardNo ||!formData.suspenseAccount ? {opacity:0.4 ,width: "125px",background: "#032541", textTransform: "inherit",}:{ background: "#032541", textTransform: "inherit", width: "125px", color: "#fff" , opacity:1 }}>Submit</Button>
            </Grid>
        </Grid>
    )
}