import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Tab, Typography, Radio } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from './CustomSearchInput';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import RadioGroup from "@mui/material/RadioGroup";
import HttpComponent from './MakeRequest';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { saveRequstableServiceDeatails } from '../../features/generateRequestableInvoiceData';

const breadcrumbs = [
    <Typography key="" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={""} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Request Service
    </Typography>
];
const RequestService = () => {
    const localCurrency = localStorage.getItem('localCurrency')
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);
    
    const [selected, setSelected] = useState("");
    const { userId, X_Authorization } = useSelector((store) => store.user);
    const customerId = localStorage.getItem("customerId");
    const [services, setServices] = useState([]);
    const [students, setStudents] = useState([]);
    const [selectStudent, setSelectStudent] = useState("");
    const [isStudent, setIsStudent] = useState(false);
    const [saveInvoiceData, setSaveInvoiceData] = useState({});//[
    const [servicesData, setServicesData] = useState({});//[
    const [searchValue, setSearchValue] = useState("");
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store?.schoolType?.schoolTypeDetail)



    const navigate = useNavigate();
    const dispatch = useDispatch();

    let studentId = "";
    const fetchStudents = () => {
        HttpComponent({
            method: 'POST',
            url: `/api/getBillableItems?page=1&limit=1000`,
            body: { customerId: customerId },
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                setStudents(data.response.data)
                if(schoolTypeName.includes("University")){
                    const studentId = data.response.data[0]._id;
                    console.log("here is studentId", studentId);
                    setSelectStudent(data.response.data[0]._id);
                    setIsStudent(true);
                }
            } else {
                console.error("Error setting info")
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }
    useEffect(() => {
        fetchStudents();
    }, [])
    const handleSelectStudent = (event, id) => {
        setSelectStudent(id); // Update the selected state with the selected service _id
        setIsStudent(true);
    }
    console.log("here is students", students);
    useEffect(() => {
        if (selectStudent) {

            fetchRequestService(selectStudent);
        }


    }
        , [selectStudent, searchValue])

    const fetchRequestService = async (studentId) => {
        console.log("here is studentId", studentId);
        try {
            HttpComponent({
                url: `/api/listRequestableServicesByStudentId?studentId=${studentId}&search=${searchValue}`,
                method: "GET",
                body: null,
                token: X_Authorization,
            }).then((data) => {
                console.log("here store is data", data);
                if (data.status === 200) {
                    setServices(data?.response?.data);
                } else {
                    console.error("Error setting info");
                    // setErrorShow({ state: true, message: data.response.message });
                }
            })
        } catch (error) {
            console.error("Error setting info", error);
            // setErrorShow({ state: true, message: error.message });
        }
    };

    const handleSelectRowClick = (event, id) => {
        setSelected(id); // Update the selected state with the selected service _id
    };


    console.log("here is selected service", selected);
    console.log("here is selectStudent", selectStudent);
    console.log("here is isStudent", isStudent);
    const saveData = () => {
        console.log("here is selected service", selected);
        const firstName = students?.filter((item) => item?._id === selectStudent)[0]?.firstName;
        console.log("here is firsName", firstName);

        const lastName = students?.filter((item) => item?._id === selectStudent)[0]?.lastName;
        console.log("here is lastName", lastName);
        const studentId = students?.filter((item) => item?._id === selectStudent)[0]?.itemNumber;
        const grade = students?.filter((item) => item?._id === selectStudent)[0]?.grade;
        const term = students?.filter((item) => item?._id === selectStudent)[0]?.term;
        const admissionDate = students?.filter((item) => item?._id === selectStudent)[0]?.admissionDate;
        const customerId = students?.filter((item) => item?._id === selectStudent)[0]?.customerId;
        const studentItemNo = selectStudent

        const productName = services?.filter((item) => item?._id === selected)[0]?.productName;
        const productPrice = services?.filter((item) => item?._id === selected)[0]?.productPrice;
        const productId = selected;
        const quantity = 1;
        console.log("here is productName", productName, productPrice, quantity);
        const saveInvoiceData = {
            firstName,
            lastName,
            studentId,
            grade,
            term,
            admissionDate,
            customerId,
            studentItemNo,
            servicesData: {
                productName,
                productPrice,
                quantity,
                productId
            }
        }
        dispatch(saveRequstableServiceDeatails(saveInvoiceData));
        navigate("/school/request/preview/invoice");

    }
    return (
        <div>
            <Box component={"div"}>
                <Box component={"div"} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Breadcrumbs separator={<FiberManualRecordIcon sx={{ fontSize: "0.5rem" }} />} aria-label="breadcrumb">
                        {breadcrumbs}
                    </Breadcrumbs>
                </Box>

                {schoolTypeName?.includes("University") ?
                    <div style={{ minHeight: "400px", width: "40%" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox">
                                        <RadioGroup
                                        // checked={selected.length === students.length}
                                        // onChange={handleSelectAllClick}
                                        />
                                    </TableCell>
                                    <TableCell>Services</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {services?.length > 0 ?
                                    services?.map((service) => (
                                        <TableRow
                                            hover
                                            key={service?._id}
                                        // selected={selected?.includes(services?.servicesId)}
                                        >
                                            <TableCell padding="checkbox">
                                                <Radio
                                                    value={service?._id} // Set the radio button value to the service _id
                                                    checked={selected === service?._id} // Check if this service is selected
                                                    onChange={(event) => handleSelectRowClick(event, service?._id)} // Handle the selection
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Box component={"div"} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                    <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            {service?.productName}
                                                        </Typography>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            {service?.serviceExpiry.substring(0, 10)}
                                                        </Typography>
                                                    </Typography>
                                                    <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            {
                                                                numberFormat(service?.productPrice)
                                                            }
                                                        </Typography>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            {
                                                                // service?.serviceExpiry
                                                                "Due in " + Math.floor((new Date(service?.serviceExpiry) - new Date()) / (24 * 60 * 60 * 1000)) + " Days"
                                                            }
                                                        </Typography>
                                                    </Typography>
                                                </Box>
                                            </TableCell>

                                        </TableRow>
                                    )) : <TableRow>
                                        <TableCell colSpan={4} align="center">
                                            <span style={{ color: "#03243F", textAlign: "center" }}>NO REQUESTABLE SERVICE AVAILABLE</span>
                                        </TableCell>
                                    </TableRow>}
                            </TableBody>
                        </Table>
                    </div>
                    : <Box component={"div"} sx={{ display: "flex", marginTop: "30px", flexDirection: "column", }}>
                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                            Select Student
                        </Typography>
                        {console.log("here is students hapa", students)}
                        {students?.length > 0 && !schoolTypeName.includes("University") ? students?.map((item) => (
                            <Box component={"div"} sx={{ display: "flex", justifyContent: "", alignItems: "center" }}>
                                <Radio value={item?._id} checked={selectStudent === item?._id} onChange={(event) => handleSelectStudent(event, item?._id)} />
                                <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                    <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                        {item?.firstName + " " + item?.lastName}
                                    </Typography>
                                    <Typography variant="h5" sx={{ color: "#707070", fontSize: "0.7rem" }}>
                                        Student Adm : {item?.itemNumber}
                                    </Typography>
                                </Typography>

                            </Box>
                        )) : schoolTypeName?.includes("University") ? <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                            NO STUDENT AVAILABLE
                        </Typography> : <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                            NO STUDENT AVAILABLE
                        </Typography>}
                    </Box>}

                {isStudent && !schoolTypeName?.includes("University") ?
                    <div>
                        <Box component={"div"} sx={{ display: "flex", marginTop: "30px", justifyContent: "space-between", alignItems: "center" }}>
                            <CustomSearchInput placeholder={"Search service name"} value={searchValue} onChange={(e) => setSearchValue(e.target.value)} />
                        </Box>
                        <div style={{ minHeight: "400px", width: "40%" }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="checkbox">
                                            <RadioGroup
                                            // checked={selected.length === students.length}
                                            // onChange={handleSelectAllClick}
                                            />
                                        </TableCell>
                                        <TableCell>Services</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {services?.length > 0 ?
                                        services?.map((service) => (
                                            <TableRow
                                                hover
                                                key={service?._id}
                                            // selected={selected?.includes(services?.servicesId)}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Radio
                                                        value={service?._id} // Set the radio button value to the service _id
                                                        checked={selected === service?._id} // Check if this service is selected
                                                        onChange={(event) => handleSelectRowClick(event, service._id)} // Handle the selection
                                                    />
                                                </TableCell>
                                                <TableCell>
                                                    <Box component={"div"} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                                {service?.productName}
                                                            </Typography>
                                                            <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                                {service?.serviceExpiry.substring(0, 10)}
                                                            </Typography>
                                                        </Typography>
                                                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                            <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                                {
                                                                    numberFormat(service?.productPrice)
                                                                }
                                                            </Typography>
                                                            <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                                                                {
                                                                    // service?.serviceExpiry
                                                                    "Due in " + Math.floor((new Date(service?.serviceExpiry) - new Date()) / (24 * 60 * 60 * 1000)) + " Days"
                                                                }
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                </TableCell>

                                            </TableRow>
                                        )) : <TableRow>
                                            <TableCell colSpan={4} align="center">
                                                <span style={{ color: "#03243F", textAlign: "center" }}>NO REQUESTABLE SERVICE AVAILABLE</span>
                                            </TableCell>
                                        </TableRow>}
                                </TableBody>
                            </Table>

                        </div>
                    </div>
                    : schoolTypeName?.includes("University") ? "" :  <div style={{ width: "40%" }}>
                        <Typography variant="h5" sx={{ color: "#707070", fontSize: "1rem" }}>
                            Select Student to view services
                        </Typography>
                    </div>}
                {isStudent && selected ? <>
                    <div style={{ width: "40%" }}>
                        <Box component={"div"} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <Button onClick={() => {
                                setIsStudent(false);
                                setSelectStudent("");
                                navigate("/dashboard");
                            }} variant="contained" style={{ backgroundColor: "#DC3545", color: "#fff", }}>
                                Cancel
                            </Button>
                            <Button onClick={saveData} style={{ backgroundColor: "#032541", color: "#fff", marginLeft: "30px" }}>
                                Preview Invoice
                            </Button>
                        </Box>
                    </div>
                </> : null}




            </Box>
        </div>
    )
}

export default RequestService