import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Dash from "../../../common/dash";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import user from "../../../common/images/user-icon.png";
import bell from "../../../common/images/bell-icon.png";
import logout from "../../../common/images/logout-icon.png";
import logoutuser from "../../logout/logout";
import { Box, Button, ButtonBase, Checkbox, IconButton, Table, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import KeyboardBackspaceOutlinedIcon from "@mui/icons-material/KeyboardBackspaceOutlined";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import PrintIcon from '@mui/icons-material/Print';
import mpesaIcon from "../../../common/images/mpesa-icn.svg";
import cardIcon from "../../../common/images/card-icn.svg";
import creditIcon from "../../../common/images/creditbal-icn.svg";
import { ReceivedPayment } from "../../Modals/Invoice/receivedPayment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { PaymentMethods } from "../../Modals/Invoice/paymentMethods";
import SyncIcon from "@mui/icons-material/Sync";
import "./invoice.css";
import HttpComponent from "../../School/MakeRequest";
import { changeToken } from "../../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { CheckBox, DeleteOutline } from "@material-ui/icons";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { removeFromCurrentServices, setCurrentServices } from "../../../features/servicesSlice";
import warningImage from "../../School/Images/warning-remove-icn.svg";
import ReusableModal from "../../School/ReusableModal";
import CustomeronAccountFunctions from "./customerOnAccountFunctions";
import { DeleteDiscountModal } from "../../bills/startorderModals/modals";
const moment = require("moment");

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  address_text_common: {
    fontSize: "13px",
    fontWeight: "300",
  },
  table_titles: {
    fontSize: "14px",
    fontWeight: "700",
  },
  table_body: {
    fontSize: "13px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  footer_text: {
    fontSize: "10px",
    fontWeight: "300",
    padding: theme.spacing(0.25),
  },
  totals: {
    fontSize: "14px",
    padding: theme.spacing(0.25),
  },
  p1: {
    padding: theme.spacing(0.25),
  },
  p2: {
    padding: theme.spacing(0.5),
  },
  p3: {
    padding: theme.spacing(1),
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  m1: {
    margin: theme.spacing(1),
  },
  textLeft: {
    textAlign: "left",
  },
  textCenter: {
    textAlign: "center",
  },
  textRight: {
    textAlign: "right",
  },
}));

const Invoice = () => {
  const navigate = useNavigate();

  const customerOnAccountsMethods = new CustomeronAccountFunctions();
  const { invoiceNumber } = useParams();
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [invoiceDiscountAmount, setInvoiceDiscountAmount] = useState(0);
  const [invoiceStatus, setInvoiceStatus] = useState('')
  const [businessInfo, setBusinessInfo] = useState();
  const [invoiceData, setInvoiceData] = useState();
  const [receivePaymentOpen, setReceivePaymentOpen] = useState(false);
  const [paymentMethodsOpen, setPaymentMethodsOpen] = useState(false);
  const [currentEmail, setCurrentEmail] = useState();
  const [errorFetching, setErrorFetching] = useState(false);
  const [invoicePayments, setInvoicePayments] = useState();
  const [invoiceBalance, setInvoiceBalance] = useState();
  const [discountNamee, setDiscountNamee] = useState("")
  //   console.log(invoiceData, "yooooooooo");

  const downloadPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
      pdf.save(`invoice.pdf`);
    });
  };
  // const viewPDF = () => {
  //   const input = document.getElementById("downloadable");
  //   html2canvas(input).then((canvas) => {
  //     const imgData = canvas.toDataURL("image/png", 1.0);
  //     const inputWidthPx = input.clientWidth;
  //     const inputHeightPx = input.clientHeight;
  //     const dpi = window.devicePixelRatio * 96;
  //     let scaleFactor = 1;
  //     if (window.innerWidth > 1512) {
  //       scaleFactor = 1.4;
  //     }
  //     const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
  //     const inputHeightMm = (inputHeightPx / dpi) * 25.4;
  //     const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
  //     pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
  
  //     // Open the PDF in a new tab or a modal window
  //     const pdfDataUri = pdf.output("datauristring");
  //     const pdfWindow = window.open(pdfDataUri, "_blank");
  //     if (!pdfWindow) {
  //       alert("Please allow pop-ups for this site to view the PDF.");
  //     }
  //   });
  // };
  const viewPDF = () => {
    const input = document.getElementById("downloadable");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png", 1.0);
      const inputWidthPx = input.clientWidth;
      const inputHeightPx = input.clientHeight;
      const dpi = window.devicePixelRatio * 96;
      let scaleFactor = 1;
      if (window.innerWidth > 1512) {
        scaleFactor = 1.4;
      }
      const inputWidthMm = ((inputWidthPx / dpi) * 25.4) / scaleFactor;
      const inputHeightMm = (inputHeightPx / dpi) * 25.4;
      const pdf = new jsPDF("p", "mm", [inputWidthMm, inputHeightMm]);
      pdf.addImage(imgData, "JPEG", 5, 5, inputWidthMm - 10, inputHeightMm - 10);
  
      // Create a modal dialog to display the PDF
      const modal = document.createElement("div");
      modal.style.position = "fixed";
      modal.style.top = "0";
      modal.style.left = "0";
      modal.style.width = "100%";
      modal.style.height = "100%";
      modal.style.backgroundColor = "rgba(0, 0, 0, 0.7)";
      modal.style.display = "flex";
      modal.style.alignItems = "center";
      modal.style.justifyContent = "center";
      modal.style.zIndex = "1000";
  
      // Create an iframe to load the PDF content
      const iframe = document.createElement("iframe");
      iframe.style.width = "80%";
      iframe.style.height = "80%";
      iframe.src = pdf.output("bloburl");
      modal.appendChild(iframe);
  
      // Add the modal to the document
      document.body.appendChild(modal);
  
      // Close the modal when the user clicks outside of it
      modal.addEventListener("click", () => {
        document.body.removeChild(modal);
      });
    });
  };
  
  
  const getInvoiceDetails = async () => {
    try {
      const invoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_by_invoice_number?invoiceNumber=${invoiceNumber}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (invoiceDetailsResponse.status === 200) {
        const invoiceDetails = await invoiceDetailsResponse.json();
        console.log('invoiceDetails <><>', invoiceDetails?.data);
        setInvoiceData(invoiceDetails.data);
        setInvoiceStatus(invoiceDetails?.data?.invoiceStatus)
        setDiscountNamee(invoiceDetails.data.discountName)
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching invoice details", error.message);
    }
  };

  const localCurrency = localStorage.getItem('localCurrency')
  const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);
  
  const formatDate = (date) => {
    return moment(date).format("dddd, MMMM Do, YYYY");
  };

  const formatPaymentDate = (unFormatted) => {
    const date = new Date(unFormatted);
    const options = { day: "numeric", month: "short", year: "numeric", hour: "numeric", minute: "numeric", hour12: true };
    const formattedDate = date.toLocaleString("en-US", options);
    const dateSuffix = function () {
      const date = new Date();
      const dateNumber = date.getDate();
      switch (dateNumber) {
        case 1:
        case 21:
        case 31:
          return "st";
        case 2:
        case 22:
          return "nd";
        case 3:
        case 23:
          return "rd";
        default:
          return "th";
      }
    };
    return `${date.getDate()}${dateSuffix()} ${formattedDate.slice(0, 3)} ${formattedDate.slice(7)}`;
  };

  const getBusinessDetails = async () => {
    try {
      const businessDetailsResponse = await fetch(baseUrl + "/api/get_business_info", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });

      if (businessDetailsResponse.status === 200) {
        const businessDetailsData = await businessDetailsResponse.json();
        // console.log("business data ", businessDetailsData.data);
        setBusinessInfo(businessDetailsData.data);
      } else {
        setErrorFetching(true);
      }
    } catch (error) {
      console.error("error fetching customer details", error.message);
    }
  };

  const calculateTotal = () => {
    if (invoiceData) {
      // console.log('invoiceData =====<><>', invoiceData);
      setTotal(invoiceData?.total);
      setInvoiceDiscountAmount(invoiceData?.invoiceDiscountAmount || invoiceData?.discountAmount);
      //   let calculatedTotal = 0;
      //   invoiceData?.items?.forEach((product) => {
      //     calculatedTotal += product.productPrice * product.quantity;
      //   });
      //   setTotal(calculatedTotal);
    } else {
      setTotal(0);
      setInvoiceDiscountAmount(0);
    }
  };

  const back = () => {
    navigate(-1);
  };

  const [isSpinning, setIsSpinning] = useState(false);

  const refresh = () => {
    setIsSpinning(true);
    getInvoiceDetails().then(() => {
      console.log("done fetching invoice data");
      setIsSpinning(false);
    });
  };

  const getCurrentEmail = () => {
    setCurrentEmail(localStorage.getItem("email"));
  };

  const fetchBusiInvoiceData = async () => {
    const businessInvoiceDetailsResponse = await fetch(baseUrl + `/api/get_invoice_and_business_data/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (businessInvoiceDetailsResponse.status === 200) {
      const businessDetailsData = await businessInvoiceDetailsResponse.json();
      // console.log("business Invoice data ", businessDetailsData.data)
      setBusinessInfo(businessDetailsData.data);
      // setInvoiceData(businessDetailsData.data)
    } else {
      setErrorFetching(true);
    }
  };

  const getInvoicePayments = async () => {
    const invoicePaymentDetailsResponse = await fetch(baseUrl + `/api/get_invoice_payments/${invoiceNumber}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    if (invoicePaymentDetailsResponse.status === 200) {
      const paymentDetailsData = await invoicePaymentDetailsResponse.json();
      //console.log("Invoice payment data ", paymentDetailsData.data)
      let { invoicePayments, invoiceBalance } = paymentDetailsData.data;
      //console.log("ip",invoicePayments)
      setInvoicePayments(invoicePayments);
      setInvoiceBalance(invoiceBalance);
    }
  };

  const { userId, X_Authorization } = useSelector((store) => store.user);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const resendEmail = () => {
    HttpComponent({
      method: "GET",
      url: `/api/resendInvoice?invoiceNumber=${invoiceNumber}`,
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        //console.log("here store is data",data);
        if (data.status === 200) {
          setSuccessShow({ state: true, message: `${invoiceNumber} successfully resent to ${data.response.data.sentTo}` });
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };
  const [warningOpen, setWarningOpen] = useState(false);
  const [indexToBeDeleted, setIndexToBeDeleted] = useState();
  const [idToBeDeleted, setIdToBeDeleted] = useState();

  const handleWarningClose = () => {
    setWarningOpen(false);
  };

  useEffect(() => {
    getBusinessDetails().then(() => console.log("done getting business info"));
    getInvoiceDetails().then(() => console.log("done fetching invoice data"));
    getCurrentEmail();
    getInvoicePayments().then(() => console.log("payments fetched"));
  }, []);

  useEffect(() => {
    if (errorFetching) {
      //console.log("we had an error fetching")
      fetchBusiInvoiceData().then(() => console.log("Fallback function used"));
    }
  }, [errorFetching]);

  useEffect(() => {
    calculateTotal();
  }, [invoiceData]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setCurrentServices(invoiceData?.items));
  }, [invoiceData]);

  const businessCategory = localStorage.getItem("businessCategory");
  const service = useSelector((store) => store.services.currentServices);

  const deleteFromInvoice = () => {
    let data = {
      invoiceNumber,
      productId: idToBeDeleted,
    };
    HttpComponent({
      method: "POST",
      url: `/api/delete_product_from_invoice`,
      body: data,
      token: X_Authorization,
    })
      .then((data) => {
        // console.log("here store is data",data);
        if (data.status === 200) {
          handleWarningClose();
          getInvoiceDetails();
          setSuccessShow({
            state: true,
            message: "Service was removed successfully",
          });
        } else {
          console.error("Error setting info");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  //   handle discount on Invoice;
  const [showDiscount, doShowDiscount] = useState(false);
  const [discountNames, setDiscountNames] = useState([]);
  const [discountTypes, setDiscountTypes] = useState([]);
  const getDiscountName = () => {
    customerOnAccountsMethods.getDiscountName().then((data) => {
      if (data?.status === 200) {
        setDiscountNames(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountName();
  }, []);

  // get discount type;

  const getDiscountType = () => {
    customerOnAccountsMethods.getDiscountType().then((data) => {
      if (data?.status === 200) {
        setDiscountTypes(data?.response?.data);
      }
    });
  };

  useEffect(() => {
    getDiscountType();
  }, []);

  // discount info;
  const [discountDetails, setDiscountDetails] = useState({ invoiceNumber: "", discountName: "", discountType: "", discountAmount: 0, discountPercent: 0 });
  const [discount, setDiscount] = useState(0);
  const [discountValue, setDiscountValue] = useState(0);
  const [discountType, setDiscountType] = useState("");
  const [showDiscountButton, setShowDiscountButton] = useState(false);
  const [discountName, setDiscountName] = useState("");
  const [otherDiscount, setOtherDiscount] = useState("")


  useEffect(() => {
    if(discountName === "Other"){
      setDiscountDetails({...discountDetails, discountName: otherDiscount })
    }
  },[otherDiscount])


  const getDiscount = (discValue) => {
    setDiscountType(discValue);
  };

  useEffect(() => {
    setDiscountDetails({ ...discountDetails, discountName: discountName, discountType: discountType, discountAmount: 0, discountPercent: 0 });

    if (discountType === "Percentage") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: 0, discountPercent: discountValue, discountType: discountType });
      const discAmount = Number(total * (discountValue / 100));
      if (Number(discountValue) >= 100) {
        setDiscount(0);
      }
      setDiscount(discAmount);
    } else if (discountType === "Amount") {
      setDiscountDetails({ ...discountDetails, discountName: discountName, discountAmount: discountValue, discountPercent: 0, discountType: discountType });
      if (Number(discountValue) >= Number(total)) {
        setDiscount(0);
      } else {
        setDiscount(Number(discountValue));
      }
    }
  }, [discountType, discountValue]);

  useEffect(() => {
    if (discountDetails.discountName && discountDetails.discountType  && discountValue && discountType) {
      setShowDiscountButton(true);
    } else if (discountDetails.discountName == "Discount Name" || discountDetails.discountType === "" || discountValue === 0 || discountType === "") {
      setShowDiscountButton(false);
    }
  }, [discountDetails.discountName, discountDetails.discountType, discountValue,discountType]);

  useEffect(() => {
    if(showDiscount === false){
      setDiscountDetails({ ...discountDetails, discountName: "", discountAmount: "", discountPercent: 0, discountType: "" });
    }
  } ,[showDiscount])

  // console.log('discount details ======<><><>', discountDetails);

  const doApplyDiscount = () => {
    if (!invoiceNumber) {
      setErrorShow({ state: true, message: "Invoice Number is required!" });
    } else if (discountDetails?.discountName === "") {
      setErrorShow({ state: true, message: "Discount Name is required!" });
    } else if (discountDetails?.discountType === "") {
      setErrorShow({ state: true, message: "Discount Type is required!" });
    } else if(discountValue === 0){
      setErrorShow({ state: true, message: "Discount Amount is required!" });
    }else if(discountName === "Other" && otherDiscount === ""){
      setErrorShow({ state: true, message: "Discount Name is required!" });
    } else {
      customerOnAccountsMethods.postDiscount(invoiceNumber, discountDetails.discountName, discountDetails.discountType, discountDetails.discountAmount, discountDetails.discountPercent).then((data) => {
        if (data?.status === 200 || data?.status === 201) {
          setSuccessShow({ state: true, message: "Discount applied successfully!" });

          setTimeout(() => {
            setSuccessShow({ state: false, message: "" });
          }, 3000);

          doShowDiscount(false);
          refresh();
        } else {
          setErrorShow({ state: true, message: data.response.message });
        }
      });
    }
  };

  // DELETE INVOICE FUNCTIONALITY;

  const [deleteDiscountModal, setDeleteDiscountModal] = useState(false);

  const deleteAppliedDiscount = () => {
    customerOnAccountsMethods.deleteInvoiceDiscount(invoiceNumber).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setDeleteDiscountModal(false);
        setSuccessShow({ state: true, message: "Discount deleted sucessfuly!" });
        doShowDiscount(false);
        refresh();
      } else if (data?.status === 400) {
        setErrorShow({ state: true, message: data?.response?.message });
      }
    });
  };

  const userGroup = localStorage.getItem('group');
  const canPerformDiscounting = userGroup && userGroup !== 'Customer' && invoiceStatus !== "Paid" && invoiceStatus !== 'Partially Paid';

  // console.log('canPerformDiscounting ========== <><><>', canPerformDiscounting);
  
  return (
    <>
      <div style={{ width: "80%", marginLeft: "10%" }}>
        <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

        <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
        <div>
          <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ paddingTop: 15, display: "flex" }}>
                <span>
                  <IconButton aria-label="back" onClick={() => back()}>
                    <KeyboardBackspaceOutlinedIcon sx={{ color: "#dc3545", fontSize: "25px" }} />
                  </IconButton>
                </span>
                <span style={{ fontFamily: "Poppins", fontSize: "25px", fontWeight: "600", textAlign: "left", color: "#dc3545" }}>Preview Invoice</span>
              </div>
              <div style={{ padding: 10, display: "flex" }}>
                {/*{invoiceData?.invoiceStatus === "Unpaid" && invoiceData?.sentTo != currentEmail &&(*/}
                {/*    <Button style={{"width":"180px","height":"45px","borderRadius":"5px","backgroundColor":"transparent", color:"#032541", border:"solid 1px #032541", marginRight:2}} onClick={()=>setReceivePaymentOpen(true)}>*/}
                {/*        Accept Payment*/}
                {/*    </Button>)}*/}

                {(invoiceData?.invoiceStatus === "Unpaid" || invoiceData?.invoiceStatus === "Partially Paid") && invoiceData?.sentTo == currentEmail && (
                  <div>
                    <IconButton aria-label="refresh icon" style={{ color: "#032541", height: "45px", width: "45px" }} onClick={() => refresh()}>
                      <SyncIcon className={`${isSpinning ? "sync-icon spinning" : ""}`} />
                    </IconButton>

                    <Button
                      style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}
                      onClick={() => {
                        navigate("/parent/pay/" + invoiceNumber);
                      }}
                    >
                      Pay
                    </Button>
                  </div>
                )}

                {invoiceData?.batchStatus !== "PENDING" && invoiceData?.batchStatus !== "DECLINED" && invoiceData?.sentTo !== currentEmail && invoiceData?.invoiceStatus !== "CANCELLED" && invoiceData?.invoiceStatus !== "DELETED" && (
                  <div>
                    {invoiceData?.invoiceStatus?.toLowerCase() === "paid" ? null : (
                      <>
                        <Button
                          className="mr-4"
                          style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}
                          onClick={() => {
                            navigate("/parent/pay/" + invoiceNumber);
                          }}
                        >
                          Request Payment
                        </Button>

                        <Button onClick={resendEmail} style={{ width: "180px", height: "45px", borderRadius: "5px", backgroundColor: "#032541", color: "white" }}>
                          Resend Invoice
                        </Button>
                      </>
                    )}
                  </div>
                )}

                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                  <IconButton aria-label="download" onClick={downloadPDF}>
                    <FileDownloadOutlinedIcon sx={{ color: "#032541", fontSize: "25px" }} />
                  </IconButton>
                 
                </span>
                <span style={{ backgroundColor: "rgba(3, 37, 65, 0.05)", marginLeft:"20px", width: "45px", height: "45px", display: "flex", justifyContent: "center", marginLeft: 5, borderRadius: 5 }}>
                <IconButton aria-label="download" onClick={viewPDF}>
                    <PrintIcon sx={{ color: "#032541", fontSize: "25px" }} />
                  </IconButton>
                 
                </span>
               
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "column", overflow: "visible", height: "auto" }} id="downloadable">
              <div className={classes.p3} style={{ backgroundColor: `${invoiceData?.invoiceStatus === "Unpaid" ? "rgba(220,53,69,0.07)" : invoiceData?.invoiceStatus === "Paid" ? "rgba(23,174,123, 0.07)" : "rgba(247,137,41, 0.05)"}`, height: "150px", display: "flex", justifyContent: "space-between" }}>
                <div className={classes.p1} style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: "black", fontSize: "40px", fontWeight: "700" }}>INVOICE</span>
                  <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Date:</span>
                  <span style={{ fontSize: "14px" }}>{formatDate(invoiceData?.createdAt)}</span>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <img src={`${baseUrl}/staticimages/logos/${invoiceData?.businessLogo}`} style={{ width: "150px", height: "150px", objectFit: "contain" }} />
                </div>

                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ color: `${invoiceData?.invoiceStatus === "Paid" ? "#17ae7b" : invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#e07c28"}`, fontSize: "40px", fontWeight: "700" }}>{invoiceData?.invoiceStatus}</span>
                  <span style={{ color: "black", fontSize: "14px", fontWeight: "700" }}>Invoice No:</span>
                  <span style={{ fontSize: "14px" }}>{invoiceData?.invoiceNumber}</span>
                </div>
              </div>
              <div className={classes.p3} style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span style={{ fontSize: "18px" }}>From:</span>
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    {businessInfo?.businessName}
                  </span>
                  <span className={classes.address_text_common}>
                    {businessInfo?.businessOwnerAddress}, {businessInfo?.country}
                  </span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerEmail}</span>
                  <span className={classes.address_text_common}>{businessInfo?.businessOwnerPhone}</span>
                </div>
                <div className={classes.mt4} style={{ display: "flex", flexDirection: "column", textAlign: "right" }}>
                  <span className={classes.address_text_common} style={{ fontWeight: "700" }}>
                    Billed to:
                  </span>
                  <span className={classes.address_text_common}>{invoiceData?.customerName}</span>
                  <span className={classes.address_text_common}>{invoiceData?.sentTo}</span>
                  <span className={classes.address_text_common}>{invoiceData?.customerPhoneNumber}</span>
                </div>
              </div>
              <br />
              {/*<br/>*/}
              {businessCategory == "School" ? (
                <div style={{ border: "dotted 1px", display: "flex", justifyContent: "space-between", padding: "0.625rem" }}>
                  <div>
                    STUDENT ID:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {invoiceData?.itemNumber}
                    </span>
                  </div>
                  <div>
                    STUDENT NAME:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {invoiceData?.studentName}
                    </span>
                  </div>
                  <div>
                    GRADE:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {" "}
                      {invoiceData?.grade}
                    </span>
                  </div>
                  <div>
                    TERM:{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                      }}
                    >
                      {invoiceData?.term}
                    </span>
                  </div>
                </div>
              ) : (
                <div style={{ border: "dotted 1px" }}></div>
              )}
              <br />
              <div>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.textLeft}>Description</TableCell>
                      <TableCell className={classes.textCenter}>Price ({localCurrency})</TableCell>
                      <TableCell className={classes.textCenter}>Qty</TableCell>
                      <TableCell className={classes.textRight}>Total ({localCurrency})</TableCell>
                      {invoiceData?.batchStatus == "PENDING" ? <TableCell className={classes.textRight}></TableCell> : null}
                    </TableRow>
                  </TableHead>
                  {invoiceData?.items?.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell className={classes.textLeft}>
                        <span className={classes.table_body}>{product.productName}</span>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <span className={classes.table_body}>{numberFormat(product.productPrice)}</span>
                      </TableCell>
                      <TableCell className={classes.textCenter}>
                        <span className={classes.table_body}>{product.quantity}</span>
                      </TableCell>
                      <TableCell className={classes.textRight}>
                        <span className={classes.table_body}>{numberFormat(product.productPrice * product.quantity)}</span>
                      </TableCell>
                      {invoiceData?.batchStatus == "PENDING" ? (
                        <TableCell className={classes.textRight}>
                          <ButtonBase
                            onClick={() => {
                              const index = service.findIndex((service) => service.productName === product.productName);
                              setIndexToBeDeleted(index);
                              setIdToBeDeleted(product.productId);
                              setWarningOpen(true);
                            }}
                          >
                            <DeleteOutline style={{ color: "#dc3545" }} />
                          </ButtonBase>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  ))}
                </Table>
              </div>

              {invoiceData?.batchStatus == "PENDING" ? (
                <Button onClick={() => navigate(`/school/invoice/edit/${invoiceNumber}`)} style={{ border: "solid 1px rgba(3, 37, 65, 0.1)", fontWeight: "bold", color: "#032541", borderRadius: "2px", alignContent: "center", background: "#fff", fontSize: "14px", marginTop: 5 }}>
                  <AddCircleOutlineIcon style={{ fontSize: "18px", marginRight: "12px" }} /> Add Services
                </Button>
              ) : null}

              <div
                className={classes.p3}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap:'20px'
                }}
              >

                {
                  canPerformDiscounting  ? <div style={{width:'70%'}}>
                  {invoiceDiscountAmount > 0 ? (
                    <div>
                      {/* <Checkbox style={{ color: "red" }} onChange={(e) => { 
                        
                        // doShowDiscount(!showDiscount)
                        // setDiscount(0)
                    }}/> */}
                      <div>
                        <p style={{margin:'0', color:'#032541',fontWeight:'bold', margin:'1% 0 1% 0'}}>
                          Discount Name : {discountNamee}
                        </p>
                        
                      </div>

                      <Button onClick={() => setDeleteDiscountModal(true)} style={{ backgroundColor: "red", color: "#ffffff" }}>
                        Delete Discount
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: "flex", gap: "2px", alignItems: "center" }}>
                      <Checkbox
                        style={{ color: "#000000" }}
                        onChange={(e) => {
                          doShowDiscount(!showDiscount);
                          setDiscount(0);
                        }}
                      />
                      <span style={{ color: "#000000", fontWeight: "bold" }}>Apply Discount</span>
                    </div>
                  )}

                  {showDiscount && invoiceDiscountAmount === 0 && (
                    <>
                      <div style={{ display: "flex", alignItems: "center", gap: "5px", margin: "0px 0px 2% 0px" }}>

                        <div> 
                            <select onChange={(e) => setDiscountName(e.target.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                              <option>Discount Name</option>
                              {discountNames?.map((disc) => {
                                return <option value={disc}>{disc}</option>;
                              })}
                            </select>
                
                        </div>
                     

                 
                        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                          <input placeholder="Value" onChange={(e) => setDiscountValue(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} />
                          <select onChange={(e) => getDiscount(e.target?.value)} style={{ color: "#000000", border: "1px solid #bec5d1", outline: "none", padding: "5px 5px 5px 20px", width: "100%", cursor: "pointer", borderRadius: "5px", backgroundColor: "#ffffff", fontFamily: "Poppins", fontSize: "14px" }}>
                            <option></option>
                            {discountTypes?.map((type) => {
                              return <option value={type}>{type}</option>;
                            })}
                          </select>
                        </div>
                      </div>
                          
                      <div style={{margin:'0px 0px 2% 0px'}}>
                            {
                              discountName === "Other" ? <input placeholder="Description" onChange={(e) => setOtherDiscount(e.target.value)} style={{ width: "50%", border: "1px solid #bec5d1", outline: "none", padding: "5px" }} />  : null
                            }
                      </div>
                          
                      <div style={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button onClick={() => (showDiscountButton ? doApplyDiscount() : null)} style={showDiscountButton ? { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 1 } : { backgroundColor: "#17ae7b", color: "#ffffff", opacity: 0.5 }}>
                          Apply Discount
                        </Button>
                      </div>
                    </>
                  )}
                </div> : <div style={{width:'70%'}}></div>
                }


                <div
                  className={classes.p1}
                  style={{
                    backgroundColor: "#f7f7f7",
                    width: "30%",
                    height: "130px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Subtotal</span>
                    <span>{discount === 0 && invoiceDiscountAmount === 0 ? numberFormat(total - discount) : numberFormat(total + invoiceDiscountAmount)}</span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span>Discount</span>
                    <span> {discount === 0 && invoiceDiscountAmount === 0 ? numberFormat(discount) : numberFormat(invoiceDiscountAmount)} </span>
                  </div>
                  <div className={classes.totals} style={{ display: "flex", justifyContent: "space-between" }}>
                    <span style={{ color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`, fontWeight: "bold" }}>Total</span>
                    <span
                      style={{
                        fontSize: "16px",
                        color: `${invoiceData?.invoiceStatus === "Unpaid" ? "#dc3545" : "#17ae7b"}`,
                        fontWeight: "bold",
                      }}
                    >
                      {numberFormat(total)}
                    </span>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ border: "dotted 1px" }}></div>
              <br />

              {invoiceData?.invoiceStatus === "Partially Paid" || invoiceData?.invoiceStatus === "Paid" ? (
                <>
                  <div>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.textLeft}>Mode of Payment</TableCell>
                          <TableCell className={classes.textCenter}>Date</TableCell>
                          <TableCell className={classes.textRight}>Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      {invoicePayments?.map((payment, index) => (
                        <TableRow key={index}>
                          <TableCell className={classes.textLeft}>
                            <span className={classes.table_body}>
                              {payment.modeOfPayment}: {payment.ReferenceNumber}
                            </span>
                          </TableCell>
                          <TableCell className={classes.textCenter}>
                            <span className={classes.table_body}>{formatPaymentDate(payment.date)}</span>
                          </TableCell>
                          <TableCell className={classes.textRight}>
                            <span className={classes.table_body}>{numberFormat(payment.amount)}</span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </Table>
                  </div>
                  <br />
                  <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ color: "#dc3545", fontWeight: "bold" }}>
                      <span>Balance: {numberFormat(invoiceBalance)}</span>
                    </div>
                  </div>
                  <br />
                  <div style={{ border: "dotted 1px" }}></div>
                  <br />
                </>
              ) : (
                <></>
              )}
              <div className={classes.p3} style={{ display: "flex", flexDirection: "column" }}>
                <div className={classes.p1}>
                  <span
                    style={{
                      color: "#032541",
                      fontWeight: "700",
                      fontSize: "16px",
                    }}
                  >
                    Accepted Payment Methods
                  </span>
                </div>
                <div style={{ display: "flex" }} id="payments">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="icon"
                  >
                    <img className={classes.p2} src={mpesaIcon} alt="M-pesa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>M-Pesa</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="icon"
                  >
                    <img className={classes.p2} src={cardIcon} alt="Visa" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Card</span>
                    </div>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    className="icon"
                  >
                    <img className={classes.p2} src={creditIcon} alt="Credit" />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        fontSize: "12px",
                      }}
                    >
                      <span>Credit Balance</span>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <div style={{ border: "dotted 1px" }}></div>
              <br />
              <div className={classes.p2} style={{ display: "flex", justifyContent: "center" }}>
                <div className={classes.textCenter} style={{ fontSize: "10px", fontWeight: "300" }}>
                  This is a system-generated invoice is created without any alteration whatsoever.
                  <br />
                  Thank you for your business.
                </div>
              </div>
              <div
                className={classes.p2}
                style={{
                  backgroundColor: "#f7f7f7",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <span className={classes.footer_text}>
                  Powered by{" "}
                  <span
                    style={{
                      fontWeight: "700",
                    }}
                  >
                    ZED Payments Limited
                  </span>
                </span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>info@zed.business</span>
                <span className={classes.footer_text}>.</span>
                <span className={classes.footer_text}>v1.0.2</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ReusableModal open={warningOpen} onClose={handleWarningClose} width="34.688rem">
        <Box sx={{ display: "flex", justifyContent: "center", padding: "3.125rem" }}>
          <Box sx={{ alignSelf: "center", display: "flex", flexDirection: "column", justifyContent: "center" }}>
            <Box sx={{ display: "flex" }}>
              <img style={{ paddingRight: "2.5rem" }} src={warningImage} alt="Warning" />
              <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                <Typography sx={{ color: "#032541", fontWeight: 600 }}>Remove Service ?</Typography>
                <Typography sx={{ color: "#323d52", fontSize: "0.875rem" }}>
                  Service will be deleted. <br /> This action cannot be undone.
                </Typography>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2, marginTop: 5 }}>
              <Button
                sx={{
                  backgroundColor: "transparent",
                  border: "1px solid #002543",
                  color: "#002543",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "#002543",
                  },
                }}
                onClick={handleWarningClose}
              >
                Cancel
              </Button>
              <Button
                sx={{
                  backgroundColor: "#dc3545",
                  color: "#ffffff",
                  width: "7.813rem",
                  height: "2.813rem",
                  "&:hover": {
                    backgroundColor: "#dc3545",
                    color: "#ffffff",
                  },
                }}
                onClick={deleteFromInvoice}
              >
                Remove
              </Button>
            </Box>
          </Box>
        </Box>
      </ReusableModal>

      {/*Legacy modals below*/}
      {/*Received Payment Modal*/}
      <ReceivedPayment paymentOpen={receivePaymentOpen} onClose={() => setReceivePaymentOpen(false)} />

      {/*    Payment Methods*/}
      <PaymentMethods paymentMethodsOpen={paymentMethodsOpen} onClose={() => setPaymentMethodsOpen(false)} invoiceBalance={invoiceData?.invoiceBalance} invoiceNumber={invoiceNumber} />

      <DeleteDiscountModal deleteDiscountModal={deleteDiscountModal} billAmount={invoiceDiscountAmount} setDeleteDiscountModal={setDeleteDiscountModal} deleteAppliedDiscount={deleteAppliedDiscount} />
    </>
  );
};

export default Invoice;
