import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {Link, useLocation} from "react-router-dom";
import React, { useState } from "react";
import {makeStyles} from "@material-ui/core/styles";
import DraftsIcon from '@mui/icons-material/Drafts';
import StudentPromoIcon from "../../../images/promoteIcon.svg"
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined'


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function StudentPromotion () {
	const classes = useStyles();
	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>
			<Link to="/school/studentpromotion" style={{textDecoration: 'none', color: isActiveLink("/school/studentpromotion") ? activeColor : '#fff'}}>
				<ListItem button className={classes.nested}>
					<div style={{marginRight : '5%'}}>
						<SchoolOutlinedIcon/>
					</div>
					<div>
						<ListItemText primary="Student Promotion" />
					</div>
				</ListItem>
			</Link>
		</div>
	)
}