import { Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, TextField, Typography } from "@mui/material";
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import HttpComponent from "../../../School/MakeRequest";
import CustomInputLabel from "../../../School/CustomInputLabel";
import CustomTextField from "../../../School/CustomTextField";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Terminal</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Change OutLet</Typography>
]

export default function AssignNewTerminal(props){
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [outlets ,setOutlets]= useState([])
    const [outlet ,setOutlet] = useState('')
    const [branchID ,setBranchId] = useState()
    const [outletName ,setOutletName] = useState()
    const [previousStore ,setPreviousStore] = useState()

    console.log(props , 'propsssssssssss')

    // navigate
    const navigate = useNavigate();
    const brachid = localStorage.getItem('BranchID')
  

    //fetch outlets
    function getOutlets() {
        try {
            HttpComponent({
                method: "GET",
                url: `/api/list_all_stores_by_type_status?storeType=MainStore&status=ACTIVE&page&limit=`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                //console.log(data , 'heeeeeee')
                if (data.status === 200) {
                    setOutlets(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getOutlets()
    }, [])

    //store details

    function handlePreviousStore(){
        try {
            HttpComponent({
                method:"GET",
                url:`/api/v1/getOutletsAssignedToTerminal?storeId=${props?.storedId}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                //console.log(data , 'keliiiii')
                if(data.status === 200){
                    //branchId //outletName //storeId
                    setBranchId(data?.response?.data?.branchId)
                    setPreviousStore(data?.response?.data?.storeId)
                    setOutletName(data?.response?.data?.outletName)
                }

            })
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(()=>{
        handlePreviousStore()
    },[])

//assignStoreId
    //handle update 

    const formSubmit = {
        storeId:previousStore,
        assignStoreId:outlet,
        branchId:branchID ? branchID : brachid
    }

    const newTerminal = {
        assignStoreId:outlet,
        branchId:branchID ? branchID : brachid,
        status:"New"
    }

    console.log("props?.outletName", props?.outletName)
    //update store

    function handleSubmit(){
        try {
            HttpComponent({
                method:"POST",
                url:`/api/v1/assignTerminalToOutlet?terminalId=${props?.terminalId}`,
                body:props?.outletName === 'N/A' ? newTerminal: formSubmit,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    setSuccessShow({state:true , message:"Terminal assigned to a new outlet successfully"})
                }else{
                    setErrorShow({state:true ,message:"Error assigning to a new outlet successfully" })
                }
                setTimeout(()=>{
                    navigate(-1)
                },[1000])
            })
        } catch (error) {
            setErrorShow({state:true ,message:error })
        }
    }


    return(
        <Grid container direction={'column'}>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Change OutLet</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 400, fontSize: "18px", color: "#032541" }}>Change OutLet</Typography>
            </Grid>
            {props?.terminalStatus === 'Active' ?
            <Grid item  mt={2} style={{ width: "50%" }} >
                <CustomInputLabel label={'Current Outlet'}/>
                <CustomTextField value={outletName} placeholder={'Current Outlet'}  InputProps={{ readOnly: true,}} style={{ width: "100%" }}/>
            </Grid>:null}
            <Grid item mt={2} style={{ width: "50%" }}>
                <FormControl style={{ width: "100%" }}>
                    <InputLabel id="demo-simple-select-label">New Outlet</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={outlet}
                        label="New Outlet"
                        onChange={(e)=>setOutlet(e.target.value)}
                        name="outlet"
                    >
                        {outlets?.map((item)=>(
                            <MenuItem name="outlet" key={item?._id} value={item?._id}>{item?.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid mt={2} item style={{ width: "50%" }} display={'flex'} justifyContent={'flex-end'}>
                <Button onClick={()=>navigate(-1)} style={{ background: "#fff", color: "#DC3545", border: "1px solid #DC3545", width: "125px", marginRight: "5px", textTransform: "inherit" }}>Cancel</Button>
                <Button onClick={handleSubmit} style={!outlet ? { opacity: 0.4, width: "125px", background: "#032541", textTransform: "inherit", } : { background: "#032541", textTransform: "inherit", width: "125px", color: "#fff", opacity: 1 }}>Update</Button>
            </Grid>

        </Grid>
    )
}