import React, { useState, useEffect } from 'react';
import { Box, Button, Typography } from "@mui/material";
import CustomDropZone from "./CustomDropZone";
import ServiceCategoryForm from "./ServiceCategoryForm";
import ServiceForm from "./ServiceForm";
import ParentGuardianForm from "./ParentGuardianForm";
import StudentSetup from "./StudentSetUp";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "./CustomTable";
import { removeFromServicesToAdd } from "../../features/servicesSlice";
import HttpComponent from "./MakeRequest";
import { useNavigate } from "react-router-dom";
import { clearAdditions, removeFromStudentsToAdd } from "../../features/customerSlice";
import SetWorkFlow from "../Rental/Utility/updateSetupWorkFlow";
import csvTemplate from "./Parent-Student-Template.csv";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { reversePageLoadState } from '../../features/autorefreshonpageload';
const baseUrl = process.env.REACT_APP_BASE_URL;


const ParentStudentSetUp = () => {
    const [formView, setFormView] = useState("initial"); // return this to inital
    const { parentToAdd, studentToAdd, studentsToAdd, additionServices } = useSelector((store) => store.customers);
    const [tableData, setTableData] = useState([])
    const buttonStyles =
    {
        "minWidth": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    const createButton = {
        "width": "15.313rem",
        "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color": "#032541",
        marginX: 1,
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }
    //
    useEffect(() => {
        if (parentToAdd && !studentsToAdd) {
            setFormView("student")
        } else if (parentToAdd && studentsToAdd) {
            setFormView("done")
        }
    }, []);

    useEffect(() => {
        const dataWithGridId = studentsToAdd?.map((row, index) => ({
            ...row,
            gridId: `${index}-${row.id}`
        }));
        setTableData(dataWithGridId)
    }, [studentsToAdd]);

    useEffect(() => {
        dispatch(clearAdditions())
        setFormView("initial")
    }, [])


    const columns = [
        { headerName: "Admission No", field: "itemNumber", flex: 1 },
        {
            headerName: "Student Name", field: "studentName", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.row.firstName} {params.row.lastName}
                </Typography>
            )
        },
        { headerName: "Gender", field: "gender", flex: 1 },
        { headerName: "Grade", field: "grade", flex: 1 },
        { headerName: "Term", field: "term", flex: 1 },
        {
            headerName: "Additional Info", field: "additionalInfo", flex: 1,
            renderCell: params => (
                <Typography>
                    {params.row.additionalInfo == "" ? "N/A" : params.row.additionalInfo}
                </Typography>
            )
        },
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => (
                <Button
                    sx={{
                        color: "#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => {
                        const index = studentsToAdd?.findIndex((student) => student.itemNumber === params.row.itemNumber);
                        dispatch(removeFromStudentsToAdd({ index }))
                    }}
                >
                    Remove
                </Button>
            ),
        },
    ];

    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successToast, setSuccessToast] = useState({ state: false, message: "" })

    const showUploaded = () => {
        dispatch(clearAdditions())
        localStorage.setItem("workflow", "PARENT")
        localStorage.setItem("group", "Merchant")
        navigate("/dashboard")
    }

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [watch, setWatch] = useState()
    const [schoolTypeName, setSchoolTypeName] = useState("")
    const GetSchoolTypeIdForBusiness = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_type_by_businesss`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            }
        });
        const data = await response.json();
        console.log(data, 'School Type Id');
        if (response.status === 201) {
            setSchoolTypeName(data.data?.schoolType);

        }
    }
    useEffect(() => {
        GetSchoolTypeIdForBusiness();
    }, [])


    const submit = () => {


        let firstParent = []
        let secondParent = []
        if (parentToAdd?.length > 0) {
            firstParent = parentToAdd[0]
            secondParent = parentToAdd[1]
        }

        console.log('second parent', secondParent, 'first parent', firstParent, 'addition services', additionServices, 'students to add', studentsToAdd);

        // let data =[ { ...firstParent,  billableItems:[...addStude]}, {...secondParent}];
        let data = []

        if (secondParent && firstParent) {
            data = [{ ...firstParent, extraServices: [{ ...additionServices }], billableItems: [{ ...studentToAdd }] }, { ...secondParent }];
            console.log('if mydatra', data)
        } else if (firstParent) {
            data = [{ ...firstParent, billableItems: [{ ...studentToAdd }], extraServices: [{ ...additionServices }] }];
            console.log('else mydatra', data)
        } else if (schoolTypeName?.includes("University")) {
            data = { ...studentToAdd, extraServices: [{ ...additionServices }] }
        }


        setWatch(data)
        console.log('Data  added to addCustomer', data)
        HttpComponent({
            method: 'POST',
            url: '/api/addCustomer',
            body: data,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                dispatch(clearAdditions())
                localStorage.setItem("workflow", "PARENT")
                localStorage.setItem("group", "Merchant")
                dispatch(reversePageLoadState())
                setTimeout(() => {
                    navigate("/dashboard")
                }, 2000);

            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }


    const skipIt = () => {
        SetWorkFlow({ flowName: 'PARENT', token: X_Authorization })
        localStorage.setItem("workflow", "PARENT")
        localStorage.setItem("group", "Merchant")
        localStorage.removeItem("schoolTypeState")
        navigate("/dashboard")
    }



    return (
        <Box component="div" sx={{ display: "flex", justifyContent: "center", margin: 2, width: "100%" }}>
            <Box component="div" sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                {formView == "initial" ?
                    <>
                        <Box sx={{ alignSelf: "center", marginY: 2 }}>
                            <Typography sx={{ fontSize: "1rem", color: "#707070" }}>
                                {schoolTypeName?.includes("University") ? "Add at least 1 Student" : "Add at least 1 Parent/Guardian"}
                            </Typography>
                        </Box>

                        <Box sx={{ marginRight: "auto", marginLeft: "auto", marginY: "1%" }}>
                            {schoolTypeName?.includes("University") ?
                                <div>
                                    <Button sx={{ color: "#032541", fontSize: "16px" }} startIcon={<FileDownloadOutlinedIcon />}>
                                        Download CSV Template --- Coming soon
                                    </Button>
                                </div> : <a href={csvTemplate} download>
                                    <Button sx={{ color: "#032541", fontSize: "16px" }} startIcon={<FileDownloadOutlinedIcon />}>
                                        Download CSV Template
                                    </Button>
                                </a>}
                        </Box>


                        <Box sx={{ alignSelf: "center" }}>
                            <CustomDropZone
                                setShowErrorToast={setErrorShow}
                                setShowSuccessToast={setSuccessToast}
                                successAction={showUploaded}
                                url={"/api/upload_parents_students"} />
                        </Box>


                        <Box component="div" sx={{ alignSelf: "center", marginY: 2 }}>
                            <Button sx={createButton} onClick={skipIt}>
                                Skip Step
                            </Button>
                            <Button sx={createButton} onClick={() => {
                                if (schoolTypeName?.includes("University")) {
                                    setFormView("student")
                                } else {
                                    setFormView("parent")
                                }
                            }}>
                                {schoolTypeName?.includes("University") ? "Add Student" :
                                    "Add Parent/Guardian"
                                }
                            </Button>
                        </Box>

                    </>
                    : formView == "parent" ?
                        <Box component="div" sx={{ alignSelf: "center" }}>
                            <ParentGuardianForm setView={setFormView} />
                        </Box>
                        : formView == "student" ?
                            <Box component="div" sx={{ alignSelf: "center", width: "100%" }}>
                                <StudentSetup setView={setFormView} />
                            </Box>
                            :
                            <>
                                <Box component={"div"} sx={{ display: "flex", width: "100%", justifyContent: "space-between", marginTop: "2%" }}>
                                    <Box component="div" sx={{ marginLeft: "5.8%" }}>
                                        <Typography sx={{ fontSize: "1rem", color: "#707070" }}>
                                            Students
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", marginRight: "5%" }}>
                                        <Button sx={{
                                            ...buttonStyles,
                                            border: " solid 1px #002543",
                                            color: "#002543",
                                            '&:hover': {
                                                backgroundColor: "transparent",
                                                color: '#002543'
                                            }
                                        }}>
                                            Upload File
                                        </Button>

                                        <Button sx={{
                                            ...buttonStyles,
                                            marginLeft: 1,
                                            backgroundColor: " #032541",
                                            color: "white",
                                            '&:hover': {
                                                backgroundColor: '#032541',
                                                color: 'white'
                                            }
                                        }}
                                            onClick={() => setFormView("student")}
                                        >
                                            Add Student
                                        </Button>
                                    </Box>
                                </Box>
                                <Box sx={{ alignSelf: "center", width: "90%", marginTop: "2%" }}>
                                    <CustomTable data={tableData} columns={columns} rowsPerPage={5} />
                                </Box>
                                <Box component="div" sx={{ display: "flex", width: "95%", justifyContent: "end" }}>
                                    <Button sx={{
                                        "minWidth": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                        onClick={submit}
                                    >
                                        Complete Setup
                                    </Button>
                                    <Button sx={{
                                        "minWidth": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        marginLeft: "10px",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                        onClick={skipIt}
                                    >
                                        Skip Step
                                    </Button>
                                </Box>
                            </>
                }
            </Box>
        </Box>
    )
}

export default ParentStudentSetUp;
