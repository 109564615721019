import { Breadcrumbs, Button, Grid, Typography } from "@mui/material"
import { SuccessAlert } from "../../../components/snackBar Alerts/successAlert"
import { ErrorAlert } from "../../../components/snackBar Alerts/errorAlert"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useState } from "react"
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../components/No Rows/noRowsOverlay";
import CustomSearchInput from "../../../components/School/CustomSearchInput";
import HttpComponent from "../../../components/School/MakeRequest";
import { useNavigate } from "react-router-dom";
import CreatenewLedgerAccount from "./createNewLedger";
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';

const breadcrumbs = [
    <Typography style={{ fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.inactive">Dashboard</Typography>,
    <Typography style={{ color: '#dc3545', fontFamily: 'Poppins', fontWeight: 600, fontSize: '14px' }} key="3" color="text.error">General Ledger Account</Typography>
]



export default function GeneralLedgerSetUp() {

    const queryparams = decodeURIComponent(window.location.search);
    const navigate = useNavigate()
    const state = queryparams.slice(1)

    const [searchValue , setSearchValue] = useState('')
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })


    // ledger columns

    const RenderCell = (params)=>{
        return(
            <div>
                <ModeEditOutlineIcon onClick={()=>setSuccessShow({state:true , message:'Coming soon'})}/>
            </div>
        )
    }

    const GeneralLedgerColumn = [
        { field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Category Name</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.name}</span></>) } },
        { field: 'accountType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Classification</strong>) }, renderCell: (params) => { return (<span>{params?.row?.accountType}</span>) } },
        { field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell },
    ]

    // create new Ledger

    const handleNewLedger = () => navigate('/ledgersetup?createnewLedger')

    //get all ledgers

    function handleGetAllLedger(){
        setPageState((old) => ({ ...old, isLoading: true }));
        try{
            HttpComponent({
                method:'GET',
                url:`/api/v1/business/generallledger/all?page=${pageState?.page}&limit=${dataGridPageSize}&search=${searchValue}`,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if(data.status === 200){
                    console.log(data , 'all ledgerrrrrrrrrrrrrr')
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        }catch(error){
            console.log(error)
        }
    }

    // all ledger results

    useEffect(()=>{
        handleGetAllLedger()
    },[state ,dataGridPageSize , pageState.page , searchValue])



    return (
        <Grid container display={'flex'} direction={'column'}>
            {state === 'createnewLedger' ? <CreatenewLedgerAccount/> :<>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item>
                <Typography style={{ color: "#032541", fontSize: "25px", fontWeight: 600 }}>General Ledger Account</Typography>
            </Grid>
            <Grid item mt={2}>
                <Grid container display={'flex'} alignItems={'center'} alignContent={'center'} justifyContent={'space-between'} direction={'row'}>
                    <Grid item display={'flex'} direction={'row'} alignItems={'center'} alignContent={'center'}>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />} aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                     {pageState?.data?.length > 0 ?
                    <Grid item>
                        <Button onClick={handleNewLedger} style={{ textTransform: "none", textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Add New</Button>
                    </Grid>:null}
                </Grid>
            </Grid>
            {pageState?.data?.length > 0 ?
            <Grid item display={'flex'} direction={'column'} mt={2}>
                <CustomSearchInput name={'search'} placeholder={'search'} value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}/>
                <Grid item mt={3}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState?.total}
                        loading={pageState?.isLoading}
                        pagination
                        page={pageState?.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={GeneralLedgerColumn}
                        rows={pageState?.data}
                        getRowId={row => row?._id}
                    />                
                </Grid>
               
            </Grid>
            :
            <Grid container width={'100%'} height={'100%'} direction={'column'} alignContent={'center'} justifyContent={'center'} display={'flex'}>
                <Grid item display={'flex'} mt={20} justifyContent={'center'} alignItems={'center'}  alignContent={'center'} direction={'column'} height={'400px'} width={'400px'} >
                    <Typography>No records found !</Typography>
                    <Typography sx={{fontSize:'16px'}}>Create General ledger accounts and view them here</Typography>
                    <Button onClick={handleNewLedger} style={{ textTransform: "none", marginTop:'10px', textDecoration: "none", marginTop: '5px', background: '#032541', color: '#fff', fontSize: `14px`, fontWeight: 600, height: '45px', width: '125px' }} >Create Acount</Button>
                </Grid>
            </Grid>
            }
            </> 
        }
        </Grid>
    )
}