import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Grid, InputLabel, MenuItem, Select, TableFooter } from "@mui/material";
import moment from "moment-timezone";
import "./Calender/DateRangePicker.css";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import { useNavigate } from "react-router-dom";
import Breadcrumbcomponent from "../products/stockComponents/breadcrumb";
import CustomDataGrid from "../products/stockComponents/customDataGrid";
import HttpComponent from "../School/MakeRequest";
// import CustomSelectField from "./CustomSelectField";
import CustomSelectField from "../School/CustomSelectField";
import { Exports } from "../customerAccounts/customerComponents/exports";
import { CustomizedDate } from "../customerAccounts/customerComponents/customDate";
import DateFormatter from "../../utils/dateFormatter";

// session Expired
const style = {
  borderRadius: "36px",
  boxShadow: "0 0 12px 0 rgba(120, 90, 236, 0.2)",
  backgroundColor: "#fff",
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "660px",
  height: "570px",
  p: 4,
};

const baseUrl = process.env.REACT_APP_BASE_URL;

export default function Transaction() {
  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  const breadcrumbs = [
    <span key={1}>Dashboard</span>,
    <span key={2} style={{ color: "red" }}>
      Transactions
    </span>,
  ];
  const [transType, setTransType] = useState("");

  const navigate = useNavigate();

  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionOpen = () => setSessionOpen(true);

  // Transactions Setters and Getters
  const [transactions, setTransactions] = useState([]);

  //chart dropDown
  const [dropDown, setDropDown] = useState("7days");

  // Transaction typesetters and Getters
  const [transactionType, setTransactionType] = useState([]);

  const [days, setDays] = useState([
    { value: "Default", label: "Default" },
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 Days", label: "Last 7 Days" },
    { value: "This Month", label: "This Month" },
    { value: "Last Month", label: "Last Month" },
    { value: "Custom Range", label: "Custom Range" },
  ]);

  const [handleDaysChange, setHandleDaysChange] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");


  useEffect(() => {
    switch (handleDaysChange) {
      case "Today":
        const today = new Date().toLocaleDateString();
        setStartDate(today);
        setEndDate(today);
        break;
      case "Yesterday":
        let date = new Date();
        const yeseterday = new Date(date.setDate(date.getDate() - 1)).toLocaleDateString();
        // console.log(yeseterday);
        setStartDate(yeseterday);
        setEndDate(yeseterday);
        break;
      case "Last 7 days":
        function Last7Days() {
          var result = [];
          for (var i = 0; i < 7; i++) {
            var d = new Date();
            result.push(d.setDate(d.getDate() - i));
          }
          const lastSevenFirst = new Date(result[0]).toLocaleDateString();
          const lastSevenLast = new Date(result[result.length - 1]).toLocaleDateString();

          setStartDate(lastSevenLast);
          setEndDate(lastSevenFirst);
        }
        Last7Days();
        break;
      case "This Month":
        const date1 = new Date();
        const firstDay = new Date(date1.getFullYear(), date1.getMonth(), 1).toLocaleDateString();
        const currentDay = new Date().toLocaleDateString();
        setStartDate(firstDay);
        setEndDate(currentDay);

        break;
      case "Last Month":
        const date2 = new Date();
        const firstDayLastMonth = new Date(date2.getFullYear(), date2.getMonth() - 1).toLocaleDateString();
        const lastDayLastMonth = new Date(date2.setDate(1)).toLocaleDateString();
        setStartDate(firstDayLastMonth);
        setEndDate(lastDayLastMonth);
        break;
      case "Default":
        setStartDate("");
        setEndDate("");
      case "Custom Range":
        setStartDate(dateFrom);
        setEndDate(dateTo);
      default:
        return;
    }
  }, [handleDaysChange, dateFrom, dateTo]);

  //fetch transactions
  const fetchTransactions = async () => {
    HttpComponent({
      method: "GET",
      body: null,
      url: `/viewAllTransactions?page=${pageState?.page}&limit=${dataGridPageSize}&startDate=${startDate}&endDate=${endDate}&transactionType=${transType}`,
      token: localStorage.getItem("X-Authorization"),
    }).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setPageState({ ...pageState, data: data?.response?.data, count: data?.response?.count, isLoading: false });
      }
    });
  };

  useEffect(() => {
    fetchTransactions();
  }, [dataGridPageSize, pageState?.page, transType, endDate, startDate]);

  // Currency Formatter
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const rowData = pageState?.data?.map((transaction) => {
    return {
      id: transaction._id,
      businessNo: transaction.businessNo,
      businessName: transaction.businessName,
      businessShortCode: transaction.businessShortCode,
      transactionID: transaction.transactionID,
      transactionType: transaction.transactionType,
      transtime: transaction.transtime,
      uploadTime: transaction.uploadTime,
      customerFirstName: transaction.customerFirstName,
      customerMiddleName: transaction.customerMiddleName,
      customerPhone: transaction.customerPhone,
      transamount: transaction.transamount,
      serialNo: transaction.serialNo,
      cashier: transaction.cashier,
      paymentChanel: transaction.paymentChanel,
      paidOrderId: transaction.paidOrderId,
    };
  });

  // Fetch Payment Types from DB
  const fetchTransactionTypes = async () => {
    try {
      const response = await fetch(baseUrl + "/api/transactionType", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200) {
        const transTYpe = data?.data?.map((data) => {
          return {
            value: data._id.transactionType,
            label: data._id.transactionType,
          };
        });
        setTransactionType(transTYpe);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTransactionTypes();
  }, []);


  useEffect(() => {
	  // CSV Export Format
	  const csvColumns = [
		{ label: "Receipt No.", key: "transactionID" },
		{ label: "Transaction Type", key: "transactionType" },
		{ label: "Business Name", key: "businessName" },
		{ label: "Payment Mode", key: "paymentChanel" },
		{ label: "Terminal User", key: "cashier" },
		{ label: "Date", key: "transtime" },
		{ label: "Amount", key: "transamount" },
	];

	const csvData = pageState?.data?.map((data) => {
		return {
			"transactionID":data?.transactionID,
			"transactionType":data?.transactionType,
			"businessName":data?.businessName,
			"paymentChanel":data?.paymentChanel,
			"cashier":data?.cashier,
			"transtime":DateFormatter(data?.transTime) ,
			"transamount": data?.transamount
		}
	})

	// columns selection for Excel Export
	const pdfData = pageState?.data?.map((transaction) => {
		return {
			ReceiptNo: transaction.transactionID,
			transactionType: transaction.transactionType,
			BusinessName: transaction.businessName,
			PaymentMode: transaction.paymentChanel,
			TerminalUser: transaction.cashier,
			Date: DateFormatter(transaction.transtime),
			Amount: transaction.transamount,
		};
	});
  
   
	//PDF export Format
  
		const title =
			` SALE REPORT FROM `;
		const headers = [
			[
				"Receipt No",
				"Business Name",
				"Transaction Type",
				"Payment Mode",
				"Terminal User",
				"Date",
				"Amount",
			],
		];
  
		const data = pdfData.map((data) => [
			data.ReceiptNo,
			data.BusinessName,
			data.transactionType,
			data.PaymentMode,
			data.TerminalUser,
			moment(data.Date).format("llll"),
			numberFormat(data.Amount),
		]);
		
		setStartTransactionTime(new Date(pdfData[pdfData?.length - 1]?.Date).toLocaleDateString() )
		setEndTransactionTime(new Date(pdfData[0]?.Date).toLocaleDateString())
		setExportData({headers :headers, data: data });
		setcsvExport({ headers: csvColumns, data: csvData });
  } ,[pageState.data, pageState.pageSize, dataGridPageSize])


	const [exportData, setExportData] = useState({ headers: [], data: [] });
	const [csvExport, setcsvExport] = useState({ headers: [], data: [] })
	const [startTransactionTime, setStartTransactionTime] = useState("")
	const [endTransactionTime, setEndTransactionTime] = useState("")

  const column = [
    {
      field: "transactionID",
      headerName: "TXID",
      flex: 1,
      filter: "text",
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"TXID"}
        </strong>
      ),
      renderCell: (params) => {
        return (
          <span style={{ color: "#dc3545", cursor: "pointer" }} onClick={() => navigate(`/detailedbill/${params?.row?.transactionID}`)}>
            {" "}
            {params?.row?.transactionID}{" "}
          </span>
        );
      },
    },
    {
      field: "businessNo",
      headerName: "Business No",
      flex: 1,
      headerAlign: "left",
      align: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Business No"}
        </strong>
      ),
    },
    {
      field: "businessName",
      headerName: "Business Name",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Business Name"}
        </strong>
      ),
    },
    {
      field: "transactionType",
      headerName: "Type",
      flex: 1,
      filter: "text",
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Type"}
        </strong>
      ),
    },
    {
      field: "transtime",
      headerName: "Time",
      flex: 1,
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Time"}
        </strong>
      ),
      width: 200,
      valueGetter: (params) => {
        const transTime = params.row.transtime;
        return moment(transTime).tz("Africa/Nairobi").format("llll");
      },
    },
    {
      field: "uploadTime",
      headerName: "Upload Time",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Upload Time"}
        </strong>
      ),
      flex: 1,
      width: 200,
      headerAlign: "left",
      align: "left",
      valueGetter: (params) => {
        return moment(params.row.uploadTime).format("llll");
      },
    },
    {
      field: "customerFirstName",
      headerName: "Name",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Name"}
        </strong>
      ),
      align: "left",
      flex: 1,
    },
    {
      field: "customerPhone",
      headerName: "Phone",
      flex: 1,
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Phone"}
        </strong>
      ),
      valueGetter: (params) => {
        const phone = params.row.customerPhone || "";
        return phone.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2");
      },
    },
    {
      field: "transamount",
      flex: 1,
      headerName: "Amount",
      type: "number",
      width: 200,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Amount"}
        </strong>
      ),
      valueGetter: (params) => {
        const amount = params.row.transamount || "";
        return Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        }).format(amount);
      },
    },
    {
      field: "serialNo",
      headerName: "Serial",
      type: "string",
      flex: 1,
      align: "left",
      headerAlign: "left",
      renderHeader: () => (
        <strong
          style={{
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "600",
            color: "#032541",
          }}
        >
          {"Serial"}
        </strong>
      ),
    },
  ];

  return (
    <div style={{ width: "100%" }}>
      <Grid container width={"100%"} direction={"column"}>
        <Grid margin={"0 0 1% 0"} item>
          <h2 style={{ fontWeight: "bold", color: "#dc3545" }}>All Transactions</h2>
        </Grid>
        <Grid margin={"0 0 1% 0"} item>
          <Breadcrumbcomponent breadcrumbs={breadcrumbs} />
        </Grid>
      </Grid>

      <Grid container direction={"row"} width={"100%"} spacing={1} alignItems={"center"} justifyContent={"space-between"}>
        <Grid item display={"flex"} gap={"10px"} alignItems={"center"}>
          <CustomSelectField onChange={(e) => setTransType(e.target.value)} value={transType} placeholder={"Transaction Type"} options={transactionType} name={"Transaction Type"} />
          {handleDaysChange !== "Custom Range" && <CustomSelectField onChange={(e) => setHandleDaysChange(e.target.value)} value={handleDaysChange} placeholder={"Select Day"} options={days} name={"Transaction Type"} />}

          {handleDaysChange === "Custom Range" && <CustomizedDate setDateFrom={setDateFrom} setDateTo={setDateTo} style={{ display: "flex", alignItems: "center" }} />}
        </Grid>

        <Grid item>
          <Exports exportData={exportData} csvExport={csvExport} activeTabs={`Transaction Report for ${localStorage.getItem("businessName")} for period ${startTransactionTime} and ${endTransactionTime} printed on `} />
        </Grid>
      </Grid>

      <Grid container>
        <CustomDataGrid customStyles={{ border: "none", height: "600px", width: "100%" }} column={column} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={rowData} />
      </Grid>
    </div>
  );
}
