import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, MenuItem, OutlinedInput, Select, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import HttpComponent from "./MakeRequest";
import { useSelector } from "react-redux";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Load Student Wallet</Typography>
]

export default function LoadStudentWallete() {
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [currentWalletBalance, setCurrentWalletBalance] = useState()
    const [userPhone, setPhone] = useState('')
    const [amount, setAmount] = useState()
    const [startTransaction, setStartTransaction] = useState(false)
    const [count, setCount] = useState(65)
    const businessNumber = localStorage.getItem('businessId')
    const [selectedAdminssionNumber, setSelectedAdmissionNumber] = useState(null)
    const [selectedStudent, setSelectedStudent] = useState(null)
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store?.schoolType?.schoolTypeDetail)

    const navigate = useNavigate();


    //get wallet balance
    const formData = {
        amount: amount,
        phone: userPhone,
        type: "wallet",
        accountType: "pocketmoney",
        orderIds: [{ admissionNumber: selectedAdminssionNumber, businessNumber: businessNumber }]
    }

    //handle onChange

    const handleOnSelectChange = (e) => {
        setSelectedStudent(e.target.value)
        const selectedStud = e.target.value
        setSelectedAdmissionNumber(selectedStud?.itemNumber)
        setCurrentWalletBalance(selectedStud?.walletBalance)
    }

    const [students, setStudents] = useState([])
    const [socketInterval, setMyInterval] = useState(null)
    const [isNavigating, setIsNavigating] = useState(false);

    const fetchStudents = () => {
        HttpComponent({
            method: 'POST',
            url: `/api/getBillableItems?page=1&limit=1000`,
            body: { customerId: localStorage.getItem('customerId') },
            token: localStorage.getItem('X-Authorization')
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 200) {
                setStudents(data.response.data)
                if (schoolTypeName === 'University/College') {
                    setSelectedAdmissionNumber(data?.response?.data[0].itemNumber)
                    setCurrentWalletBalance(data?.response?.data[0].walletBalance)
                }
            } else {
                console.error("Error setting info")
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchStudents()
    }, [])

    //console.log(formData ,'formData')
    //handle initiate kcb stk push

    function handleInitiateKCBStkPush() {
        setStartTransaction(true)
        try {
            HttpComponent({
                method: 'POST',
                url: "/api/v1/payments/initiate_kcb_stk_push",
                body: formData,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    let socketInterValId = setInterval(() => {
                        listenWebsocket(data?.response?.data?.walletNumber)
                    }, 5000);

                    setMyInterval(socketInterValId)
                } else {
                    setErrorShow({ state: true, message: "Error in initiating transaction" })
                }
            })
        } catch (error) {

        }
    }
    useEffect(() => {
        let interval;

        if (startTransaction) {
            interval = setInterval(() => {
                setCount((prevCount) => prevCount - 1);
            }, 1000);
        } else {
            clearInterval(interval);
        }

        return () => clearInterval(interval);

    }, [startTransaction]);

    //ws://api.dev.zed.business/getWalletNotification

    const listenWebsocket = async (admNumber) => {
        let ws = ""

        if (process.env.NODE_ENV === 'development') {
            ws = new WebSocket(`wss://api.dev.zed.business/getWalletNotification/${admNumber}`);
        } else if (process.env.NODE_ENV === 'production') {
            ws = new WebSocket(`wss://api.dev.zed.business/getWalletNotification/${admNumber}`);
        } else if (process.env.NODE_ENV === 'test') {
            //zed.swerri.io
            ws = new WebSocket(`wss://api.dev.zed.business/getWalletNotification/${admNumber}`);
        }
        ws.onopen = () => {
        }
        ws.onmessage = function (event) {
            const data = JSON.parse(event.data); 
            if (data.status === 200) {
                const message = data.message;
                setSuccessShow({ state: true, message: message })
                setStartTransaction(false)
                setIsNavigating(true)
                ws.close()

            } else if (data.status === 403) {
                const message = data?.message;
                setErrorShow({ state: true, message: message });
                setStartTransaction(false)
                setIsNavigating(true)
                ws.close()
               
            }
        };
        ws.onclose = function (event) {
            console.log('WebSocket connection closed with code:', event.code);
        };

        ws.onerror = function (error) {
            console.error('WebSocket error:', error);
        };

    }
    useEffect(() => {
        if (isNavigating) {
            clearInterval(socketInterval);
            setMyInterval(null);
            setTimeout(()=>{
                navigate(-1);
            },1500)
          
        }
    }, [isNavigating]);
    useEffect(() => {
        if (socketInterval === null) return;

        return () => {
            clearInterval(socketInterval);
            setMyInterval(null);
        };
    }, [socketInterval]);



    return (
        <Grid direction={'column'} container>
            <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
            <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Load Student Wallet</Typography>
            </Grid>
            <Grid item mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 600, fontSize: "16px", color: "#707070" }}>Load your student wallet and continue transacting</Typography>
            </Grid>
            <Grid item mt={3} width={'50%'}>
                <Box sx={{ display: "flex", alignItems: "center" }} style={{ height: "40px", background: "rgba(23, 174, 123, 0.1)", width: "100%" }}>
                    <Typography sx={{ marginLeft: "10px", fontSize: "16px" }}>Current wallet balance  <span style={{ color: "#000000", fontWeight: "900" }}>{currentWalletBalance ? currentWalletBalance : 0}</span></Typography>
                </Box>
            </Grid>
            {schoolTypeName !== 'University/College' ?
                <Grid item mt={3} width={'50%'}>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Select Student</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={selectedStudent}
                            label="Select Student"
                            onChange={handleOnSelectChange}
                        >
                            {students?.map((item) => (
                                <MenuItem value={item}>{item?.firstName + item?.lastName}</MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid> : null}
            <Grid item mt={3}>
                <Typography style={{ fontSize: "14px", color: "#707070" }}>Kindly enter your mobile number to receive the prompt and enter you M-Pesa Pin.</Typography>
            </Grid>
            <Grid item width={'50%'} mt={2}>
                <PhoneInput inputStyle={{ height: "55px" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setPhone(phone)} />
            </Grid>
            <Grid mt={2} item mb={3} xs={12} sm={12} lg={12} md={12} width={'50%'}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                    <InputLabel htmlFor="outlined-adornment-password">Amount</InputLabel>
                    <OutlinedInput
                        required
                        id="outlined-adornment-password"
                        value={amount}
                        autoComplete="new-password"
                        onChange={(e) => setAmount(e.target.value)}
                        label="Amount"
                    />
                </FormControl>
            </Grid>
            <Grid item alignItems={'flex-end'} display={'flex'} alignContent={'flex-end'} direction={'column'} width={'50%'}>
                <Button onClick={handleInitiateKCBStkPush} style={{ background: "#032541", textTransform: "inherit", color: "#fff" }}> {startTransaction === true ? 'Awaiting Payment' : 'Send Prompt'}</Button>
                <Typography>{startTransaction === true ? `'Resend Prompt': ${count}` : null}</Typography>
            </Grid>
        </Grid>
    )
}