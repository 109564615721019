import React, { useEffect, useState } from "react";
import { Box, Button, Grid, Modal, Select, Stack, styled, Tab, TablePagination } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@mui/material/TextField";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useNavigate, useParams } from "react-router-dom";
import profilePicture from "../../../common/images/profile_picture.svg";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HttpComponent from "../../School/MakeRequest";
import { useSelector } from "react-redux";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomSelectField from "../../School/CustomSelectField";
import { TabPanel, TabContext, TabList } from "@mui/lab";
import Typography from "@mui/material/Typography";
import CustomTable from "../../School/CustomTable";
import CustomTextField from "../../School/CustomTextField";
import ExportMenu from "../../School/ExportMenu";
let baseUrl = process.env.REACT_APP_BASE_URL

const AntTabs = styled(TabList)({
    borderBottom: "0px solid #e8e8e8",
    "& .MuiTabs-indicator": {
        backgroundColor: "#ffffff00",
    },
});

const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);

const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        // textTransform: "none",
        minWidth: 0,
        [theme.breakpoints.up("sm")]: {
            minWidth: 0,
        },
        // fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        // fontSize: "13px",
        // fontStretch: "normal",
        // fontStyle: "normal",
        lineHeight: "2.75",
        // letterSpacing: "normal",
        // textAlign: "left",
        color: "#6e7074",
        fontFamily: ["Poppins"].join(","),
        "&:hover": {
            color: "#032541",
            opacity: 1,
        },
        "&.Mui-selected": {
            color: "#dc3545",
            // fontWeight: 600,
        },
        "&.Mui-focusVisible": {
            backgroundColor: "blue",
        },
    })
);

const invoicesColumns = [
    {
        field: "invoiceNumber", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice No</span>, renderCell: (params) => {
            return (
                <div style={{ fontSize: "1rem", fontWeight: "510" }}>
                    <Link to={`/school/invoice/${params.value}`}>
                        {params.value}
                    </Link>

                </div>
            );
        }
    },
    // {

    //     field: "invoiceDate", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Balance</span>, renderCell: (params) => {
    //         return (
    //             <div style={{ fontSize: "1rem", fontWeight: "500" }}>
    //                 {params.value}
    //             </div>
    //         );
    //     },
    //     headerName: "Invoice Date",
    //     flex: 1,
    //     headerClassName: "super-app-theme--header",
    // },
    {
        field: "invoiceBalance", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Balance</span>, renderCell: (params) => {
            console.log(params, 'params')

            return (
                <div style={{ fontSize: "1rem", fontWeight: "500" }}>
                    {params.value}
                </div>
            );
        }
    },
    {
        field: "amountPaid", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Amount Paid</span>, renderCell: (params) => {
            return (
                <div style={{ fontSize: "1rem", fontWeight: "500" }}>
                    {params.row.invoiceOverPayStatus === true ? params.row.invoiceAmount + params.row.invoiceOverPayment : params.row.invoiceOverPayStatus === false ? params.row.invoiceAmount - params.row.invoiceBalance : "else"}
                </div>
            );
        }
    },
    {
        field: "invoiceAmount", flex: 1, headerName: <span style={{ fontSize: "1rem", fontWeight: "bold", textAlign: "center" }}>Invoice Amount</span>, renderCell: (params) => {
            return (
                <div style={{ fontSize: "1rem", fontWeight: "500" }}>
                    {params.value}
                </div>
            );
        }
    },
    // {
    //     field: "type",
    //     headerName: "Type",
    //     flex: 1,
    //     headerClassName: "super-app-theme--header",
    // },
    {
        field: "invoiceStatus", flex: 1, headerName: "Status",
        renderCell: (params) => {
            console.log(params, 'params')
            return (
                <div>
                    <Typography variant="body2" color="textSecondary">

                        {
                            params.row.invoiceOverPayment > 0 ? <span style={{ color: "#032541", fontWeight: "bold" }}>{'Over Payment'}</span>
                                : params.value == 'Paid' ? <span style={{ color: "#00B87C", fontWeight: "bold" }}>{params.value}</span>
                                    : params.value === 'Unpaid' ? <span style={{ color: "#FF0000", fontWeight: "bold" }}>{params.value}</span>
                                        : params.value === 'Partially Paid' ? <span style={{ color: "#F79029", fontWeight: "bold" }}>{params.value}</span>

                                            : <span style={{ color: "#FF0000", fontWeight: "bold" }}>{params.value}</span>}

                    </Typography>
                </div>
            );
        }
    },
];


const StudentProfile = (props) => {
    const {customerId, itemNo } = useParams();
    const [firstName, setFirstName] = useState("Student First Name")
    const [lastName, setLastName] = useState("Student Last Name")
    const [additionalInfo, setAdditionalInfo] = useState("Add additional student information")
    const [relationship, setRelationship] = useState("Select Relationship");
    const [studentId, setStudentId] = useState("Student ID")
    const [grade, setGrade] = useState("Select Grade");
    const [term, setTerm] = useState("Select Term")
    const [stream, setStream] = useState("Select Stream")
    const [boardingStatus, setboardingStatus] = useState("Select Stream")
    const [dob, setDob] = useState(Date.now())
    const [currentStudent, setCurrentStudent] = useState()
    const [formDisabled, setFormDisabled] = useState(true);
    const [checkboxData, setCheckboxData] = useState([]);
    const [currentStudentServices, setCurrentStudentServices] = useState([])
    const [gradeOptions, setGradeOptions] = useState([])
    const [streamsOptions, setStreamesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);
    const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
    const [tabvalue, setTabValue] = useState("invoices");
    const [receivedReceipts, setReceivedReceipts] = useState([]);
    const [studentCourses ,setStudentCourses]=useState([]);
    const [courseName, setCourseName]=useState([]);
    const [duration, setDuration]=useState([]);
    const [durationType, setDurationType]=useState([]);
    const [status,setStatus]=useState([]);
    const [noOfSemestersPerDuration ,setNoOfSemestersPerDuration]=useState([]);

    const fetchStudentData = async () => {
        let url = baseUrl + "/api/getBillableItems";
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({ customerId })
        });
        const data = await response.json();
        if (response.status === 200) {
            console.log("data", data);
            const theStudent = data.data.find(student => student.itemNumber === itemNo);
            console.log("current student", theStudent)
            setCurrentStudent(theStudent);
            setCurrentStudentServices(theStudent.servicesForThisStudent)

        }
    };

    const fetchProducts = async () => {
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/listProducts/Active?search`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 200) {
            const customerDetailsData = await customerDetailsResponse.json();
            console.log("init", customerDetailsData.data)
            setCheckboxData(customerDetailsData.data)
        }
    }

   
    useEffect(() => {
        console.log("ci", customerId)
        console.log("in", itemNo)
        fetchStudentData()
    }, [customerId, itemNo])

    useEffect(() => {
        if (currentStudent) {
            setStudentId(currentStudent.itemNumber)
            setFirstName(currentStudent.firstName)
            setLastName(currentStudent.lastName)
            setTerm(currentStudent.term)
            setGrade(currentStudent.grade)
            setStream(currentStudent?.stream)
            setboardingStatus(currentStudent?.boardingStatus)
            setDob(Date(currentStudent?.dateOfBirth))
            setRelationship(currentStudent?.relationShip)
            setAdditionalInfo(currentStudent?.additionalInfo)
        }
    }, [currentStudent])

    useEffect(() => {
        fetchProducts()
    }, [])

    const handleCheckboxChange = (event, label, amount, id) => {
        let serviceObject = {
            amount,
            frequency: "ThreeMonths",
            itemNumber: itemNo,
            name: label,
            productId: id
        }
        if (event.target.checked) {
            console.log("checked")
            let newCurrent = [...currentStudentServices]
            newCurrent.push(serviceObject)
            setCurrentStudentServices(newCurrent)
        } else {
            console.log("not checked");
            let currents = [...currentStudentServices]
            console.log("inital currents", currents)
            const index = currents.findIndex((services) => services.productId === id)
            console.log("index", index)
            currents.splice(index, 1);
            console.log("currents new", currents)
            setCurrentStudentServices(currents)
        }
    };
    const handleDobChange = (newValue) => {
        setDob(newValue)
    }
    const theColumns = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: "center"
    }
    const commonTextSize = {
        fontSize: "15px"
    }

    const navigate = useNavigate()

    const [checkboxes, setCheckboxes] = useState(true);
    const [fields, setFields] = useState(true)
    const editServices = () => {
        setCheckboxes(!checkboxes)
    }

    const editDetails = () => {
        
        setFields(!fields)
    }

    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })

    const handleSaveNewServices = () => {
        let body = {
            studentId: currentStudent._id,
            extraServices: currentStudentServices
        }
        HttpComponent({
            method: 'POST',
            url: `/api/update_student_services`,
            token: X_Authorization,
            body: body,
        }).then((data) => {
            console.log("here is post data for services", data);
            if (data.status === 200) {
                setCheckboxes(true)
                setSuccessShow({ state: true, message: "Services Updated Successfully" })
            }
            else {
                console.error("Error setting info")
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    const handleSaveStudentDetails = () => {
        let body = {
            firstName,
            lastName,
            studentNumber: itemNo,
            grade,
            term,
            stream,
            boardingStatus,
            itemNumber:studentId
        }
        HttpComponent({
            method: 'POST',
            url: `/api/edit_student_details`,
            token: X_Authorization,
            body: body,
        }).then((data) => {
            console.log("here is post data", data);
            if (data.status === 200) {
                setFields(true)
                setSuccessShow({ state: true, message: "Details Updated Successfully" })
            }
            else {
                console.error("Error setting info")
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }




    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)
    console.log('School Type Name', schoolTypeName);

    console.log('School Type Id', schoolTypeId);
    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const GetStreams = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setStreamesOptions(data.data.map((itemStream) => {
                return { value: itemStream.streamName, label: itemStream.streamName }
            }
            ))
        }
    };

    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreams();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);
    const getBoardingStatus = async () => {
        const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        // console.log("here store is data",data);
        if (data.status === "SUCCESS") {
            console.log("here Boarding Status", data);
            setBoardingStatusOptions(data.data.map((boardingStatus) => {
                return { value: boardingStatus, label: boardingStatus }
            }
            ))
        } else {
            console.error("Error setting info")
            setErrorShow({ state: true, message: "Error setting info" })
        }

    }
    useEffect(() => {
        getBoardingStatus()
    }, [])
    // /api/get_invoice_by_customerId/64b53f5379ed13a151274213
    const [invoiceData, setInvoiceData] = useState([])
    const [studentDetails, setStudentDetails] = useState([])
    const [studentAllData, setStudentAllData] = useState([])
    const [studentTranscations, setStudentTranscations] = useState([])
    const [customerActivity, setCustomerActivity] = useState()
    const [customerTransRows, setCustomerTransRows] = useState([])
    const [receivedRows, setReceivedRows] = useState([]);
    const [transactionDetails, setTransactionDetails] = useState([]);
    const [contacts,setContacts]=useState([])



    const getInvoiceData = async () => {
        // https://dev.zed.business/api/v1/student_invoice_status?admissionNumber=12336

        const response = await fetch(`${baseUrl}/api/v1/student_invoice_status?admissionNumber=${itemNo}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        // const data = await response.json();
        // console.log("here store is data", data);
        if (response.status === 200) {
            const studentData = await response.json();
            console.log('studentData', studentData);
            setStudentDetails(studentData.data)
            setStudentAllData(studentData)
            setStudentTranscations(studentData.studentInvoices)
        }
    }
    useEffect(() => {
        getInvoiceData()
    }, [])

    const fetchReceived = async () => {
        try {
            const headers = { method: 'GET', 'X-Authorization': localStorage.getItem('X-Authorization') };
            const response = await fetch(baseUrl + "/api/get_user_invoices", { headers });
            if (response.status === 200) {
                await response.json()
                    .then((data) => {
                        console.log("received invoices", data)
                        const rowsWithIds = data.data.map(row => {
                            const createdAt = new Date(row.createdAt).toLocaleDateString("de-DE", {
                                day: "2-digit",
                                month: "2-digit",
                                year: "numeric"
                            });
                            const amountPaid = row.invoiceAmount - row.invoiceBalance
                            return { ...row, id: row._id, createdAt, amountPaid }
                        });
                        setReceivedRows(rowsWithIds);
                    })
            }
        } catch (e) {
            console.error("error fetching recived invoices", e.message);
        }
    }

    const fetchActivity = async () => {
        const customerDetailsResponse = await fetch(
            baseUrl + `/api/CustomersActivities`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            }
        );
        if (customerDetailsResponse.status === 201) {
            const customerDetailsData = await customerDetailsResponse.json();
            console.log("init", customerDetailsData.data)
            setCustomerActivity(customerDetailsData.data)
        }
    }
    const fetchReceivedReceipts = async () => {
        try {
          const headers = {
            method: "GET",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          };
          const response = await fetch(
            baseUrl + "/api/get_user_receipts_invoice_only",
            { headers }
          );
          if (response.status === 201) {
            await response.json().then((data) => {
              console.log("received-receipts", data);
              const rowsWithIds = data.data.map((row) => {
                const issuedDate = new Date(row.issuedDate).toLocaleDateString(
                  "de-DE",
                  {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }
                );
                const amount = numberFormat(row.amount);
                return { ...row, id: row.transactionId, issuedDate, amount };
              });
              console.log(" student-receipts", rowsWithIds);
              setReceivedReceipts(rowsWithIds);
            });
          }
        } catch (e) {
          console.error("error fetching recived invoices", e.message);
        }
      };
    
      console.log(transactionDetails, "all transactions");
    
  const getCourses = async ()=>{
    const studentCourseDetails = await fetch(baseUrl + "/api/v1/getCourses",{
      method:"GET",
      headers:{
        "Content-Type":"application/json",
         Accept:"application/json",
         "X-Authorization":localStorage.getItem("X-Authorization"),
      },
    } 
    );

    if(studentCourseDetails.status===200){
      await studentCourseDetails.json().then((data)=>{
        console.log("student courses", data.data[0].schoolCourses)
        const Courses= data.data[0];
        console.log("set courses",Courses);
        setStudentCourses(Courses);
      })
    }
    else{
      console.error("there was an error fetching student courses");
    }
  }

    const getEmergencyContacts = async ()=>{
        // https://api.dev.zed.business/api/get_emergency_contacts/64d4f1d22a72ef1bcb722d52  
       
        const response = await fetch(`${baseUrl}/api/get_emergency_contacts/${customerId}`,{
          method:"GET",
          headers:{
            "Content-Type":"application/json",
            Accept: "application/json",
            "X-Authorization":localStorage.getItem("X-Authorization"),
          }
        },
        );
        if(response.status === 200){
          const data =  await response.json();
          console.log("response emargency data",data.data.emergencyContacts[0]); 
          const emergContacts = data.data.emergencyContacts.map((row)=>{
    
            return {...row,id:row._id};
          
          })
          console.log("mapped contacts", emergContacts);
          setContacts(emergContacts);
        }else{
           console.error("there was an error getting contacts")
        }
      }
    
    
      useEffect(() => {
        if (studentCourses) {
          setCourseName(studentCourses.courseName);
          setDuration(studentCourses.duration);
          setDurationType(studentCourses.durationType);
          setStatus(studentCourses.status);
          setNoOfSemestersPerDuration(studentCourses.noOfSemestersPerDuration);  
        }
      }, [studentCourses]);
    
      //convert the object to an array and spread it
      const courseArray = Object.keys(studentCourses).map((courseId) => ({
        id: studentCourses[courseId]._id, // Use _id as the course ID
        ...studentCourses[courseId],
      }));
    

    async function getTransactions() {
        console.log("test working");
        const customerDetailsResponse = await fetch(
          baseUrl + `/api/customerTransactions?customerId=${customerId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "X-Authorization": localStorage.getItem("X-Authorization"),
            },
          }
        );
    
        if (customerDetailsResponse.status === 201) {
          await customerDetailsResponse.json().then((data) => {
            console.log("customer Transactions", customerDetailsResponse);
            console.log("transa", data.transaction);
            let counter = 1;
            const rowsWithIds = data.transaction.map((row) => {
              const transactionTime = new Date(
                row.transactionTime
              ).toLocaleDateString("en-US", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              });
              return { ...row, id: counter++, transactionTime };
            });
            console.log("new rows", rowsWithIds);
            setCustomerTransRows(rowsWithIds);
          });
        }
       else{
        console.error("error getting transactions")
       }
      }


    useEffect(() => {
        if (schoolTypeName.includes("University")) {
            fetchReceived()
            fetchActivity()
            getTransactions()
            fetchReceivedReceipts();
            getCourses();
            getEmergencyContacts();
        }
    }, [])




    return (
        <>
            <Grid container marginBottom="10px" >
                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setErrorShow({ ...errorShow, state: false })}
                    open={errorShow.state}
                    message={errorShow.message} />

                <SuccessAlert
                    vertical="top"
                    horizontal="right"
                    onClose={() => setSuccessShow({ ...successShow, state: false })}
                    open={successShow.state}
                    message={successShow.message} />
                <Grid item style={{ width: "100%" }}>
                    <Box sx={{ marginLeft: 2 }}>
                        <ArrowBackIosIcon sx={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                    </Box>
                    {
                        schoolTypeName?.includes("University") ?
                            <>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", height: "150px", width: "100%", boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)", borderRadius: "15px", marginTop: "3%", marginRight: "1%", padding: "20px" }}>
                                        <div style={theColumns}>
                                            <img src={profilePicture} alt="Profile Picture Placeholder" style={{ height: "110px", width: "110px" }} />
                                        </div>

                                        <div style={theColumns}>
                                            <div style={{ color: '#032541', fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>{firstName} {lastName}</div>
                                            <div style={{ fontSize: "15px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem", color: currentStudent?.status === "ACTIVE" ? "#17ae7b" : "#dc3545", textTransform: "capitalize" }} >{currentStudent?.status} </div>


                                            <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Current Level: {currentStudent?.grade} {currentStudent?.term}</div>

                                        </div>

                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Student ID: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.itemNumber}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Year: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.year}</span></div>

                                        </div>
                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Email: <br /></span><span style={{ color: "#666f76" }}>{currentStudent?.parentEmail}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Mobile Number: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.parentPhone}</span></div>
                                        </div>
                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.pendingInvoices}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: <br /> </span><span style={{ color: "#666f76" }}>{numberFormat(currentStudent?.pendingBalance)}</span></div>
                                        </div>
                                    </div>

                                </div>
                                <br/>
                                <Button variant="contained" style={{
                                            "width": "7.813rem",
                                            "height": "2.813rem",
                                            "padding": "12.5px 43.7px 12.5px 45.3px",
                                            "borderRadius": "4px",
                                            "backgroundColor": "#032541",
                                            "color": "white",
                                            "marginLeft":"90%"
                                                    }}>
                                                    <Link style={{ textDecoration: 'none', color: '#fff' }} to={`/editprofile/${customerId}/${itemNo}`}>
                                                    Edit Profile
                                                    </Link>
                                                    </Button>

                                <TabContext value={tabvalue}>
                                    <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%" }}>
                                        <AntTabs value={tabvalue} onChange={(e, newValue) => setTabValue(newValue)} aria-label="ant example">
                                            <AntTab label="Invoices" value="invoices" />
                                            <AntTab label="Transcations" value="Transcations" />
                                            <AntTab label="Receipts" value="receipts" />
                                            <AntTab label="Course /Programs" value="courses" />
                                            <AntTab label="Emergency Contacts" value="contacts" />
                                        </AntTabs>
                                    </Box>
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1.25rem", marginLeft: "1.25rem" }}>
                                        <CustomTextField placeholder={"search....."} />
                                        <ExportMenu />
                                    </div>
                                    <TabPanel value="invoices">
                                        <CustomTable data={studentTranscations} columns={invoicesColumns} rowsPerPage={20} loading={false} />
                                    </TabPanel>
                                    <TabPanel value="Transcations">
                                    {
                    <div
                      style={{
                        height: "350px",
                        minWidth: "1000px",
                      }}
                    >
                      <CustomTable
                        data={customerTransRows}
                        columns={[
                          {
                            field: "transactionNo",
                            headerName: "Transaction No",
                            flex: 1,
                          },
                          {
                            field: "transactionTime",
                            headerName: "Date",
                            flex: 1,
                          },
                          {
                            field: "noOfItems",
                            headerName: "No. Of Items",
                            flex: 1,
                          },
                          {
                            field: "business",
                            headerName: "Business",
                            flex: 1,
                          },
                          {
                            field: "branchName",
                            headerName: "Branch",
                            flex: 1,
                          },
                          { field: "amount", headerName: "Amount", flex: 1 },
                          {
                            field: "approvedBy",
                            headerName: "Approved By",
                            flex: 1,
                          },
                        ]}
                        rowsPerPage={20}
                      />
                    </div>
                  }
                                    </TabPanel>
                                    <TabPanel value="receipts">
                                    {
                    <div
                      style={{
                        height: "350px",
                        minWidth: "1000px",
                      }}
                    >
                      <CustomTable
                        data={receivedReceipts}
                        columns={[
                          {
                            field: "receiptNo",
                            headerName: "Receipt Number",
                            flex: 1,
                            renderCell: (params) => {
                              return (
                                <Link
                                  to={`/receipt/${params.value}/${params.row.invoiceNumber}`}
                                >
                                  {params.value}
                                </Link>
                              );
                            },
                          },
                          {
                            field: "invoiceNumber",
                            headerName: "Invoice Number",
                            flex: 1,
                          },
                          { field: "from", headerName: "From", flex: 1 },
                          {
                            field: "amount",
                            headerName: "Amount Paid",
                            flex: 1,
                          },
                          {
                            field: "issuedDate",
                            headerName: "Date Issued",
                            flex: 1,
                          },
                        ]}
                        rowsPerPage={20}
                      />
                    </div>
                  }
                                    </TabPanel>
                                    <TabPanel value="courses">
                                                        
                  {
                    <div
                      style={{
                        height: "350px",
                        minWidth: "1000px",
                      }}
                    >
                      <CustomTable
                        data={courseArray}

                        columns={[
                          {
                            field: "id",
                            headerName: "CourseID",
                            flex: 1,
                          },
                          {
                            field: "courseName",
                            headerName: "Course Name",
                            flex: 1,
                          },
                          {
                            field: "durationType",
                            headerName: "Dutation Type",
                            flex: 1,
                          },
                          
                          {
                            field: "duration",
                            headerName: "Duration",
                            flex: 1,
                          },
                          {
                            field: "noOfSemestersPerDuration",
                            headerName: "Semesters Per Duration",
                            flex: 1,
                          },
                            {
                            field: "status",
                            headerName: "Semester Status",
                            flex: 1,
                          },
                          {
                            field: "createdAt",
                            headerName: "Date of Registration",
                            flex: 1,
                          },
                        ]}
                        rowsPerPage={20}
                      />
                    </div>
                  }
                                    </TabPanel>
                                    <TabPanel value="contacts">
                                    {
                    <div
                      style={{
                        height: "350px",
                        minWidth: "1000px",
                      }}
                    >
          <Button variant="contained" style={{
                  "width": "7.813rem",
                  "height": "2.813rem",
                  "padding": "12.5px 43.7px 12.5px 45.3px",
                  "borderRadius": "4px",
                  "backgroundColor": "#032541",
                  "color": "white",
                  "marginLeft":"5px"
        }}>
          <Link style={{ textDecoration: 'none', color: '#fff' }} to={`/addcontacts/${customerId}`}>
          AddContact
          </Link>
        </Button>
      
                      <CustomTable
                        data={contacts}
                        columns={[
                          {
                            field: "firstName",
                            headerName: " First Name",
                            flex: 1,
                          },
                          {
                            field: "lastName",
                            headerName: "Last Number",
                            flex: 1,
                          },
                          {
                            field: "relationship",
                            headerName: "Relationship",
                            flex: 1,
                          },
                          {
                            field: "phoneNumber",
                            headerName: "Phone Number",
                            flex: 1,
                          },
                          {
                            field: "email",
                            headerName: "Email",
                            flex: 1,
                          },
 
                        //   { field: "from", headerName: "From", flex: 1 },
                        ]}
                        rowsPerPage={20}
                      />
                    </div>
                  }
                                    </TabPanel>
                                </TabContext>




                            </> :

                            <>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <div style={{ display: "flex", justifyContent: "space-between", height: "150px", width: "35%", boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)", borderRadius: "15px", marginTop: "3%", marginRight: "1%", padding: "20px" }}>
                                        <div style={theColumns}>
                                            <img src={profilePicture} alt="Profile Picture Placeholder" style={{ height: "110px", width: "110px" }} />
                                        </div>
                                        <div style={theColumns}>
                                            <div style={{ color: '#032541', fontSize: "25px", fontWeight: "bold", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>{firstName} {lastName}</div>
                                            <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Student ID: {currentStudent?.itemNumber}</div>
                                            <div style={{ fontSize: "15px", color: "#666f76", paddingTop: "0.125rem", paddingBottom: "0.125rem" }}>Current Level: {currentStudent?.grade} {currentStudent?.term}</div>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", justifyContent: "space-between", height: "150px", width: "65%", boxShadow: "0 4px 9px 0 rgba(0, 0, 0, 0.16)", borderRadius: "15px", marginTop: "3%", marginLeft: "1%", padding: "20px" }}>
                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Parent/Guardian Name: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.parentName}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Role: <br /></span><span style={{ color: "#666f76" }}>Student</span></div>
                                        </div>
                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Email: <br /></span><span style={{ color: "#666f76" }}>{currentStudent?.parentEmail}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Mobile Number: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.parentPhone}</span></div>
                                        </div>
                                        <div style={theColumns}>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Pending Invoices: <br /> </span><span style={{ color: "#666f76" }}>{currentStudent?.pendingInvoices}</span></div>
                                            <div style={{ commonTextSize, paddingTop: "0.25rem", paddingBottom: "0.25rem" }}><span style={{ fontWeight: "bold", color: "#032541" }}>Pending Balance: <br /> </span><span style={{ color: "#666f76" }}>{numberFormat(currentStudent?.pendingBalance)}</span></div>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "100%", display: "flex", }}>

                                    <div style={{ width: "50%", borderRadius: "15px", boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)", margin: "1.25rem", marginLeft: "0", minHeight: "530px" }}>
                                        <div style={{ display: "flex", justifyContent: "space-between", margin: "1.25rem" }}>
                                            <div style={{ fontSize: "15px", fontWeight: "bold" }}>Student Details</div>
                                            <div>
                                                <Button
                                                    style={{ width: "120px", height: "45px", fontFamily: "Poppins", fontSize: "14px", fontWeight: "500", backgroundColor: "transparent", border: "1px solid #032541", borderRadius: "5px", color: "#032541", marginRight: 4 }} onClick={editDetails} >
                                                    Edit
                                                </Button>

                                    <Button
                                        style={{
                                            width: "120px",
                                            height: "45px",
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            backgroundColor: "#032541",
                                            borderRadius: "5px",
                                            color: "white",
                                        }}
                                        onClick={handleSaveStudentDetails}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </div>
                            <Box sx={{ "& .super-app-theme--header": { color: "#032541", fontWeight: "bold", fontSize: "10px", fontFamily: "Poppins" } }}>
                                <div style={{
                                    paddingTop: "2.5rem",
                                    padding: "1.25rem",
                                }}>
                                    <div>Student ID</div>
                                    <TextField id="outlined-basic" required  value={studentId} onChange={(e) => { setStudentId(e.target.value) }} variant="outlined" style={{ width: "100%", height: "3.125rem" }} />
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "2.5rem",
                                    padding: "1.25rem",
                                }}>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "45%"
                                    }}>
                                        <div>Student First Name</div>
                                        <TextField id="outlined-basic" disabled={fields} required value={firstName} onChange={(e) => { setFirstName(e.target.value) }} variant="outlined" style={{ width: "100%", height: "3.125rem" }} />
                                    </div>
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        width: "45%"
                                    }}>
                                        <div>Student Last Name</div>
                                        <TextField id="outlined-basic" disabled={fields} required value={lastName} onChange={(e) => { setLastName(e.target.value) }} variant="outlined" style={{ width: "100%", height: "3.125rem" }} />
                                    </div>
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "2.5rem",
                                    margin: "1.25rem",
                                }}>
                                    {/*<TextField id="outlined-basic" required label="Date of Birth" defaultValue="Date of Birth" variant="outlined" style={{width:"45%",height:"3.125rem"}} />*/}
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Date Of Birth"
                                            inputFormat="DD/MM/YYYY"
                                            value={dob}
                                            required
                                            onChange={handleDobChange}
                                            renderInput={(params) => <TextField {...params} />}
                                            disabled
                                            style={{
                                                width: "45%"
                                            }}
                                        />
                                    </LocalizationProvider>

                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DesktopDatePicker
                                            label="Date Of Admission"
                                            inputFormat="DD/MM/YYYY"
                                            value={dob}
                                            required
                                            onChange={handleDobChange}
                                            renderInput={(params) => <TextField {...params} />}
                                            disabled
                                            style={{
                                                width: "45%"
                                            }}
                                        />
                                    </LocalizationProvider>
                                    {/*<Select*/}
                                    {/*    disabled*/}
                                    {/*    labelId="demo-simple-select-label"*/}
                                    {/*    id="demo-simple-select"*/}
                                    {/*    value={relationship}*/}
                                    {/*    label="Relationship"*/}
                                    {/*    required*/}
                                    {/*    onChange={(e)=>setRelationship(e.target.value)}*/}
                                    {/*    style={{*/}
                                    {/*        width:"45%"*/}
                                    {/*    }}*/}
                                    {/*>*/}
                                    {/*    <MenuItem value={"Select Relationship"} disabled>Select Relationship</MenuItem>*/}
                                    {/*    <MenuItem value={"Son"}>Son</MenuItem>*/}
                                    {/*    <MenuItem value={"Daughter"}>Daughter</MenuItem>*/}
                                    {/*    <MenuItem value={"Niece"}>Niece</MenuItem>*/}
                                    {/*    <MenuItem value={"Nephew"}>Nephew</MenuItem>*/}
                                    {/*</Select>*/}
                                    {/*<TextField id="outlined-basic" required label="Relationship" defaultValue="Relationship" variant="outlined" style={{width:"45%",height:"3.125rem"}}/>*/}
                                </div>

                                <div style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    paddingTop: "2.5rem",
                                    padding: "1.25rem",
                                }}>
                                    {/* <Select
                                        disabled={fields}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={grade}
                                        label="Grade"
                                        required
                                        onChange={(e) => setGrade(e.target.value)}
                                        style={{
                                            width: "45%"
                                        }}
                                    >
                                        <MenuItem value={"Select Grade"} disabled>Select Grade</MenuItem>

                                        
                                        {Fetchedgrades.map((item) => {
                                            return (
                                                <MenuItem value={item}>{item}</MenuItem>
                                            )
                                        })}
                                    </Select> */}
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <CustomInputLabel required={true} label={"Grade"} />

                                        <CustomSelectField value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"Select Grade"} options={gradeOptions} sx={{ marginRight: "20px", maxWidth: "350px" }} />
                                    </Box>


                                    {/* <Select
                                        disabled={fields}
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={term}
                                        label="Term"
                                        required
                                        onChange={(e) => setTerm(e.target.value)}
                                        style={{
                                            width: "45%"
                                        }}
                                    >
                                        <MenuItem value={"Select Term"} disabled>Select Term</MenuItem>
                                        <MenuItem value={"Term 1"}>Term 1</MenuItem>
                                        <MenuItem value={"Term 2"}>Term 2</MenuItem>
                                        <MenuItem value={"Term 3"}>Term 3</MenuItem>
                                    </Select> */}
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>

                                        <CustomInputLabel required={true} label={"Term"} />
                                        <CustomSelectField value={term} onChange={(e) => setTerm(e.target.value)} name={"term"} placeholder={"Select Term"} options={termOptions} sx={{ maxWidth: "350px" }} />
                                        {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                                    </Box>

                                </div>
                                <div style={{ display: "flex" , paddingTop:"2.5rem", padding:"1.25rem" }}>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <CustomInputLabel required={true} label={"Stream"} />

                                        <CustomSelectField value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"Select Stream"} options={streamsOptions} sx={{ marginRight: "20px", maxWidth: "350px" }} />
                                    </Box>
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>

                                        <CustomInputLabel required={true} label={"Boarding Status"} />
                                        <CustomSelectField value={boardingStatus} onChange={(e) => setboardingStatus(e.target.value)} name={"term"} placeholder={"Select Term"} options={boardingStatusOptions} sx={{ maxWidth: "350px" }} />
                                        {/*<CustomTextFieldSmall value={formData.term} onChange={handleInputChange} name={"term"} placeholder={"Term"} />*/}
                                    </Box>
                                </div>

                                <div style={{
                                    display: "flex",
                                    paddingTop: "2.5rem",
                                    padding: "1.25rem",
                                }}>
                                    <TextField disabled={fields} id="outlined-basic" label="Additional Info" value={additionalInfo} onChange={(e) => { setAdditionalInfo(e.target.value) }} variant="outlined" style={{ width: "100%", height: "3.125rem" }} />
                                </div>

                            </Box>
                        </div>
                        <div style={{
                            width: "50%",
                            borderRadius: "15px",
                            boxShadow: "0 1px 6px 0 rgba(0, 0, 0, 0.1)",
                            margin: "1.25rem",
                            marginRight: "0",
                            minHeight: "530px"
                        }}>
                            <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                margin: "1.25rem",
                            }}>
                                <div style={{
                                    fontSize: "15px",
                                    fontWeight: "bold"
                                }}>Student Services</div>
                                <div>
                                    <Button
                                        style={{
                                            width: "120px",
                                            height: "45px",
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            fontWeight: "500",
                                            backgroundColor: "transparent",
                                            border: "1px solid #032541",
                                            borderRadius: "5px",
                                            color: "#032541",
                                            marginRight: 4
                                        }}
                                        onClick={editServices}
                                    >
                                        Edit
                                    </Button>

                                                <Button
                                                    style={{
                                                        width: "120px",
                                                        height: "45px",
                                                        fontFamily: "Poppins",
                                                        fontSize: "14px",
                                                        fontWeight: "500",
                                                        backgroundColor: "#032541",
                                                        borderRadius: "5px",
                                                        color: "white",
                                                    }}
                                                    onClick={handleSaveNewServices}
                                                >
                                                    Save
                                                </Button>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", maxHeight: "530px", overflowY: "scroll" }}>
                                            <div style={{ paddingTop: `${checkboxData.length * 40}px` }}>
                                                {checkboxData.map(data => (
                                                    <div key={data._id} style={{ display: "flex", justifyContent: "space-between", padding: "1.875rem" }}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    disabled={checkboxes}
                                                                    checked={currentStudentServices.some(
                                                                        currentService => currentService.productId === data._id
                                                                    )}
                                                                    onChange={event =>
                                                                        handleCheckboxChange(event, data.productName, data.productPrice, data._id)
                                                                    }
                                                                />
                                                            }
                                                            label={data.productName}
                                                        />
                                                        <TextField
                                                            id="outlined-basic"
                                                            required
                                                            label="Amount"
                                                            defaultValue={numberFormat(data.productPrice)}
                                                            disabled={true}
                                                            variant="outlined"
                                                            style={{ width: "60%", height: "2.188rem" }}
                                                        />
                                                    </div>
                                                ))}

                                            </div>


                                        </div>
                                    </div>

                                </div>
                            </>

                    }



                </Grid>

            </Grid>
        </>
    )
}

export { StudentProfile }
