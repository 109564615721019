import { Breadcrumbs, Button, Grid,Typography } from "@mui/material";
import { useEffect, useState } from "react";
import CircleIcon from '@mui/icons-material/Circle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import HttpComponent from "../MakeRequest";
import DateFormatter from "../../../utils/dateFormatter";


const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Student Promotions</Typography>,
]

export default function StudentPromotionNotification() {
    const [allNotifications, setAllNotification] = useState([])

    //get notifications

    function getAllStudentNotification() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/students/getPromotions`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 200) {
                    setAllNotification(data?.response?.data)
                }
            })


        } catch (error) {

        }
    }
    useEffect(() => {
        getAllStudentNotification()
    }, [])


    return (
        <Grid direction={'column'} container>
            {/* <Grid item mt={2}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Student Promotions</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Previous Activity</Typography>
            </Grid> */}
            <Grid item mt={2}>
                {allNotifications?.map((student) => {
                    return (
                        <Grid item>
                            <Grid container display={'flex'} spacing={1} direction={'column'}>
                                <Grid item display={'flex'} alignItems={'center'}>
                                    <CircleIcon style={{color:'#e0e0e0' , width:'10px' , height:'10px'}}/>
                                    <Typography style={{color:'#000000' , marginLeft:'10px', fontSize:'14px'}}>{student?.fromGrade}<span style={{ marginLeft: '10px', marginRight: '10px' }}>students promoted to</span>{student?.toGrade}</Typography>
                                </Grid>
                                <Grid item marginLeft={'15px'}>
                                    <Typography sx={{color:'#6c6c6c' , fontSize:'14px'}}>{student?.description}</Typography>
                                </Grid>
                                <Grid item  marginLeft={'15px'}>
                                    <Typography>{DateFormatter(student?.createdAt)}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}
            </Grid>
        </Grid>
    )
}