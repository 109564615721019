import { Grid, Breadcrumbs, Typography, Button, FormControl, InputLabel, OutlinedInput, InputAdornment, IconButton, Menu, MenuItem, Dialog, DialogContent, DialogActions, Slide } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import React, { forwardRef, useEffect, useState } from "react";
import HttpComponent from "../../../School/MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../../No Rows/noRowsOverlay";
import { useNavigate } from "react-router-dom";
import CreateTerminalUser from "./createterminaluser";
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "../../../School/ExportMenu";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { alpha, styled } from "@mui/material/styles";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import WarningImg from "../../../../images/warning.png"
import ReplayIcon from '@mui/icons-material/Replay';
import EditTerminalUser from "./editTerminaluser";
import RestoreImg from "../../../../images/restoreImg.svg"
import { SuccessAlert } from "../../../snackBar Alerts/successAlert";
import { ErrorAlert } from "../../../snackBar Alerts/errorAlert";
import ArticleIcon from '@mui/icons-material/Article';
import AssignNewOutlet from "./transferOutlet";


//transition

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

//breadcrumbs

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Zed-Payit</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Terminal Users</Typography>
]

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{
            vertical: "top",
            horizontal: "right",
            frontFamily: "Poppins",
        }}
        {...props}
    />
))(({ theme }) => ({ "& .MuiPaper-root": { borderRadius: 6, marginTop: theme.spacing(1), minWidth: 18, frontFamily: "Poppins", color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300], boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px", "& .MuiMenu-list": { padding: "4px 0" }, "& .MuiMenuItem-root": { "& .MuiSvgIcon-root": { frontFamily: "Poppins", fontSize: 18, color: theme.palette.text.secondary, marginRight: theme.spacing(1.5), }, "&:active": { backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity), }, }, }, }));


export default function TerminalUser() {

    const [userid, setUserId] = useState(null)
    const navigate = useNavigate()
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [searchValue , setSearchValue] = useState('')


    const queryParams = decodeURIComponent(window.location.search)
    const state = queryParams.slice(1)

    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    const RenderCell = (params) => {
        const [anchorEl, setAnchorEl] = React.useState(null);

        const [openRestoreDialog, setOpenRestoreDialog] = useState(false)
        const [openDeactivateDialog, setOpenDeactivateDialog] = useState(false)
        const [openResetPassword, setResetPassword] = useState(false)

        const handleOpenResetPassword = () => { 
            setResetPassword(true) ;
        }
        const handleCloseResetPassword = () => { setResetPassword(false) }

        const handleOpenRestoreDialog = () => { setOpenRestoreDialog(true) }
        const handleCloseRestoreDialog = () => { setOpenRestoreDialog(false) }

        const handleOpenDeactivateDialog = () => { setOpenDeactivateDialog(true) }
        const handleCloseDeactivateDialog = () => { setOpenDeactivateDialog(false) }

        const ActionOpen = Boolean(anchorEl);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget)
        };


        const handleActionClose = () => {
            setAnchorEl(null)
        };

        const handleResetPassword = (userId) => {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/resetZedPayItPassword?userId=${userId}`,
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: 'password has been reset successfully' })
                    } else {
                        setErrorShow({ state: true, message: "Error resetting password" })
                    }
                    getTerminalUsers()
                })
            } catch (error) {

            }
        }

        // /api/v1/changeZedPayItUserStatus?userId=64ad1eeb4a21a03978cf5a92

        const handleDeactivateUser = (userId) => {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/changeZedPayItUserStatus?userId=${userId}`,
                    body: { userState: "INACTIVE" },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: 'Terminal user has been deactivated successfully' })
                    } else {
                        setErrorShow({ state: true, message: "Error deactivating password" })
                    }
                    getTerminalUsers()
                })
            } catch (error) {

            }
        }

        const handleAeactivateUser = (userId) => {
            try {
                HttpComponent({
                    method: 'POST',
                    url: `/api/v1/changeZedPayItUserStatus?userId=${userId}`,
                    body: { userState: "ACTIVE" },
                    token: localStorage.getItem('X-Authorization')
                }).then((data) => {
                    if (data.status === 200) {
                        setSuccessShow({ state: true, message: 'Terminal user  has been activated successfully' })
                        setTimeout(() => {
                            handleCloseRestoreDialog()
                        }, [])

                    } else {
                        setErrorShow({ state: true, message: "Error activating password" })
                        setTimeout(() => {
                            handleCloseRestoreDialog()
                        }, [])
                    }

                    getTerminalUsers()
                })
            } catch (error) {

            }
        }

        return (
            <div>
                <Dialog
                    open={openResetPassword}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={RestoreImg} style={{ width: "100px" }} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Reset Pin</h6>
                            <span>Do you want to reset pin  </span> <br></br>
                            <span>This action cannot be undone</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseResetPassword} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={() => { handleResetPassword(params?.row?.userId); handleCloseResetPassword() }} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Reset</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openDeactivateDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={WarningImg} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Deactivate  user</h6>
                            <span>Do you want to deactivate this user  </span> <br></br>
                            <span>This action cannot be undone</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDeactivateDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={() => { handleDeactivateUser(params?.row?.userId); handleCloseDeactivateDialog() }} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Deactivate</Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openRestoreDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    PaperProps={{
                        style: {
                            height: "300px",
                            boxShadow: "0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)"
                        },
                    }}
                    BackdropProps={{ style: { backgroundColor: "transparent", opacity: "0.9", backdropFilter: "blur(0.5px)", } }}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogContent sx={{ display: "flex", justifyContent: 'space-evenly', alignContent: "center", alignItems: "center" }}>
                        <img src={RestoreImg} style={{ width: "100px" }} alt='approve' />
                        <div style={{ marginLeft: '20px' }}>
                            <h6>Activate user</h6>
                            <span>Do you want to activate user  </span> <br></br>
                            <span>This action cannot be undone</span>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseRestoreDialog} style={{ border: "1px solid #032541", color: "#032541", backgroundColor: "transparent", textTransform: 'inherit', cursor: 'pointer', width: "100px", marginRight: '10px' }}>Cancel</Button>
                        <Button onClick={() => handleAeactivateUser(params?.row?.userId)} style={{ backgroundColor: "#dc3545", border: 'none', color: "#fff", textTransform: 'inherit', cursor: 'pointer', width: "100px", paddingLeft: "10px" }}>Activate</Button>
                    </DialogActions>
                </Dialog>
                <MoreVertIcon onClick={handleClick} />
                {params?.row?.status === 'ACTIVE' ?
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={() => { navigate(`/terminal/users?edit`); setUserId(params?.row?.userId) }}>
                            <EditIcon style={{ color: "#032541" }} /> Edit User
                        </MenuItem>
                        <MenuItem disableRipple onClick={() => { navigate(`/terminal/users?assignNewOutlet`); setUserId(params?.row?.userId) }}>
                            <ArticleIcon style={{ color: "#17ae7b" }} /> Assign Another Outlet
                        </MenuItem>
                        <MenuItem disableRipple onClick={()=>{handleOpenResetPassword();setAnchorEl(null) }}>
                            <ReplayIcon style={{ color: "#17ae7b" }} /> Reset Password
                        </MenuItem>
                        <MenuItem disableRipple onClick={()=>{handleOpenDeactivateDialog();setAnchorEl(null)}}>
                            <DeleteForeverIcon style={{ color: "#DC3545FF" }} /> Deactivate User
                        </MenuItem>
                    </StyledMenu>
                    :
                    <StyledMenu anchorEl={anchorEl} open={ActionOpen} onClose={handleActionClose}>
                        <MenuItem disableRipple onClick={()=>{handleOpenRestoreDialog();setAnchorEl(null)}} >
                            <ReplayIcon style={{ color: "#17ae7b" }} /> Activate
                        </MenuItem>
                    </StyledMenu>
                }
            </div>
        )

    }

    const AllTerminalUsersColumn = [
        { headerName: "Name", field: 'name', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Name</strong>) } },
        { headerName: "Email", field: 'email', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Email</strong>) } },
        { headerName: "phone", field: 'phone', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Phone</strong>) } },
        { headerName: "Institution", field: 'institution', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Institution</strong>) } },
        { headerName: "Teller ID", field: 'tellerId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) } },
        { headerName: "User Type", field: 'userType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >User Type</strong>) } },
        { headerName: "Outlet", field: 'outlet', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >OutLet</strong>) } },
        {
            headerName: "Status", field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => {
                return (
                    <div><span style={params?.row?.status === 'INACTIVE' ? { color: "#dc3545" } : {}}>{params?.row?.status}</span></div>
                )
            }
        },
        { headerName: "Action", field: 'action', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Action</strong>) }, renderCell: RenderCell },

    ]

    //get terminal users
    function getTerminalUsers() {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/listZedPayItUsers?page=${pageState.page}&limit=${dataGridPageSize}`,
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data, 'Terminal users')
                if (data.status === 200) {
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        if(!searchValue)
        getTerminalUsers()
    }, [state, dataGridPageSize, pageState.page, searchValue])


    //handle search

    const handleSearch = ()=>{
        try {
            HttpComponent({
                method:'GET',
                url:`/api/v1/searchZedPayItUser?searchValue=${searchValue}`,
                token: localStorage.getItem('X-Authorization')
            }).then((data)=>{
                if (data.status === 200) {
                      setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })
        } catch (error) {
            
        }
    }

    useEffect(()=>{
        if(searchValue)
        handleSearch()
    },[searchValue , dataGridPageSize, pageState.page ,])



    //export data

    const filedata = pageState?.data?.map((data) => {
        return {
            "Name": data?.name,
            "Email": data?.email,
            "Phone": data?.phone,
            "Institution": data?.institution,
            "Teller ID": data?.tellerId,
            "User Type": data?.userType,
            "Status": data?.status
        }
    })
    const fileHeaders = [["Name", "Email", "Phone", "Institution", "Teller ID", 'User Type', 'Status']]
    const csvColumns = [
        { label: "Name", key: "Name" },
        { label: "Email", key: "Email" },
        { label: "Institution", key: "Institution" },
        { label: "Teller ID", key: "Teller ID" },
        { label: "User Type", key: "User Type", },
        { label: "Status", key: "Status", }
    ];
    const fileDataPDF = pageState?.data?.map((data) => [data?.name, data?.email, data?.phone, data?.institution, data?.tellerId, data?.userType, data?.status,]);


    return (
        <Grid direction={'column'} container>
            {state === 'createuser' ? <CreateTerminalUser /> : state === 'edit' ? <EditTerminalUser userid={userid} /> : state === 'assignNewOutlet' ? <AssignNewOutlet userid={userid} /> : <>
                <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                <Grid item>
                    <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>Terminal Users</Typography>
                </Grid>
                <Grid item display={'flex'} mt={2}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Grid>
                        <Grid item>
                            <Button onClick={() => navigate('/terminal/users?createuser')} style={{ background: "#032541", textTransform: "inherit", color: "#fff" }}>Create User</Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={3}>
                    <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                        <Grid item>
                            <FormControl sx={{ m: 1, width: '30ch' }} variant="outlined">
                                <InputLabel htmlFor="outlined-adornment-password">Search</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={'text'}
                                    value={searchValue}
                                    onChange={(e)=>setSearchValue(e.target.value)}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                edge="end"
                                            >
                                                <SearchIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Search"
                                />
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Terminal users'} title={'Terminal users'} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item mt={3}>
                    <DataGrid
                        components={{ NoRowsOverlay: NoRowsOverlay }}
                        sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                        rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                        rowCount={pageState.total}
                        loading={pageState.isLoading}
                        pagination
                        page={pageState.page - 1}
                        pageSize={dataGridPageSize}
                        paginationMode="server"
                        onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        columns={AllTerminalUsersColumn}
                        rows={pageState?.data}
                        getRowId={row => row._id} />
                </Grid>
            </>}
        </Grid>
    )
}