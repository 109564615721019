import React,  {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { Link } from "react-router-dom";
import Business from "./user Dashboard Components/business"
import { Divider } from "@mui/material";
import DashLogo from "./images/dashlogo.png";
import Dashboard from "./user Dashboard Components/dashboard"
import {useSelector, useDispatch} from "react-redux";
import CustomerOnAccount from "./DashBoard Components/customers";
import Inventory from "./DashBoard Components/inventory";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import PaymentSetup from "./user Dashboard Components/paymentSetup";
import House from "./user Dashboard Components/house";
import Report from "./DashBoard Components/report";
import SchoolReport from "./DashBoard Components/schoolreport";
import { ArrowBackIos } from "@mui/icons-material";
import { closeSideBar } from "../features/sideBarSlice";
import TenantConfiguration from "./DashBoard Components/tenantConfiguration";
import SchoolConfiguration from "./DashBoard Components/schoolconfiguration";
import Bills from "./DashBoard Components/bills";
import Expenditure from "./DashBoard Components/expenditure";
import Warehouse from "./DashBoard Components/warehouse";
import Users from "./DashBoard Components/users";
import GradesAndStreams from "./DashBoard Components/gradesStreams";
import PayitMenu from "./DashBoard Components/payitMenu";
import HttpComponent from "../components/School/MakeRequest";
import OtherBusinessConvenienceFee from "./DashBoard Components/convenienceSchool";
import Transactions from "./DashBoard Components/transactions";
import BankStatement from "./DashBoard Components/bankstatement";
import GeneralLedgerSidebar from "./DashBoard Components/generaledgerAcc";
import StudentPromotion from "../components/School/studentpromotion/studentPromotionmain";
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		fontFamily: "Poppins",
	},
	drawer: {
		width: 291,
		color: "#fff",
		flexShrink: 0,
	},
	drawerPaper: {
		width: 291,
		color: "#fff",
		backgroundColor: "rgb(3,37,65)",
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	nested: {
		color: "#fff",
		paddingLeft: theme.spacing(4),
	},
}));

export default function UserSidebar({ children }) {
	const dispatch = useDispatch();
	const classes = useStyles();
	const businessCat = localStorage.getItem('businessCategory')
	const userGroup = localStorage.getItem('group')
	const { isSideBarOpen, isMobile } = useSelector((store) => store.sidebar);
	const [status, setStatus] = useState()
	const localCurrency = localStorage.getItem('localCurrency')


	let drawerStyles = {
    color: "#fff",
    width: "291px",
    flexShrink: "0",
  };

  if (isSideBarOpen === false) {
    drawerStyles = {
      color: "#fff",
      width: "0px",
      flexShrink: "0",
    };
    // classes.drawer = {width: 0,
    //   color: "#fff",
    //   flexShrink: 0,}
  } else if (isSideBarOpen === true) {
    drawerStyles = {
      color: "#fff",
      width: "291px",
      flexShrink: "0",
    };
  } else {
    drawerStyles = {
      drawerColor: "#fff",
      drawerWidth: "291px",
      flexShrink: "0",
    };
  }

  const [payitStatus ,setPayitStatus] = useState()

  function getPayitStatus(){
	try {
		HttpComponent({
			method:"GET",
			url:'/api/v1/getZedPayItStatus',
			token:localStorage.getItem('X-Authorization'),
		}).then((data)=>{
			//console.log(data , 'payit status')
			if(data.status === 200){
				setPayitStatus(data?.response?.data)
			}
		})
	} catch (error) {
	}
}

useEffect(()=>{
	getPayitStatus()
},[])


  const getWareHouseStatus = async () => {
	try {
		const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				"X-Authorization": localStorage.getItem("X-Authorization"),

			},
		})
		const data = response.json()
			.then((response) => {
				// console.log('status getWareHouseStatus ', response.data.enabled);
				setStatus(response?.data?.enabled)
			})

	} catch (error) {
		

	}


}

useEffect(() => {
	getWareHouseStatus()

}, [])
	return (
		<div className={classes.root}>
			{/* style={drawerStyles} */}
			<Drawer open={isSideBarOpen} className={classes.drawer} variant="permanent" classes={{ paper: classes.drawerPaper }}>
				{/*logo*/}
        <div align={"center"} style={{ marginTop: "5%", marginBottom: "5%" }}>
          <Link to={"/dashboard"} style={{ marginRight: "30%" }}>
            <img src={DashLogo} alt="Zed Logo" width="140px" height="70px" />
          </Link>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(closeSideBar({ sideBarState: false }));
              // setSideBarToggler(false)
            }}
          >
            <ArrowBackIos />
          </span>
        </div>
				<Divider />
				<List>
					{/*Dashboard*/}
					
					{userGroup === "Cashier" ? null :  <Dashboard />}

					{businessCat == 'School' && userGroup === "Director" || userGroup === "Merchant"  &&  <Users />} 

					{/*Business*/}
					<Business userGroup={userGroup} />

					{/*Houses Menu*/}

					{(userGroup == 'Merchant'  && businessCat == 'Rental' || userGroup === "Caretaker") ? <House /> : null}
					{/* Payit */}

					{userGroup !=="Customer" && userGroup !== 'User' && userGroup !== "Cashier" && userGroup !== 'Accountant'  && businessCat === "School" && payitStatus === true && <PayitMenu businessCat={businessCat} />}

					{/*Payment Setup*/}
					{(userGroup === "Customer" || userGroup ==='User' || userGroup === "Cashier" || userGroup === "Caretaker" || userGroup === 'Accountant' || userGroup === "Tenant") && (businessCat === 'Rental' || businessCat === 'School' || businessCat === null )  ? null : <PaymentSetup />}
					

					{/* Convenience Fee */}
					{userGroup !=="Customer" && userGroup !== 'User' && userGroup !== "Cashier"   && businessCat === "School" && localCurrency === 'UGX' && <OtherBusinessConvenienceFee businessCat={businessCat} />}
					{userGroup !== "Customer" && userGroup !== 'User' && userGroup !== "Cashier" && userGroup !== 'Accountant'  && businessCat === "School" && <GradesAndStreams businessCat={businessCat} />}
				

					{/* Bank Statement */}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School')  ?  <BankStatement businessCat={businessCat}/>  :null}

				
					{/* Student Promotion */}
					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School')  ?  <StudentPromotion businessCat={businessCat}/>  :null}


					{userGroup && userGroup != "Customer" && userGroup !='User' && userGroup != "Cashier" && <CustomerOnAccount/>}

					{/* General Ledger */}

					{(userGroup === "Merchant" || userGroup === 'Accountant' || userGroup === "Director") && (businessCat === 'School')  ?  <GeneralLedgerSidebar businessCat={businessCat}/>  :null}

					{userGroup != "Customer" && userGroup !='User' && userGroup != "Cashier" && userGroup != "Caretaker" &&  <Inventory businessCat={businessCat} />}
					{userGroup != "Customer" && userGroup !='User' && businessCat !== "Rental" && businessCat === "School" && status &&  <Bills businessCat={businessCat} />}
{/* 
					{userGroup != "Customer" && userGroup !='User' && businessCat !== "Rental" && businessCat === "School" && status &&  <Expenditure businessCat={businessCat} />}
					{userGroup != "Customer" && userGroup !='User' && businessCat !== "Rental" && businessCat === "School" && status &&  <Warehouse businessCat={businessCat} />} */}

					{/*Transactions!*/}
					{(userGroup === "Customer" || userGroup ==='User' || userGroup === "Cashier" || userGroup === "Caretaker"  || userGroup === "Tenant") && (businessCat === 'Rental' || businessCat === 'School' || businessCat === null )  ? null : <Transactions />}


					{userGroup != "Customer" && userGroup !='User' && userGroup != "Cashier" &&  <SchoolReport businessCat={businessCat} />}
					{userGroup != "Customer" && userGroup !='User' && userGroup != "Cashier"  && <SchoolConfiguration businessCat={businessCat} />}

			

					{/*Reports*/}
					{(userGroup == 'Merchant' && businessCat == 'Rental')  ? <Report /> : null}
					{(userGroup == 'Merchant' && businessCat == 'Rental') ? <TenantConfiguration /> : null}


					{/*Footer*/}
					<div align={"center"} style={{ marginTop: "30%" }}>
						<span>Copyright ©{new Date().getFullYear()} Zed. All rights reserved.</span>
					</div>
				</List>
			</Drawer>
			<main className={classes.content}>{children}</main>
		</div>
	);
}
