import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { Link, useLocation } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import creditNote from '../../common/images/credit.svg'
import { TextSnippet } from "@mui/icons-material"


const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(2),
	},
}));

export default function CreditNote() {
	const classes = useStyles();
	const location = useLocation();
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	return (
		<div>
			<Link style={{ textDecoration: 'none', color: isActiveLink("/creditnote") ? activeColor : '#fff' }} to="/creditnote">
				<ListItem button className={classes.nested}>
					<div style={{ marginRight: '5%' }}>
						<TextSnippet />
					</div>
					<ListItemText primary="Credit Note" />
				</ListItem>
			</Link>
		</div>
	)
}
