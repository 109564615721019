import React, { useEffect, useState } from "react";
import { Box, Button, TextField, Typography } from "@mui/material";
import makeStyles from "@material-ui/core/styles";
import CustomInputLabel from "../../School/CustomInputLabel";
import CustomTextField from "../../School/CustomTextField";
import CustomSelectField from "../../School/CustomSelectField";
import { useDispatch, useSelector } from "react-redux";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { addCategoriesToAdd, removeFromCategoriesToAdd } from "../../../features/businessCategorySlice";
import { addService, setCategories } from "../../../features/servicesSlice";
import {addStudentsServices} from '../../../features/customerSlice'
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { useNavigate } from "react-router-dom";

let baseUrl = process.env.REACT_APP_BASE_URL;

const GeneralBusinessServiceForm = (props) =>{
  const { servicesToAdd, categories } = useSelector((store) => store.services);
  const { userId, X_Authorization } = useSelector((store) => store.user);

  const cancelButtonStyles = { width: "7.813rem", height: "2.813rem", padding: "12.5px 36.7px 12.5px 38.3px", borderRadius: "4px", border: "solid 1px #dc3545", color: "#dc3545" };

  const buttonStyles = { width: "7.813rem", height: "2.813rem", borderRadius: "4px" };
  const bid = localStorage.getItem('businessId')
 
  const [formData, setFormData] = useState({
    productName: "",
    reorderLevel:"",
    priceStatus:"",
    productCategory:"",
    buyingPrice:"",
    productPrice:"",
    productDescription:"",
    businessID:bid,
  });
  //console.log(formData , 'formdata isssssssssss');
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  const dispatch = useDispatch();
  // const { additionServices } = useSelector((store) => store.customers);
  // console.log(additionServices ,'heeeeer')

  const addToList = () => {

    console.log("we are adding data to redux store", formData);
    dispatch(addService(formData));
    // dispatch(addStudentsServices(formData))
    setFormData({
      priceStatus:"",
      productCategory:"",
      reorderLevel:"",
      buyingPrice:"",
      productName: "",
      productPrice:"",
      productDescription:""
    });
    //addServiceToDB()
    setSuccessShow({ state: true, message: "Service Added" });
  };

 

  const saveHandler = () => {
    addToList();
    props.setView("student");

  };

  const pricingStatusOptions = [
    {
      label:"Fixed Price",
      value:"Fixed"
    },
    {
      label:"Variable Price",
      value:"Variable Price Item"
    }
  ]

 

  const [errorShow, setErrorShow] = useState({ state: false, message: "" });

  const [options, setOptions] = useState([]);

  const fetchCategories = () => {
    HttpComponent({
      method: "GET",
      url: "/api/listCategories/Active",
      body: null,
      token: X_Authorization,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 200) {
          const mappedData = data.response.data.map(({ categoryName, _id }) => ({ label: categoryName, value: categoryName }));
          console.log("mapped complete", mappedData);
          setOptions(mappedData);
          dispatch(setCategories(mappedData));
        } else {
          console.error("Error setting info");
          setErrorShow({ state: true, message: data.response.message });
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  useEffect(() => {
    fetchCategories();
  }, []);
  return(
    <Box component="div" sx={{ display: "flex", flexDirection: "column", marginX: 1 }}>
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <Box component="div" sx={{ display: "flex" }}>
        <Box>
          <CustomInputLabel required={true} label={"Category"} />
       
          <CustomSelectField value={formData.productCategory} onChange={handleInputChange} name={"productCategory"} placeholder={"Select Category"} options={options} medium />
          
          <CustomInputLabel required={true} label={"Pricing Status"} />
          <CustomSelectField value={formData.priceStatus} name={'priceStatus'}  onChange={handleInputChange} options={pricingStatusOptions}/>

          <CustomInputLabel required={true} label={"Buying Price"} />
          <CustomTextField value={formData.buyingPrice} name={"buyingPrice"}  onChange={handleInputChange}/>

          <CustomInputLabel required={true} label={"Reorder Level"} />
          <CustomTextField value={formData.reorderLevel} onChange={handleInputChange} placeholder={"Reorder Level"} name={"reorderLevel"} sx />
        </Box>

        <Box component="div" sx={{ marginLeft: 1 }}>
          <CustomInputLabel required={true} label={"Product Name"} />
          <CustomTextField value={formData.productName} onChange={handleInputChange} name={"productName"} placeholder={"Product Name"} />

          <CustomInputLabel required={true} label={"Amount"} />
          <CustomTextField value={formData.productPrice} onChange={handleInputChange} name={"productPrice"} placeholder={"Amount"} />

          <CustomInputLabel required={false} label={"Service Description"} />
          <TextField
          name={"productDescription"}
          value={formData.productDescription}
          onChange={handleInputChange}
          placeholder={"Service Description"}
          variant="outlined"
          margin="dense"
          sx={{ marginBottom: 2 }}
          multiline
          minRows={1}
          InputProps={{
            sx: {
              width: "407px",
              height:'54px',
              fontSize: "0.875rem",
              "& fieldset": {
                borderColor: "#bec5d1",
              },
            },
          }}
        />
        </Box>
      </Box>
      <Box component="div" sx={{ width: "100%", display: "flex", marginLeft: "55%" }}>
        {
          servicesToAdd === null ? (<Box component="div">
          <Button
          variant="text"
            sx={{
              ...buttonStyles,
              color: "#dc3545",
              marginRight: '10px',
              cursor: 'pointer',
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            onClick={props.next}
          >
            Skip
          </Button>
          <Button
          variant="outlined"
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              color: "#002543",
              marginRight: '10px',
              cursor: 'pointer',
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            id="cancel-category"
            disabled={!formData.productName || !formData.productCategory || !formData.productPrice}
          >
            Cancel
          </Button>
          <Button
          variant="outlined"
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              cursor: 'pointer',
              marginRight: '10px',
              backgroundColor:" #032541",
              color:"white",
              '&:hover': {
                  backgroundColor: '#032541',
                  color: 'white'
              }
            }}
            id="preview-category"
            onClick={saveHandler}
            disabled={!formData.productName || !formData.priceStatus ||!formData.productCategory}
          >
            Preview
          </Button>
        </Box>) : (<Box component="div">
        <Button
          variant="text"
            sx={{
              ...buttonStyles,
              color: "#dc3545",
              border: " solid 1px #dc3545",
              marginRight: '10px',
              cursor: 'pointer',
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            onClick={()=> {
              //add if for incase some categories has been added
              console.log("ndjw")
              if(!servicesToAdd || servicesToAdd?.length < 1){
                  props.setView("initial")
              }else{
                  props.setView("done")
              }
          }}
          >
            Cancel
          </Button>
          <Button
          variant="outlined"
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              color: "#fff",
              marginRight: '10px',
              cursor: 'pointer',
              backgroundColor:" #032541",
              "&:hover": {
                backgroundColor: "transparent",
                color: "#002543",
              },
            }}
            onClick={saveHandler} 
            disabled={!formData.productName || !formData.priceStatus || !formData.productDescription}
          >
            Save
          </Button>
          <Button
          variant="outlined"
            sx={{
              ...buttonStyles,
              border: " solid 1px #002543",
              cursor: 'pointer',
              marginRight: '10px',
              backgroundColor:" #032541",
              color:"white",
              '&:hover': {
                  backgroundColor: '#032541',
                  color: 'white'
              }
            }}
            onClick={()=> {
              if(!servicesToAdd || servicesToAdd?.length < 1){
                  props.setView("initial")
              }else{
                  props.setView("done")
              }
          }}
          >
            Preview ({servicesToAdd.length})
          </Button>
        </Box>)
        }
      </Box>
    </Box>
  )
}

export default GeneralBusinessServiceForm;