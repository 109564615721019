import React, { useEffect, useState } from 'react';
import { Box, Breadcrumbs, Button, Card, Grid, TextField, Typography } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AmountIcon from '../School/Images/amount-icon.svg';
import DisabledAmountIcon from '../School/Images/disabledamount-icon.svg';
import DisabledPhoneIcon from '../School/Images/phone-icnon.svg';
import PhoneIcon from '../School/Images/disabledphone-icon.svg';
import DisabledCardIcon from '../School/Images/card-icnon.svg';
import CardIcon from '../School/Images/disabledcard-icon.svg';
import BankIcon from '../School/Images/bank-icon.svg';
import DisabledBankIcon from '../School/Images/disabldbank-icon.svg';
import PayItIcon  from '../School/Images/payit_trans_icn.svg';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ExportMenu from './ExportMenu';
import CustomTable from './CustomTable';
import { DataGrid } from '@mui/x-data-grid';
import HttpComponent from './MakeRequest';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { makeStyles } from "@material-ui/core/styles";
import DateFormatter from '../../utils/dateFormatter';
import CurrencyFormatter from '../../utils/currencyFormatter';
import CashIcon from '../School/Images/cashicon.svg';



const activeStyles = makeStyles({ active: { border: "1px solid #53365c" }, activeSummary: { border: "1px solid #17ae7b" } });
const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Transactions
    </Typography>
];

const localCurrency = localStorage.getItem('localCurrency')

const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency }).format(value);


const SchoolTranscations = () => {
    const [page, setPage] = React.useState(0);
    const [pageSize, setPageSize] = React.useState(10);
    const { month, year } = useParams()
    const [gridLoading, setGridLoading] = useState(false)
    const { X_Authorization } = useSelector((store) => store.user)
    const [transacation, setTranscations] = useState([])
    const [total, setTotal] = useState(0)
    const [mpesaTotal, setMpesaTotal] = useState(0)
    const [CardTotal, setCardTotal] = useState(0)
    const [BankTotal, setBankTotal] = useState(0)
    const [transactionType, setTransactionType] = useState('')
    const [transacationData, setTranscationData] = useState([])
    const [activeCard, setActiveCard] = useState(null);
    const classes = [activeStyles()]
    const [loader, setLoader] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const [transactionBreakDown, setTransactionBreakDown] = useState([])






    const handlePageChange = (params) => {
        setPage(params.page);
    };



    // Define custom CSS classes for the header and data cells
    const headerClass = 'custom-header';
    const cellClass = 'custom-cell';
    console.log('New Date', new Date());
    const [startDate, setstartDate] = useState(null)
    const [endDate, setEndDate] = useState(null);
    const [level, setLevel] = useState(1)

    let trascationType = ''
    const handleEndDateChange = (value) => {
        setEndDate(value)
    };

    const handleStartDateChange = (value) => {
        setstartDate(value)
    }
    const setActiveLevel = (level) => {
		setLevel(level)
		console.log(`Level set to`, level)
	}



    const columns = [
        { field: "receiptNumber", headerName: "Transaction ID", flex: 1, },
        { field: "transactionType", headerName: "Transaction Type", flex: 1, },
        { field: "createdAt", headerName: "Date & Time", flex: 1, valueFormatter: (params) => { return DateFormatter(params.value); }, },
        { field: "transamount", headerName: "Amount", flex: 1 }]

    let url=`/api/v1/payments/by_month?year=${year}&month=${month}`
    
    if(startDate && endDate){
        url =`/api/v1/payments/by_month?year=${year}&month=${month}&startDate=${startDate}&endDate=${endDate}`
    } else {
        url =`/api/v1/payments/by_month?year=${year}&month=${month}`
    }

    const getSummaryTranscations = async () => {
        HttpComponent({
            method: "GET",
            url: url,
            token: X_Authorization

        }).then((data) => {
            if (data.status === 200) {
                // console.log(`Transaction breakdown Data`, data.response.transactions)
                setTransactionBreakDown(data.response.transactions)

                // console.log(CardTotals, 'CardTotals');
                // const BankTotals = data.response.transactions.find(transactionType => transactionType.transactionType === "MPESA")?.total;


                // setMpesaTotal(parseInt(mpesaTotals))
                // setCardTotal(parseInt(CardTotals))


                setTotal(data.response.total)
                setTranscations(data.response.transactions)
            } else {
                console.log(`Error getting transaction BreakDown`)
            }



        })
    }

    useEffect(() => {
        getSummaryTranscations()
        getTransactionsByType(trascationType)
    }, [startDate , endDate])

    const handleClick = (index, name) => {
        setActiveCard(index);
        console.log(name, 'NAME');
        getTransactionsByType(name)
    };


    // Get Transactions By type
    const getTransactionsByType = (cardName) => {
        let cardNamet = cardName
        try {
            setLoader(true)
            HttpComponent({
                method: 'GET',
                url: `/api/v1/payments/by_month_by_transaction_type?year=${year}&month=${month}&transactionType=${cardNamet}&page=1&limit=1000`,
                token: X_Authorization
            }).then((data) => {
                if (data.status === 200) {
                    setLoader(false)
                    setTranscationData(data.response.transactions)
                    console.log(`${cardNamet} Transaction breakdown Data`, data.response.transactions)
                } else {
                    console.log(`Error getting ${cardName} transaction `)
                }

            }).catch((e) => {
                console.log(`Error getting ${cardName} transaction`, e.message)
            })

        } catch (e) {
            console.log(`Error getting ${cardName} transaction`, e.message)
        }
    }




    const rows = transacationData?.filter((item) => {
        return searchValue?.toLowerCase() === '' ? item : item?.receiptNumber?.toLowerCase().includes(searchValue?.toLowerCase())
    }).map((item) => ({
        id: item.transactionID,
        transactionType: item.transactionType,
        transamount: item.transamount,
        receiptNumber: item.receiptNumber,
        createdAt: item.createdAt
    }

    ))


    console.log('rows', rows);

    // const changeTranscationType =(transacationName) => {
    //      getTranscationByTranscationType(transacationName)

    // }

    return (
        <div>
            <div>
                <div>
                    <Box component="div" sx={{ marginY: 2 }}>
                        <Breadcrumbs
                            separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                            aria-label="breadcrumb">
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Box>
                </div>
                <div className="payment_types">
                    {/* <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 0 ? classes[0].activeSummary : ""}`} onClick={() => {
                            { setActiveCard(0); handleClick(0, "") }
                        }}>
                            <div className="image-div">
                                <img src={AmountIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>Amount Collected</h4>
                                <h6> {numberFormat(isNaN(total) ? 0 : total)} </h6>
                            </div>
                        </div>
                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 1 ? classes[0].activeSummary : "MPESA"}`} onClick={() => {

                            { setActiveCard(1); handleClick(1, `MPESA`) }
                        }}>
                            <div className="image-div">
                                <img src={PhoneIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>Mpesa Transcations</h4>
                                <h6> {numberFormat(isNaN(mpesaTotal) ? 0 : mpesaTotal)} </h6>
                            </div>
                        </div>

                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 1 ? classes[0].activeSummary : "MPESA"}`} onClick={() => {

                            { setActiveCard(3); handleClick(1, `PAYITCARD`) }
                        }}>
                            <div className="image-div">
                                <img src={BankIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>PAYIT Transacations</h4>
                                <h6> {numberFormat(0)} </h6>
                            </div>
                        </div>
                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 1 ? classes[0].activeSummary : "MPESA"}`} onClick={() => {

                            { setActiveCard(3); handleClick(1, `Cash Payment`) }
                        }}>
                            <div className="image-div">
                                <img src={BankIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>CASH Transacations</h4>
                                <h6> {numberFormat(0)} </h6>
                            </div>
                        </div>
                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 1 ? classes[0].activeSummary : "MPESA"}`} onClick={() => {

                            { setActiveCard(2); handleClick(1, `CARD`) }
                        }}>
                            <div className="image-div">
                                <img src={CardIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>Card Transcations</h4>
                                <h6> {numberFormat(isNaN(CardTotal) ? 0 : CardTotal)} </h6>
                            </div>
                        </div>
                        <div style={{ display: "flex", border: "solid 1px rgba(3,37,65,0.2)", width: "350px", height: "100px", margin: "10px 15px 0px 0px", borderRadius: "6px", border: "solid 1px rgba(3,37,65,0.2)", background: "#fff" }} className={`${classes.root} ${activeCard === 1 ? classes[0].activeSummary : "MPESA"}`} onClick={() => {

                            { setActiveCard(3); handleClick(1, `Cash Payment`) }
                        }}>
                            <div className="image-div">
                                <img src={BankIcon} style={{ height: "70px", marginLeft: "10px", marginTop: "10px" }} alt="" />
                            </div>
                            <div className="textAmount ml-3" style={{ marginLeft: "10px", marginTop: "16px" }}>
                                <h4 style={{ color: "#032541", fontSize: "14px", fontWeight: "normal" }}>Bank Transacations</h4>
                                <h6> {numberFormat(0)} </h6>
                            </div>
                        </div>

                    </div> */}
                    <Grid container justifyContent={'flex-start'} alignContent={'center'} mt={3} column={4} spacing={2}>

                        {/*Amount Collected*/}
                        <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Card className={`${classes.root} ${activeCard === 0 ? classes[0].activeSummary : ""}`} onClick={() => { setActiveCard(0); handleClick(0, "") }} style={{ cursor: "pointer", width: '100%', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff' }}>

                                {/*Icon container*/}
                                <Grid container direction={'row'} spacing={3}>

                                    {/*Icon*/}
                                    <Grid item mt={3} ml={3}>
                                        <img src={AmountIcon} alt={'amount collected'} />
                                    </Grid>

                                    {/*Title and amount*/}
                                    <Grid item mt={3}>
                                        <Grid container direction={'column'} justifyContent={'flex-start'}>

                                            {/*Title*/}
                                            <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 'bold', fontSize: '11px' }}> Amount Collected</span>
                                            </Grid>

                                            {/*Amount*/}
                                            <Grid item>
                                                <span style={{ color: '#17ae7b', fontWeight: 'bold', fontSize: '20px' }}>{CurrencyFormatter(total, localCurrency)}</span>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                            </Card>
                        </Grid>
                        {/*transaction types map*/}
                        {transactionBreakDown.map((transactionType, index) => (
                            <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                                <Card className={`${classes.root} ${activeCard === index ? classes[0].active : ""}`} onClick={() => { setActiveLevel(index + 1); handleClick(index, transactionType.transactionType) }} style={{ cursor: 'pointer', height: '100px', boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px', backGroundColor: '#fff' }}>

                                    {/*Icon container*/}
                                    <Grid container direction={'row'} spacing={3}>

                                        {/*Icon*/}
                                        <Grid item mt={3} ml={3}>
                                            {
                                                transactionType.transactionType === 'MPESA' ? <img src={PhoneIcon} alt={'transaction type'} /> :
                                                    transactionType.transactionType === 'Card Payment' ? <img src={CardIcon} alt={'transaction type'} /> :
                                                        transactionType.transactionType === 'BANK' ? <img src={BankIcon} alt={'transaction type'} /> :
                                                        transactionType.transactionType === 'Cash Payment' ? <img src={CashIcon} alt={'transaction type'} /> :
                                                        transactionType.transactionType === 'PAYITCARD' ? <img src={PayItIcon} alt={'transaction type'} /> :
                                                         ''
                                            }

                                        </Grid>

                                        {/*Title and amount*/}
                                        <Grid item mt={3}>
                                            <Grid container direction={'column'} justifyContent={'flex-start'}>

                                                <Grid item>
                                                    <span style={{ color: '#707070', fontWeight: 'bold', fontSize: '11px' }}>{transactionType.transactionType}</span>
                                                </Grid>

                                                {/*Amount*/}
                                                <Grid item>
                                                    <span style={{ color: '#032541', fontWeight: 'bold', fontSize: '20px' }}>{CurrencyFormatter(transactionType.total, localCurrency)}</span>
                                                </Grid>

                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Card>
                            </Grid>
                        ))}

                    </Grid>

                </div>
                <div className="filters mt-5" style={{ display: "flex" }}>
                    <div className="searchinput mr-2">
                        <Paper
                            component="form"
                            sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                        >

                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder="Search transacation ID"
                                inputProps={{ 'aria-label': 'Search transacation ID' }}
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchIcon />
                            </IconButton>

                        </Paper>
                    </div>

                    <LocalizationProvider dateAdapter={AdapterDayjs} className="">
                        <DatePicker
                            label="From"
                            value={startDate}
                            onChange={handleStartDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="DD-MM-YYYY"
                            format="DD-MM-YYYY"
                        />

                        <DatePicker
                            className='ml-2'
                            label="To"
                            value={endDate}
                            onChange={handleEndDateChange}
                            renderInput={(params) => <TextField {...params} />}
                            inputFormat="DD-MM-YYYY"
                            format="DD-MM-YYYY"
                        />
                    </LocalizationProvider>

                    <div className="export ml-auto" >
                        <ExportMenu
                            csvColumns={''}
                            fileData={''}
                            fileHeaders={'fileHeaders'}
                            fileDataPDF={'fileDataPDF'}
                            title={''}
                            fileName={''}
                        />

                    </div>

                </div>
                <div className="mt-2">
                    <Box component={"div"} style={{ display: 'flex', minHeight: "24rem", width: "100%" }}>
                        <Box sx={{ flexGrow: 1 }}>
                            <DataGrid
                                sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: '0.875rem', color: '#272d3d', boxShadow: 0, border: 0 }}
                                pagination
                                page={page}
                                onPageChange={handlePageChange}
                                rows={rows}
                                columns={columns}
                                pageSize={pageSize}
                                rowsPerPageOptions={[5, 10, 25, 100]}
                                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                                autoWidth
                                autoHeight
                                disableColumnMenu
                                loading={loader}
                            />

                        </Box>
                        <style>

                            {`.${headerClass} .MuiDataGrid-colCellTitle {
                    font-size: 1rem;
                    font-weight: 700;
                    text-align: center;
                }
                .${headerClass} {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
                .${cellClass} {
                    font-size:0.875rem;
                    font-weight: 400;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .${cellClass} .MuiDataGrid-cell {
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    padding: '16px';
                }
                `}
                        </style>
                    </Box>

                </div>




            </div>
        </div>
    )
}

export default SchoolTranscations