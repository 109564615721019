import { useState, useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ArrowBack } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import Switch from "@mui/material/Switch";
import { useSelector,useDispatch } from 'react-redux';
import KCBMobileMoney from './KCBMobileMoney';
import KCBBankDeposit from './KCBBankDeposit';
import EquityMobileMoney from './EquityMobileMoney';
import EquityBankDeposit from './EquityBankDeposit';
import CopMobileMoney from './CopMobileMoney';
import CopBankDeposit from './CopBankDeposit';
import { resetPaymentOptions, setKCBBankDepositStatus, setKCBMobileStatus } from '../../../features/paymentOptionsSlice';
import HttpComponent from '../MakeRequest';
import KCBPocketMoney from './KCBPocketmoney';

const BankOptions = () => {

  const [formState, setFormState] = useState(false)
  const [showKCBOptions, setShowKCBOptions] = useState(false);
  const [showEquityOptions, setShowEquityOptions] = useState(false);
  const [showCoperativeOptions, setShowCoperativeOptions] = useState(false);
  const [kcbMobileChecked, setKCBMobileChecked] = useState(false);
  const [kcbDepositChecked, setKCBDepositChecked] = useState(false);
  const [kcbPocketMoneyChecked, setKCBPocketChecked] = useState(false);
  const [equityMobileChecked, setEquityMobileChecked] = useState(false);
  const [equityDepositChecked, setEquityDepositChecked] = useState(false);
  const [copMobileChecked, setCopMobileChecked] = useState(false);
  const [copDepositChecked, setCopDepositChecked] = useState(false);
  const [showKCBMobileMoneySetup, setShowKCBMobileMoneySetup] = useState(false);
  const [showKCBBankDepositSetup, setShowKCBBankDepositSetup] = useState(false);
  const [showEquityMobileMoneySetup, setShowEquityMobileMoneySetup] = useState(false);
  const [showEquityBankDepositSetup, setShowEquityBankDepositSetup] = useState(false);
  const [showCopMobileMoneySetup, setShowCopMobileMoneySetup] = useState(false);
  const [showCopBankDepositSetup, setShowCopBankDepositSetup] = useState(false);
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const dispatch = useDispatch();

  const { userId ,X_Authorization } = useSelector((store) => store.user);
  const [kcbpocketMoneyStatus , setKcbpocketMoneystatus] = useState()
  const [showKcbpocketMoneySetUp , setShowKcbPocketMoneySetUp] = useState(false)

  // redux toolkit imports
  let { KCBMobileStatus, KCBBankDepositStatus, equityMobileMoneyStatus, equityBankDepositStatus, copMobileMoneyStatus, copBankDepositStatus } = useSelector((store) => store.payment);

  // option switch
  const CustomSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 45,
    height: 23,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 18,
      height: 18,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  const changeFormState = () => {
    if (formState) {
      setFormState(false)
    } if (!formState) {
      setFormState(true)
    }

  }

  //payit status

  const [payitStatus ,setPayitStatus] = useState()

  function getPayitStatus(){
	try {
		HttpComponent({
			method:"GET",
			url:'/api/v1/getZedPayItStatus',
			token:localStorage.getItem('X-Authorization'),
		}).then((data)=>{
			//console.log(data , 'payit status')
			if(data.status === 200){
				setPayitStatus(data?.response?.data)
			}
		})
	} catch (error) {
	}
}

useEffect(()=>{
	getPayitStatus()
},[])

  // form toggling effects
  useEffect(() => {
    if (kcbMobileChecked) {
      setShowKCBMobileMoneySetup(true)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [kcbMobileChecked]);

  // pocket money

  useEffect(()=>{
    if(kcbPocketMoneyChecked){
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(true)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  },[kcbPocketMoneyChecked])


  //   bank option rendering
  useEffect(() => {
    if (kcbDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowKCBBankDepositSetup(true)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [kcbDepositChecked]);



  //   bank paybill option rendering
  useEffect(() => {
    if (equityMobileChecked) {
      setShowEquityMobileMoneySetup(true)
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [equityMobileChecked]);

  //   card option rendering
  useEffect(() => {
    if (equityDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(true)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [equityDepositChecked]);

  //   MTN MoMo option rendering
  useEffect(() => {
    if (copMobileChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(true)
      setShowCopBankDepositSetup(false)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [copMobileChecked]);

  
  // paypal option rendering
  useEffect(() => {
    if (copDepositChecked) {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowEquityBankDepositSetup(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(true)
    } else {
      setShowKCBMobileMoneySetup(false)
      setShowKCBBankDepositSetup(false)
      setShowEquityMobileMoneySetup(false)
      setShowEquityBankDepositSetup(false)
      setShowKcbPocketMoneySetUp(false)
      setShowCopMobileMoneySetup(false)
      setShowCopBankDepositSetup(false)
    }
  }, [copDepositChecked]);
  const checkStatus = () => {
    console.log('here check status');
    dispatch(resetPaymentOptions())

    HttpComponent({
      method: 'GET',
      url: `/api/get_payment_methods_with_status`,
      body: null,
      token: X_Authorization
    }).then((data) => {
      console.log("all payment status", data);
      if (data.status === 201) {
        console.log('we here Bank Deposit', data.response.data.find(method => method.name === "Bank Deposit")?.bankdepositStatus);
        KCBMobileStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[0]?.status
        KCBBankDepositStatus = data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[2]?.status
        setKcbpocketMoneystatus(data.response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[3]?.status)

        dispatch(setKCBMobileStatus(KCBMobileStatus))
        dispatch(setKCBBankDepositStatus(KCBBankDepositStatus))
       console.log('we here Bank Deposit KCBBankDepositStatus', KCBBankDepositStatus);
        

        
      } else {
        console.error("Error setting info")
        setErrorShow({ state: true, message: data.response.message })
      }
    }).catch((error) => {
      console.error(error.message);
    })
  }
  useEffect(() => {
    checkStatus()

  }, [])


  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', fontFamily: 'Poppins', width: "100%", minHeight: "20.938rem", "borderRadius": "6px", "border": "solid 1px #eaeaea", marginTop: "4.6%" }}>
      <div className="topHeader" style={{ display: "flex", marginTop: "15px", marginLeft: "16px" }}>
        <ArrowBack onClick={changeFormState} style={{ fontSize: "30px", cursor: "pointer" }} />
        <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Banks</h6>
        {/* <h6 className='ml-4 mt-1' style={{ fontSize: "16px", color: "#032541", fontWeight: "600", letterSpacing: "" }}>Edit Card Details</h6> */}
      </div>

      {/* bank payment option selections */}
      {(!showCopBankDepositSetup || !showCopMobileMoneySetup || !showKCBBankDepositSetup || !showKcbpocketMoneySetUp || !showKCBMobileMoneySetup || !showEquityBankDepositSetup || !showEquityMobileMoneySetup) &&
        <Box component="div" style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: '50px' }}>
          {/* kcb grid */}
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => setShowKCBOptions(!showKCBOptions)}>
              <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>KCB Bank</Typography>
              {!showKCBOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Grid>
            {showKCBOptions && <Grid item>

              {/* KCB Mobile Money Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  setKCBMobileChecked(!kcbMobileChecked);
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Mobile Money
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!KCBMobileStatus ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}>Active</Typography>}
                    <CustomSwitch
                      checked={KCBMobileStatus}
                      onChange={() => setKCBMobileChecked(!kcbMobileChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>

              {/* KCB Bank Deposit Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  // setKCBDepositChecked(!kcbDepositChecked);
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Bank Deposit
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!KCBBankDepositStatus ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}>Active</Typography>}
                    <CustomSwitch
                      checked={KCBBankDepositStatus}
                      onChange={() => setKCBDepositChecked(!kcbDepositChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>

              {/* POCKET Money */}
              {/* {payitStatus === true ? <>
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }}>
                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Pocket Money
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!kcbpocketMoneyStatus ? <Typography style={{ color: "#707070", fontSize: '14px', marginRight: '5px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', fontSize: '14px', lineHeight: 2.57, marginRight: '5px' }}>Active</Typography>}
                    <CustomSwitch
                      checked={kcbpocketMoneyStatus}
                      onChange={() => setKCBPocketChecked(!kcbPocketMoneyChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box></> :null} */}
            </Grid>}
          </Grid>
          <br />

          {/* equity grid */}
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => {

              //  setShowEquityOptions(!showEquityOptions)
            }}>
              <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>Equity Bank <span style={{ color: "gray" }}>COMING SOON</span></Typography>
              {!showEquityOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Grid>
            {showEquityOptions && <Grid item>

              {/* Equity Mobile Money Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  setEquityMobileChecked(!equityMobileChecked);
                  // console.log('Mobile Money Option!');
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Mobile Money
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!equityMobileMoneyStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                    <CustomSwitch
                      checked={equityMobileMoneyStatus}
                      onChange={() => setEquityMobileChecked(!equityMobileChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>

              {/* Equity Bank Deposit Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  setEquityDepositChecked(!equityDepositChecked);
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Bank Deposit
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!equityBankDepositStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                    <CustomSwitch
                      checked={equityBankDepositStatus}
                      onChange={() => setEquityDepositChecked(!equityDepositChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>}
          </Grid>
          <br style={{ color: '#c2cbd1' }} />

          {/* co-operative bank grid */}
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <Grid item style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
              // setShowCoperativeOptions(!showCoperativeOptions)
            }}>
              <Typography variant='h6' style={{ fontSize: '14px', fontWeight: 500, color: '#032541', margin: '0 0 0 1.5px', lineHeight: 1.14, letterSpacing: '0.47px' }}>Co-operative Bank <span style={{ color: "gray" }}>COMING SOON</span></Typography>
              {!showCoperativeOptions ? <ExpandMoreIcon /> : <ExpandLessIcon />}
            </Grid>
            {showCoperativeOptions && <Grid item>

              {/* Co-operative Mobile Money Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  setCopMobileChecked(!copMobileChecked);
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Mobile Money
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!copMobileMoneyStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                    <CustomSwitch
                      checked={copMobileMoneyStatus}
                      onChange={() => setCopMobileChecked(!copMobileChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>

              {/* Co-operative Bank Deposit Setup */}
              <Box component="div" sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
                <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "space-between", padding: 0.5, cursor: 'pointer' }} onClick={() => {
                  setCopDepositChecked(!copDepositChecked);
                }}>

                  <Box component="div" sx={{ display: "flex" }}>
                    <Box component="div" sx={{ display: "flex" }}>
                      <Typography sx={{ color: "#707070", fontSize: '14px', lineHeight: 2.57 }}>
                        Bank Deposit
                      </Typography>
                    </Box>
                  </Box>

                  <Box component="div" sx={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                    {!copBankDepositStatus ? <Typography style={{ color: "#707070", marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Not Set</Typography> : <Typography style={{ color: '#33cf4d', marginRight: '5px', fontSize: '14px', lineHeight: 2.57 }}>Active</Typography>}
                    <CustomSwitch
                      checked={copBankDepositStatus}
                      onChange={() => setCopDepositChecked(!copDepositChecked)}
                      inputProps={{ 'aria-label': 'controlled' }}
                      color="warning"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>}
          </Grid>
        </Box>}

      {/* payment options forms  */}
      {(showCopBankDepositSetup || showCopMobileMoneySetup || showKcbpocketMoneySetUp || showKCBBankDepositSetup || showKCBMobileMoneySetup || showEquityBankDepositSetup || showCopMobileMoneySetup) &&
        <Box component="div" sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          {/* {showKcbpocketMoneySetUp && <KCBPocketMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />} */}
          {showKCBMobileMoneySetup && <KCBMobileMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
          {showKCBBankDepositSetup && <KCBBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
          {showEquityMobileMoneySetup && <EquityMobileMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
          {showEquityBankDepositSetup && <EquityBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
          {showCopMobileMoneySetup && <CopMobileMoney setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
          {showCopBankDepositSetup && <CopBankDeposit setSuccessShow={setSuccessShow} setErrorShow={setErrorShow} errorShow={errorShow} successShow={successShow} />}
        </Box>}
    </Box>
  )
}

export default BankOptions;