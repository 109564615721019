import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {ExpandLess} from "@material-ui/icons";
import {ExpandMore} from "@mui/icons-material";
import {Collapse} from "@mui/material";
import List from "@material-ui/core/List";
import {Link, useLocation} from "react-router-dom";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import ReceiptIcon from '@mui/icons-material/Receipt';

const useStyles = makeStyles((theme) => ({
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function Transactions (props) {
	const classes = useStyles();
	const [open, setOpen] = React.useState(false);

	const location = useLocation()
	const isActiveLink = (path) => location.pathname === path;
	const activeColor = "#04f2fc"

	const handleClick = () => {setOpen(!open);};

	const userGroup = localStorage.getItem('group')


	const canViewTransaction = userGroup && userGroup === 'Admin' || userGroup === 'Merchant' || userGroup === "Owner" || userGroup === 'Supervisor' || userGroup === 'Accountant' || userGroup === 'Director'

	return (
		<div>

			{/*Users*/}
			<ListItem button onClick={handleClick}  style={{ color: open ? '#04f2fc' : '#fff' }}>
				<div style={{marginRight : '5%'}}>
					<ReceiptIcon />
				</div>
				<ListItemText primary="Transactions" style={{fontFamily : 'Poppins' ,color: open ? '#04f2fc' : '#fff' }}/>
				{open ? <ExpandLess /> : <ExpandMore />}
			</ListItem>

			{/*Collapsed Users*/}
			<Collapse in={open} timeout="auto" unmountOnExit>
				<List component="div" disablePadding>

					{canViewTransaction ?
						<div>
							<Link style={{textDecoration: 'none',color: isActiveLink('/transactions') ? activeColor: '#fff'}} to="/transactions">
								<ListItem button className={classes.nested}>
									<ListItemText primary="All Transactions" />
								</ListItem>
							</Link>
						</div> : null}

				</List>
			</Collapse>
		</div>
	)
}