import { createSlice } from "@reduxjs/toolkit";

const initialState={
  bankPaymentData: [],
  bankPaybillData: [],
  cardPaymentData: [],
  KCBMobileMoneyData:[],
  KCBBankDepositData:[],
  paypalData: [],
  momoData: [],
  mpesaData:[],
  isBankSetup: false,
  isPaypalSetup: false,
  isGsSetup: false,
  isCardSetup: false,
  isBankPaybillSetup: false,
  isCashSetup: false,
  isMpesaSetup: false,
  isKCBMobileSetup: false,
  isKCBBankDepositSetup: false,
  isEquityMobileSetup: false,
  isEquityBankDepositSetup: false,
  isCopMobileSetup: false,
  isCopBankDepositSetup: false,
  isMomoSetup: false,
  mpesaStatus: false,
  cardStatus:false,
  bankPaybillStatus:false,
  paypalStatus: false,
  gsStatus: false,
  bankStatus:false,
  cashStatus:false,
  momoStatus:false,
  KCBMobileStatus: false, 
  KCBPocketMoney:false,
  KCBBankDepositStatus: false,
  equityMobileMoneyStatus: false, 
  equityBankDepositStatus: false, 
  copMobileMoneyStatus: false, 
  copBankDepositStatus: false,
}

const paymentOptionsSlice = createSlice({
  name: 'payment',
  initialState,
  reducers:{
    handleBankStatus: (state, action) =>{
      state.bankData = [...state.bankData, action.payload]
    },
    handleIsBankSetup: (state, action) =>{
      state.isBankSetup = action.payload
    },
    handleIsBankPaybillSetup: (state, action) =>{
      state.isBankPaybillSetup = action.payload
    },
    handleIsCardSetup: (state, action) =>{
      state.isCardSetup =action.payload
    },
    handleIsCashSetup: (state, action) =>{
      state.isCashSetup = action.payload
    },
    handleIsMpesaSetup: (state, action) =>{
      state.isMpesaSetup = action.payload;
    },
    handleIsMomoSetup: (state, action) =>{
      state.isMomoSetup = action.payload;
    },
    handleIsPaypalSetup: (state, action) =>{
      state.isPaypalSetup = action.payload;
    },
    handleIsGsSetup: (state, action) =>{
      state.isGsSetup = action.payload;
    },
    handleIsKCBMobileSetup: (state, action) =>{
      state.isKCBMobileSetup = action.payload;
    },
    handleIsKCBBankDepositSetup: (state, action) =>{
      state.isKCBBankDepositSetup = action.payload;
    },
    handleIsEquityMobileSetup: (state, action) =>{
      state.isEquityMobileSetup = action.payload;
    },
    handleIsEquityBankDepositSetup: (state, action) =>{
      state.isEquityBankDepositSetup = action.payload;
    },
    handleIsCopMobileSetup: (state, action) =>{
      state.isCopMobileSetup = action.payload;
    },
    handleIsCopBankDepositSetup: (state, action) =>{
      state.isCopBankDepositSetup = action.payload;
    },
    setCardPaymentData: (state, action) =>{
      const newCardObj = {...action.payload}
      state.cardPaymentData = [...state.cardPaymentData, newCardObj]; 
    },
    setMpesaPaymentData: (state, action) =>{
      const newMpesaObj = {...action.payload.response.data}
      state.mpesaData = [...state.mpesaData, newMpesaObj]; 
    },
    setBankPaybillData: (state, action) =>{
      const newObj = {...action.payload.data}
      state.bankPaybillData = [...state.bankPaybillData, newObj]; 
    },
    setMomoData: (state, action) =>{
      const newMomoObj = {...action.payload.data}
      state.momoData = [...state.momoData, newMomoObj]; 
    },
    setPaypalData: (state, action) =>{
      const newPaypalObj = {...action.payload.data}
      state.paypalData = [...state.paypalData, newPaypalObj]; 
    },
    setKCBMobileData: (state, action) =>{
      const newMobileObj = {...action.payload.data}
      state.KCBMobileData = [...state.KCBMobileData, newMobileObj]; 
    },
    setKCBBankDepositData: (state, action) =>{
      const newDepoObj = {...action.payload.data}
      state.KCBBankDepositData = [...state.KCBBankDepositData, newDepoObj]; 
    },
    deleteBankPaybill: (state, action) =>{
      let index = action.payload.index;
            if (index > -1) {
              state.bankPaybillData.splice(index, 1);
            }
    },
    setMpesaStatus: (state, action) =>{
      state.mpesaStatus = action.payload
    },
    setCardStatus: (state, action) =>{
      state.cardStatus = action.payload
    },
    setBankPaybillStatus: (state, action) =>{
      state.bankPaybillStatus = action.payload
    },
    setBankStatus: (state, action) =>{
      state.bankStatus = action.payload
    },
    setCashStatus: (state, action) =>{
      state.cashStatus = action.payload
    },
    setMomoStatus: (state, action) =>{
      state.momoStatus = action.payload
    },
    setPaypalStatus: (state, action) =>{
      state.paypalStatus = action.payload
    },
    setGSStatus: (state, action) =>{
      state.gsStatus = action.payload
    },
    setKCBMobileStatus: (state, action) =>{
      state.KCBMobileStatus = action.payload
    },
    setKCBBankDepositStatus: (state, action) =>{
      console.log(action.payload,'Page KCB Bank Deposit Status')
      state.KCBBankDepositStatus = action.payload
    },
    setEquityMobileStatus: (state, action) =>{
      state.equityMobileMoneyStatus = action.payload
    },
    setEquityBankDepositStatus: (state, action) =>{
      state.equityBankDepositStatus = action.payload
    },
    setCopMobileStatus: (state, action) =>{
      state.copMobileMoneyStatus = action.payload
    },
    setCopBankDepositStatus: (state, action) =>{
      state.copBankDepositStatus = action.payload
    },
    resetPaymentOptions: (state, action) =>{
      state.isCardSetup = false;
      state.isMpesaSetup = false;
      state.isBankPaybillSetup = false;
      state.isBankSetup = false;
      state.isCashSetup = false;
      state.KCBBankDepositStatus = false
    }
  }
})

export const {
  setMpesaStatus, 
  setCardStatus, 
  setBankPaybillStatus, 
  setBankStatus, 
  setCashStatus, 
  setMomoStatus, 
  resetPaymentOptions, 
  deleteBankPaybill, 
  setBankPaybillData, 
  setMpesaPaymentData, 
  setCardPaymentData, 
  setPaypalData,
  setKCBBankDepositData,
  setKCBMobileData,
  handleBankData, 
  handleIsBankPaybillSetup, 
  handleIsBankSetup, 
  handleIsMpesaSetup, 
  handleIsMomoSetup, 
  handleIsCardSetup, 
  setMomoData,
  handleIsGsSetup,
  handleIsPaypalSetup,
  setPaypalStatus,
  setGSStatus,
  setKCBMobileStatus,
  setKCBBankDepositStatus,
  handleIsKCBBankDepositSetup,
  handleIsKCBMobileSetup,
  setCopBankDepositStatus,
  setCopMobileStatus,
  setEquityBankDepositStatus,
  setEquityMobileStatus,
  handleIsCopBankDepositSetup,
  handleIsCopMobileSetup,
  handleIsEquityBankDepositSetup,
  handleIsEquityMobileSetup,
  handleIsCashSetup} = paymentOptionsSlice.actions;
export default paymentOptionsSlice.reducer;

