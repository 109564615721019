import LoadingButton from "@mui/lab/LoadingButton";
import { Button, ButtonBase, Card, Grid, Box, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Mpesa from "../../../common/images/mpesa-icon.svg";
import CardImg from '../../../common/images/card-icon.svg'
import { PaymentSent } from "../../Modals/Invoice/paymentSent";
import HttpComponent from "../../School/MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import SendIcon from '@mui/icons-material/Send';
import BankPayBillIcon from "../../School/Images/bank-paybill.svg";
import CARDREADY from '../../School/Images/cardlinkready.svg';
import BankSlip from "../../../common/images/bank-slip-icn.svg";
import CashIcon from "../../School/Images/cash-icon.svg"
import { sumBy } from "lodash";


const baseUrl = process.env.REACT_APP_BASE_URL;
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: "KES", }).format(value);

export default function RequestPayFromCustomer({ orderIDS }) {

    //get the order ids

    const orderids = orderIDS?.map((order) => order?.orderId)

    // get startOrderData;

    const queryParams = decodeURIComponent(window.location.search);
    const startOrderData = queryParams.slice(1);
    const startOrderTag = startOrderData?.split("?")[0];
    const newStartOrderId = startOrderData?.split("?")[1];
    // const startOrderId = startOrderData?.split("?")[1];
    const startOrderAmount = startOrderData?.split("?")[2];
    const startOrderArrayItems = startOrderData?.split("?")[3];
    const [startOrderId, setStartOrderId] = useState([])
    const [startOrderItems, setStartOrderItems] = useState([])

    //return products

    const productData = orderIDS?.flatMap((item) => item?.products?.map((product) => product))
    //billTotal
    let totalBills = sumBy(orderIDS, v => { return v?.billTotal })
    //get pushyTransaction ids 
    const checkedpushTransactionIds = orderIDS.map((checkord) => checkord?.orderId)
    

    //show balance
    const [balance, setBalance] = useState(totalBills);


    useEffect(() => {
        if(startOrderTag === "startOrderTag"){
            setStartOrderId([newStartOrderId])
        }else if(startOrderTag === "unPaidstartOrderTag"){
            
            setStartOrderId(JSON.parse(newStartOrderId))
            if(startOrderArrayItems){
                setStartOrderItems(JSON.parse(startOrderArrayItems))
            }
           
        }

        if(startOrderArrayItems){
            setStartOrderItems(JSON.parse(startOrderArrayItems))
        }

        setCashAmount(startOrderAmount)
        
    }, [startOrderTag])

    function getRandomPushTransactionIdFromArray(numbers) {
        const randomIndex = Math.floor(Math.random() * numbers.length);
        return numbers[randomIndex];
    }
    const pushIdArray = checkedpushTransactionIds;
    const randomNum = getRandomPushTransactionIdFromArray(pushIdArray);

    const navigate = useNavigate()
    const [successOpen, setSuccessOpen] = useState(false);
    const [activeButton, setActiveButton] = useState('')
    const [userPhone, setUserPhone] = useState('')
    const [invoiceBalance, setInvoiceBalance] = useState();
    const [amount, setAmount] = useState("")


    const [bankAccount, setBankAccount] = useState('')
    const [kcbphone, setkcbphone] = useState('')
    const [kcbAmount, setKcbAmount] = useState('')

    const [email, setEmailAddress] = useState('')
    const [cardAmount, setCardAmout] = useState('')

    const { invoiceNumber, darajaConfigId } = useParams();

    // Success Notification
    const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
    const handleSuccessNotificationClick = () => { setSuccessNotificationOpen(true); };
    const handleSuccessNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };


    const [successNotificationOpen2, setSuccessNotificationOpen2] = React.useState(false);
    const handleSuccessNotificationClick2 = () => { setSuccessNotificationOpen2(true); };
    const handleSuccessNotificationClose2 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen(false); };



    const [successNotificationOpen1, setSuccessNotificationOpen1] = React.useState(false);
    const handleSuccessNotificationClick1 = () => { setSuccessNotificationOpen1(true); };
    const handleSuccessNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationOpen1(false); };

    // Success Notification
    const [successNotificationReceivedPaymentOpen, setSuccessNotificationReceivedPaymentOpen] = React.useState(false);
    const handleSuccessNotificationPaymentReceivedClick = () => { setSuccessNotificationReceivedPaymentOpen(true); };
    const handleSuccessNotificationReceivedPaymentClose = (event, reason) => { if (reason === 'clickaway') { return } setSuccessNotificationReceivedPaymentOpen(false); };

    const [bussinessStkId, setBussinessStkId] = useState('')

    // Error Notification
    const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
    const handleErrorNotificationClick = () => { setErrorNotificationOpen(true); };
    const handleErrorNotificationClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen(false); };
    // Card Error
    const [errorNotificationOpen1, setErrorNotificationOpen1] = React.useState(false);
    const handleErrorNotificationClick1 = () => { setErrorNotificationOpen1(true); };
    const handleErrorNotificationClose1 = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationOpen1(false); };

    // Error Notification
    const [errorNotificationTimeoutOpen, setErrorNotificationTimeoutOpen] = React.useState(false);
    const handleErrorNotificationTimeoutClick = () => { setErrorNotificationTimeoutOpen(true); };
    const handleErrorNotificationTimeoutClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationTimeoutOpen(false); };

    // Error Notification
    const [errorNotificationNoBusinessConfigOpen, setErrorNotificationNoBusinessConfigOpen] = React.useState(false);
    const handleErrorNotificationNoBusinessConfigClick = () => { setErrorNotificationNoBusinessConfigOpen(true); };
    const handleErrorNotificationNoBusinessConfigClose = (event, reason) => { if (reason === 'clickaway') { return } setErrorNotificationNoBusinessConfigOpen(false); };



    const [mpesaLoading, setMpesaLoading] = useState(false)
    const [kcbloading, setkcbloading] = useState(false)
    const [Cardloading, setCardloading] = useState(false)

    const [intervalId, setIntervalId] = useState(null);
    const { X_Authorization } = useSelector((store) => store.user)


    const [successmessage, SetSuccessMessageCard] = useState(false)
    const [StkMessage, setSTKMessage] = useState(false)

    const [SuccessShow, setSuccessShow] = useState({ state: false, message: "" })
    const [GenerteLink, setGeneratedLink] = useState(false)
    const [GenerateLinkStatus, setGenerateLinkStatus] = useState(false)
    const [dataLINK, setDatagenerated] = useState('')

    const intervalIdRef = useRef(null);
    const textRef = useRef(null);

    const handleCopy = () => {
        textRef.current.select();
        document.execCommand('copy');
        handleSuccessNotificationClick2()
    };

    //check if the push transactions is payed
    const bsNo = localStorage.getItem('businessId')
    const XAuthorization = localStorage.getItem('X-Authorization')
    const [orderStatus, setOrderStatus] = useState()
    const [newTotalBill, setNewTotalBill] = useState()
    // console.log("orderStatus ======<><>>", orderStatus);
    // console.log("newTotalBill ======<><>>", newTotalBill);
    useEffect(() => {
        if(startOrderTag === "startOrderTag"){
            setNewTotalBill( parseInt(startOrderAmount) )
        }else if(startOrderTag === "unPaidstartOrderTag"){
            setNewTotalBill( parseInt(startOrderAmount) )
        }
        else{
            setNewTotalBill(totalBills)
        }
    },[totalBills,startOrderAmount ])
    const listenWebsocket = async (randomNum) => {
        let ws = ""
        if (process.env.NODE_ENV === 'development') {
            ws = new WebSocket(`wss://dev.zed.business/notification/${randomNum}`);
        } else if (process.env.NODE_ENV === 'production') {
            ws = new WebSocket(`wss://portal.zed.business/notification/${randomNum}`);
        } else if (process.env.NODE_ENV === 'test') {
            //zed.swerri.io
            ws = new WebSocket(`wss://zed.swerri.io/notification/${randomNum}`);
        }
        console.log(ws, 'ws');

        // const ws = new WebSocket(`wss://dev.zed.business/notification/${invoiceNumber}}`);
        ws.onopen = () => {
            console.log('connected')
        }
        ws.onmessage = function (event) {
            const data = JSON.parse(event.data); // Parse the JSON string into an object


            if (data.status === "OK") {
                const message = data.message;
                console.log('Received message:', data);
                setSTKMessage(message)
                console.log(message, 'message')
                handleSuccessNotificationClick();

            } else if (data.status === "FAILED") {
                setSuccessNotificationOpen(false)
                const message = data.statusMessage;
                console.log('Error message:', data);
                setSTKMessage(message)
                console.log(message, 'message')
                setErrorShow({ state: true, message: message });
                setMpesaLoading(false);


            }

            // Handle incoming messages from the WebSocket server here.
        };
        ws.onclose = function (event) {
            console.log('WebSocket connection closed with code:', event.code);
            // Handle WebSocket connection close event here.
        };

        ws.onerror = function (error) {
            console.error('WebSocket error:', error);
            // Handle any WebSocket errors here.
        };

    }


    function checkOrderPaymentStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: `/api/order_payment_status`,
                body: {
                    pushyTransactionId: startOrderTag === "startOrderTag" ? startOrderId[0]: startOrderTag === "unPaidstartOrderTag" ? startOrderId[0] : randomNum,
                    businessNo: bsNo || localStorage.getItem("businessId")
                },
                token: XAuthorization
            }).then((data) => {
                console.log(data, 'order status')

                if (data.status === 200) {
                    setOrderStatus(data?.response?.data?.deficit)
                }
            })
        } catch (error) {

        }
    }

    useEffect(() => {
        checkOrderPaymentStatus()
    }, [])

    let [ object, setMpesaObject] = useState({})

    useEffect(() => {
        if(startOrderTag === "startOrderTag"){
            let object = { amount: amount || totalBills , phone: userPhone, orderID: startOrderId };
            setMpesaObject(object)
        }else if(startOrderTag === "unPaidstartOrderTag"){
            let object = { amount: amount || totalBills , phone: userPhone, orderID: startOrderId };
            setMpesaObject(object)
        }
        else {
            let object = { amount: amount || totalBills, phone: userPhone, orderID: orderids };
            setMpesaObject(object)
        }

    }, [userPhone, amount, totalBills])


    const handleClick = async () => {
        setMpesaLoading(true);
        if (object.phone == '') {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the phone number' })
            handleErrorNotificationClick();
        } else if (object.amount == '' || object.amount == 0) {
            setMpesaLoading(false);
            setErrorShow({ state: true, message: 'Please provide the amount ' })
            handleErrorNotificationClick();
        } else {
            try {
                HttpComponent({
                    method: "POST",
                    url: "/api/pushstk",
                    token: X_Authorization,
                    body: {
                        amount: object.amount,
                        phone: object.phone,
                        businessId:localStorage.getItem("businessID"),
                        orderIds: object.orderID,
                        type:"order"
                    }

                })
                    .then((data) => {
                        console.log(data, 'pay via mpesaa')
                        if (data.response.data.status === 200) {
                            // listenWebsocket(randomNum)
                            handleSuccessNotificationClick()

                        } else {
                            setMpesaLoading(false);
                            handleErrorNotificationClick();
                        }
                    })
                    .catch((e) => {
                        setMpesaLoading(false);
                        console.error(`Error Sending STK`, e.message);
                    });
            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();
            }

        }
    };
    console.log('totanewTotalBilllBills ======<><>', newTotalBill);
    const handleKcbPush = async () => {
       
        let object = { amount: kcbAmount || newTotalBill, phone: kcbphone, orderID: startOrderTag === "startOrderTag" ? startOrderId : orderids };
console.log('the object', object);
        if (kcbphone === '') {
            // alert('kindly add a phone number')
            setErrorShow({ state: true, message: 'kindly add a phone number' })
            setkcbloading(false)
        } else if (bankAccount === '') {
            setErrorShow({ state: true, message: 'kindly select  a bank account' })

            setkcbloading(false)
        } else if (object.amount === '' || object.amount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
            setkcbloading(false)
        } else {
            setkcbloading(true)
            try {
                HttpComponent({
                    method: 'POST',
                    url: "/api/v1/payments/initiate_kcb_stk_push",
                    body: {
                        amount: kcbAmount || newTotalBill,
                        phone: kcbphone,
                        orderIds: startOrderTag === "startOrderTag" ? startOrderId:checkedpushTransactionIds,
                        type: "order"
                    }
                }).then((data) => {
                    if (data.status === 200) {
                        handleSuccessNotificationClick();
                    }
                    else if (data.status === 400) {
                       
                        setErrorShow({ state: true, message: data.response.message })
                        setkcbloading(false)

                    }

                    else {
                        setErrorShow({ state: true, message: `Error in sending stk push!` })
                        setkcbloading(false)

                    }

                })
                    .catch((e) => {
                        setErrorShow({ state: true, message: `Error in sending stk push!` })
                        setkcbloading(false)
                       

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick();

            }

        }

    }

    const handleCardPayment = async () => {
        const obj = { email: email, amount: cardAmount || totalBills, orderID: orderids }
 
        if (obj.cardAmount === '' || obj.cardAmount === 0) {
            setErrorShow({ state: true, message: 'kindly add  a amount' })
        } else {
            try {
                HttpComponent({
                    method: 'POST',
                    url: "/api/v1/payments/card/pay",
                    body: {
                        amount: cardAmount || totalBills,
                        orderIds: checkedpushTransactionIds,
                        type: "order"
                    }
                }).then((data) => {
                    // console.log(data.response.data.data.data.invoiceInformation.paymentLink, 'Data');
                    console.log(data, 'Response data hapa');

                    if (data.status === 200) {

                        if (data.response.data.status === 500) {
                            console.log(data.response.data.error.message, 'Response data hapa');
                            console.log(' mesage arrr', data.response.data.error.message)
                            setErrorShow({ state: true, message: data.response.data.error.message })

                        } else if (data.response.data.status === 200) {
                            console.log("link data =>>>>>>>>>>>>", data.response.data.data);

                            handleSuccessNotificationClick1();
                            setGeneratedLink(true)
                            setGenerateLinkStatus(true)
                            setCardloading(true)

                            setDatagenerated(data.response.data.data.invoiceInformation.paymentLink)

                        }


                    } else if (data.status == '401') {
                        console.log(' mesage arrr', data.response.message)
                        setErrorShow({ state: true, message: data.response.message })
                    } else if (data.status == '400') {
                        console.log(' mesage arrr', 'Error here')
                        setErrorShow({ state: true, message: data.response.error })
                    } else {
                        handleErrorNotificationClick1();
                        console.log(' mesage arrr', 'Error here')
                        setErrorShow({ state: true, message: 'Erriirr  r' })
                    }

                })
                    .catch((e) => {
                        console.error(`Error Sending payment`, e.message);

                    })

            } catch (error) {
                console.error(error);
                handleErrorNotificationClick1();
                // console.log(' mesage arrr', 'Error here')
            }

        }
    }

    useEffect(() => {
        if (dataLINK === undefined) {
            console.log("hakuna data");
            setGeneratedLink(false)
        }

        if (dataLINK) {
            setGeneratedLink(true)
        }
    }, [dataLINK])

    const [errorShow, setErrorShow] = useState({ state: false, message: "" })

    function handleButtonClick(name) {
        setActiveButton(name)
    }

    useEffect(() => {
        setBussinessStkId(darajaConfigId)
    }, []);

    useEffect(() => {
        handleButtonClick(activeButton)
    }, [activeButton])

    const [counter, setCounter] = useState(65);

    useEffect(() => {
        let intervalId;

        if (mpesaLoading || kcbloading) {
            setCounter(65)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 1000);

            setTimeout(() => {
                clearInterval(intervalId);
                setMpesaLoading(false);
                setkcbloading(false)
                setCardloading(false)
                handleErrorNotificationTimeoutClick();
            }, 65000);
        }


        return () => clearInterval(intervalId);
    }, [mpesaLoading, kcbloading]);

    useEffect(() => {
        if (Cardloading) {
            let intervalId;

            setCounter(15)
            intervalId = setInterval(() => {
                setCounter((prevCounter) => prevCounter - 1);
            }, 3000);

            setTimeout(() => {

                setCardloading(false)
            }, 3000);
        }

    }, [Cardloading])


    useEffect(() => {
        if (successmessage) {
            setTimeout(() => {
                SetSuccessMessageCard(false)

            }, 1000)

        }

    }, [successmessage])
    useEffect(() => {

        if (intervalIdRef.current) {
            clearInterval(intervalIdRef.current);
        }

       
        if (mpesaLoading || kcbloading) {

            intervalIdRef.current =
                setInterval(() => {
                    checkOrderPaymentStatus();
                    // console.log("orderStatus =====<><>", orderStatus);
                    // console.log("orderStatus =====<><>", newTotalBill);
                    if (orderStatus < newTotalBill ) {

                        // console.log("amount to be ppaud ========<><>", orderStatus,totalBills );
                        // setBalance(orderStatus)
                        setMpesaLoading(false);
                        setkcbloading(false)
                        setCounter(0)
                        handleSuccessNotificationPaymentReceivedClick()
                            setTimeout(() => {
                                navigate(-1)
                            }, 3000);
                        clearInterval(intervalIdRef.current);
                        intervalIdRef.current = null;
                        setUserPhone('')
                        setAmount('')
                    }
                }, 1000)
            setTimeout(() => {
                clearInterval(intervalIdRef.current);
                intervalIdRef.current = null;
                handleErrorNotificationTimeoutClick()
                setMpesaLoading(false);
                setkcbloading(false)
            }, 65000);
        }
        return () => {
            clearInterval(intervalIdRef.current);
            intervalIdRef.current = null;
        };
    }, [mpesaLoading, kcbloading, totalBills, orderStatus, startOrderAmount]);


    const [bankAccountsKCB, setBankAccountsKCB] = useState([])
    const getListOfBankAccounts = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_bank_payment_listing`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()
                .then((response) => {
                    console.log('Bank Accounts hii no hapa', response.data);
                    setBankAccountsKCB(response.data)


                    // setBankAccount(response.data[0].accountNumber) // setBankAccount(response.data[0].accountNumber)
                })

        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getListOfBankAccounts()
    }, [])

    const [bankAccountKCB, setBankAccountKCB] = useState('')
    const [referenceNo, setReferenceNo] = useState('')

    const obj = { reference: referenceNo, creditAccount: bankAccountKCB, invoiceNumber: orderids }

    const handleBankSlip = async () => {

        console.log('Bank Slip', obj)
        if (obj.reference === '' || obj.creditAccount === '') {
            setErrorShow({ state: true, message: "Please fill in all fields" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        } else {
            try {
                const response = await fetch(baseUrl + `/api/v1/validate_kcb_bank_slip`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify(obj)
                })
                const data = response.json()
                    .then((response) => {
                        console.log('Bank Accounts hii no hapa', response.data);
                        if (response.status === 'success') {
                            navigate(-1)
                        } else {
                            setErrorShow({ state: true, message: response.message + `${response.data}` })
                            setTimeout(() => {
                                setErrorShow({ state: false, message: "" })
                            }, 3000)
                        }


                    })

            } catch (error) {
                console.log(error);
            }
        }

    }
    const [MpesaStatus, setMpesaStatus] = useState(false)
    const [KCBBankPaybillStatus, setKCBSBankPaybillstatus] = useState(false)
    const [CardStatus, setCardStatus] = useState(false)
    const [BankStatus, setBankStatus] = useState(false)
    console.log(BankStatus , 'BankStatus >>>>>>>>>>>>>>')


    const checkPayemntStatus = async () => {
        try {
            const response = await fetch(baseUrl + `/api/get_payment_methods_with_status`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            })
            const data = response.json()

                .then((response) => {
                     console.log('Bank Accounts hapa',response.data);

                    // console.log('Bank Accounts hapa', );
                    const mpesaStatus = response.data.find(method => method.name === "Mpesa")?.status;
                    const cardStatus = response.data.find(method => method.name === "Card")?.status;
                    const paybillStatus = response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[0]?.status
                    const bankStatus = response.data.find(method => method.name === "Banks")?.paymentOptions[0].kcb[2]?.status;
                    setMpesaStatus(mpesaStatus)
                    setCardStatus(cardStatus)
                    setKCBSBankPaybillstatus(paybillStatus)
                    setBankStatus(bankStatus)
                })

        } catch (error) {
            console.log(error);
        }

    }
    useEffect(() => {
        checkPayemntStatus()
    }, [])

    const [cashAmount, setCashAmount] = useState(0)

    const payViaCash = async () => {

        if (cashAmount === 0) {
            setErrorShow({ state: true, message: "Please fill in cash amount received" })
            setTimeout(() => {
                setErrorShow({ state: false, message: "" })
            }, 3000)
        }

        try {
            const response = await fetch(baseUrl + `/api/transactions`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify({
                    appBuildTime: "N/A",
                    billRefNo: Date.now(),
                    businessName: localStorage.getItem("businessName"),
                    businessNo: localStorage.getItem("businessId"),
                    businessShortCode: "0000",
                    cashier: localStorage.getItem("username"),
                    customerFirstName: 'N/A',
                    customerMiddleName: "N/A",
                    customerPhone: "N/A",
                    customerSecondName: "N/A",
                    items: productData?.length === 0 ? startOrderItems : productData ,
                    paybillBalance: 0,
                    discountAmount: 0,
                    paymentChanel: "Web",
                    productCategory: "--",
                    productName: "--",
                    pushyTransactionId: checkedpushTransactionIds?.length === 0 ? startOrderId : checkedpushTransactionIds ,
                    receiptNumber: Date.now(),
                    requestType: "N/A",
                    serialNo: " N/A",
                    transactionID: Date.now(),
                    transactionType: 'Cash Payment',
                    transamount: totalBills || cashAmount ,
                    transtime: new Date(Date.now()),
                    uploadTime: new Date(Date.now()),
                    userId: localStorage.getItem("userId"),
                    versionCode: "webv1",
                    versionName: "webv1",
                })
            })
            const data = await response.json();
            // console.log('data =========<><><>', data);
            if (data.Status === 'SUCCESS') {

                setSuccessNotificationReceivedPaymentOpen(true)
                setTimeout(() => {
                    setSuccessNotificationReceivedPaymentOpen(false)
                    navigate(-1)
                }, 3000)
            } else {
                setErrorShow({ state: true, message: "Error in making payments!" })
                setTimeout(() => {
                    navigate(-1)
                    setErrorShow({ state: false, message: "" })
                }, 3000)
            }
        } catch (error) {
            console.log(error)

        }
    }


    return (
        <div style={{width:"100%"}}>

            {/*Alerts*/}

            {/*Success Creation*/}
            <SuccessAlert message={'You will receive a prompt on your phone'} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'We have sent you an email kindly complete the transcation'} open={successNotificationOpen1} onClose={handleSuccessNotificationClose1} horizontal={'right'} vertical={'top'} />
            <SuccessAlert message={'Link copied successfully'} open={successNotificationOpen2} onClose={handleSuccessNotificationClose2} horizontal={'right'} vertical={'top'} />



            {/*Payment Received*/}
            <SuccessAlert message={`Payment received`} open={successNotificationReceivedPaymentOpen} onClose={handleSuccessNotificationReceivedPaymentClose} horizontal={'right'} vertical={'top'} />

            {/*error sending stk*/}
            <ErrorAlert message={`Error With Stk`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert message={`Error With Sending the payment`} open={errorNotificationOpen1} onClose={handleErrorNotificationClose1} horizontal={'right'} vertical={'top'} />


            {/*error TimeOut*/}
            <ErrorAlert message={StkMessage ? `${StkMessage}` : `Timeout On Awaiting Payment `} open={errorNotificationTimeoutOpen} onClose={handleErrorNotificationTimeoutClose} horizontal={'right'} vertical={'top'} />

            {/*Missing Business Config ID*/}
            <ErrorAlert message={`Missing Mpesa STK Config Id!`} open={errorNotificationNoBusinessConfigOpen} onClose={handleErrorNotificationNoBusinessConfigClose} horizontal={'right'} vertical={'top'} />

            <ErrorAlert
                vertical="top"
                horizontal="right"
                onClose={() => setErrorShow({ ...errorShow, state: false })}
                open={errorShow.state}
                message={errorShow.message} />

            {/*mainContainer*/}
            <Grid container direction={'row'} justifyContent={'space-between'} >

                {/*Labels*/}
                <Grid xs={12} sm={12} md={12} lg={4} item margin={'0px 0px 5% 0px'}>

                    {/*Label*/}
                    <Grid container mb={7}>
                        <Grid item>
                            <span style={{ color: '#032541', fontSize: '20px', fontWeight: '600' }} >Select Payment Method</span>
                        </Grid>
                    </Grid>

                    {/*Cards*/}
                    <Grid container direction={'column'} justifyContent={'flex-start'} spacing={7}>

                        {/*Mpesa Card*/}
                        {MpesaStatus ?
                            <Grid item width={'100%'}>
                                <ButtonBase onClick={() => { handleButtonClick('mpesa') }}>
                                    <Card style={{ background: `${activeButton === 'mpesa' ? 'rgba(23, 174, 123, 0.06)' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*mpesa image*/}
                                            <Grid item>
                                                <img src={Mpesa} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLeft: '15px' }} />
                                            </Grid>

                                            {/*Mpesa name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Mpesa</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}
                        {/* bank paybill */}
                        {KCBBankPaybillStatus ?
                            <Grid item width={"100%"}>
                                <ButtonBase onClick={() => { handleButtonClick('bankpaybill') }} >
                                    <Card style={{ background: `${activeButton === 'bankpaybill' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>

                                            {/*Card image*/}
                                            <Grid item>
                                                <img src={BankPayBillIcon} alt="BankPayBillIcon" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>

                                            {/*Card name*/}
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Paybill</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null}

                        {/*Card*/}
                        {/* {CardStatus ?
                            <Grid item>
                                <ButtonBase onClick={() => { handleButtonClick('card') }}>
                                    <Card style={{ background: `${activeButton === 'card' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                        <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                            <Grid item>
                                                <img src={CardImg} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                            </Grid>
                                            <Grid item style={{ marginTop: "30px" }}>
                                                <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Card</span>
                                            </Grid>

                                        </Grid>
                                    </Card>
                                </ButtonBase>
                            </Grid>
                            : null} */}
                        {/* RECEIVE PAYMENT */}

                        {/* <Grid item>
                            <span>Receive Payment</span>
                        </Grid> */}
                        {BankStatus ?
                            <>
                                <Grid item>
                                    <ButtonBase onClick={() => { handleButtonClick('bankSlip') }}>
                                        <Card style={{ background: `${activeButton === 'bankSlip' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                            <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                                {/*Card image*/}
                                                <Grid item>
                                                    <img src={BankSlip} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                                </Grid>
                                                {/*Card name*/}
                                                <Grid item style={{ marginTop: "30px" }}>
                                                    <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Bank Slip</span>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </ButtonBase>
                                </Grid>
                            </>
                            : null}
                        <Grid item>
                            <ButtonBase onClick={() => { handleButtonClick('cash') }}>
                                <Card style={{ background: `${activeButton === 'cash' ? '#f0f2f3' : '#fff'}`, height: '94px', width: '365px', boxShadow: '0 0 10px 0 rgba(0, 0, 0, 0.16)', borderRadius: '6px' }}>
                                    <Grid container direction={'row'} justifyContent={'flex-start'} alignContent={'center'} spacing={-2}>
                                        {/*Card image*/}
                                        <Grid item>
                                            <img src={CashIcon} alt="Mpesa" style={{ height: "32px", width: "100px", marginTop: "30px", marginLef: '0px' }} />
                                        </Grid>
                                        {/*Card name*/}
                                        <Grid item style={{ marginTop: "30px" }}>
                                            <span style={{ color: '#032541', marginLeft: '15px', fontSize: '18px', fontWeight: 600 }}>Cash</span>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </ButtonBase>
                        </Grid>


                    </Grid>

                </Grid>

                {/*Display Arena*/}
                {activeButton === 'mpesa' ?
                    <Grid item style={{ background: 'rgba(23, 174, 123, 0.06)', width: '60%', display: 'flex', flexShrink: 1, marginRight: '2.5%' }}>

                        <Grid container direction={'column'} ml={1} spacing={3}>

                            {/*Header lable*/}
                            <Grid item mt={5} >
                                <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order With Mpesa</span>
                            </Grid>

                            {/*Invoice card Information*/}
                            <Grid item>

                                <Grid container direction={'row'} spacing={2}>
                                    <Grid item style={{ width: '70%' }}>
                                        {/*PlyCard*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                            <Grid item>
                                                <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal</span>
                                            </Grid>
                                            {/* <Grid item>
                                                <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                    <Grid item style={{ width: '30%' }}>
                                        {/*Amount*/}
                                        <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                            <Grid item>
                                                <span style={{ color: '#dc3545', fontSize: '14px' }}>{numberFormat( startOrderTag === "startOrderTag" ? startOrderAmount : startOrderTag === "unPaidstartOrderTag" ? startOrderAmount  : totalBills)}</span>
                                            </Grid>
                                            {/* <Grid item>
                                                <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(invoiceBalance)}</span>
                                            </Grid> */}
                                        </Grid>
                                    </Grid>

                                </Grid>

                            </Grid>

                            {/*Mpesa Instruction*/}
                            <Grid item mt={4}>
                                <span style={{ fontSize: '14px' }} >Kindly enter your mobile number to receive the payment prompt and enter you M-Pesa Pin.</span>
                            </Grid>

                            {/*Mobile input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                </div>

                                <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
                            </Grid>

                            {/*Amount Input*/}
                            <Grid item>
                                <div style={{ marginBottom: '10px' }}>
                                    <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                </div>
                                <TextField defaultValue={totalBills} onChange={(e) => { setAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                            </Grid>

                            {/*Send prompt button*/}
                            <Grid item mt={10} mb={9}>
                                <Grid container justifyContent={'center'} alignContent={'center'}>
                                    <Grid item>
                                        {mpesaLoading ?
                                            <>
                                                <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                    endIcon={<SendIcon />}
                                                    loading={true}
                                                    loadingPosition="end"
                                                    variant="contained">
                                                    <span>Awaiting Payment</span>
                                                </LoadingButton>
                                                <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                    <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                        Resend Prompt: {counter}
                                                    </Typography>
                                                </Box>
                                            </>
                                            :
                                            <>
                                                <Button onClick={handleClick} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Pay Now</Button>
                                            </>
                                        }
                                    </Grid>
                                </Grid>

                            </Grid>

                        </Grid>

                    </Grid> : activeButton === 'card' ?

                        <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)'}}>

                            <Grid container direction={'column'} ml={1} spacing={3}>
                                {GenerteLink ? <>
                                    {/* {console.log('GenerteLink inside div',GenerteLink)} */}
                                    <div className="pt-5 mt-5">
                                        <div className="div-card-ready" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
                                            <img src={CARDREADY} alt="" />

                                            <div className="pt-3">
                                                <h5 style={{ fontSize: "20px", color: "#032541", textAlign: "center", fontWeight: "bold" }}>Your Payment Link is Ready</h5>
                                                <h4 style={{ fontSize: "14px", color: "#707070", textAlign: "center" }}>Open or Copy your payment link for </h4>
                                                <h4 style={{ fontSize: "14px", color: "#032541", textAlign: "center" }}>BillTotal</h4>
                                                <h4 style={{ fontSize: "16px", color: "#dc3545", textAlign: "center" }}>Balance  {numberFormat(totalBills)} </h4>
                                                <input style={{ display: "", opacity: "0" }} ref={textRef} type="text" value={dataLINK} readOnly />


                                                <div className="button-group" style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>

                                                    <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#032541", border: "2px solid #032541", }} onClick={handleCopy} >Copy Link</Button>
                                                    <a href={`${dataLINK}`}>
                                                        <Button style={{ height: "40px", marginTop: "19px", padding: "0px 20px", fontSize: "16px", fontWeight: "500", color: "#fff", backgroundColor: "#032541", border: "2px solid #032541" }} className="ml-3" onClick={() => {
                                                            window.href(`${dataLINK}`)
                                                        }} >Open LInk</Button>

                                                    </a>


                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </> : <>
                                    <>
                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Bill Total:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(totalBills)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                        {/*Mpesa Instruction*/}
                                        <Grid item mt={-2}>
                                            {/* <span style={{ fontSize: '14px' }} >Kindly enter your email address to receive the payment prompt </span> */}
                                        </Grid>

                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>EMAIL ADDRESS.</span>
                                            </div>
                                            <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" />


                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={email} onChange={(e) => setEmailAddress(e.target.value)} id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={invoiceBalance} onChange={(e) => { setCardAmout(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {/* 
                                                {GenerteLink ? <>
                                                    <Button style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >CLICK HERE TO PAY</Button>

                                                </> : <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate  Link</Button>

                                                </>} */}

                                                    {Cardloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting </span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Generating Link: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            {/* {GenerateLinkStatus  ?  <div className="d-flex">
                                                        <div className="d-flex justify-content-space-between">
                                                            <Link to={`${dataLINK}`}>
                                                        
                                                        <Button  style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Open LINK</Button>
                                                        </Link>
                                                        <Button onClick={() => { handleCardPayment(invoiceNumber) }} className="ml-2" style={{ width: '300px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </div>
                                                    </div> :
                                                <>
                                                <Button onClick={() => { handleCardPayment(invoiceNumber) }} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button> </> */}
                                                            {/* } */}

                                                            <Button onClick={handleCardPayment} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Generate Link</Button>

                                                        </>
                                                    }

                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </>
                                </>}



                            </Grid>

                        </Grid> : activeButton === 'bankpaybill' ?
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)'}}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Pay Order via Bank Paybill</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(startOrderTag === "startOrderTag" ? startOrderAmount : totalBills)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Bank </span>
                                            </div>

                                            {/* <SelectInput required inputStyle={{height : '55px', width : '500px'}} /> */}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}
                                                defaultValue={bankAccount}
                                                onChange={(e) => setBankAccount(e.target.value)}
                                                required>
                                                <MenuItem value={'522533'}>KCB</MenuItem>

                                            </TextField>
                                        </Grid>



                                        {/*Mobile input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Mobile no.</span>
                                            </div>

                                            <PhoneInput required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={kcbphone} onChange={(kcbphone) => setkcbphone(kcbphone)} />
                                        </Grid>

                                        {/*Amount Input*/}
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={startOrderTag === "startOrderTag" ? startOrderAmount : invoiceBalance} onChange={(e) => { setKcbAmount(e.target.value) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>

                                        {/*Send prompt button*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    {kcbloading ?
                                                        <>
                                                            <LoadingButton sx={{ width: "407px", backgroundColor: "#34A353", color: "white", '&:hover': { backgroundColor: '#34A353', color: 'white' } }}
                                                                endIcon={<SendIcon />}
                                                                loading={true}
                                                                loadingPosition="end"
                                                                variant="contained">
                                                                <span>Awaiting Payment</span>
                                                            </LoadingButton>
                                                            <Box sx={{ display: "flex", textAlign: "center", justifyContent: "center", width: "100%" }}>
                                                                <Typography sx={{ alignSelf: "center", marginLeft: "auto", marginRight: "auto" }}>
                                                                    Resend Prompt: {counter}
                                                                </Typography>
                                                            </Box>
                                                        </>

                                                        :
                                                        <>
                                                            <Button onClick={handleKcbPush} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Send Prompt</Button>
                                                        </>
                                                    }
                                                </Grid>
                                            </Grid>

                                        </Grid>

                                    </Grid>

                                </Grid>
                            </> : activeButton === 'bankSlip' ? <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)'}}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Bank Slip Validation</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(totalBills)}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '20px', }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Select    Banks </span>
                                            </div>
                                            {console.log(bankAccountsKCB, "bankAccountsKCB")}
                                            <TextField select label="Select Bank Name" id="demo-select-small" style={{ height: "55px", width: "500px", borderRadius: "1px", fontFamily: "Poppins", }}

                                                defaultValue={bankAccountKCB}
                                                onChange={(e) => setBankAccountKCB(e.target.value)}
                                                required>
                                                {bankAccountsKCB.map((option) => (
                                                    <MenuItem key={option.id} value={option.accountNumber} style={{ height: "40px", paddingLeft: "10px" }}>
                                                        {option.bankName} -  {option.accountNumber}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            {/* <CustomSelect required inputStyle={{ height: '55px', width: '500px' }} country={"ke"} enableSearch={true} value={bankAccountKCB} onChange={(bankAccountKCB) => setBankAccountKCB(bankAccountKCB)} /> */}
                                            {/* <TextField required InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} value={'EMAIL ADDRESS'} disabled id="outlined-basic" label="Email Address" variant="outlined" /> */}
                                        </Grid>
                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={referenceNo} onChange={(e) => { setReferenceNo(e.target.value) }} type='text' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Reference number" variant="outlined" required />
                                        </Grid>

                                        {/*VALIDATE  SLIP*/}
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={handleBankSlip} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Validate Slip</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : activeButton === 'cash' ? <>
                                <Grid item xs={12} sm={12} md={12} lg={8} style={{ background: 'rgba(23, 174, 123, 0.06)'}}>

                                    <Grid container direction={'column'} ml={1} spacing={3}>

                                        {/*Header lable*/}
                                        <Grid item mt={5} >
                                            <span style={{ fontStyle: '16px', fontWeight: '600', color: '#032541' }} >Receive via Cash</span>
                                        </Grid>

                                        {/*Invoice card Information*/}
                                        <Grid item>

                                            <Grid container direction={'row'} spacing={2}>
                                                <Grid item style={{ width: '70%' }}>
                                                    {/*PlyCard*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px', background: 'rgba(112, 112, 112, 0.05)' }}>
                                                        <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>BillTotal:</span>
                                                        </Grid>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#032541', fontWeight: 600, fontSize: '18px' }}>{invoiceNumber}</span>
                                                        </Grid> */}
                                                    </Grid>
                                                </Grid>

                                                <Grid item style={{ width: '30%' }}>
                                                    {/*Amount*/}
                                                    <Grid container direction={'column'} spacing={0.5} style={{ borderRadius: '10px' }}>
                                                        {/* <Grid item>
                                                            <span style={{ color: '#707070', fontSize: '14px' }}>Invoice Balance:</span>
                                                        </Grid> */}
                                                        <Grid item>
                                                            <span style={{ color: '#dc3545', fontWeight: 600, fontSize: '18px' }}>{numberFormat(totalBills || startOrderAmount )}</span>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>

                                            </Grid>

                                        </Grid>

                                        <Grid item>
                                            <div style={{ marginBottom: '10px' }}>
                                                <span style={{ fontSize: '12px', color: '#032541' }}>Amount.</span>
                                            </div>
                                            <TextField defaultValue={cashAmount || startOrderAmount} onChange={(e) => { setCashAmount( parseInt(e.target.value)) }} type='number' InputProps={{ style: { fontFamily: 'Poppins', background: '#fff' } }} InputLabelProps={{ style: { fontFamily: 'Poppins', fontSize: "13px", color: "#032541" } }} style={{ width: '500px', border: "solid 0px #e4e4e4", fontSize: "12px" }} id="outlined-basic" label="Amount" variant="outlined" required />
                                        </Grid>
                                        <Grid item mt={10} mb={9}>
                                            <Grid container justifyContent={'center'} alignContent={'center'}>
                                                <Grid item>
                                                    <Button onClick={payViaCash} style={{ width: '407px', background: "#032541", textTransform: 'none', color: '#fff', fontWeight: 'bold', fontSize: '14px' }} >Process</Button>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>

                                </Grid>

                            </> : null
                }
                {/*Mpesa payment*/}
                <PaymentSent successOpen={successOpen} onClose={() => setSuccessOpen(false)} />
            </Grid>

        </div>
    )
}
