import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Box, Grid, MenuItem, Typography, Button } from '@mui/material'
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
// import CustomInputLabel from '../CustomInputLabel'
// import CustomTextField from "../CustomTextField";
import HttpComponent from "../MakeRequest";
import { handleIsKCBBankDepositSetup, setKCBBankDepositStatus } from '../../../features/paymentOptionsSlice';
import { SuccessAlert } from '../../snackBar Alerts/successAlert';
import { ErrorAlert } from '../../snackBar Alerts/errorAlert';
// import CustomSelectField from "../CustomSelectField";

const baseUrl = process.env.REACT_APP_BASE_URL;

const KCBBankDeposit = ({ showKCBBankDepositSetup }) => {

    console.log('showKCBBankDepositSetup', showKCBBankDepositSetup)

    const [branch, setBranch] = useState('');
    const [accountName, setAccountName] = useState('');
    const [accountNo, setAccountNo] = useState('');
    const [branches, setBranches] = useState([]);
    const [message, setmessage] = useState('')
    const [ifError, setError] = useState(false)
    const [openSnack, setOpenSnack] = useState(false);
    const dispatch = useDispatch();

    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })




    const buttonStyles =
    {
        "width": "7.813rem",
        "height": "2.813rem",
        "borderRadius": "4px",
    }

    // saving bank deposit details
    const saveBankDepositDetails = async () => {
        console.log('businessName', branch, accountNo, accountName)
        if (branch === ' ') {
            // alert('Kindly select the bankname')
            setOpenSnack(true)
            setError(true)
            setmessage('paybill is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else if (accountNo === '') {
            setOpenSnack(true)
            setError(true)
            setmessage('till number is required')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);

        } else if (accountName === '') {
            setOpenSnack(true)
            setError(true)

            setmessage('till not verified')
            setTimeout(() => {
                setOpenSnack(false);
            }, 2000);
        } else {
            try {
                const response = await fetch(`${baseUrl}/api/add_bank_payment_details`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Accept: "application/json",
                        "X-Authorization": localStorage.getItem("X-Authorization"),
                    },
                    body: JSON.stringify({
                        accountName: accountName,
                        branchName: "ALL BRANCHES",
                        bankName: "KCB",
                        businessName: localStorage.getItem('businessName'),
                        accountNumber: accountNo,
                    }),
                })
                await response.json().then((data) => {
                    console.log('response', data)
                    setOpenSnack(true)
                    setError(false)
                    setmessage('Successfully added card details')
                    dispatch(setKCBBankDepositStatus(true));
                    dispatch(handleIsKCBBankDepositSetup(true));
                    setSuccessShow({ state: true, message: "Bank created successfully" })
                    setTimeout(() => {
                        window.location.reload()
                    }, [1000])



                })
            } catch (error) {
                console.log(error);
            }
        }
    }


    return (
        <>
            <>
                <Box sx={{ display: 'flex', flexDirection: "column", width: '100%', padding: '26px' }}>
                    <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                    <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
                    <form style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        {/* <FormControl style={{ width:'100%' }}>
            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px', marginLeft: '7px' }}>Branch</InputLabel>
            <TextField select style={{ height: "50px", width:'100%', marginTop:'40px', borderRadius: "1px", fontFamily: "Poppins", }}
                // defaultValue={branch}
                // onChange={changePayBills}
                placeholder='Select Branch'
                multiline
                required>

                {branches?.map((item, index) => (
                    <MenuItem key={index} value={item}>{item.bankName}</MenuItem>
                ))}
            </TextField>
          </FormControl> */}
                        <FormControl style={{ width: '100%', marginTop: '-5px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>Account Name</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }}
                                id="outlined-multiline-flexible"
                                value={accountName}
                                onChange={(e) => setAccountName(e.target.value)}
                                multiline
                                placeholder="e.g John Doe"
                                required
                            />
                        </FormControl>
                        <FormControl style={{ width: '100%', marginTop: '-5px' }}>
                            <InputLabel style={{ fontWeight: 'normal', fontSize: '14px' }}>Account Number</InputLabel>
                            <TextField style={{ width: "100%", marginTop: "40px" }}
                                id="outlined-multiline-flexible"
                                placeholder='e.g 1234567890'
                                value={accountNo}
                                onChange={(e) => setAccountNo(e.target.value)}
                                multiline
                                required
                            />
                        </FormControl>

                        <div className="foooter d-flex" style={{ display: "flex", justifyContent: "end", paddingRight: '0px', paddingBottom: '20px' }}>
                            <Box component="div" sx={{ width: "95%", display: "flex", justifyContent: "end", marginTop: '30px' }}>
                                <Box sx={{ display: 'flex', }}>
                                    {/* <Button sx={{
                                        ...buttonStyles,
                                        border: " solid 1px #002543",
                                        color: "#002543",
                                        '&:hover': {
                                            backgroundColor: "transparent",
                                            color: '#002543'
                                        }
                                    }}
                                        onClick={() => showKCBBankDepositSetup(false)}
                                    >
                                        Cancel
                                    </Button> */}

                                    <Button sx={{
                                        ...buttonStyles,
                                        marginLeft: 1,
                                        backgroundColor: " #032541",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#032541',
                                            color: 'white'
                                        }
                                    }}
                                        onClick={saveBankDepositDetails}
                                    >
                                        Save
                                    </Button>
                                </Box>
                            </Box>
                        </div>

                    </form>


                </Box>

            </>
            <>

            </>
        </>
    )
}

export default KCBBankDeposit;