import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import HttpComponent from './MakeRequest';
import { Box, Breadcrumbs, styled, Button, FormControl, Tab, Tabs, IconButton, InputBase, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import { ArrowBackIos, EditOutlined } from '@material-ui/icons';
import DeactivateUser from '../../common/images/deactivate-user.svg'
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import SearchIcon from "@mui/icons-material/Search";

import ExportMenu from './ExportMenu';
import { TabPanel, TabContext, TabList } from "@mui/lab";
import { DataGrid } from '@mui/x-data-grid';
import { NoRowsOverlay } from '../No Rows/noRowsOverlay';
import CustomSearchInput from "./CustomSearchInput";
import CustomSelectField from "./CustomSelectField";



let baseUrl = process.env.REACT_APP_BASE_URL;

const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"students"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Student Paid Invoices
    </Typography>
];
const AntTabs = styled(TabList)({ borderBottom: "0px solid #e8e8e8", "& .MuiTabs-indicator": { backgroundColor: "#f9f9f8" } });

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: { minWidth: 0 },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    fontSize: "13px",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "2.75",
    letterSpacing: "normal",
    textAlign: "left",
    color: "#6e7074",
    fontFamily: ["Poppins"].join(","),
    "&:hover": { color: "#032541", opacity: 1 },
    "&.Mui-selected": { color: "#dc3545", fontWeight: 600 },
    "&.Mui-focusVisible": { backgroundColor: "blue" },
}));

const StudentPaidInvoices = () => {
    const [tabvalue, setTabValue] = useState("active");
    const [allStudentData, setAllStudentData] = useState([])
    const [pageSize, setPageSize] = React.useState(10);
    const [otherData, setOtherData] = useState()
    const [grade, setGrade] = useState('')
    const [selectedYear, setSelectedYear] = useState('')
    const [stream, setStream] = useState('');
    const [courseName, setCourseName] = useState('');
    const [selectedSchoolType, setSelectedSchoolType] = useState('')
    const { schoolTypeName, schoolTypeId } = useSelector((store) => store.schoolType.schoolTypeDetail)

    //   console.log(pageSize);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: 100, });
    const navigate = useNavigate()
    const [gridLoading, setGridLoading] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const setNewTabValue = (prev, newValue) => {
        setTabValue(newValue);
    };
    const { userId, X_Authorization } = useSelector((store) => store.user);

    console.log(allStudentData, 'selectedYear')

    const fetchAllStudents = async () => {
        setPageState((old) => ({ ...old, isLoading: true }));
        try {
            HttpComponent({
                method: "GET",
                url: `/api/get_paid_invoices?page=${pageState.page}&limit=${pageSize}&classification=${selectedSchoolType}`,
                token: X_Authorization,
            }).then((data) => {
                console.log('data', data);
                setOtherData(data.response)
                setAllStudentData(data.response.data)
                setPageState((old) => ({
                    ...old,
                    isLoading: false,
                    data: data.response.data,
                    total: data.response.count,
                }));


            });

        } catch (error) {

        }
    }
    useEffect(() => {
        fetchAllStudents()
    }, [pageState.page, pageSize ,selectedSchoolType])
    const AllStudentscolumns = [
        {
            field: "invoiceNumber", headerName: "Invoice Number", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/invoice/${params.row.invoiceNumber}`)}>
                            {params.row.invoiceNumber}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "studentNumber", headerName: "Student Admission", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography sx={{ color: "#33699d", cursor: "pointer" }} >
                            {params.row.studentNumber}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "studentName", headerName: "student Name", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography >
                            {params.row.studentName}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "stream", headerName: "Stream ", flex: 1, renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.row.stream}

                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "grade", headerName: "Grade ", flex: 1, renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.row.grade}

                        </Typography>
                    </div>
                );
            }
        },



        {
            field: "invoiceAmount", headerName: "Invoice Amount", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.value}

                        </Typography>
                    </div>
                );
            },

        },


        {
            field: "invoiceDiscountAmount", headerName: "Discount Amount", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.value}

                        </Typography>
                    </div>
                );
            },

        },




    ]
    const AllStudentscolumnsCollege = [
        {
            field: "invoiceNumber", headerName: "Invoice Number", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography sx={{ color: "#33699d", textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/school/invoice/${params.row.invoiceNumber}`)}>
                            {params.row.invoiceNumber}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "studentNumber", headerName: "Student Admission", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography sx={{ color: "#33699d", cursor: "pointer" }} >
                            {params.row.studentNumber}
                        </Typography>
                    </div>
                );
            }
        },
        {
            field: "studentName", headerName: "student Name", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography >
                            {params.row.studentName}
                        </Typography>
                    </div>
                );
            }
        },
        // {
        //     field: "stream", headerName: "Stream ", flex: 1, renderCell: (params) => {
        //         return (
        //             <div>
        //                 <Typography variant="body2" color="textSecondary">

        //                     {params.row.stream}

        //                 </Typography>
        //             </div>
        //         );
        //     }
        // },
        {
            field: "grade", headerName: "Course ", flex: 1, renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.row.grade}

                        </Typography>
                    </div>
                );
            }
        },



        {
            field: "invoiceAmount", headerName: "Invoice Amount", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.value}

                        </Typography>
                    </div>
                );
            },

        },

        {
            field: "invoiceDiscountAmount", headerName: "Discount Amount", flex: 1,
            renderCell: (params) => {
                return (
                    <div>
                        <Typography variant="body2" color="textSecondary">

                            {params.value}

                        </Typography>
                    </div>
                );
            },

        },
    ]
    const AllStudentsrows = pageState?.data?.filter((item) => {
        return searchValue.toLowerCase() === '' ? item :
            item?.studentName.toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase()) ||
            item?.studentNumber.includes(searchValue)
        //toLocaleLowerCase().includes(searchValue?.toLocaleLowerCase())
    }).filter((item) => {
        return courseName === '' ? item : courseName === 'all' ? item : item?.grade?.includes(courseName)
    }).filter((item) => {
        return selectedYear === '' ? item : selectedYear === 'all' ? item : item?.year?.toLocaleLowerCase().includes(selectedYear?.toLocaleLowerCase())
    }).filter((item) => {
        return grade === '' ? item : grade === 'all' ? item : item?.grade?.includes(grade)
    }).filter((item) => {
        return stream === '' ? item : stream === 'all' ? item : item?.stream?.toLowerCase().includes(stream?.toLowerCase())
    }).map((item) => {
        return {
            id: item.invoiceId,
            customerId: item.customerId,
            invoiceNumber: item.invoiceNumber,
            studentNumber: item.studentNumber,
            studentName: item.studentName,
            grade: item.grade,
            stream: item.stream,
            pendingInvoices: item?.pendingInvoices,
            invoiceAmount: item.invoiceAmount,
            amountPaid: item?.amountPaid,
            year: item?.year,
            invoiceDiscountAmount:item?.invoiceDiscountAmount
        }
    })

    const navigateStudentPayments = (id) => {
        navigate(`/school/student/${id}`);
    }
    const [gradeOptions, setGradeOptions] = useState([])
    const [streamsOptions, setStreamesOptions] = useState([]);
    const [termOptions, setTermOptions] = useState([]);

    const GetGrades = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_grades?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Grades");

        if (response.status === 201) {
            setGradeOptions(data.data.map((itemGrade) => {
                return { value: itemGrade.schoolGrades, label: itemGrade.schoolGrades }
            }
            ))
        }
    };
    const GetStreams = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_streams`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setStreamesOptions(data.data.map((itemStream) => {
                return { value: itemStream.streamName, label: itemStream.streamName }
            }
            ))
        }
    };

    const GetStreamsTerms = async () => {
        const response = await fetch(`${baseUrl}/api/get_school_terms?schoolType_id=${schoolTypeId}`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        console.log(data, "Streams");
        if (response.status === 201) {
            setTermOptions(data.data.map((terms) => {
                return { value: terms.schoolGrades, label: terms.schoolGrades }
            }
            ))
        }
    };
    useEffect(() => {
        if (schoolTypeId) {
            GetGrades();
            GetStreams();
            GetStreamsTerms();
        }

    }, [schoolTypeId]);
    const [boardingStatusOptions, setBoardingStatusOptions] = useState([])
    const getBoardingStatus = async () => {
        const response = await fetch(`${baseUrl}/api/getboardingStatus`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
        });
        const data = await response.json();
        // console.log("here store is data",data);
        if (data.status === "SUCCESS") {
            console.log("here Boarding Status", data);
            setBoardingStatusOptions(data.data.map((boardingStatus) => {
                return { value: boardingStatus, label: boardingStatus }
            }
            ))
        } else {
            console.error("Error setting info")
            // setErrorShow({ state: true, message: "Error setting info" })
        }

    }
    useEffect(() => {
        getBoardingStatus()
    }, [])
    const [coursesOptions, setCoursesOptions] = useState([]);

    const getCourses = async () => {
        const response = await fetch(`${baseUrl}/api/v1/get_school_courses`, {
            headers: {
                "Content-Type": "application/json",
                "X-Authorization": X_Authorization,
            },
            method: "GET",
        });
        const data = await response.json();
        console.log(data, "Get Courses");
        if (response.status === 200) {
            // setCoursesOptions(data.data);
            setCoursesOptions(data.data.map((courses) => {
                return { value: courses.courseName, label: courses.courseName }
            }
            ))
        }
    };

    useEffect(() => {
        getCourses();
    }, []);

    const csvStudentColumns = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Student Admission", key: "Student Admission" },
        { label: "Student Name", key: "Student Name" },
        { label: "Grade", key: "Grade" },
        { label: "Stream", key: "Stream" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
    ]
    const csvStudentColumnsCollege = [
        { label: "Invoice No", key: "Invoice No" },
        { label: "Student Admission", key: "Student Admission" },
        { label: "Student Name", key: "Student Name" },
        { label: "Course", key: "Grade" },
        { label: "Invoice Amount", key: "Invoice Amount" },
        { label: "Amount Paid", key: "Amount Paid" },
    ]
    const newstudentFileData = allStudentData.map((item) => {
        return {
            "Invoice No": item.invoiceNumber,
            "Student Admission": item.studentNumber,
            "Student Name": item.studentName,
            "Grade": item.grade,
            "Stream": item.stream,
            "Invoice Amount": item.invoiceAmount,
            "Amount Paid": item.amountPaid,
        }
    })
    const newstudentFileDataCollege = allStudentData.map((item) => {
        return {
            "Invoice No": item.invoiceNumber,
            "Student Admission": item.studentNumber,
            "Student Name": item.studentName,
            "Course": item.grade,
            "Invoice Amount": item.invoiceAmount,
            "Amount Paid": item.amountPaid,
        }
    })


    const studentFileData = allStudentData.map((item) => {
        return {
            invoiceNumber: item.invoiceNumber,
            studentNumber: item.studentNumber,
            studentName: item.studentName,
            grade: item.grade,
            stream: item.stream,
            invoiceAmount: item.invoiceAmount,
            amountPaid: item.amountPaid,
        }
    })
    const studentFileDataCollege = allStudentData.map((item) => {
        return {
            invoiceNumber: item.invoiceNumber,
            studentNumber: item.studentNumber,
            studentName: item.studentName,
            Course: item.grade,
            invoiceAmount: item.invoiceAmount,
            amountPaid: item.amountPaid,
        }
    })

    const fileDataStudentHeaders = [["Invoice No", "Student Admission", "Student Name", "Grade", "Stream", "Invoice Amount", "Amount Paid",]];
    const fileDataStudentHeadersCollege = [["Invoice No", "Student Admission", "Student Name", "Course", "Invoice Amount", "Amount Paid",]];
    const fileDataStudentPDF = allStudentData.map((item) => [item?.invoiceNumber, item?.studentNumber, item?.studentName, item?.grade, item?.stream, item?.invoiceAmount, item?.amountPaid])
    const fileDataStudentPDFCollege = allStudentData.map((item) => [item?.invoiceNumber, item?.studentNumber, item?.studentName, item?.grade, item?.invoiceAmount, item?.amountPaid])
    const businessName = localStorage.getItem("businessName")
   

    // get school types
    const [allSchoolCategory, setAllSchoolCategory] = useState([])

    function GetType_school() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/config/school_classification`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setAllSchoolCategory(data?.response?.data)
                }
            })
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        GetType_school()
    }, [])

    return (
        <div>
            <div>
                <div>
                    <Box style={{ display: "flex", }}>
                        <Box style={{ display: "flex" }}>
                            <ArrowBackIos className='mr-2' />
                            <h1 style={{ color: "#032541", fontSize: "25px", fontWeight: "600" }}>Students</h1>
                        </Box>
                        <Box style={{}} className='ml-auto'>
                            <h4 style={{ color: "#17ae7b", fontWeight: "bold", fontSize: "30px" }}>{otherData?.totalAmountPaid}</h4>
                            <h4 style={{ color: " #111927", fontSize: "14px", fontWeight: "normal" }}>Paid Amount</h4>
                        </Box>
                    </Box>
                    <div className=''>
                        <Box component={'nav'} aria-label="breadcrumb">
                            <Breadcrumbs
                                separator={
                                    <FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />
                                }
                                aria-label="breadcrumb"
                            >
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>
                    </div>
                    <Box>
                        <TabContext value={tabvalue} index={0}>
                            <AntTabs variant="centered" onChange={setNewTabValue} aria-label="ant example">
                                <AntTab label="Active Students" value="active" />
                                <AntTab label="Suspended Students" value="suspended" />
                            </AntTabs>
                            {/* Search Tab */}
                            <Box sx={{ display: "flex", mt: 2, alignItems: "center", justifyContent: "space-between" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <CustomSearchInput value={searchValue} onChange={(e) => setSearchValue(e.target.value)} placeholder={'Search by Admission...'} sx={{ marginRight: "20px" }} />
                                    {schoolTypeName === 'Kindergarten/Junior/High School' ?
                                    <FormControl style={{ width: "200px", marginLeft: "10px", marginRight:"10px", height: "3.438rem", border: "solid 1px #cdd39d9", color: "#fff" }}>
                                        <InputLabel id="year-select-label">School Type</InputLabel>
                                        <Select
                                            labelId="year-select-label"
                                            id="year-select"
                                            value={selectedSchoolType}
                                            label="School Type"
                                            onChange={(e) => setSelectedSchoolType(e.target.value)}
                                        >
                                            <MenuItem value="">
                                                Select School
                                            </MenuItem>
                                            {allSchoolCategory.map((school) => (
                                                <MenuItem key={school} value={school}>
                                                    {school}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>:null}
                                    {schoolTypeName?.includes("University") ?
                                        <CustomSelectField value={courseName} style={{width:"200px"}} onChange={(e) => setCourseName(e.target.value)} name={"courseName"} placeholder={"select CourseName"} options={coursesOptions} sx={{ marginRight: "20px" }} />
                                        : <CustomSelectField style={{width:"200px"}} value={grade} onChange={(e) => setGrade(e.target.value)} name={"grade"} placeholder={"select Grade"} options={gradeOptions} sx={{ marginRight: "20px" }} />}

                                    {schoolTypeName?.includes("University") ?
                                        <CustomSelectField style={{width:"200px"}} value={selectedYear} onChange={(e) => setSelectedYear(e.target.value)} name={"grade"} placeholder={"select Year"} options={gradeOptions} sx={{ marginRight: "20px" }} />
                                        : <CustomSelectField style={{width:"200px"}} value={stream} onChange={(e) => setStream(e.target.value)} name={"stream"} placeholder={"select Stream"} options={streamsOptions} sx={{ marginRight: "20px" , width:"200px" }} />}
                                </div>

                                <div className='export ml-auto' >
                                    <ExportMenu
                                        csvColumns={schoolTypeName?.includes("University") ? csvStudentColumnsCollege : csvStudentColumns}
                                        fileData={schoolTypeName?.includes("University") ? newstudentFileDataCollege : newstudentFileData}
                                        fileHeaders={schoolTypeName?.includes("University") ? fileDataStudentHeadersCollege : fileDataStudentHeaders}
                                        fileDataPDF={schoolTypeName?.includes("University") ? fileDataStudentPDFCollege : fileDataStudentPDF}
                                        title={`${businessName} | Students Report  Paid Invoices | ${new Date()}`}
                                        fileName={'Student Report  Paid invoices'}

                                    />
                                </div>

                            </Box>


                            <TabPanel value="active">
                                <DataGrid style={{ height: "500px", width: "auto", overflow: "visible", }}
                                    rows={AllStudentsrows}
                                    components={{ NoRowsOverlay: NoRowsOverlay }}
                                    rowCount={pageState?.total}
                                    loading={pageState.isLoading}
                                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                                    pagination
                                    page={pageState.page - 1}
                                    pageSize={pageState.pageSize}
                                    paginationMode="server"
                                    columns={schoolTypeName?.includes("University") ? AllStudentscolumnsCollege : AllStudentscolumns}
                                    onPageChange={(newPage) => {
                                        setPageState((old) => ({
                                            ...old,
                                            page: newPage + 1,
                                            pageSize: pageSize,
                                        }));
                                    }}
                                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}

                                />


                            </TabPanel>
                            <TabPanel value="suspended">
                                <div className='row'>
                                    <div style={{ fontSize: "50rem", color: "grey" }}>
                                        <h1>Coming Soon</h1>

                                    </div>

                                </div>
                            </TabPanel>

                        </TabContext>
                    </Box>







                </div>
            </div>
        </div>
    )
}

export default StudentPaidInvoices