import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Input, TextField } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import loginLogo from "../../images/zedLogo.png";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import PhoneInput from "react-phone-input-2";
import "./phoneBootstrap.css";
import Button from "@mui/material/Button";
import { logInUser, logoutUser, changeToken } from "../../features/userSlice";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import loginImage from "../../common/images/login_image.svg";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";

const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  imgRes: {
    [theme.breakpoints.up("md")]: {},
    [theme.breakpoints.down("sm")]: {
      display: "none",
      alignItems: "center",
      width: "100%",
      objectFit: "contain",
      height: "100%",
    },
  },
  textf: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridLayout: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "4rem",
    },
  },
}));

const Signup = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  // Form Data
  const [firstName, setFirstName] = useState("");
  const [secondName, setSecondName] = useState("");
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [userPhone, setUserPhone] = useState("");

  // Notifications
  const [errorShow, setErrorShow] = useState({ state: false, message: "" });
  const [successShow, setSuccessShow] = useState({ state: false, message: "" });

  // handle login
  const submitHandler = async () => {
    const response = await fetch(baseUrl + "/api/addNewUser", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ firstName: firstName, secondName: secondName, userName: userName, userEmail: userEmail, userPhone: "+" + userPhone }),
    });
    let data = await response.json();

    if (data.error) {
      if (response.status === 400) {
        setErrorShow({ state: true, message: data.error });
      }
    } else {
      if (response.status === 201) {
        setSuccessShow({ state: true, message: 'Account creation successful!Please follow instructions sent to your email or in whatsApp to complete  the registration process.' });
        setTimeout(() => {
          
          navigate("/");
        }, 6000);
      }
    }
  };

  const handleNavigate = ()=> navigate('/signup')

  return (
    <Grid container justifyContent={"center"} style={{ fontFamily: "Poppins" }}>
      {/*Alerts*/}
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />

      {/*Header logo Container*/}
      <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        {/*Logo image*/}
        <Grid item>
          <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
        </Grid>
      </Grid>

      {/*login image and Form container*/}
      {/* <Grid container justifyContent={"center"} alignContent={"center"}> */}
      <Grid container justifyContent={"space-between"} direction={"row"} spacing={1} column={1} alignContent={"flex-start"} style={{ marginTop: "5%", paddingLeft: "5%" }}>
        {/*login image*/}
        <Grid item sm={12} lg={6} md={12} xl={6} xs={12}>
          <img src={loginImage} className={`img-fluid ${classes.imgRes}`} alt={"login image"} style={{ width: "50rem" }} />
        </Grid>

        {/*Signup Form*/}
        <Grid item style={{ marginTop: "5%", width: "500px" }} className={classes.gridLayout} sm={12} lg={6} md={12} xl={6} xm={12}>
          {/*Signup form*/}
          <Grid item display={'flex'} alignItems={'center'} mb={2}>
              <span onClick={handleNavigate} style={{ fontSize: "20px", cursor:"pointer", color: "#707070", marginLeft:'-10px', fontWeight: "600" }}>  <ArrowBackIosNewIcon style={{marginRight:'5px'}} /> Back</span>
            </Grid>
          <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>
            {/*login label*/}
            <Grid item>
              <span style={{ fontSize: "20px", color: "#032541", fontWeight: "600" }}>Create Account</span>
            </Grid>
          </Grid>
          {/*First and Last name*/}

          <Grid container direction={"row"} mb={4} mt={3} pr={3} spacing={{ xl: 4, lg: 4, sm: 3, xs: 3 }} justifyContent={"space-between"}>
            {/*First name*/}
            <Grid item lg={6} xl={6} xs={12} sm={12} md={12}>
              <TextField
                fullWidth
                className={classes.textf}
                value={firstName}
                onChange={(e) => {
                  setFirstName(e.target.value);
                }}
                type="text"
                InputProps={{ style: { fontFamily: "Poppins" } }}
                InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                id="outlined-basic"
                label="First Name"
                variant="outlined"
                required
              />
            </Grid>

            {/*Last name*/}
            <Grid item lg={6} xl={6} sm={12} xs={12} md={12}>
              <TextField
                fullWidth
                className={classes.textf}
                value={secondName}
                onChange={(e) => {
                  setSecondName(e.target.value);
                }}
                type="text"
                InputProps={{ style: { fontFamily: "Poppins" } }}
                InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                id="outlined-basic"
                label="Last Name"
                variant="outlined"
                required
              />
            </Grid>
          </Grid>
          {/*Username*/}
          <Grid container direction={"column"} mt={10} spacing={{ xl: 4, lg: 4, sm: 3, xs: 3 }} pr={3}>
            <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
              <TextField
                fullWidth
                value={userName}
                className={classes.textf}
                onChange={(e) => {
                  setUserName(e.target.value);
                }}
                type="text"
                InputProps={{ style: { fontFamily: "Poppins" } }}
                InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                id="outlined-basic"
                label="UserName"
                variant="outlined"
                required
              />
            </Grid>

            {/*Email*/}
            <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
              <TextField
                fullWidth
                className={classes.textf}
                value={userEmail}
                onChange={(e) => {
                  setUserEmail(e.target.value);
                }}
                type="email"
                InputProps={{ style: { fontFamily: "Poppins" } }}
                InputLabelProps={{ style: { fontFamily: "Poppins", fontSize: "13px", color: "#032541" } }}
                style={{ border: "solid 0px #e4e4e4", fontSize: "12px" }}
                id="outlined-basic"
                label="Email"
                variant="outlined"
                required
              />
            </Grid>

            {/*phone number input*/}
            <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
              <PhoneInput className={classes.textf} inputStyle={{ height: "55px" }} required country={"ke"} enableSearch={true} value={userPhone} onChange={(phone) => setUserPhone(phone)} />
            </Grid>
          </Grid>

          {/*Terms & Condition link*/}
          <Grid item lg={12} xl={12} sm={12} xs={12} md={12}>
            <Grid container justifyContent={"center"}>
              <Grid item style={{ fontSize: "11px" }}>
                <span>By Proceeding, you agree to Zed Payment's Terms of Service and Privacy Policy.</span>
              </Grid>
            </Grid>
          </Grid>

          {/*Login button*/}
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%" }}>
            <Button onClick={submitHandler} style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
              Create Account
            </Button>
          </Grid>

          {/*New user Signup*/}
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%", fontSize: "15px", color: "#032541" }}>
            <span>
              Already have an account?{" "}
              <Link style={{ fontWeight: "bold", textDecoration: "none" }} to={"/"}>
                Login
              </Link>
            </span>
          </Grid>
          {/* </Grid> */}
        </Grid>

        {/*fake Columns*/}
        <Grid item></Grid>
        <Grid item></Grid>
        <Grid item></Grid>
        {/* </Grid> */}
      </Grid>
    </Grid>
  );
};

export default Signup;
