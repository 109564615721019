import { Box, Breadcrumbs, Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from "react-router-dom";
import DateFormatter from "../../../utils/dateFormatter";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../../No Rows/noRowsOverlay";
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";
import CustomSearchInput from "../CustomSearchInput";
import ExportMenu from "../ExportMenu";

const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? "KES" : localCurrency, }).format(value);

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>DashBoard</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Bank Statements</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Upload File</Typography>,
    <Typography key={2} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Preveiw Bank Statement</Typography>,
    <Typography key={2} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Transactions</Typography>
]

export default function BankSlipDrillDown() {

    //navigate
    const navigate = useNavigate();
    const [searchValue ,setSearchValue] = useState('')
    const [dataGridPageSize, setPageSize] = useState(10);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })
    const queryParams = decodeURIComponent(window.location.search)
    const stateDate = queryParams.slice(1)
    const state = stateDate.split("?")[1]
    const balance = stateDate.split("?")[3]
    const refencePara = stateDate.split("?")[4]
    const accountNu = stateDate.split("?")[5]
    const bankNam = stateDate.split("?")[6]
   

    //preview bank transactions used
    // /api/v1/getBankSlipInvoiceTransactions?

    function getBankStatements(){
        setPageState((old) => ({ ...old, isLoading: true }));
        try{
            HttpComponent({
                method:'GET',
                url:`/api/v1/getBankSlipInvoiceTransactions?bankSlipId=${state}&accountNumber=${accountNu}&page=${pageState.page}&limit=${dataGridPageSize}`,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                //console.log(data , 'pagestatttttttttttt')
                if(data.status === 200){
                    setPageState({ ...pageState, isLoading: false, total: data?.response?.count, data: data.response.data })
                }
            })

        }catch(error){
            console.log(error)
        }

    }

    useEffect(()=>{
        getBankStatements()
    },[searchValue,state ,dataGridPageSize])


    const preveiwColumns = [
        { field: 'Receipt No', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Receipt No</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.receiptNo}</span></>) } },
        { field: 'TXN Type', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >TXN Type</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.transactionType}</span></>) } },
        { field: 'Amount', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Amount</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{numberFormat(params?.row?.amount)}</span></>) } },
        { field: 'User', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >User</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.name === '' ? 'N/A':params?.row?.name }</span></>) } },
        { field: 'Date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{DateFormatter(params?.row?.transactionTime)}</span></>) } },
        { field: 'Description', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Description</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.description}</span></>) } },
    ]

    const filedata = pageState?.data?.map((data) => {
        return {
            "Receipt No": data?.receiptNo,
            "TXN Type": data?.transactionType,
            "Amount":numberFormat(data?.amount),
            "User": data?.name,
            "Date":DateFormatter(data?.transactionTime),
            "Description":data?.description,
        }
    })
    const fileHeaders = [["Receipt No", "TXN Type", "Amount", "User", "Date" , "Description"]]
    const csvColumns = [
        { label: "Receipt No", key: "Receipt No" },
        { label: "TXN Type", key: "TXN Type" },
        { label: "Amount", key: "Amount" },
        { label: "User", key: "User", },
        { label: "Date", key: "Date", },
        { label: "Description", key: "Description", },
    ];

    const fileDataPDF = pageState?.data?.map((data) => [data?.receiptNo, data?.transactionType,numberFormat(data?.amount),data?.name, DateFormatter(data?.transactionTime), data?.description]);



    return (
        <Grid direction={'column'} display={'flex'} container>
            <Grid item display={'flex'} alignContent={'center'} alignItems={'center'}>
                <ArrowBackIosIcon style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
                <Typography style={{ fontWeight: 600, fontSize: "25px", color: "#032541" }}>{refencePara}</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Grid container display={'flex'} alignItems={'center'} justifyContent={'space-between'} alignContent={'center'}>
                    <Grid item>
                        <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                            {breadcrumbs}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item mt={2} display={'flex'} height={'80px'} style={{backgroundColor:'#fafafa'}} justifyContent={'space-between'} alignItems={'center'}>
                <Box>
                    <Typography sx={{fontSize:'18px' , color:'#707070'}}>Slip Number</Typography>
                    <Typography sx={{fontSize:'14px' ,color:'#032541'}}>{refencePara}</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:'18px' , color:'#707070'}}>BankName</Typography>
                    <Typography  sx={{fontSize:'14px' ,color:'#032541'}}>{bankNam}</Typography>
                </Box>
                <Box>
                    <Typography sx={{fontSize:'18px' , color:'#707070'}}>Balance</Typography>
                    <Typography  sx={{fontSize:'14px' ,color:'#032541'}}>{numberFormat(balance)}</Typography>
                </Box>
            </Grid>
            <Grid item mt={2} display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                <CustomSearchInput  name={'search'} placeholder={'search :'} value={searchValue} onChange={(e)=>setSearchValue(e.target.value)}/>
                <ExportMenu csvColumns={csvColumns} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} fileData={filedata} fileName={'Bank Statements'} title={'Bank Statements'}/>
            </Grid>
            <Grid item mt={2}>
                <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '500px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState?.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={preveiwColumns}
                    rows={pageState?.data}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}