import HttpComponent from "../../School/MakeRequest";

export default class startOrderFunction {
  constructor() {}

  filterTransactionByDateAndType(startDate, endDate, status) {
    return HttpComponent({
      method: `POST`,
      url: `/api/fetchBillsByDate/?status=${status}`,
      body: { dateList: [startDate, endDate] },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getDetailedBill(id) {
    return HttpComponent({
      method: "POST",
      url: `/api/order_payment_status`,
      body: {
        pushyTransactionId: id,
        businessNo: localStorage.getItem("businessId"),
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  requestToVoidBill(transId, comments) {
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "request",
        comments: comments,
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  voidTransactions(transId) {
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "approve",
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  cancelVoidTransactions(transId){
    return HttpComponent({
      method: "POST",
      url: `/api/v1/payments/void`,
      body: {
        transactionId: transId,
        action: "decline",
      },
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getBillByTransactionID(transId){
    return HttpComponent({
      method: "GET",
      url: `/api/v1/payments/by-transaction-id?transactionId=${transId}`,
      body: null,
      token: localStorage.getItem("X-Authorization"),
    });
  }

  getBillsByStatus(status, page, limit){
    return HttpComponent({
      method:'GET',
      url:`/api/fetchByStatus/?status=${status}&page=${page}&limit=${limit}`,
      token:localStorage.getItem("X-Authorization")
    })
  }
}
