import React, { useEffect, useState } from "react";
import Dash from "../../common/dash";
import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import Box from "@mui/material/Box";
import Form from "react-bootstrap/Form";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import moment from "moment-timezone";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Modal from "@mui/material/Modal";
import "bootstrap/dist/css/bootstrap.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "./react-bootstrap-table2.min.css";
import "./bootstrap.css";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { SessionModal } from "../Modals/SessionExpiredModal/sessionModal";
import axios from "axios";
import ExportMenu from "../School/ExportMenu";
import CustomSearchInput from "../School/CustomSearchInput";

const baseUrl = process.env.REACT_APP_BASE_URL;
const localCurrency = localStorage.getItem('localCurrency')

// Number Format
const numberFormat = (value) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: localCurrency,
  }).format(value);

// OpenTable Modal Styling
const modalTableStyle = {
  transform: "translate(-50%, -50%)",
  position: "absolute",
  top: "50%",
  left: "50%",
  width: "900px",
  height: "350px",
  padding: "0 0 73.9px",
  borderRadius: "15px",
  backgroundColor: "#fff",
};

const columns = [
  {
    field: "transactionID",
    headerName: "Receipt No",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Receipt No "}
      </strong>
    ),
  },
  {
    field: "businessName",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"BusinessName"}
      </strong>
    ),
  },
  {
    field: "items",
    headerName: "Units",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Units"}
      </strong>
    ),
    renderCell: (params) => {
      // modal config
      const [tableModal, setTableModal] = useState(false);
      const handleTableOpen = () => setTableModal(true);
      const handleTableClose = () => setTableModal(false);

      // pagination config
      const pagination = paginationFactory({
        page: 1,
        hideSizePerPage: true,
        sizePerPage: 6,
        lastPageText: ">>",
        firstPageText: "<<",
        nextPageText: ">",
        prePageText: "<",
        showTotal: true,
        alwaysShowAllBtns: false,
        onPageChange: function (page, sizePerPage) {
          console.log("page", page);
          console.log("sizePerPage", sizePerPage);
        },
        onSizePerPageChange: function (page, sizePerPage) {
          console.log("page", page);
          console.log("sizePerPage", sizePerPage);
        },
      });

      // columns for the row data
      const internalColumns = [
        { dataField: "reciptNumber", text: "Receipt No.", sort: true },
        { dataField: "itemName", text: "Item Name", sort: true },
        { dataField: "itemCategory", text: "Item Category", sort: true },
        { dataField: "itemAmount", text: "Item Price", sort: true },
        { dataField: "itemCount", text: "Units", sort: true },
        { dataField: "totalAmount", text: "Total", sort: true },
      ];

      return (
        <div>
          {/*button*/}
          <Button onClick={handleTableOpen} sx={{ fontFamily: "Poppins", fontWeight: 600, color: "#06a3ab" }}>
            {params.row.items.length <= 0 ? "N/A" : params.row.items.length}
          </Button>

          {/*modal with table*/}
          <Modal open={tableModal} onClose={handleTableClose}>
            <Box sx={modalTableStyle}>
              <Grid
                sx={{
                  width: "auto",
                  height: "50px",
                  padding: "13px 30px 3px",
                  borderRadius: "0px",
                  backgroundColor: "#dc3545",
                }}
              >
                <span
                  style={{
                    width: "auto",
                    height: "2px",
                    margin: "0 340px 0 0",
                    fontFamily: "Poppins",
                    fontSize: "15px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#fff",
                  }}
                >
                  Product Details For Receipt {params.row.transactionID}
                </span>
              </Grid>
              <BootstrapTable bootstrap4 keyField="id" data={params.row.items} columns={internalColumns} pagination={pagination} />
            </Box>
          </Modal>
        </div>
      );
    },
  },
  {
    field: "transactionType",
    headerName: "Payment Type",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Payment Type "}
      </strong>
    ),
  },
  {
    field: "paymentChanel",
    headerName: "Payment Device",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Payment Device "}
      </strong>
    ),
  },
  {
    field: "serialNo",
    headerName: "Device Serial",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Serial Number"}
      </strong>
    ),
  },
  {
    field: "cashier",
    headerName: "Terminal User",
    flex: 1,
    align: "left",
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Terminal User"}
      </strong>
    ),
  },
  {
    field: "transtime",
    headerName: "Date",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return moment(params.row.transtime).tz("Africa/Banjul").format("llll");
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Date"}
      </strong>
    ),
  },
  {
    field: "transamount",
    headerName: "Amount",
    flex: 1,
    align: "left",
    valueGetter: (params) => {
      return numberFormat(params.row.transamount || 0);
    },
    renderHeader: () => (
      <strong
        style={{
          fontFamily: "Poppins",
          fontSize: "10px",
          fontWeight: "600",
          color: "#032541",
        }}
      >
        {"Amount"}
      </strong>
    ),
  },
];

const Reports = () => {
  // page size setter and getter
  const [pageSize, setPageSize] = React.useState(10);

  //add days to current date
  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  // session Modal Config
  const [sessionOpen, setSessionOpen] = React.useState(false);
  const handleSessionClose = () => setSessionOpen(false);
  const handleSessionOpen = () => setSessionOpen(true);

  //date range Filter setters and Getters
  const [dValue, onDValueChange] = useState([moment(new Date()).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);

  // Transactions Setters and Getters
  const [transactions, setTransactions] = useState([]);

  //const dp open state
  const [isOpen, setIsOpen] = useState(false);

  //chart dropDown
  const [dropDown, setDropDown] = useState("7days");

  // Transaction typesetters and Getters
  const [transactionType, setTransactionType] = useState([]);

  // payment Type setter and getter
  const [paymentType, setPaymentType] = useState("All");

  // Filter data grid by transaction type using select input
  const [filteredData, setFilteredData] = useState([]);
  console.log(filteredData , 'filteredData')

  //const
  const [searchValue, setSearchValue] = useState("");

  //fetch transactions
  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const res = await axios.get(baseUrl + `/viewAllTransactions`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            "X-Authorization": localStorage.getItem("X-Authorization"),
          },
        });
        if (res.status === 200) {
          setTransactions(res.data.data);
        } else if (res.status === 401) {
          handleSessionOpen();
        }
      } catch (e) {
        console.log(e);
      }
    };
    fetchTransactions();
  }, []);

  // Currency Formatter
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const rowData = transactions.map((transaction) => {
    return {
      id: transaction._id,
      businessNo: transaction.businessNo,
      businessName: transaction.businessName,
      businessShortCode: transaction.businessShortCode,
      transactionID: transaction.transactionID,
      transactionType: transaction.transactionType,
      transtime: transaction.transtime,
      uploadTime: transaction.uploadTime,
      customerFirstName: transaction.customerFirstName,
      customerMiddleName: transaction.customerMiddleName,
      customerPhone: transaction.customerPhone,
      transamount: transaction.transamount,
      serialNo: transaction.serialNo,
      cashier: transaction.cashier,
      productName: transaction.productName,
      productCategory: transaction.productCategory,
      paymentChanel: transaction.paymentChanel,
      items: transaction.items,
    };
  });

  // Fetch Payment Types from DB
  const fetchTransactionTypes = async () => {
    try {
      const response = await fetch(baseUrl + "/api/transactionType", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      const data = await response.json();
      if (response.status === 200 && data.Status === "SUCCESS") {
        setTransactionType(data.data);
      } else if (response.status === 401) {
        handleSessionOpen();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    const doFetchTransactionTypes = async () => {
      await fetchTransactionTypes();
    };
    doFetchTransactionTypes().then(() => console.log(transactionType, "check"));
  }, []);

  const paymnentTypes = transactionType.map((type) => {
    return type._id.transactionType;
  });

  const handleChange = (event) => {
    setPaymentType(event.target.value);
  };

  const handleSearchBox = (event) => {
    setSearchValue(event.target.value);
  };

  // filters
  // date range filter
  const filterByDateRange = (data) => {
    return data.filter((item) =>
      dropDown === "range"
        ? item.transtime >= moment(new Date(dValue[0]).toDateString()).format("YYYY-MM-DD") && item.transtime <= moment(new Date(dValue[1]).toDateString()).format("YYYY-MM-DD")
        : item.transtime >= dValue[0] && item.transtime <= dValue[1]
    );
  };

  const filterByPaymentType = (data) => {
    return data.filter((item) => {
      if (paymentType === "All") {
        return item;
      } else if (item.transactionType === paymentType.includes(paymnentTypes)) {
        return item;
      } else if (item.transactionType === paymentType) {
        return item;
      }
    });
  };

  const filterBySearchBox = (data) => {
    return data.filter((item) => {
      if (
        item.businessNo.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.businessName.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.transactionID.toLowerCase().includes(searchValue.toLowerCase()) ||
        item.serialNo.toLowerCase().includes(searchValue.toLowerCase())
      ) {
        return item;
      }
    });
  };

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [transactionType]);

  useEffect(() => {
    let filteredData = rowData;

    filteredData = filterByDateRange(filteredData);
    filteredData = filterByPaymentType(filteredData);
    filteredData = filterBySearchBox(filteredData);
    setFilteredData(filteredData);
  }, [dValue, paymentType, searchValue]);

  //use Effect
  useEffect(() => {
    if (dropDown === "Today") {
      onDValueChange([moment(new Date()).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "YesterDay") {
      onDValueChange([moment(addDays(new Date(), -1)).format("YYYY-MM-DD"), moment(addDays(new Date(), 0)).format("YYYY-MM-DD")]);
    } else if (dropDown === "7days") {
      onDValueChange([moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "this Month") {
      onDValueChange([moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"), moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD")]);
    } else if (dropDown === "last Month") {
      onDValueChange([
        moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).format("YYYY-MM-DD"),
        moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"),
      ]);
    } else if (dropDown === "range") {
      setIsOpen(true);
      handleRange();
      onDValueChange([
        moment(new Date(new Date().getFullYear(), new Date().getMonth() - 1)).format("YYYY-MM-DD"),
        moment(new Date(new Date().getFullYear(), new Date().getMonth())).format("YYYY-MM-DD"),
      ]);
    }
  }, [dropDown]);

  // handle delay
  function delayChange() {
    setDropDown("Today");
  }

  // handle drop down change
  const dropChange = (event) => {
    setDropDown(event.target.value);
  };

  // handle custom range
  const handleRange = () => {
    const x = document.getElementById("rangeDiv");
    if (x.style.display === "none") {
      x.style.display = "flex";

      // work on open calendar
    } else if (x.style.display === "flex") {
      x.style.display = "none";
      setIsOpen(false);
      setTimeout(delayChange, 5000000);
    } else {
      x.style.display = "none";
    }
  };

  // CSV Export Format
  const csvColumns = [
    { label: "Receipt No.", key: "transactionID" },
    { label: "Product Category", key: "productCategory" },
    { label: "Product Name", key: "productName" },
    { label: "Payment Mode", key: "paymentChanel" },
    { label: "Terminal User", key: "cashier" },
    { label: "Date", key: "transtime" },
    { label: "Amount", key: "transamount" },
  ];
  const headers = [["Receipt No", "Product Category", "Product Name", "Payment Mode", "Terminal User", "Date", "Amount", "Item"]];

  const data = filteredData.map((data) => [data.transactionID, data.productCategory, data.productName, data.paymentChanel, data.cashier, moment(data.transtime).format("llll"), numberFormat(data.transamount)]);
  const fileDataPdf = filteredData.map((transaction) => {
    return {
      "Receipt No": transaction.transactionID,
      "productCategory": transaction.productCategory,
      "product Name": transaction.productName,
      "Payment Mode": transaction.paymentChanel,
      "Terminal User": transaction.cashier,
      "Date" : transaction.transtime,
      "Amount": transaction.transamount,
    };
  });

  // CSV Export
  const csvReport = {
    data: filteredData,
    headers: csvColumns,
    filename: paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.csv`,
  };

  // columns selection for Excel Export
  const pdfData = filteredData.map((transaction) => {
    return {
      ReceiptNo: transaction.transactionID,
      productName: transaction.productName,
      productCategory: transaction.productCategory,
      PaymentMode: transaction.paymentChanel,
      TerminalUser: transaction.cashier,
      Date: transaction.transtime,
      Amount: transaction.transamount,
    };
  });

  //Excel Export Format
  const xlsxReport = (e, data) => {
    console.log(data);
    e.preventDefault();
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");

    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.xlsx`);
  };

  //PDF export Format
  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(12);

    const title = paymentType.toUpperCase() + ` SALE REPORT FROM ${dValue[0]} TO ${dValue[1]}`;
    const headers = [["Receipt No", "Product Category", "Product Name", "Payment Mode", "Terminal User", "Date", "Amount", "Item"]];

    const data = pdfData.map((data) => [data.ReceiptNo, data.productCategory, data.productName, data.PaymentMode, data.TerminalUser, moment(data.Date).format("llll"), numberFormat(data.Amount)]);
    console.log(data);

    let content = {
      startY: 50,
      head: headers,
      body: data,
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(paymentType.toLowerCase() + `_${dValue[0]}-${dValue[1]}_report.pdf`);
  };

  return (
    <div>
      <div>
        <div>
          <div>
            {/*sessionModal*/}
            <SessionModal onClose={handleSessionClose} sessionOpen={sessionOpen} />

            <Grid container sx={{ flex: 1 }}>
              <Grid item>
                <label
                  style={{
                    width: "100%",
                    height: "35px",
                    margin: "10px 32px 21px 12px",
                    fontFamily: "Poppins",
                    fontSize: "25px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.32",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#dc3545",
                  }}
                >
                  Product Sales Report
                </label>
              </Grid>
            </Grid>

            <Grid container sx={{ margin: "0px 0px 20px 0px" }}>
              <Grid item>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  Dashboard
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#6e7074",
                  }}
                >
                  \
                </span>
                <span
                  style={{
                    width: "265px",
                    height: "28px",
                    margin: "21px 0px 39px 12px",
                    fontFamily: "Poppins",
                    fontSize: "20px",
                    fontWeight: "600",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "1.65",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#032541",
                  }}
                >
                  Product Sales Report
                </span>
              </Grid>
            </Grid>
            <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
              <Grid item display={'flex'} alignItems={'center'}>
                <Grid container spacing={3} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Grid item mt={1}>
                    <CustomSearchInput name={'search'} value={searchValue} onChange={handleSearchBox} placeholder={'Search here'} />
                  </Grid>
                  <Grid item>
                    <FormControl style={{width:"200px"}}>
                      <InputLabel id="demo-simple-select-label">Payment Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={paymentType}
                        onChange={handleChange}
                        label="Payment Type"
                      >
                        <MenuItem value="All">All</MenuItem>
                        {transactionType.map((type) => (
                          <MenuItem sx={{ fontFamily: "Poppins", fontSize: "12px" }} value={type._id.transactionType} key={Math.floor(Math.random() * 1000000000) + 1}>
                            {type._id.transactionType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl style={{width:"200px"}}>
                      <InputLabel id="demo-simple-select-label">Date Range</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={dropDown}
                        onChange={dropChange}
                        label="Date Range"
                      >
                        <MenuItem value="Today">Today</MenuItem>
                        <MenuItem value="YesterDay">Yesterday</MenuItem>
                        <MenuItem value="7days">Last 7 Days</MenuItem>
                        <MenuItem value="this Month">This Month</MenuItem>
                        <MenuItem value="last Month">Last Month</MenuItem>
                        <MenuItem value="range">Custom range</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <ExportMenu csvColumns={csvColumns} fileHeaders={headers} fileDataPDF={data} fileData={fileDataPdf} fileName={'Product Sales Report'} title={'Product sales Report'}  />
              </Grid>
            </Grid>
            <Grid
                  sx={{
                    height: "500px",
                    margin: "50px 0 0 0",
                    border: "solid 0px transparent",
                    backgroundColor: "#fff",
                  }}
                >
                  <DataGrid
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "10px",
                      color: "#272d3b",
                    }}
                    rows={filteredData}
                    columns={columns}
                    pageSize={pageSize}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    pagination
                  ></DataGrid>
                </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
