import React, { useState, useEffect } from "react";
import { Box, Breadcrumbs, Button, List, ListItem, MenuItem, Tab, Typography, styled } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import CustomSearchInput from './CustomSearchInput';
import Switch from '@mui/material/Switch';
import Modal from "@mui/material/Modal";
import ZedaccImg from '../../images/zedacc.svg'
import EnableSmallIcon from "../../components/School/Images/path-25436-2.svg";
import EnableBigIcon from "../../components/School/Images/path-25436.svg";
import userIcon from "../../components/School/Images/userIcon.svg";
import warehouseIcon from "../../components/School/Images/warehouse-icn.svg";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import HttpComponent from "./MakeRequest";
import PayitImg from "../../images/payit.svg"
import axios from "axios";
import jwtDecode from "jwt-decode";
import { switchWarehouseState } from "../../features/sideBarSlice";
import StockFunctions from "../products/stockComponents/stockEndpoints";
import { useDispatch } from "react-redux";
import SchoolGradeAutogenerateConfigSetUp from "./studentpromotion/SchoolGradesAutogconfig";
import { Link, useNavigate } from "react-router-dom";


const breadcrumbs = [
    <Typography key="X" sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"Invoices"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Configurations
    </Typography>
];

const label = { inputProps: { 'aria-label': 'Switch demo' } };
const style = { borderRadius: "36px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 4, };
const zedAccountingmodal = {
    borderRadius: "10px", backgroundColor: "#fff", position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "600px", height: "390px", p: 2,
}



const baseUrl = process.env.REACT_APP_BASE_URL;

//styled switch
const IOSSwitch = styled((props) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: '#33cf4d',
            border: '6px solid #fff',
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color:
                theme.palette.mode === 'light'
                    ? theme.palette.grey[100]
                    : theme.palette.grey[600],
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));


const SchoolConfigurationEnable = () => {
    const [OpenConfigurationModal, setOpenConfigurationModal] = useState(false);
    const [openZedAccountingModal, setOpenZedAccountingModal] = useState(false);
    const [openZedPayit, setOpenZedPayit] = useState(false)
    const [accountStatus, setAccountStatus] = useState()
    const [payitStatus, setPayitStatus] = useState()
    const [zedPayitStatus, setZedPayitStatus] = useState()

    const stockfunctions = new StockFunctions();
    const dispatch = useDispatch();
    const [zedAccountingStatus, setZedAccountingStatus] = useState(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        return savedStatus ? JSON.parse(savedStatus) : false;
    })

    const navigate = useNavigate();


    const queryparams = decodeURIComponent(window.location.search);
    const state = queryparams.slice(1);
   

    const handleOpenZedAccountingModal = () => {
        setOpenZedAccountingModal(true)
    }
    const handleCloseZedAccountingModal = () => {
        if (zedAccountingStatus === true) {
            return;
        }
        setOpenZedAccountingModal(false);
    }

    const handleOPenZedPayitModal = () => {
        setOpenZedPayit(true)
    }
    const handleCloseZedPayitModal = () => {

    }

    const switchZedPayit = () => {
        if (payitStatus) {
            handleCloseZedPayitModal()
        } else if (!payitStatus) {
            handleOPenZedPayitModal()
        }
    }

    const swicthAccounting = () => {
        if (accountStatus) {
            handleCloseZedAccountingModal()
        } else if (!accountStatus) {
            handleOpenZedAccountingModal()
        }

    }

    //zed accounting persists

    useEffect(() => {
        const savedStatus = localStorage.getItem('zedAccountingStatus');
        if (savedStatus) {
            setZedAccountingStatus(JSON.parse(savedStatus));
        }
    }, [])

    const handleOpenConfigurationModal = () => {
        setOpenConfigurationModal(true);

    };
    const handleClosesetConfigurationModal = () => {
        setOpenConfigurationModal(false);


    };

    const [DisableConfigurationInvetory, setDisableConfigurationInvetory] = useState(false);
    const handleOpenDisableConfigurationInvetory = () => {
        setDisableConfigurationInvetory(true);

    };
    const handleCloseDisableConfigurationInvetory = () => {
        setDisableConfigurationInvetory(false);
    };

    const [successNotificationOpen, setSuccessNotificationOpen] = useState(false);
    const handleSuccessNotificationClick = () => {
        setSuccessNotificationOpen(true);
    };

    const handleSuccessNotificationClose = (event, reason) => { 
        if (reason === 'clickaway') { 
            return 
        }
     setSuccessNotificationOpen(false);
     };
    const [status, setStatus] = useState()

    const [checked, setChecked] = useState();
    const [message, setMessage] = useState('')
    const [stockStatus, setStockStatus] = useState()


    const handleSwithChange = () => {
        if (status) {
            handleOpenDisableConfigurationInvetory()


        } else if (!status) {
            handleOpenConfigurationModal()
            localStorage.setItem("category", "Service")
        }
    };


    //get enable autogenerate students adm
    const [autogenerateAdmstatus, setAutogenerateAdmstatus] = useState(false)

    function getAutogenerateAdmnumberStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/students/setup/getAutogenerateStudentNumberStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAutogenerateAdmstatus(data?.response?.data?.autogenerateStudentNumber)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getAutogenerateAdmnumberStatus()
    }, [])


    // const handleMoveConfig = ()=> 


    function getAccountingStatus() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/check_accounting_status',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                if (data.status === 200) {
                    setAccountStatus(data?.response?.data?.accountingEnabled)
                }
            })
        } catch (error) {
        }
    }


    function getPayitStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/getZedPayItStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log(data, 'payit status')
                if (data.status === 200) {
                    setPayitStatus(data?.response?.data)
                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getPayitStatus()
    }, [])

    useEffect(() => {
        getAccountingStatus()
    }, [])


    // EnableWare House 
    const EnableWareHouseConfiguration = async () => {
        console.log('i am here');
        let obj = {}
        if (status) {
            obj = { enabled: false }
        } else if (!status) {
            obj = { enabled: true }
        }

        try {
            const reposonse = await fetch(`${baseUrl}/api/v1/stores/enable_inventory`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
                body: JSON.stringify(obj)

            })
            const data = reposonse.json()
                .then((response) => {
                    console.log(response);
                    setMessage(obj.enabled ? 'Inventory Enabled Successfully' : 'Inventory Disabled Successfully')
                    handleSuccessNotificationClick();
                    obj.enabled ? handleClosesetConfigurationModal() : handleCloseDisableConfigurationInvetory()

                    setTimeout(() => {
                        getWareHouseStatus()
                        window.location.reload()
                    }, [1000])
                })


        } catch (err) {

        }
    }
    const getWareHouseStatus = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/v1/stores/get_inventory_status `, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),

                },
            })
            const data = response.json()
                .then((response) => {
                    console.log('response', response.data.enabled);
                    setStatus(response?.data?.enabled)
                })

        } catch (error) {

        }


    }

    useEffect(() => {
        getWareHouseStatus()

    }, [])

    // ENABLE STOCK IN ZED
    const [stockConfigMap, setStockConfigMap] = useState([])
    const [switchState, setSwitchState] = useState('')
    const handleSwitchClick = (index) => {
        const newItems = [...stockConfigMap];
        newItems[index].isSelected = !newItems[index].isSelected;
        setStockConfigMap(newItems);
    };

    const handleStockConfigUpdate = async (index) => {
        try {
            const response = await fetch(baseUrl + `/api/update_branch_stock_option`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization")
                },
                body: JSON.stringify({ stockOptionId: stockConfigMap[index].configName })
            });
            await response.json()
                .then((data) => {
                    fetchStockConfigs()
                })

        } catch (err) {
            console.error(err);
        }
    };

    const fetchStockConfigs = async () => {
        try {
            const response = await fetch(baseUrl + '/api/get_branch_stock_options_list', {
                method: 'GET',
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization")
                }
            })
            await response.json()
                .then((data) => {
                    const stockMap = data.data.map((config) => {
                        return {
                            id: config.id,
                            configName: config.name,
                            isSelected: config.isSelected
                        }
                    })
                    setStockConfigMap(stockMap)
                })
                .catch((err) => { console.log('error getting stocks', err.message) })
        } catch (e) { console.log(e.message) }
    }

    useEffect(() => {
        fetchStockConfigs()
    }, [])



    //handle enable accounting

    //enable accounting
    function handleEnableAccounting() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enable_accounting?status=true',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {
                //console.log(data, 'acc status')
                if (data.status === 201) {
                    setMessage('Zed Accounting enabled successfully , click zedAccounting icon to proceed')
                    handleSuccessNotificationClick();
                    setZedAccountingStatus(data.response.data.accountingEnabled)
                    localStorage.setItem('zedAccountingStatus', true);
                }
            }).then(async () => {
                const newT = await HttpComponent({
                    method: "POST",
                    url: `/api/get_token_after_invite`,
                    token: localStorage.getItem('X-Authorization')
                })
                const result = newT?.response?.data?.token
                const decodedT = jwtDecode(result)
                localStorage.setItem('X-Authorization', result);
                localStorage.setItem('zedAccountingId', decodedT?.zedAccountingId);
                setTimeout(() => {
                    handleCloseZedAccountingModal()
                    window.location.reload()
                }, 1000);
            })
        } catch (error) {

        }
    }

    //enable zed payit
    function handleEnableZedPayit() {
        try {
            HttpComponent({
                method: "POST",
                url: '/api/v1/enabaleZedpayItConfig',
                body: null,
                token: localStorage.getItem('X-Authorization')
            }).then(async (data) => {
                if (data.status === 201) {
                    await stockfunctions.getWarehouseStatus().then((data) => {
                        if (data?.status === 201) {
                            console.log("data?.response?.data?.warehouseOn", data?.response?.data?.warehouseOn)
                            dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
                        }
                    })
                    await HttpComponent({
                        method: "POST",
                        url: `/api/get_token_after_invite`,
                        token: localStorage.getItem('X-Authorization')
                    }).then((data) => {
                        console.log(data, 'new token')
                    })
                }
                if (data.status === 201) {
                    setMessage('Zed-payit  enabled successfully , click ZED-PAYIT menu  to proceed')
                    handleSuccessNotificationClick();
                    setZedPayitStatus(data?.response?.data)
                    setTimeout(() => {
                        handleCloseZedPayitModal()
                        window.location.reload()

                    }, [1000]);
                }
            })
        } catch (error) {

        }
    }


    // enable stock in sale!
    const upDateStockConfigs = (stock) => {
        switch (stock?.id) {
            case "No Stock":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_option`,
                    body: { stockOptionId: "No Stock" },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then(() => {
                        HttpComponent({
                            method: "POST",
                            url: `/api/updateBranchStockNegativeStatus`,
                            body: { status: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())

                        HttpComponent({
                            method: "POST",
                            url: `/api/update_supervisor_can_do_return`,
                            body: { status: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())

                        HttpComponent({
                            method: "POST",
                            url: `/api/update_show_buying_price_in_sale_status`,
                            body: { showBuyingPriceInSale: false },
                            token: localStorage.getItem("X-Authorization"),
                        }).then(() => fetchStockConfigs())
                    })
                    .catch((e) => console.log(e.message));
                break;

            case "Stock In Zed":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_option`,
                    body: { stockOptionId: "Stock In Zed" },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Stock in Sale":
                HttpComponent({
                    method: "POST",
                    url: `/api/update_branch_stock_in_sale_status`,
                    body: { showStockInSale: stock.isSelected },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => {
                        fetchStockConfigs();
                    })
                    .catch((e) => {
                        console.log(e.message);
                    });
                break;
            case "Buying price In Sale":
                const noStockBuying = [...stockConfigMap]
                const noStockBuyingValue = noStockBuying[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/update_show_buying_price_in_sale_status`,
                    body: { showBuyingPriceInSale: noStockBuyingValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Allow Negative Order":
                const newStock = [...stockConfigMap]
                const noStockValue = newStock[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/updateBranchStockNegativeStatus`,
                    body: { status: noStockValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            case "Enable Return by Supervisor":
                const noStockReturn = [...stockConfigMap]
                const noStockReturnValue = noStockReturn[0]?.isSelected
                HttpComponent({
                    method: "POST",
                    url: `/api/update_supervisor_can_do_return`,
                    body: { status: noStockReturnValue === true ? false : true },
                    token: localStorage.getItem("X-Authorization"),
                })
                    .then((data) => fetchStockConfigs())
                    .catch((e) => console.log(e.message));
                break;
            default:
                break;
        }
    };

    return (
        <Box>
        {state === 'configadm' ?  <SchoolGradeAutogenerateConfigSetUp/> :  <>
        <SuccessAlert message={message} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={'right'} vertical={'top'} />
            <Box> <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Configurations</Box></Box>
            <Box>
                <Box>
                    <Box>
                        <Box component="Box" sx={{}}>
                            <Breadcrumbs
                                separator={<FiberManualRecordIcon sx={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}
                                aria-label="breadcrumb">
                                {breadcrumbs}
                            </Breadcrumbs>
                        </Box>

                        <Box style={{ marginTop: "20px" }}>
                            <CustomSearchInput />
                        </Box>

                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Inventory</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={status} onClick={handleSwithChange} />
                                </Box>
                            </Box>
                        </Box>


                        {/* The other configurations */}
                        {status ?
                            <>
                                {/* <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Swith to (with or without items)</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Activate Customer on account</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>No. of receipt reprint</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>No. Stock</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Stock IN ZED</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Stock in 3rd Party</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Stock in Sale</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>View All Business Bills & Report</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>No. of Mpesa sms to be displayed</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Enable Buying Price</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Return by Supervisor</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Enable Barcode</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Enable Warehouses</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                    <Switch checked={false} />
                                </Box>
                            </Box>
                        </Box>

                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>No. Stock</Box>
                                <Box style={{ marginLeft: "auto", display:"flex" }}>
                                   <Button style={{fontSize:"12px", width:"250px"}}>Payment After Delivery</Button>
                                   <Button>Payment After Dispatch</Button>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                            <Box style={{ display: "flex", justifyContent: "" }}>
                                <Box style={{ color: "#032541", fontSize: "14px",color:"#707070", fontWeight: "normal" }}>Display Stock Selling Price to customers</Box>
                                <Box style={{ marginLeft: "auto" }}>
                                <Switch checked={false} />
                                   
                                </Box>
                            </Box>
                        </Box> */}
                                <List style={{ width: "547.5px" }}>
                                    {
                                        stockConfigMap.map((stock, index) => {
                                            return (
                                                <ListItem key={stock.id} style={{ marginBottom: '10px', fontFamily: "Poppins", fontSize: '14px', "borderRadius": "6px", "border": "solid 1px rgba(193,193,193, .3)" }}>
                                                    <div style={{ display: "flex", justifyContent: "space-between", width: '100%' }}>
                                                        <div style={{ paddingTop: '10px', "fontFamily": "Poppins", "fontSize": "15px", "color": "#032541" }} >{stock.configName}</div>
                                                        <div ><Switch checked={stock.isSelected} onClick={() => {
                                                            handleSwitchClick(index)
                                                            upDateStockConfigs(stock);
                                                        }

                                                        } />
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            )
                                        })
                                    }
                                </List>

                            </>
                            : null}


                    </Box>
                    <Box onClick={() => navigate('/school/configuration?configadm')}  style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Auto Generate Student Number</Box>
                            <Box style={{ marginLeft: "auto" }}>
                                <Switch checked={autogenerateAdmstatus}/>
                            </Box>
                        </Box>
                    </Box>
                                        
                    <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Accounting</Box>
                            <Box style={{ marginLeft: "auto" }}>
                                <Switch checked={accountStatus} onClick={swicthAccounting} />
                            </Box>
                        </Box>
                    </Box>
                    <Box style={{ marginTop: "40px", borderBottom: "2px solid rgba(0, 0, 0, 0.12)", width: "547.5px" }}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            <Box style={{ color: "#032541", fontSize: "14px", fontWeight: "600" }}>Enable Zed-Payit</Box>
                            <Box style={{ marginLeft: "auto" }}>
                                <Switch checked={payitStatus} onClick={switchZedPayit} />
                            </Box>
                        </Box>
                    </Box>
                    
                </Box>
            </Box>
            <Modal
                open={OpenConfigurationModal} onClose={handleClosesetConfigurationModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={style}>
                    <form onSubmit={EnableWareHouseConfiguration}>

                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={EnableBigIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />

                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Inventory in School?</Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Enabling inventory in school will allow the business to track and manage products, stock, stores, warehouses and customers.
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={EnableSmallIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your inventory, products and services.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={warehouseIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your suppliers, stores and warehouses.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={userIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your customer accounts, activity, invoices, receipts and statements.</p>
                                </Box>

                                <Box style={{ display: "flex", marginTop: "15px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleClosesetConfigurationModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={() => {
                                                EnableWareHouseConfiguration()
                                                console.log('EnableWareHouseConfiguration');
                                            }}>Enable</Button>
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={DisableConfigurationInvetory} onClose={handleCloseDisableConfigurationInvetory} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={style}>
                    <form onSubmit={EnableWareHouseConfiguration}>

                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={EnableBigIcon} style={{ marginTop: "40px" }} alt="EnableBigIcon" />

                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Disable Inventory in School?</Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Disabling inventory in school will not allow the business to track and manage products, stock, stores, warehouses and customers.
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={EnableSmallIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your inventory, products and services.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={warehouseIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your suppliers, stores and warehouses.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <img src={userIcon} style={{ height: "25px", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage your customer accounts, activity, invoices, receipts and statements.</p>
                                </Box>

                                <Box style={{ display: "flex", marginTop: "15px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleCloseDisableConfigurationInvetory

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={() => {
                                                EnableWareHouseConfiguration()
                                                console.log('DISEnableWareHouseConfiguration');
                                            }}>Disable</Button>
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={openZedAccountingModal} onClose={handleCloseZedAccountingModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={zedAccountingmodal}>
                    <form>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={ZedaccImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Accounting </Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Enabling accounting  will allow the business to:
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Manage financial transactions</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage all accounting transactions.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Prepare budget forecasts.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Manage balance sheets and profit/loss statements..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Publish financial statements in time.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Reconcile accounts payable and receivable..</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleCloseZedAccountingModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={handleEnableAccounting}>Enable</Button>
                                    </Box>

                                </Box>
                            </Box>

                        </Box>
                    </form>
                </Box>
            </Modal>
            <Modal
                open={openZedPayit} onClose={handleCloseZedPayitModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" border="none">
                <Box sx={zedAccountingmodal}>
                    <form>
                        <Box style={{ display: "flex", justifyContent: "" }}>
                            <Box>
                                <img src={PayitImg} style={{ marginTop: "40px" }} alt="EnableBigIcon" />
                            </Box>
                            <Box style={{ marginLeft: "20px" }}>
                                <Box style={{ color: "#032541", fontSize: "25px", fontWeight: "600", }}>Enable Zed-Payit ? </Box>
                                <Box>
                                    <p style={{ fontSize: "14px", color: "#707070" }}>
                                        Enabling Zed-Payit in school  will display the ZED-PAYIT menu on the navbar:
                                    </p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}> Terminal users</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Terminals.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>

                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Device models.</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "0px" }}>
                                    <Box>
                                        <FiberManualRecordIcon style={{ height: "25px", color: "#032541", width: "25px" }} />
                                    </Box>
                                    <p style={{ marginLeft: "10px", fontSize: "14px", color: "#707070" }}>Outlets</p>
                                </Box>
                                <Box style={{ display: "flex", marginTop: "15px", marginBottom: "20px", paddingBottom: "10px" }}>

                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", border: "solid 1px #002543", color: "#032541" }} onClick={
                                            handleCloseZedAccountingModal

                                        }>Cancel</Button>
                                    </Box>
                                    <Box>
                                        <Button style={{ height: "45px", width: "125px", padding: "12.5px 37.4px 12.5px", borderRadius: "4px", backgroundColor: "#17ae7b", color: "#fff", marginLeft: "40px" }}
                                            onClick={handleEnableZedPayit}>Enable</Button>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>
                    </form>
                </Box>
            </Modal>
            </> 
            }
           
        </Box>
    )
}

export default SchoolConfigurationEnable