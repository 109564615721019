import { Typography, Grid, Breadcrumbs, Button, InputLabel, FormControl, OutlinedInput, InputAdornment, IconButton, Select, TextField } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import SearchIcon from '@mui/icons-material/Search';
import ExportMenu from "./ExportMenu";
import moment from "moment-timezone/moment-timezone-utils";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useEffect, useState } from "react";
import HttpComponent from "./MakeRequest";
import { DataGrid } from "@mui/x-data-grid";
import { NoRowsOverlay } from "../No Rows/noRowsOverlay";

//Number Format
const localCurrency = localStorage.getItem('localCurrency')
const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency === 'undefined' ? 'KES':localCurrency}).format(value);


const breadcrumbs = [
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Dashboard
    </Typography>,
    <Typography key={"add"} sx={{ color: "#707070", fontSize: "0.875rem" }}>
        Reports
    </Typography>,
    <Typography key={"add"} sx={{ color: "#dc3545", fontSize: "0.875rem" }}>
        Failed Transactions
    </Typography>
]


export default function FailedPayitTransactions() {
    const [startDate, setstartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [failedTransactions , setFailedTransactions] = useState([])
    const bsGroup = localStorage.getItem('group')

    //data grid options

    const [dataGridPageSize, setPageSize] = useState(5);
    const [pageState, setPageState] = useState({ isLoading: false, data: [], total: 0, page: 1, pageSize: dataGridPageSize, })

    //handle date change

    const handleStartDateChange = (e) => {
        setstartDate(e.target.value)
    }

    //handle end date

    const handleEndDateChange = (e) => {
        setEndDate(e.target.value)
    }

    //fetch failed transactions

    function fetchFailedTransactions(){
        try {
            setPageState((old) => ({ ...old, isLoading: true }));
            HttpComponent({
                method:"POST",
                url:`/api/v1/payments/card/payit/failed`,
                body:null,
                token:localStorage.getItem('X-Authorization')
            }).then((data)=>{
                console.log(data , 'fetch failed transactions')

                if(data.status === 200){
                    setFailedTransactions(data?.response?.transactions)
                    setPageState((old) => ({
                        ...old,
                        isLoading: false,
                        data: data?.response?.data,
                        total: data?.response?.count,
                    }));
                }
            })
        } catch (error) {
            
        }
    }

    useEffect(() => {
        fetchFailedTransactions()
    }, [])

    //transactions columns

    const FailedTransactionColumns = [
        { field: 'rrn', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >RRN</strong>) }, renderCell: (params) => { return (<><span style={{ marginLeft: "10px" }}>{params?.row?.rrn}</span></>) } },
        { field: 'date', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Date & Time</strong>) }, renderCell: (params) => { return (<span>{moment(params?.value)?.format('MM/DD/yyyy')}</span>) } },
        { field: 'transactionType', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Trxn Type</strong>) }, renderCell: (params) => { return (<span>{params?.row?.transactionType}</span>) } },
        { field: 'status', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Status</strong>) }, renderCell: (params) => { return (<span>{params?.row?.status}</span>) } },
        { field: 'message', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Description</strong>) }, renderCell: (params) => { return (<span>{params?.row?.message}</span>) } },
        { field: 'userId', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Teller ID</strong>) }, renderCell: (params) => { return (<span>{params?.row?.userId}</span>) } },
        { field: 'terminalserialid', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Terminal Serial</strong>) }, renderCell: (params) => { return (<span>{params?.row?.termId}</span>) } },
        { field: 'total', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Total ({localCurrency})</strong>) }, renderCell: (params) => { return (<span>{numberFormat(params?.row?.amount)}</span>) } },
        
    ]
    if(bsGroup === 'Admin'){
        const extraColum ={headerName: "reorderLevel", field: 'reorderLevel', headerAlign: 'left', align: 'left', flex: 1, renderHeader: () => { return (<strong style={{ fontSize: '12px', color: '#032541', fontWeight: 600 }} >Re-order Level</strong>) }}
        FailedTransactionColumns.splice(4 ,0 , extraColum);
    }

    //download the data in different formats

    const fileHeaders = [["Receipt No" ,"Date & Time","Description" ,"Teller ID","Transaction Type" ,"Status" ,"Terminal Serialid","total"]]
   
    const csvColumns = [
        { label: "Receipt No", key: "RRN" },
        { label: "Date & Time", key: "Date & Time" },
        { label: "Transaction Type", key: "Transaction Type" },
        { label: "Status", key: "Status" },
        { label: "Terminal Serialid", key: "Terminal Serialid" },
        { label: "Total", key: "Total" },
        { label: "Description", key: "Description" },
        { label: "Teller ID", key: "Teller ID" },
    ];

    const fileDataPDF = failedTransactions?.map((transaction)=>[
        transaction?.rrn,
        transaction?.tnxTime,
        transaction?.message,
        transaction?.userId,
        transaction?.transactionType,
        transaction?.status,
        transaction?.termId,
        transaction?.amount,
       
      
    ])

    const fileData = failedTransactions?.map((transaction) => {
        return {
            "ReceiptNo": transaction?.rrn,
            "Date": transaction?.tnxTime,
            "Description":transaction?.message,
            "TellerId":transaction?.userId,
            "TransactionType": transaction?.transactionType,
            "Status": transaction?.status,
            "TerminalserialId": transaction?.termId,
            "Total": transaction?.amount,
        }
    });

    return (
        <Grid display={'flex'} direction={'column'}>
            <Grid item mt={2}>
                <Typography style={{ fontSize: "25px", fontWeight: 600, color: "#032541" }}>
                    Failed Transactions
                </Typography>
            </Grid>
            <Grid item mt={2} display={'flex'} alignContent={'center'} alignItems={'center'} justifyContent={'space-between'}>
                <Breadcrumbs style={{ fontFamily: "Poppins", fontSize: "14px" }} separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: "Poppins", color: "#e1e5e8" }} />} aria-label="breadcrumb">
                    {breadcrumbs}
                </Breadcrumbs>
                <Button>
                    <ExportMenu csvColumns={csvColumns} fileData={fileData} fileHeaders={fileHeaders} fileDataPDF={fileDataPDF} title={'Failed Payit Trabnsaction'} fileName={'Failed Payit Transactions'} />
                </Button>
            </Grid>
            <Grid item>
                <Grid container display={'flex'} justifyContent={'space-between'} alignItems={'center'} alignContent={'center'}>
                    <Grid item>
                        <FormControl sx={{ width: '22ch' }} variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Search Transactions</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={'text'}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                        >
                                            <SearchIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Search Transactions"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ width: '25ch' }}>
                            <InputLabel htmlFor="grouped-native-select">Transaction Type</InputLabel>
                            <Select native defaultValue="" id="grouped-native-select" label="Transaction Type">
                                <option aria-label="None" value="" />
                                <option value='CASH'>Cash</option>
                                <option value='PAYITCARD'>Card</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item>
                        <LocalizationProvider dateAdapter={AdapterDayjs} className="">
                            <DatePicker
                                label="From"
                                value={startDate}
                                onChange={handleStartDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="DD-MM-YYYY"
                                format="DD-MM-YYYY"
                            />

                            <DatePicker
                                className='ml-2'
                                label="To"
                                value={endDate}
                                onChange={handleEndDateChange}
                                renderInput={(params) => <TextField {...params} />}
                                inputFormat="DD-MM-YYYY"
                                format="DD-MM-YYYY"
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item>
                        <FormControl sx={{ m: 1, width: '25ch' }}>
                            <InputLabel htmlFor="grouped-native-select">Date</InputLabel>
                            <Select native defaultValue="" id="grouped-native-select" label="Date">
                                <option aria-label="None" value="" />
                                <option value='All'>All</option>
                                <option value='Today'>Today</option>
                                <option value='Today'>This Week</option>
                                <option value='Today'>This Month</option>
                                <option value='Today'>Last Month</option>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
            <DataGrid
                    components={{ NoRowsOverlay: NoRowsOverlay }}
                    sx={{ height: '400px', width: 'auto', fontFamily: 'Poppins', fontSize: "10px", color: '#272d3d', boxShadow: 0, border: 0 }}
                    rowsPerPageOptions={[1, 5, 10, 20, 50, 100]}
                    rowCount={pageState?.total}
                    loading={pageState?.isLoading}
                    pagination
                    page={pageState?.page - 1}
                    pageSize={dataGridPageSize}
                    paginationMode="server"
                    onPageChange={(newPage) => { setPageState((old) => ({ ...old, page: newPage + 1, pageSize: dataGridPageSize })); }}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    columns={FailedTransactionColumns}
                    rows={failedTransactions}
                    getRowId={row => row?._id} />
            </Grid>
        </Grid>
    )
}