import React, { useState, useEffect } from "react";
import { Modal, Button, Box, Alert, Snackbar, Grid, List, ListItemButton, ListItemIcon, ListItemText, Checkbox, ListItem, TextField, FormControl, InputLabel, Input, InputAdornment } from "@mui/material";
import powericon from "../../../common/images/powericon.svg";
import customeraccountsprofile from "../../../common/images/customeraccountsprofile.svg";
import { universalStyles } from "../startorderuniversalstyles/styles";
import approvetick from "../../../common/images/approvetick.svg";
import emptyState from "../../../common/images/emptystate.svg";
import { Close, ArrowBack, Search } from "@mui/icons-material";
import creditnote from "../../../common/images/creditnotepay.svg";
import mpesasvg from "../../../common/images/mpesa.svg";
import equitelsvg from "../../../common/images/equitel.svg";
import banksvg from "../../../common/images/bank.svg";
import cardsvg from "../../../common/images/card.svg";
import cashsvg from "../../../common/images/cash.svg";
import voomasvg from "../../../common/images/vooma.svg";
import vouchersvg from "../../../common/images/voucher-icn.svg";
import cashpayment from "../../../common/images/cashpayment.svg";
import warningsvg from "../../../common/images/warning.svg";
import approveTick from "../../../images/Appro.svg"
import { useNavigate } from "react-router-dom";

const outofstock = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "15px",
  backgroundColor: "#fff",
  width: "20%",
  height: "fit-content",
};

const OutOfStock = (props) => {
  // open out of stck modal;
  const noItems = props.noItems;
  const setNoItems = props.setNoItems;

  return (
    <>
      {/* OUT OF STOCK MODAL */}
      <Modal
        open={noItems}
        //  onClose={() => setNoItems(false)}
      >
        <div style={outofstock}>
          <Box style={{ width: "100%", padding: "10px" }}>
            <p style={{ textAlign: "center" }}>
              <img src={powericon} />
            </p>
            <p
              style={{
                textAlign: "center",
                color: "#032541",
                fontFamily: "poppins",
                fontWeight: "bold",
              }}
            >
              Out of Stock
            </p>
            <p
              style={{
                textAlign: "center",
                color: "#032541",
                fontFamily: "poppins",
              }}
            >
              Product is out of stock
            </p>

            <p style={{ textAlign: "center" }}>
              <Button
                onClick={() => setNoItems(false)}
                style={{
                  border: "solid 1px #dc3545",
                  color: "#dc3545",
                  backgroundColor: "#ffffff",
                  margin: "0px 10px 0px 0px",
                  borderRadius: "6px",
                  padding: "5px 20px 5px 20px",
                }}
              >
                CLOSE
              </Button>
              <Button
                onClick={() => setNoItems(false)}
                style={{
                  backgroundColor: "#032541",
                  color: "#ffffff",
                  margin: "0px 0px 0px 10px",
                  borderRadius: "6px",
                  padding: "5px 20px 5px 20px",
                }}
              >
                OKAY
              </Button>
            </p>
          </Box>
        </div>
      </Modal>
    </>
  );
};

const MorePayments = (props) => {
  const mpesaAmountModal = props.mpesaAmountModal;
  const setMpesaAmountModal = props.setMpesaAmountModal;
  return (
    <>
      <Modal
        open={mpesaAmountModal}
        //  onClose={() => setMpesaAmountModal(false)}
      >
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "15px",
            backgroundColor: "#fff",
            width: "15%",
            height: "fit-content",
          }}
        >
          <Box style={{ width: "100%", padding: "10px" }}>
            <p style={{ textAlign: "center" }}>
              <img src={powericon} />
            </p>
            <p
              style={{
                textAlign: "center",
                fontFamily: "Poppins",
                fontSize: "15px",
                fontWeight: "500",
                fontStretch: "normal",
                fontStyle: "normal",
                lineHeight: "2.2",
                letterSpacing: "normal",
                textAlign: "left",
                color: "#032541",
              }}
            >
              The amount selected is greater than the total bill
            </p>
          </Box>

          <Box
            style={{
              width: "100%",
              padding: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button onClick={() => setMpesaAmountModal(false)} style={{ backgroundColor: "#032541", color: "#ffffff" }}>
              ok
            </Button>
          </Box>
        </div>
      </Modal>
    </>
  );
};

const SucessModal = (props) => {
  const openSnack = props.openSnack;
  const message = props.message;
  const setOpenSnack = props.setOpenSnack;
  return (
    <Snackbar open={openSnack} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
      <Alert severity="success" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

// const customer on accounts modal;
const CustomerAccounts = (props) => {
  const customerPreview = props.customerPreview;
  const setCustomerPreview = props.setCustomerPreview;
  const saveOrderToBd = props.saveOrderToBd;
  const setCustomerConfirm = props.setCustomerConfirm;
  const generalDiscountAmount = props.generalDiscountAmount;
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  return (
    <>
      {/* OUT OF STOCK MODAL */}
      <Modal open={customerPreview} style={universalStyles.universalFont}>
        <div style={universalStyles.customerOnAccountsDetails}>
          <p style={universalStyles.paraWithImg}>
            <img src={customeraccountsprofile} />
          </p>

          <p style={universalStyles.xxxlParaBoldCenter}>Customer Account</p>

          <div style={universalStyles.divWithDisplayFlex}>
            <p style={universalStyles.listItemText}>Customer Name:</p>
            <p style={universalStyles.xlParaBold}>{props.customerOnAccountsName}</p>
          </div>

          <div style={universalStyles.divWithDisplayFlex}>
            <p style={universalStyles.listItemText}>Current Balance:</p>
            <p style={universalStyles.xlParaBold}>{numberFormat(props.customerOnAccountsBalance)}</p>
          </div>

          <div style={universalStyles.divWithDisplayFlex}>
            <p style={universalStyles.listItemText}>Amount Charged:</p>
            <p style={universalStyles.xlParaBold}>{numberFormat(props.customerOnAccountsBill)}</p>
          </div>

          <div style={universalStyles.divWithDisplayFlex}>
            <p style={universalStyles.listItemText}>Discount:</p>
            <p style={universalStyles.xlParaBold}>{numberFormat(props.generalDiscountAmount)}</p>
          </div>

          <hr></hr>
          <div style={universalStyles.divWithDisplayFlex}>
            <p style={universalStyles.listItemText}>Balance:</p>
            <p style={universalStyles.xlParaBold}>{numberFormat(props.customerOnAccountsBalance - props.customerOnAccountsBill)}</p>
          </div>

          <p style={universalStyles.divWithDisplayFlex}>
            <Button style={universalStyles.cancelButton} onClick={() => setCustomerPreview(false)}>
              Cancel
            </Button>
            <Button
              style={universalStyles.confirmButton}
              onClick={() => {
                saveOrderToBd();
                setCustomerPreview(false);
              }}
            >
              Confirm
            </Button>
          </p>
        </div>
      </Modal>
    </>
  );
};

const ConfirmOrder = (props) => {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const customerConfirm = props.customerConfirm;
  const setCustomerConfirm = props.setCustomerConfirm;
  const saveOrderToBd = props.saveOrderToBd;
  const customerOnAccountsName = props.customerOnAccountsName;
  const customerOnAccountsBill = props.customerOnAccountsBill;
  const executePay = props.executePay;
  return (
    <>
      {/* OUT OF STOCK MODAL */}
      <Modal open={customerConfirm} style={universalStyles.universalFont}>
        <div style={universalStyles.customerOnAccountsConfirmOrder}>
          <form onSubmit={""}>
            <p style={universalStyles.paraWithImg}>
              <img src={powericon} />
            </p>

            <p style={universalStyles.xxxlParaBoldCenter}>Confirm Order</p>

            <p style={universalStyles.mdNormalParaTextAlignCenter}>Are you sure you want</p>
            <p style={universalStyles.mdNormalParaTextAlignCenter}>to Debit {customerOnAccountsName}</p>
            <p style={universalStyles.mdNormalParaTextAlignCenter}>{numberFormat(customerOnAccountsBill)}</p>

            <p>
              <p style={universalStyles.divWithDisplayFlex}>
                <Button style={universalStyles.cancelButton} onClick={() => setCustomerConfirm(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    executePay();
                    setCustomerConfirm(false);
                  }}
                  style={universalStyles.payNowButton}
                >
                  Pay Now
                </Button>
              </p>
            </p>
          </form>
        </div>
      </Modal>
    </>
  );
};

// ALERT MESSAGE PON EXCEEDING CUSTOMER BALANCE;
const AlertIfBalanceIsDepleted = (props) => {
  const message = props.message;
  const customerBalanceDepleted = props.customerBalanceDepleted;
  return (
    <Snackbar open={customerBalanceDepleted} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

// ALERT SWITCH BUSINESS SUCCESSFUL;
const SuccessAlertRightAligned = (props) => {
  const message = props.message;
  const sucess = props.sucess;
  return (
    <Snackbar open={sucess} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert variant="filled" severity="success" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

// ERROR ALERTS
const ErrorAlertRightAligned = (props) => {
  const error = props.error;
  const message = props.message;
  return (
    <Snackbar open={error} autoHideDuration={2000} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
      <Alert variant="filled" severity="error" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const SuccessCustomerOnAccounts = (props) => {
  const sucessCustomerAccounts = props.sucessCustomerAccounts;
  const setSuccessCustomerAccounts = props.setSuccessCustomerAccounts;
  return (
    <>
      {/* OUT OF STOCK MODAL */}
      <Modal open={sucessCustomerAccounts} style={universalStyles.universalFont}>
        <div style={universalStyles.customerOnAccountsSuccess}>
          <form onSubmit={""}>
            <p style={universalStyles.paraWithImg}>
              <img style={{ width: "80px", height: "80px", objectFit: "contain" }} src={approvetick} />
            </p>

            <p style={universalStyles.xxxlParaBoldCenter}>Successful</p>

            <p style={universalStyles.mdNormalParaTextAlignCenter}>Do you want to print receipt?</p>

            <div style={universalStyles.divWithDisplayFlex}>
              <Button onClick={() => setSuccessCustomerAccounts(false)} style={universalStyles.cancelButton}>
                Close
              </Button>
              <Button style={universalStyles.printButton}>Print</Button>
            </div>
          </form>
        </div>
      </Modal>
    </>
  );
};

// MPESA PAYMENT MODAL;
const MpesaPaymentModal = (props) => {
  const [mobilePayQuery, setMobilePayQuery] = useState("");
  const [toggleDiscount, setToggleDiscount] = useState(false);
  const mpesaPayModal = props.mpesaPayModal;
  const setMpesaPayModal = props.setMpesaPayModal;
  const messagesCollection = props.messagesCollection;
  const allPayTotals = props.allPayTotals;
  const setAllPayAmount = props.setAllPayAmount;
  const setAllPayCustomerName = props.setAllPayCustomerName;
  const setAllPayCustomerNumber = props.setAllPayCustomerNumber;
  const setAllPayTransId = props.setAllPayTransId;
  const setConfirmPatDetails = props.setConfirmPatDetails;
  const unUseMessage = props.unUseMessage;
  const setMpesaAmountModal = props.setMpesaAmountModal;
  const setConfirmPayModal = props.setConfirmPayModal;
  const detailedBill = props.detailedBill;
  const setAllPaymentDiscount = props.setAllPaymentDiscount;
  const allPaymentDiscount = props.allPaymentDiscount;

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  return (
    <Modal
      open={mpesaPayModal}
      // onClose={() => setMpesaPayModal(false)}
    >
      <div style={universalStyles.mpesaPaymentModal}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "#dc3545",
            borderRadius: "15px 15px 0px 0px",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "#ffffff" }}>
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMpesaPayModal(false);
              }}
            />
          </span>
          <span
            style={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "0.71",
              letterSpacing: "normal",
              textAlign: "left",
            }}
          >
            Received Payment
          </span>
          <span style={{ color: "#ffffff" }}>
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMpesaPayModal(false);
              }}
            />
          </span>
        </Box>

        <Grid container style={{ width: "100%", padding: "20px" }}>
          <Grid
            item
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                width: "50%",
              }}
            >
              Bill Total = {numberFormat(allPayTotals)}
            </span>
            <span>
              {" "}
              Discount{" "}
              <Checkbox
                onClick={() => {
                  setToggleDiscount(!toggleDiscount);
                  setAllPaymentDiscount(0);
                }}
              />{" "}
            </span>
          </Grid>
          <Grid item style={{ width: "100%", marginBottom: "10px" }}>
            <TextField label="Enter last 3 digits or code" style={{ width: "100%", color: "#032541" }} helperText={"Search"} onChange={(e) => setMobilePayQuery(e.target.value)} />
          </Grid>

          {detailedBill && toggleDiscount && (
            <Grid item style={{ width: "100%" }}>
              <TextField label="Discount" type={"number"} value={allPaymentDiscount} onChange={(e) => setAllPaymentDiscount(e.target.value)} style={{ width: "100%" }} />
            </Grid>
          )}

          {/* LOOP THROUGH ALL MESSAGES; */}
          <Grid
            item
            style={{
              width: "100%",
              height: "40vh",
              overflow: "auto",
            }}
          >
            {messagesCollection.length > 0 && (
              <List>
                {messagesCollection
                  ?.filter((messo) => {
                    if (mobilePayQuery.toLocaleLowerCase() === "") {
                      return messo;
                    } else if (messo.transactionID.toLocaleLowerCase().includes(mobilePayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(mobilePayQuery)) {
                      return messo;
                    }
                  })
                  .map((messo, index) => {
                    let { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;
                    // phoneNumber.replace(/(\d{5})\d{3}(\d{4})/, "$1***$2")
                    // const date = ` ${day}/${month}/${year}`;
                    function replaceAtMultipleIndexes(str, indexes, newValue) {
                      let result = str;
                      for (let i = 0; i < indexes.length; i++) {
                        const index = indexes[i];
                        result = result.substr(0, index) + newValue + result.substr(index + newValue.length);
                      }
                      return result;
                    }
                    let myString = phoneNumber;
                    let indexes = [6, 7, 8];
                    let newValue = "*";
                    let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                    return (
                      <ListItem
                        key={index}
                        disablePadding
                        onClick={() => {
                          setAllPayCustomerName(customerName);
                          setAllPayAmount(amount);
                          setAllPayCustomerNumber(phoneNumber);
                          // setPayDate(date);
                          setAllPayTransId(transactionID);

                          if (amount > allPayTotals) {
                            setMpesaAmountModal(true);
                            setMpesaPayModal(false);
                          } else {
                            setMpesaPayModal(false);

                            if (props.setConfirmPayModal) {
                              unUseMessage(messo);
                              setConfirmPayModal(true);
                              setConfirmPatDetails(false);
                              console.log("we are going with confirm pay");
                            }
                            if (props.setConfirmPatDetails) {
                              unUseMessage(messo);
                              setConfirmPatDetails(true);
                              setConfirmPayModal(false);
                              console.log("we are going with confirm pat");
                            }
                          }
                        }}
                      >
                        <ListItemButton>
                          <ListItemText
                            style={{
                              fontFamily: "Poppins",
                              fontSize: "15px",
                              fontWeight: "500",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "2.2",
                              letterSpacing: "normal",
                              textAlign: "left",
                              color: "#6e7074",
                            }}
                            primary={`${transactionID}  ${customerName} ${newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                          />
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
              </List>
            )}

            {messagesCollection.length === 0 && (
              <Grid item style={{ width: "100%" }}>
                <p style={{ textAlign: "center" }}>
                  <img
                    style={{
                      width: "80px",
                      height: "80px",
                      objectFit: "fit-content",
                      margin: "0px",
                    }}
                    src={emptyState}
                  />
                </p>
                <p style={{ textAlign: "center", margin: "0px" }}>No tranasctions added transactions will appear here</p>
              </Grid>
            )}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

const BankPayModal = (props) => {
  const [bankPayQuery, setBankPayQuery] = useState("");
  const [toggleDiscount, setToggleDiscount] = useState(false);

  const setBankPayNum = props.setBankPayNum;
  const setBankCustomerName = props.setBankCustomerName;
  const setPayBankAmount = props.setPayBankAmount;
  const setpayBankDate = props.setpayBankDate;
  const bankPayModal = props.bankPayModal;
  const setPayBankId = props.setPayBankId;
  const bankTotal = props.bankTotal;
  const setBankPayModal = props.setBankPayModal;
  const setMessagesToBeFetched = props.setMessagesToBeFetched;
  const messagesCollection = props.messagesCollection;
  const setBankLists = props.setBankLists;
  const bankList = props.bankList;
  const setBankMessages = props.setBankMessages;
  const bankMessages = props.bankMessages;
  const setMpesaAmountModal = props.setMpesaAmountModal;
  const setConfirmPayModal = props.setConfirmPayModal;
  const unUseMessage = props.unUseMessage;
  const setBankpayDiscount = props.setBankpayDiscount;
  const detailedBill = props.detailedBill;
  const bankPayDiscount = props.bankPayDiscount;

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  return (
    <Modal
      open={bankPayModal}
      // onClose={() => setBankPay(false)}
    >
      <div style={universalStyles.mpesaPaymentModal}>
        <Box
          style={{
            width: "100%",
            backgroundColor: "#dc3545",
            borderRadius: "15px 15px 0px 0px",
            padding: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span style={{ color: "#ffffff" }}>
            <ArrowBack
              style={{ cursor: "pointer" }}
              onClick={() => {
                // setBankPayModal(false);
                setBankLists(true);
                setBankMessages(false);
              }}
            />
          </span>
          <span
            style={{
              color: "#ffffff",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: "normal",
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "0.71",
              letterSpacing: "normal",
              textAlign: "left",
            }}
          >
            Received Payment
          </span>
          <span style={{ color: "#ffffff" }}>
            <Close
              style={{ cursor: "pointer" }}
              onClick={() => {
                setBankPayModal(false);
                setBankLists(true);
                setBankMessages(false);
              }}
            />
          </span>
        </Box>

        <Grid container style={{ width: "100%", padding: "20px" }}>
          <Grid
            item
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <span
              style={{
                fontFamily: "Poppins",
                fontWeight: "bold",
                width: "50%",
              }}
            >
              Total = {numberFormat(bankTotal)}
            </span>
            <span>
              {" "}
              <Checkbox
                onClick={() => {
                  setToggleDiscount(!toggleDiscount);
                  setBankpayDiscount(0);
                }}
              />{" "}
            </span>
          </Grid>
          <Grid item style={{ width: "100%" }}>
            <TextField label="Enter last 3 digits or code" value={bankPayQuery} onChange={(e) => setBankPayQuery(e.target.value)} style={{ width: "100%" }} helperText={"Search"} />
          </Grid>
          {detailedBill && toggleDiscount && (
            <Grid item style={{ width: "100%" }}>
              <TextField label="Discount" type={"number"} value={bankPayDiscount} onChange={(e) => setBankpayDiscount(e.target.value)} style={{ width: "100%" }} />
            </Grid>
          )}

          {/* LOOP THROUGH ALL MESSAGES; */}
          <Grid
            item
            style={{
              width: "100%",
              height: "40vh",
              overflow: "auto",
            }}
          >
            {bankList && (
              <>
                <Grid
                  item
                  style={{
                    backgroundColor: "#deedfa",
                    width: "100%",
                    padding: "20px",
                    margin: "0px 0px 10px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("DTB");
                    setBankLists(false);
                    setBankMessages(true);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",

                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    DTB
                  </span>
                </Grid>

                <Grid
                  item
                  style={{
                    backgroundColor: "#deedfa",
                    width: "100%",
                    padding: "20px",
                    margin: "0px 0px 10px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("COOP");
                    setBankLists(false);
                    setBankMessages(true);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",

                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    COOP-BANK
                  </span>
                </Grid>

                <Grid
                  item
                  style={{
                    backgroundColor: "#deedfa",
                    width: "100%",
                    padding: "20px",
                    margin: "0px 0px 10px 0px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setMessagesToBeFetched("ABSA");
                    setBankLists(false);
                    setBankMessages(true);
                  }}
                >
                  <span
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: "normal",
                      fontStretch: "normal",
                      fontStyle: "normal",

                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#032541",
                    }}
                  >
                    ABSA
                  </span>
                </Grid>
              </>
            )}

            {bankMessages && (
              <>
                <List sx={{ width: "100%", bgcolor: "background.paper" }}>
                  {/* LOOP THROUGH MESSAGES TO MAP */}
                  {messagesCollection
                    ?.filter((messo) => {
                      if (bankPayQuery.toLocaleLowerCase() === "") {
                        return messo;
                      } else if (messo.transactionID.toLocaleLowerCase().includes(bankPayQuery.toLocaleLowerCase()) || messo.phoneNumber.includes(bankPayQuery)) {
                        return messo;
                      }
                    })
                    .map((messo) => {
                      const { customerName, amount, transactionID, phoneNumber, day, hour, meridian, minute, month, sms, year, smsType } = messo;
                      const date = ` ${day}/${month}/${year}`;
                      function replaceAtMultipleIndexes(str, indexes, newValue) {
                        let result = str;
                        for (let i = 0; i < indexes.length; i++) {
                          const index = indexes[i];
                          result = result.substr(0, index) + newValue + result.substr(index + newValue.length);
                        }
                        return result;
                      }
                      let myString = phoneNumber;
                      let indexes = [6, 7, 8];
                      let newValue = "*";
                      let newPhone = replaceAtMultipleIndexes(myString, indexes, newValue);

                      return (
                        <ListItem
                          // key={bill._id}
                          sx={{
                            // border: "1px solid",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            width: "100%",
                          }}
                          component="div"
                          // value={listItemValue}
                          onClick={() => {
                            if (phoneNumber === "") {
                              setBankPayNum("+254");
                            } else {
                              setBankPayNum("+254");
                            }

                            setBankCustomerName(customerName);
                            setPayBankAmount(amount);
                            // setpayBankDate(date);
                            setPayBankId(transactionID);

                            if (amount > bankTotal) {
                              setMpesaAmountModal(true);
                              setBankPayModal(false);
                            } else {
                              setBankPayModal(false);
                              setConfirmPayModal(true);
                              unUseMessage(messo);
                            }
                          }}
                        >
                          <ListItemButton
                            sx={{
                              border: "1.5px solid #e1d6d6",
                              borderRadius: "6px",
                              width: "100%",
                            }}
                          >
                            <ListItemText
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "15px",
                                fontWeight: "500",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "2.2",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#6e7074",
                              }}
                              primary={`${transactionID}  ${customerName} ${newPhone}  ${numberFormat(amount)} ${day}/${month}/${year}  ${hour}.${minute} ${meridian}`}
                            />
                          </ListItemButton>
                        </ListItem>
                      );
                    })}
                </List>
                {/* empty state transaction */}
                {messagesCollection.length === 0 && (
                  <Grid item style={{ width: "100%" }}>
                    <p style={{ textAlign: "center" }}>
                      <img
                        style={{
                          width: "80px",
                          height: "80px",
                          objectFit: "fit-content",
                          margin: "0px",
                        }}
                        src={emptyState}
                      />
                    </p>
                    <p style={{ textAlign: "center", margin: "0px" }}>No tranasctions added transactions will appear here</p>
                  </Grid>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

const CreditnotepayModal = (props) => {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const openCreditNote = props.openCreditNote;
  const setCreditnotesearch = props.setCreditnotesearch;
  const creditNoteData = props.creditNoteData;
  const creditnotesearch = props.creditnotesearch;
  const creditNoteError = props.creditNoteError;
  const setCreditNotePayId = props.setCreditNotePayId;
  const setOpenCreditNote = props.setOpenCreditNote;
  const setCreditNoteId = props.setCreditNoteId;
  const setopenConfirmCredit = props.setopenConfirmCredit;
  const creditNotedetails = props.creditNotedetails;
  const setCreditNoteDetails = props.setCreditNoteDetails;
  const billTotal = props.billTotal;
  const setOpenCreditPayOverPayment = props.setOpenCreditPayOverPayment;

  const [showText, setShowText] = useState(false);

  const { creditNoteNumber, creditNoteAmount, expiryDate, status, _id } = creditNoteData;
  const newExpiryDate = new Date(expiryDate).toDateString() + " " + new Date(expiryDate).toLocaleTimeString();
  let newStatus = "";
  status === "APPROVED" ? (newStatus = "UNUSED") : (newStatus = "USED");

  const showTextFn = () => {
    if (creditNoteData) {
      setShowText(true);
    } else {
      setShowText(false);
    }
    if (creditnotesearch === "") {
      setShowText(false);
    }
  };

  useEffect(() => {
    showTextFn();
  }, [creditNoteData, creditnotesearch]);

  const getcreditNoteId = () => {
    if (status === "APPROVED") {
      setCreditNoteId(_id);
      setOpenCreditNote(false);
      setCreditNoteDetails({
        ...creditNotedetails,
        amount: creditNoteAmount,
        no: creditNoteNumber,
      });
    }

    if (billTotal < creditNoteAmount) {
      console.log("credit Note amount", creditNoteAmount, "bill Total", billTotal);
      setOpenCreditPayOverPayment(true);
    } else {
      setopenConfirmCredit(true);
    }
  };

  return (
    <Modal style={universalStyles.universalFont} open={openCreditNote}>
      <div style={universalStyles.creditnotepaymodal}>
        <div style={universalStyles.creditnoteintro}>
          <span>
            {" "}
            <ArrowBack />{" "}
          </span>
          <span style={universalStyles.creditNotetext}> Credit Note</span>
          <span>
            {" "}
            <Close onClick={() => setOpenCreditNote(false)} />{" "}
          </span>
        </div>

        <div style={universalStyles.creditNoteBillHolder}>
          <span style={universalStyles.creditnotebilltext}>Total Bill Amount</span>
          <span style={universalStyles.creditnoteamounttext}>{numberFormat(billTotal)}</span>
        </div>

        <Box style={universalStyles.creditNoteSearchInput}>
          <FormControl style={universalStyles.creditNoteSearchInput} variant="standard">
            <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
            <Input
              onChange={(e) => setCreditnotesearch(e.target.value.toLocaleUpperCase())}
              style={universalStyles.creditNoteSearch}
              // id="input-with-icon-adornment"
              startAdornment={
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              }
              value={creditnotesearch}
            />
          </FormControl>
        </Box>

        {showText && (
          <>
            <div style={universalStyles.creditNoteCardholder} onClick={getcreditNoteId}>
              <div>
                <p style={universalStyles.unusedcreditNotes}>Credit Note No. {creditNoteNumber} </p>
                <p style={universalStyles.unusedcreditNotes}>Credit Note Amount {creditNoteAmount} </p>
                <p style={universalStyles.unusedcreditNotes}>Expiry Date: {newExpiryDate} </p>
              </div>

              <div>
                <span style={universalStyles.unusedcreditnotestatus}>{newStatus}</span>
              </div>
            </div>
          </>
        )}

        <div>{!showText && <p style={{ color: "red", textAlign: "center" }}>{creditNoteError}</p>}</div>
      </div>
    </Modal>
  );
};

const ConfirmPayCreditNote = (props) => {
  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const setopenConfirmCredit = props.setopenConfirmCredit;
  const openConfirmCredit = props.openConfirmCredit;
  const creditNoteAmount = props.creditNoteAmount;
  const creditNoteNumber = props.creditNoteNumber;
  const executePayVoucher = props.executePayVoucher;
  const billTotal = props.billTotal;

  const makePayment = () => {
    setopenConfirmCredit(false);
    executePayVoucher();
  };

  return (
    <Modal open={openConfirmCredit} style={universalStyles.universalFont}>
      <div style={universalStyles.customerOnAccountsConfirmOrder}>
        <form onSubmit={""}>
          <p style={universalStyles.paraWithImg}>
            <img src={powericon} />
          </p>

          <p style={universalStyles.xxxlParaBoldCenter}>Confirm Payment</p>

          <p style={universalStyles.mdNormalParaTextAlignCenter}>Are you sure you want pay with</p>
          <p style={universalStyles.mdNormalParaTextAlignCenter}>credit not number {creditNoteNumber} </p>
          <p style={universalStyles.mdNormalParaTextAlignCenter}>{numberFormat(creditNoteAmount)}</p>

          <p>
            <p style={universalStyles.divWithDisplayFlex}>
              <Button style={universalStyles.cancelButton} onClick={() => setopenConfirmCredit(false)}>
                Cancel
              </Button>
              <Button onClick={() => makePayment()} style={universalStyles.payNowButton}>
                Pay Now
              </Button>
            </p>
          </p>
        </form>
      </div>
    </Modal>
  );
};

const ConfirmOverPaymentCreditnote = (props) => {
  const openCreditpayOverPayment = props.openCreditpayOverPayment;
  const message = props.message;
  const setOpenCreditPayOverPayment = props.setOpenCreditPayOverPayment;
  const setOpenCashPayment = props.setOpenCashPayment;

  return (
    <Modal style={universalStyles.universalFont} open={openCreditpayOverPayment}>
      <Box style={universalStyles.creditOverpaymodal}>
        <p style={universalStyles.paraWithImg}>
          <img src={powericon} />
        </p>

        <p style={universalStyles.mdNormalParaTextAlignCenter}> {message} </p>

        <p style={universalStyles.mdNormalParaTextAlignCenter}>
          <Button variant="standard" style={universalStyles.closeCreditPaymodal} onClick={() => setOpenCreditPayOverPayment(false)}>
            ok
          </Button>
        </p>
      </Box>
    </Modal>
  );
};

{
  /* <====== MODE OF PAYMENT SELECTION =======> */
}
export const AllPaymentModal = (props) => {
  const openPayModal = props.openPayModal;
  const setPayOpenModal = props.setPayOpenModal;
  const saveOrderToDbAndPopulateMpesa = props.saveOrderToDbAndPopulateMpesa;
  const setMessagesToBeFetched = props.setMessagesToBeFetched;
  const setMpesaPayModal = props.setMpesaPayModal;
  const setOpenCashPayment = props.setOpenCashPayment;
  const setCardModalOpen = props.setCardModalOpen;
  const setBankPayModal = props.setBankPayModal;
  const setopenVoucherPay = props.setopenVoucherPay;
  const setOpenCreditNote = props.setOpenCreditNote;

  return (
    <Modal
      open={openPayModal}
      //  onClose={closePaymentModal}
    >
      <div style={universalStyles.modeOfPaymentModal}>
        <Grid container justifyContent="space-around" direction="row" style={{ width: "100%", padding: "20px" }}>
          <Grid item>
            <span style={universalStyles.spanXXLBlack}>Choose Payment Method</span>
          </Grid>

          <Grid item>
            <span>
              <Close style={{ cursor: "pointer" }} onClick={() => setPayOpenModal(false)} />
            </span>
          </Grid>
        </Grid>

        <Grid container direction="column" style={{ width: "100%", padding: "20px" }}>
          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setPayOpenModal(false);
              setMessagesToBeFetched("MPESA");
              setMpesaPayModal(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={mpesasvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Mpesa</span>
          </Grid>
          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              setPayOpenModal(false);
              setOpenCashPayment(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={cashsvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Cash</span>
          </Grid>

          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setMessagesToBeFetched("VOOMA");
              setPayOpenModal(false);
              setMpesaPayModal(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={voomasvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Vooma</span>
          </Grid>

          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setMessagesToBeFetched("EQUITEL");
              setPayOpenModal(false);
              setMpesaPayModal(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={equitelsvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Equitel</span>
          </Grid>

          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setPayOpenModal(false);
              setMessagesToBeFetched("CARD");
              setCardModalOpen(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={cardsvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Card</span>
          </Grid>

          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setBankPayModal(true);
              setPayOpenModal(false);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px" }} src={banksvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Banks</span>
          </Grid>
          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              // setShowReturnBill(false);
              setPayOpenModal(false);
              setopenVoucherPay(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px", height: "49px" }} src={vouchersvg} /> <span style={universalStyles.spanXLBlueNormalFont}>Voucher</span>
          </Grid>

          <Grid
            item
            style={universalStyles.allPayMethodGridStyles}
            onClick={() => {
              saveOrderToDbAndPopulateMpesa();
              setPayOpenModal(false);
              setOpenCreditNote(true);
            }}
          >
            <img style={{ padding: "5px 20px 5px 15px", height: "49px" }} src={creditnote} /> <span style={universalStyles.spanXLBlueNormalFont}>Credit Note</span>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

/* CASH PAYMENT MODAL */
export const CashModal = (props) => {
  const cashModalPayment = props.cashModalPayment;
  const newOverallTotal = props.newOverallTotal;
  const itemDiscount = props.itemDiscount;
  const setItemDiscount = props.setItemDiscount;
  const checkedDiscount = props.checkedDiscount;
  const setCashRecieved = props.setCashRecieved;
  const setOpenCashPayment = props.setOpenCashPayment;
  const cashRecieved = props.cashRecieved;
  const setCashPaymentDetails = props.setCashPaymentDetails;

  const confirmPayments = () => {
    if (cashRecieved > 0) {
      setOpenCashPayment(false);
      setCashPaymentDetails(true);
    }
  };

  return (
    <Modal
      open={cashModalPayment}
      //  onClose={closeCashModal}
    >
      <div style={universalStyles.cashPaymentModal}>
        <Grid container style={{ padding: "20px" }}>
          {/* <Grid item style={{ width: "100%" }}>
                <p
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "500",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "2.08",
                    letterSpacing: "normal",
                    textAlign: "right",
                    color: "#032541",
                  }}
                >
                  {" "}
                  <Checkbox checked={checkedDiscount} onChange={setCheckledStatus} inputProps={{ "aria-label": "controlled" }} />
                  Discount
                </p>
              </Grid> */}

          <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
            <Grid item>
              <p style={{ margin: "0", textAlign: "center" }}>
                <img src={cashpayment} />
              </p>
              <p style={universalStyles.xxxlParaBold}>Cash Payment</p>

              <p style={universalStyles.xxxlParaBold}>Sub Total = KES {newOverallTotal}</p>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item style={{ width: "100%" }}>
              <Box
                style={{ display: "flex", flexDirection: "column" }}
                component="form"
                sx={{
                  "& > :not(style)": { m: 1, width: "90%" },
                }}
                noValidate
                autoComplete="off"
              >
                {checkedDiscount && <TextField id="outlined-basic" label="Enter Cash Discount" variant="outlined" value={itemDiscount} type="number" onChange={(e) => setItemDiscount(e.target.value)} />}

                <TextField id="outlined-basic" helperText="Enter Cash Recieved" variant="outlined" type="number" onChange={(e) => setCashRecieved(e.target.value)} />
              </Box>
            </Grid>
          </Grid>

          <Grid container style={{ width: "100%" }} justifyContent="center" alignItems="center">
            <Button
              style={{
                borderRadius: "5px",
                border: "solid 1px #dc3545",
                color: "#dc3545",
                padding: "10px 40px 10px 40px",
                margin: "0px 20px 0px 0px",
              }}
              onClick={() => setOpenCashPayment(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                borderRadius: "5px",
                backgroundColor: "#032541",
                padding: "10px 40px 10px 40px",
                margin: "0px 0px 0px 20px",
                color: "#ffffff",
              }}
              onClick={() => confirmPayments()}
            >
              Pay
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export const Confirmcashpaymentmodal = (props) => {
  const cashPaymentDetails = props.cashPaymentDetails;
  const setCashPaymentDetails = props.setCashPaymentDetails;
  const newOverallTotal = props.newOverallTotal;
  const cashRecieved = props.cashRecieved;
  const newcustomerBalance = props?.newcustomerBalance;
  const generalDiscountAmount = props.generalDiscountAmount;
  const saveOrderToDb = props.saveOrderToDb;

  const numberFormat = (value) =>
    new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "KES",
    }).format(value);

  const makePayment = () => {
    setCashPaymentDetails(false);
    saveOrderToDb();
  };

  let balance = 0;
  const [customerBalance, setCustomerBalance] = useState(0);
  useEffect(() => {
    balance = newOverallTotal - cashRecieved;
    if (Math.sign(balance) === -1) {
      balance = balance * -1;
      setCustomerBalance(balance);
    } else {
      balance = balance;
      setCustomerBalance(balance);
    }
  }, [newOverallTotal, cashRecieved]);

  return (
    <Modal
      open={cashPaymentDetails}
      //  onClose={closeCashPaymentDetails}
    >
      <div style={universalStyles.cashPaymentDetailsModal}>
        <Grid container style={{ width: "100%", padding: "20px" }} alignItems="center" justifyContent="center">
          <Grid item>
            <p style={{ margin: "0", textAlign: "center" }}>
              <img src={cashpayment} />
            </p>
            <p style={universalStyles.xxxlParaBold}>Cash Payment</p>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            padding: "0px 30px 0px 30px",
            margin: "0px 0px 10px 0px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>Bill Amount:</span>
          </Grid>
          <Grid item>
            <span style={universalStyles.spanBoldBlack}>{numberFormat(newOverallTotal)}</span>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            padding: "0px 30px 0px 30px",
            margin: "0px 0px 10px 0px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>Amount Recieved:</span>
          </Grid>
          <Grid item>
            <span style={universalStyles.spanBoldBlack}>{numberFormat(cashRecieved)}</span>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            padding: "0px 30px 0px 30px",
            margin: "0px 0px 10px 0px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>Discount:</span>
          </Grid>
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>{numberFormat(generalDiscountAmount)}</span>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            padding: "0px 30px 0px 30px",
            margin: "0px 0px 10px 0px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>Total:</span>
          </Grid>
          <Grid item>
            <span style={universalStyles.spanBoldBlack}>KES {numberFormat(newOverallTotal)}</span>
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            width: "100%",
            padding: "0px 30px 0px 30px",
            margin: "0px 0px 20px 0px",
          }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid item>
            <span style={universalStyles.spanLGreyBold}>customer Balance:</span>
          </Grid>
          <Grid item>
            <span style={universalStyles.spanBoldBlack}> {numberFormat(customerBalance)}</span>
          </Grid>
        </Grid>

        <Grid container style={{ width: "100%" }} alignItems="center" justifyContent="center">
          <Grid item>
            <Button
              style={{
                objectFit: "contain",
                borderRadius: "5px",
                border: "solid 1px #dc3545",
                color: "#dc3545",
                padding: "10px 20px 10px 20px",
                margin: "0px 0px 0px 20px",
              }}
              onClick={() => setCashPaymentDetails(false)}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item>
            <Button
              style={{
                objectFit: "contain",
                borderRadius: "5px",
                backgroundColor: "#032541",
                color: "#ffffff",
                padding: "10px 20px 10px 20px",
                margin: "0px 0px 0px 20px",
              }}
              onClick={() => makePayment()}
            >
              Confirm
            </Button>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export const RequestToVoidTransactions = ({ openVoidModal, detailedBillId, sendVoidPaymentRequest, billAmount, paymentMethod }) => {
  const navigate = useNavigate();
  return (
    <Modal open={openVoidModal}>
      <Grid container>
        <div style={universalStyles?.voidRequestModal}>
          {/* <p style={{ textAlign: "center" }}> */}
          <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <div>
              <img src={warningsvg} alt="warning icon" />
            </div>

            <div style={{ textAlign: "left" }}>
              <p style={{ color: "#032541", fontWeight: "bold" }}>Request To Void Transaction?</p>
              <p style={{ color: "#707070" }}>
                Transaction of KES {billAmount} paid via {paymentMethod} will be marked as void. This action can't be undone!
              </p>

              <p>Are you sure you want to proceed?</p>

              <div style={{ display: "flex", gap: "30px", margin:'30px 0px 0px 0px' }}>
                <Button style={universalStyles?.cancelVoidPaymentActiveButton} onClick={() => navigate(`/detailedbill/${detailedBillId}`)}>
                  Cancel
                </Button>
                <Button onClick={() => sendVoidPaymentRequest()} style={universalStyles?.requestToVoidActiveButton}>
                  Request to Void
                </Button>
              </div>
            </div>
          </div>
          {/* </p> */}
        </div>
      </Grid>
    </Modal>
  );
};

export const ApproveVoidTransactionModal = ({approveVoidModal, setApproveVoidModal, billAmount, paymentMethod , doApproveVoidPayments }) => {
  const navigate = useNavigate();
  return (
    <Modal open={approveVoidModal}>
      <Grid container>
        <div style={universalStyles?.voidRequestModal}>
          {/* <p style={{ textAlign: "center" }}> */}
          <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <div>
              <img src={approveTick} alt="warning icon" />
            </div>

            <div style={{ textAlign: "left" }}>
              <p style={{ color: "#032541", fontWeight: "bold" }}>Request To Void Transaction?</p>
              <p style={{ color: "#707070" }}>
                Transaction of KES {billAmount} paid via {paymentMethod} will be marked as void. This action can't be undone!
              </p>

              <p>Are you sure you want to proceed?</p>

              <div style={{ display: "flex", gap: "30px", margin:'30px 0px 0px 0px' }}>
                <Button style={universalStyles?.cancelVoidPaymentActiveButton} onClick={() => setApproveVoidModal (false)}>Cancel</Button>
                <Button style={universalStyles?.approveVoidActiveButton} onClick={() => doApproveVoidPayments()} >Approve</Button>
              </div>
            </div>
          </div>
          {/* </p> */}
        </div>
      </Grid>
    </Modal>
  );
};

export const CancelVoidTransactionModal = ({ cancelVoidModal, detailedBillId, doDeclineVoidPayment, billAmount, paymentMethod, setCancelPayModal }) => {
  const navigate = useNavigate();
  return (
    <Modal open={cancelVoidModal}>
      <Grid container>
        <div style={universalStyles?.voidRequestModal}>
          {/* <p style={{ textAlign: "center" }}> */}
          <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <div>
              <img src={warningsvg} alt="warning icon" />
            </div>

            <div style={{ textAlign: "left" }}>
              <p style={{ color: "#032541", fontWeight: "bold" }}>Request To Void Transaction?</p>
              <p style={{ color: "#707070" }}>
                Transaction of KES {billAmount} paid via {paymentMethod} will be marked as void. This action can't be undone!
              </p>

              <p> Are you sure you want to proceed?</p>

              <div style={{ display: "flex", gap: "30px", margin:'30px 0px 0px 0px' }}>
                <Button style={universalStyles?.cancelVoidPaymentActiveButton} onClick={() => setCancelPayModal(false)}>
                  Cancel
                </Button>
                <Button onClick={() => doDeclineVoidPayment()} style={universalStyles?.requestToVoidActiveButton}>
                  Decline
                </Button>
              </div>
            </div>
          </div>
          {/* </p> */}
        </div>
      </Grid>
    </Modal>
  );
};

export const DeleteDiscountModal = ({deleteDiscountModal, billAmount, setDeleteDiscountModal, deleteAppliedDiscount }) => {
  return (
    <Modal open={deleteDiscountModal}>
      <div style={universalStyles?.voidRequestModal}>
      <div style={{ display: "flex", alignItems: "center", gap: "30px" }}>
            <div>
              <img src={warningsvg} alt="warning icon" />
            </div>

            <div style={{ textAlign: "left" }}>
              <p style={{ color: "#032541", fontWeight: "bold" }}>Delete Discount?</p>
              <p style={{ color: "#707070" }}>
                Applied discount of KES {billAmount} will be deleted from the invoice. This action can not be undone.
              </p>

              <p> Are you sure you want to proceed?</p>

              <div style={{ display: "flex", gap: "30px", margin:'30px 0px 0px 0px' }}>
                <Button style={universalStyles?.cancelVoidPaymentActiveButton} onClick={() => setDeleteDiscountModal(false)} >
                  Cancel
                </Button>
                <Button onClick={() => deleteAppliedDiscount ()}  style={universalStyles?.requestToVoidActiveButton}>
                  Delete
                </Button>
              </div>
            </div>
          </div>
      </div>
    </Modal>
  )
}

export { OutOfStock, MorePayments, SucessModal, CustomerAccounts, ConfirmOrder, SuccessCustomerOnAccounts, AlertIfBalanceIsDepleted, MpesaPaymentModal, BankPayModal, SuccessAlertRightAligned, ErrorAlertRightAligned, CreditnotepayModal, ConfirmPayCreditNote, ConfirmOverPaymentCreditnote };
