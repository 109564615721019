import React, {useState, useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CustomDropZone from "../../School/CustomDropZone";
import HttpComponent from "../../School/MakeRequest";
import {useDispatch, useSelector} from "react-redux";
import {ErrorAlert} from "../../snackBar Alerts/errorAlert";
import CustomTable from "../../School/CustomTable";
import {SuccessAlert} from "../../snackBar Alerts/successAlert";
import {addService, clearServicesToAdd, removeFromServicesToAdd} from "../../../features/servicesSlice";
import warningImage from "../../School/Images/warning-remove-icn.svg";
import ReusableModal from "../../School/ReusableModal";
import {addCategoriesToAdd, removeFromCategoriesToAdd} from "../../../features/businessCategorySlice";
import GeneralBusinessServiceForm from './GeneralBusinessServiceForm';
import EditService from './EditService';
const GeneralBusinessServices = (props) =>{
    const dispatch = useDispatch();
    const [formView, setFormView] = useState("form"); // return this to inital
    const [successToast, setSuccessToast] = useState({state: false, message: ""})
    const [errorShow, setErrorShow] = useState({state: false, message: ""})
    const [tableData, setTableData] = useState([])
    const [indexToBeDeleted, setIndexToBeDeleted] = useState();
    const [fromUpload, setFromUpload] = useState(false);

    

    const showUploaded = (newServices) => {
        let data = newServices.newRecords
        setSuccessToast({state: true, message:"Services uploaded successfully."})
        for(let i =0; i < data.length; i++){
            let newData ={
                serviceName:data[i].productName,
                categoryId:data[i].productCategory,
                amount:data[i].productPrice,
                description:data[i].productDescription,
                priceType:data[i].priceStatus,
            }
            dispatch(addService(newData))
        }
        setFromUpload(true)
        setFormView("Done")
    }

    const buttonStyles =
        {"minWidth":"7.813rem",
            "height":"2.813rem",
            "borderRadius":"4px",
        }

    const createButton = {
        "width": "15.313rem",
        "height": "2.813rem",
        "borderRadius": "5px",
        "border": "solid 1px #002543",
        "backgroundColor": "#fff",
        "color":"#032541",
        '&:hover': {
            backgroundColor: '#fff',
            color: '#032541'
        }
    }


    const { servicesToAdd,categories} = useSelector((store) => store.services);
    const { categoriesToAdd} = useSelector((store) => store.businessCategory);
    const { userId ,X_Authorization } = useSelector((store) => store.user);
  
    let newItembs = servicesToAdd


       
    const [newItems ,setNewItems] = useState()
    useEffect(()=>{
        setNewItems(newItembs)
    },[])
    

    //console.log(newItems , 'newItems')

    useEffect(() => {
        if(!servicesToAdd){
            (categoriesToAdd !== null && Object.keys(categoriesToAdd).length > 1)  ? setSuccessToast({state:true, message: "Categories created successfully.."}) : setSuccessToast({state:true, message: "Category created successfully.."})
        }else{
            setFormView("done")
        }
    }, []);

    const [buttonsDisabled, setButtonsDisabled] = useState(true)
    const isButtonsDisabled = () =>{
        if(servicesToAdd == null){
            console.log("disabled")
            setButtonsDisabled(true)
            return true
        }else{
            console.log("not disabled")
            setButtonsDisabled(false)
            return false
        }
    }

    useEffect(() => {
        isButtonsDisabled()
    }, [servicesToAdd]);


    const categoryIdMap = categories?.reduce((map, category) => {
        map[category.value] = category.label;
        return map;
    }, {});

    const [warningOpen, setWarningOpen] = useState(false);

    const columns = [
        { headerName: "Name", field: "productName", flex:1 },
        { headerName: "Category", field: "productCategory" ,flex:1,
            renderCell:params => (
                <Typography>
                    {categoryIdMap[params.value] || params.value}
                </Typography>
            )
        },
        { headerName: "Price Type", field: "priceStatus" ,flex:1},
        { headerName: "Amount", field: "productPrice" ,flex:1},
        { headerName: "Description", field: "productDescription" ,flex:1},
        {
            field: 'Action',
            headerName: 'Action',
            renderCell: (params) => (
                <Box>
                    
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => setFormView('edit')}
                >
                    <EditIcon color="primary" />
                </Button>
                <Button
                    sx={{
                        color:"#dc3545",
                        '&:hover': {
                            color: '#dc3545'
                        }
                    }}

                    onClick={() => {
                        const index = servicesToAdd.findIndex((service) => service.serviceName === params.row.serviceName);
                        setIndexToBeDeleted(index)
                        setWarningOpen(true);
                    }}
                >
                    <DeleteIcon color="danger" />
                </Button>
                </Box>
            ),
            flex: 1
        },
    ];

    const handleWarningClose = () =>{
        setWarningOpen(false);
    }

    const doRemove = () =>{
        dispatch(removeFromServicesToAdd({index:indexToBeDeleted}))
        handleWarningClose()
        setSuccessToast({state: true, message:"Service removed successfully."})
    }


    useEffect(() => {
        const dataWithGridId = servicesToAdd?.map((row, index) => ({
            ...row,
            gridId: `${index}-${row.id}`
        }));
        setTableData(dataWithGridId)
    }, [servicesToAdd]);

  

    const submit = () =>{
        HttpComponent({
            method: 'POST',
            url: '/api/createProduct',
            body: servicesToAdd,
            token: localStorage.getItem('X-Authorization')
        }).then((data)=>{
            console.log("here store is data",data);
            if(data.status === 201){
                props.next()
                dispatch(clearServicesToAdd())
            }else{
                console.error("Error setting info")
                setErrorShow({state:true, message:data.response.message})
            }
        }).catch((error)=>{
            console.error(error.message);
        })
    }

    const uploadNext = () =>{
        props.next()
        dispatch(clearServicesToAdd())
    }

    const onRowsSelectionHandler = (ids) => {
        const selectedRowsData = ids.map((id) => tableData.find((item) => item.categoryId === id));
        //console.log(selectedRowsData);
        //console.log(tableData)
      };

    return(
    <Box component="div" sx={{display:"flex", justifyContent:"center", margin:2, width:"100%"}}>
            <Box component="div" sx={{display:"flex", flexDirection:"column", width:"100%"}}>
                <SuccessAlert  vertical="top"
                               horizontal="right"
                               onClose={()=>setSuccessToast({...successToast, state:false})}
                               open={successToast.state}
                               message={successToast.message} />
                <ErrorAlert
                    vertical="top"
                    horizontal="right"
                    onClose={()=>setErrorShow({...errorShow, state:false})}
                    open={errorShow.state}
                    message={errorShow.message}/>
                {formView == "initial" ?
                    //    default view
                    <>
                        <Box sx={{alignSelf:"center", marginY:2}}>
                            <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                Add at least 1 service
                            </Typography>
                        </Box>

                        <Box sx={{alignSelf:"center"}}>
                            <CustomDropZone
                                setShowErrorToast={setErrorShow}
                                setShowSuccessToast={setSuccessToast}
                                url={"/api/upload_products"}
                                successAction={showUploaded}
                            />

                        </Box>


                        <Box component="div" sx={{alignSelf:"center", marginY:2}}>
                            <Button sx={createButton} onClick={()=>setFormView("form")}>
                                Create Service
                            </Button>
                        </Box>

                    </>
                    : formView == "form" ?
                        //    form view here
                        <>
                            <Box sx={{alignSelf:"center", marginY:2}}>
                                <Box sx={{marginY:2, textAlign:"center",display:'flex', justifyContent:'center', alignItems:'center', width:'100%'}}>
                                    <Typography sx={{fontSize:"1rem", color:"#707070", width: '90%'}}>
                                    Create a Product/Service
                                    </Typography>
                                    <Button sx={{
                                       ...buttonStyles,
                                       border:" solid 1px #002543",
                                       display:'flex',
                                       justifyContent:'center',
                                       alignItems:'center',
                                       color:"#002543",
                                       '&:hover': {
                                           backgroundColor:"transparent",
                                           color: '#002543'
                                       }
                                    }}
                                           onClick={()=>setFormView("initial")}
                                    >
                                       Upload File
                                    </Button>
                                </Box>

                                <GeneralBusinessServiceForm  setView={setFormView} next={props.next} showUploaded={showUploaded}/>
                            </Box>


                        </>
                        : formView == "edit" ?
                        //    form view here
                        <>
                            <Box sx={{alignSelf:"center", marginY:2}}>
                                <Box sx={{marginY:2, textAlign:"center",}}>
                                    <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                        Edit a Service
                                    </Typography>
                                </Box>

                                <EditService  setView={setFormView} next={props.next}/>
                            </Box>


                        </>
                        :
                        <>
                            {/*    custom data grid will go*/}
                            <Box component={"div"} sx={{display:"flex", width:"100%", justifyContent:"space-between", marginTop:"2%"}}>
                                <Box component="div" sx={{marginLeft:"5.8%"}}>
                                    <Typography sx={{fontSize:"1rem", color:"#707070"}}>
                                        List Of Services
                                    </Typography>
                                </Box>
                                <Box sx={{display:"flex", marginRight:"5%"}}>
                                    <Button sx={{
                                       ...buttonStyles,
                                       border:" solid 1px #002543",
                                       color:"#002543",
                                       '&:hover': {
                                           backgroundColor:"transparent",
                                           color: '#002543'
                                       }
                                    }}
                                           onClick={()=>setFormView("initial")}
                                    >
                                       Upload File
                                    </Button>

                                    <Button sx={{
                                        ...buttonStyles,
                                        marginLeft:1,
                                        backgroundColor:" #032541",
                                        color:"white",
                                        '&:hover': {
                                            backgroundColor: '#032541',
                                            color: 'white'
                                        }
                                    }}
                                            onClick={()=>setFormView("form")}
                                    >
                                        Add Service
                                    </Button>
                                </Box>
                            </Box>
                            <Box sx={{alignSelf:"center", width:"90%", marginTop:"2%"}}>
                                <CustomTable data={tableData} columns={columns} rowsPerPage={5} checkboxSelection disableSelectionOnClick  onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)} />
                            </Box>
                            <ReusableModal open={warningOpen} onClose={handleWarningClose} width="34.688rem">
                                <Box sx={{display:"flex", justifyContent:"center", padding:"3.125rem"}}>
                                    <Box sx={{alignSelf:"center",display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                        <Box sx={{display:"flex"}}>
                                            <img style={{paddingRight:"2.5rem"}} src={warningImage} alt="Warning"/>
                                            <Box sx={{display:"flex", flexDirection:"column", justifyContent:"center"}}>
                                                <Typography sx={{color:"#032541", fontWeight:600}}>
                                                    Delete Service?
                                                </Typography>
                                                <Typography sx={{color:"#323d52", fontSize:"0.875rem"}}>
                                                    Service will be deleted. <br/> This action cannot be undone.
                                                </Typography>
                                            </Box>
                                        </Box>

                                        <Box sx={{display:"flex", justifyContent:"center", gap:2, marginTop:5}}>
                                            <Button sx={{
                                                backgroundColor:"transparent",
                                                border:"1px solid #002543",
                                                color:"#002543",
                                                width:"7.813rem",
                                                height:"2.813rem",
                                                '&:hover': {
                                                    backgroundColor:"transparent",
                                                    color:"#002543",
                                                }
                                            }}
                                                    onClick={handleWarningClose}
                                            >
                                                Cancel
                                            </Button>
                                            <Button sx={{
                                                backgroundColor:"#dc3545",
                                                color:"#ffffff",
                                                width:"7.813rem",
                                                height:"2.813rem",
                                                '&:hover': {
                                                    backgroundColor:"#dc3545",
                                                    color:"#ffffff",
                                                }
                                            }}
                                                    onClick={doRemove}
                                            >
                                                Delete
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                            </ReusableModal>
                            {!fromUpload ?
                                <Box component="div" sx={{display: "flex", width: "95%", justifyContent: "end"}}>
                                    <Button sx={{
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                            disabled={buttonsDisabled}
                                            onClick={submit}
                                    >
                                        Next
                                    </Button>
                                </Box>
                                :
                                <Box component="div" sx={{display: "flex", width: "95%", justifyContent: "end"}}>
                                    <Button sx={{
                                        "width": "7.813rem",
                                        "height": "2.813rem",
                                        backgroundColor: "#17ae7b",
                                        color: "white",
                                        '&:hover': {
                                            backgroundColor: '#17ae7b',
                                            color: "white",
                                        }
                                    }}
                                            onClick={uploadNext}
                                    >
                                        Next
                                    </Button>
                                </Box>
                            }
                        </>
                }
            </Box>
        </Box>
  )
}

export default GeneralBusinessServices;