import React, { useEffect, useRef, useState } from "react";
import moment from "moment/moment";
import Calendar from "react-calendar";
import { handleTransactionDate } from "../../features/transactionDateSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Form from "react-bootstrap/Form";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import StudentIcon from "../../common/images/student-total-icn.svg";
import StudentNew from "../../common/images/student-new-icn.svg";
import StudentBal from "../../common/images/student-bal-icn.svg";
import PaidInvoice from "../../common/images/paid-invoice-icn.svg"
import UnpaidInvoice from "../../common/images/unpaidinvoiceicon.svg"
import partialinvoiceiconn from '../../common/images/partialinvoiceiconn.svg';
import { Box } from "@material-ui/core";
import HttpComponent from "../School/MakeRequest";
import SyncIcon from '@mui/icons-material/Sync';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { handleSchoolTypeName, removeSchoolTypeName } from "../../features/schoolType";
let baseUrl = process.env.REACT_APP_BASE_URL




const todayDate = new Date();
const day = todayDate.getDate();
const month = todayDate.getMonth() + 1;
const year = todayDate.getFullYear();
const fullDate = year + "-" + month + "-" + day;

function addDays(date, days) {
    const result = new Date(date); result.setDate(result.getDate() + days);
    return result;
}

const StudentDash = (props) => {
    const navigate = useNavigate();
    const [year,setYear] = useState(new Date().getFullYear())
    const [schoolTypeName, setSchoolTypeName] = useState("");
    const [schoolTypeId, setSchoolTypeId] = useState("");
  
    const  getSchoolTypeId =async () => {
        dispatch(removeSchoolTypeName())
      try {
        HttpComponent({
          method: 'GET',
          // /api/get_school_type_by_businesss
          url: "/api/get_school_type_by_businesss",
          body: null,
          token: localStorage.getItem("X-Authorization"),
        }).then((data) => {
        //  console.log('heeeeeeeeeeeeee', data.response.data.schoolType, data.response.data._id);
          if (data.status === 201) {
            setSchoolTypeName(data?.response?.data?.schoolType);
            setSchoolTypeId(data?.response?.data?._id);
    
            const schoolDetails = {
              schoolTypeName: data?.response?.data?.schoolType,
              schoolTypeId: data?.response?.data?._id
            }
            
            // console.log("inside if ", schoolDetails);
            dispatch(handleSchoolTypeName(schoolDetails))
    
          }else {
            console.error("Error setting info")
            setErrorShow({ state: true, message: data.response.message })
          }
  
        })
      } catch (error) {
        console.error(error.message);
      }
    }
    const calendarDateRef = useRef(new Date());
    const dispatch = useDispatch();
    const [totals, setTotals] = useState([]);
    const [chartData, setChartData] = useState([]);
    const [dropDown, setDropDown] = useState("7days");
    const [currentIndex, setCurrentIndex] = useState(0);
    const [loading, setLoading] = useState(false)
    const [schoolData, setSchoolData] = useState({
        totalStudents: 0,
        studentsWithBalances: 0,
        paidInvoices: "KES 0.00",
        unpaidInvoices: "KES 0.00",
        newStudents: 0,
        newParents: 0,
        totalParents: 0,
        totalPaidInvoices: 0,
        parentsWithBalances: 0,
        paidInvoicesCount: 0,
        unpaidInvoicesCount: 0,
        partiallyPaidInvoicesCount: 0,
        partiallyPaidInvoicesTotal: 0
    })

        //handle prev
        function handlePrevClick() {
            setYear((year) => year - 1);
          }
      
          // next year
      
          function handleNextClick() {
            setYear((year) => year + 1);
          }

   



    const handleNext = () => {
        setCurrentIndex(currentIndex + 1);
    };

    const handlePrev = () => {
        setCurrentIndex(currentIndex - 1);
    };

    const dropChange = (event) => {
        setDropDown(event.target.value)
    }
    const addDate = (value) => { return new Date(value.replace(/-/g, "/")) }
    const localCurrency = localStorage.getItem('localCurrency')
    const numberFormat = (value) => new Intl.NumberFormat("en-US", { style: "currency", currency: localCurrency, }).format(value);
    const [dValue, onDValueChange] = useState([moment(addDays(new Date(), -7)).format("YYYY-MM-DD"), moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),]);


    const paymentData = async () => {
        const donutResponse = await fetch(baseUrl + "/api/donutRange", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
            body: JSON.stringify({ dateList: dValue }),
        });

        const data = await donutResponse.json();
        if (donutResponse.status === 401) { return window.location.href = "/logout" }
        if (donutResponse.status === 200) { setChartData(data.totals); }
    };

    const fetchSchoolInfo = async () => {
        setLoading(true)
        const response = await fetch(baseUrl + "/api/get_school_information", {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "X-Authorization": localStorage.getItem("X-Authorization"),
            },
        });
        const data = await response.json();
        if (response.status === 201) {
            console.log("data", data.data)
            setLoading(false)
            setSchoolData({
                totalStudents: data.data.numberOfStudents,
                studentsWithBalances: data.data.studentsWithBalances,
                paidInvoices: numberFormat(data.data.paidInvoicesTotal),
                unpaidInvoices: numberFormat(data.data.unpaidInvoicesTotal),
                newStudents: data.data.newStudents,
                newParents: data.data.newStudents,
                totalParents: data.data.numberOfParents,
                totalPaidInvoices: data.data.paidInvoicesCount,
                parentsWithBalances: data.data.parentsWithBalances,
                paidInvoicesCount: data.data.paidInvoicesCount,
                unpaidInvoicesCount: data.data.unpaidInvoicesCount,
                partiallyPaidInvoicesCount: data.data.partialPaidInvoicesCount,
                partiallyPaidInvoicesTotal: numberFormat(data.data.partiallyPaidInvoicesTotal)
            });
        }
    }
    


    const [monthData, setMonthData] = useState([])
    console.log("schoolData here", schoolData)
    console.log("schoolData parents", schoolData.totalParents)

    const divs = [
        {
            id: "one",
            textOne: "New",
            textTwo: "Students",
            textThree: schoolTypeName?.includes("University") ? null :  "Parents/Guardians",
            image: StudentNew,
            firstNumber: schoolData.newStudents,
            secondNumber: schoolTypeName?.includes("University") ? null : schoolData.newParents,
            backgroundColor: 'rgba(61, 162, 248, 0.12)',
            color: '#3da2f8',
            url:"newstudents"
            


        },
        {
            id: "two",
            textOne: "Total",
            textTwo: "Students",
            textThree: schoolTypeName?.includes("University") ? null :  "Parents/Guardians",
            image: StudentIcon,
            firstNumber: schoolData?.totalStudents,
            secondNumber: schoolTypeName?.includes("University") ? null : schoolData.totalParents,
            backgroundColor: '#f3f4f6',
            color: "#111927",
            url: "allstudents"

        },
        {
            id: "three",
            textOne: "Students with",
            textTwo: "Balances",
            textThree: schoolTypeName?.includes("University") ? null :  "Parents/Guardians",
            image: StudentBal,
            firstNumber: schoolData?.studentsWithBalances,
            secondNumber: schoolTypeName?.includes("University") ? null : schoolData.newParents,
            backgroundColor: 'rgba(247, 144, 9, 0.12)',
            color: '#212b36',
            url: "studentswithbalances"


        },
        {
            id: "four",
            textOne: "Paid",
            textTwo: "Invoices",
            textThree: "Invoices",
            image: PaidInvoice,
            firstNumber: schoolData.paidInvoices,
            secondNumber: schoolData.paidInvoicesCount,
            backgroundColor: 'rgba(16, 185, 129, 0.12)',
            color: '#17AE7B',
            url: "studentspaidinvoices"


        },
        {
            id: "five",
            textOne: "Unpaid",
            textTwo: "Invoices",
            textThree: "Invoices",
            image: UnpaidInvoice,
            firstNumber: schoolData.unpaidInvoices,
            secondNumber: schoolData.unpaidInvoicesCount,
            backgroundColor: 'rgba(240, 68, 56, 0.12)',
            color: '#dc3545',
            url: "unpaidinvoices"

        },
        {
            id: "six",
            textOne: "Partially Paid",
            textTwo: "Invoices",
            textThree: "Invoices",
            image: partialinvoiceiconn,
            firstNumber: schoolData.partiallyPaidInvoicesTotal,
            secondNumber: schoolData.partiallyPaidInvoicesCount,
            backgroundColor: ' rgba(247, 144, 9, 0.12)',
            color: '#ff8503',
            url: "partiallypaidinvoices"
        }
    ];



    const fetchMonthlyTotals = async () => {
        try {
            const response = await fetch(baseUrl + `/api/v1/payments/by_year?year=${year}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            if (response.status === 401) { return window.location.href = "/logout" }
            await response.json()
                .then((data) => { console.log(`Monthly totals Data`, data.data); setMonthData(data.data) })
                .catch((e) => { console.log(`Error Getting Month Totals`, e.message) })
        } catch (e) {
            console.log(e);
        }
    }

    const monthDiv = [
        { id: "January", textOne: "Total Amount", textTwo: "JAN", firstNumber: monthData.JAN },
        { id: "February", textOne: "Total Amount", textTwo: "FEB", firstNumber: monthData.FEB },
        { id: "March", textOne: "Total Amount", textTwo: "MAR", firstNumber: monthData.MAR },
        { id: "April", textOne: "Total Amount", textTwo: "APR", firstNumber: monthData.APR },
        { id: "May", textOne: "Total Amount", textTwo: "MAY", firstNumber: monthData.MAY },
        { id: "June", textOne: "Total Amount", textTwo: "JUN", firstNumber: monthData.JUN },
        { id: "July", textOne: "Total Amount", textTwo: "JUL", firstNumber: monthData.JUL },
        { id: "August", textOne: "Total Amount", textTwo: "AUG", firstNumber: monthData.AUG },
        { id: "September", textOne: "Total Amount", textTwo: "SEP", firstNumber: monthData.SEP },
        { id: "October", textOne: "Total Amount", textTwo: "OCT", firstNumber: monthData.OCT },
        { id: "November", textOne: "Total Amount", textTwo: "NOV", firstNumber: monthData.NOV },
        { id: "December", textOne: "Total Amount", textTwo: "DEC", firstNumber: monthData.DEC },
    ]



    // Fetch Total transactions per day
    const fetchTotals = async () => {
        try {
            const response = await fetch(baseUrl + "/api/getTotals", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    "X-Authorization": localStorage.getItem("X-Authorization"),
                },
            });
            const data = await response.json();
            if (response.status === 401) { return window.location.href = "/logout" }
            if (response.status === 200) {
                setCalenderData(data.totals);
                for (const dayTotal of data.totals) {
                    const dayDate = addDate(dayTotal._id.Time);
                    const newDate = addDate(fullDate);
                    if (dayDate.toDateString() === newDate.toDateString()) { setTotals(dayTotal.amount); }
                }
            }
        } catch (e) {
            console.log(e);
        }
    };


    useEffect(() => {
        if (dropDown === "Today") {
            onDValueChange([
                moment(new Date()).format("YYYY-MM-DD"),
                moment(addDays(new Date(), 1)).format("YYYY-MM-DD"),
            ]);
        } else if (dropDown === "YesterDay") {
            onDValueChange([
                moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
                moment(addDays(new Date(), -1)).format("YYYY-MM-DD"),
            ]);
        } else if (dropDown === "7days") {
            onDValueChange([
                moment(addDays(new Date(Date.now()), -7)).format("YYYY-MM-DD"),
                moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
            ]);
        } else if (dropDown === "this Month") {
            const divs = [
                {
                    id: "one",
                    textOne: "New",
                    textTwo: "Students",
                    textThree: "Parents/Guardians",
                    image: StudentNew,
                    firstNumber: schoolData.newStudents,
                    secondNumber: schoolData.newParents,
                    backgroundColor: 'rgba(61, 162, 248, 0.12)',
                    color: '#3da2f8'


                },
                {
                    id: "two",
                    textOne: "Total",
                    textTwo: "Students",
                    textThree: "Parents/Guardians",
                    image: StudentIcon,
                    firstNumber: schoolData.totalStudents,
                    secondNumber: schoolData.totalParents,
                    backgroundColor: '#f3f4f6',
                    color: "#111927"

                },
                {
                    id: "three",
                    textOne: "Students with",
                    textTwo: "Balances",
                    textThree: "Parents/Guardians",
                    image: StudentBal,
                    firstNumber: schoolData.studentsWithBalances,
                    secondNumber: schoolData.parentsWithBalances,
                    backgroundColor: 'rgba(247, 144, 9, 0.12)',
                    color: '#212b36'


                },
                {
                    id: "four",
                    textOne: "Paid",
                    textTwo: "Invoices",
                    textThree: "Invoices",
                    image: PaidInvoice,
                    firstNumber: schoolData.paidInvoices,
                    secondNumber: schoolData.paidInvoicesCount,
                    backgroundColor: 'rgba(16, 185, 129, 0.12)',
                    color: '#17AE7B'


                },
                {
                    id: "five",
                    textOne: "Unpaid",
                    textTwo: "Invoices",
                    textThree: "Invoices",
                    image: UnpaidInvoice,
                    firstNumber: schoolData.unpaidInvoices,
                    secondNumber: schoolData.unpaidInvoicesCount,
                    backgroundColor: 'rgba(240, 68, 56, 0.12)',
                    color: '#dc3545'

                },
                {
                    id: "six",
                    textOne: "Partially Paid",
                    textTwo: "Invoices",
                    textThree: "Invoices",
                    image: partialinvoiceiconn,
                    firstNumber: schoolData.partiallyPaidInvoicesTotal,
                    secondNumber: schoolData.partiallyPaidInvoicesCount,
                    backgroundColor: ' rgba(247, 144, 9, 0.12)',
                    color: '#ff8503',
                }
            ]; onDValueChange([
                moment(
                    new Date(new Date().getFullYear(), new Date().getMonth())
                ).format("YYYY-MM-DD"),
                moment(addDays(new Date(Date.now()), +1)).format("YYYY-MM-DD"),
            ]);
        } else if (dropDown === "last Month") {
            onDValueChange([
                moment(
                    new Date(new Date().getFullYear(), new Date().getMonth() - 1)
                ).format("YYYY-MM-DD"),
                moment(
                    new Date(new Date().getFullYear(), new Date().getMonth())
                ).format("YYYY-MM-DD"),
            ]);
        }
    }, [dropDown]);

    const { userId, X_Authorization } = useSelector((store) => store.user);
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [batchData, setBatchData] = useState([])

    const fetchBatches = () => {
        HttpComponent({
            method: 'GET',
            url: `/api/get_invoice_batches?status=APPROVED&limit=5`,
            body: null,
            token: X_Authorization
        }).then((data) => {
            console.log("here store is data", data);
            if (data.status === 201) {
                setBatchData(data.response.data)
            } else {
                console.error("Error setting info")
                setErrorShow({ state: true, message: data.response.message })
            }
        }).catch((error) => {
            console.error(error.message);
        })
    }

    useEffect(() => {
        fetchMonthlyTotals()
            .then(() => { console.log('Monthyl totals Found') })
            .catch((e) => { console.log(`Error getting totals`, e.message) })
        paymentData()
            .then(() => { console.log('Branch totals Found') })
            .catch((e) => { console.log(`Error getting totals`, e.message) })
        fetchTotals()
            .then(() => { console.log('Day totals Found') })
            .catch((e) => { console.log(`Error getting totals`, e.message) })
    }, [dValue]);

    useEffect(() => {
        fetchSchoolInfo()
        getSchoolTypeId()

        fetchBatches()
    }, [])

    useEffect(()=>{
        fetchMonthlyTotals()

    },[dValue , year])

    return (
        <>
            <div style={{
                // margin:"2.188rem",
                display: "flex",
                flexDirection: "column"
            }}>

                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                    {currentIndex > 0 && (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"

                        }}>
                            <KeyboardArrowLeft onClick={handlePrev} />
                        </div>

                    )}
                    {divs.slice(currentIndex, currentIndex + 5).map(div => (

                        <div key={div.id} style={{ width: "100%", height: "135px", borderRadius: "12px", margin: "1.125rem", display: "flex", transition: "all 0.3s ease-in-out", backgroundColor: `${div?.backgroundColor}` }}>

                            <Link to={`/school/${div?.url}`} style={{ textDecoration: "none" }}>
                                 <div style={{ width: "100%", height: "135px", borderRadius: "12px", margin: ".9125rem", display: "flex", transition: "all 0.3s ease-in-out", }}>
                                    <div style={{ display: "flex", flexDirection: "column", margin: "0.5rem",  justifyContent: "flex-start", transition: "all 0.3s ease-in-out"}}>
                                        <div style={{color: "#212b36",fontSize: "16px",transition: "all 0.3s ease-in-out" }}>{div.textOne}</div>
                                        <div style={{color: "#212b36",fontSize: "16px",transition: "all 0.3s ease-in-out"}}>{div.textTwo}</div>

                                      {loading ? 
                                      <>
                                      <Box style={{display:"flex", justifyContent:"center"}}>
                                        <CircularProgress/>
                                      </Box>
                                      </> :
                                       <>
                                       <div style={{fontWeight: "bold",fontSize: "26px",transition: "all 0.3s ease-in-out",color: `${div?.color}` }}>
                                            {div.firstNumber}
                                        </div>
                                        <div sx={{color: "#212b36",fontSize: "14px",transition: "all 0.3s ease-in-out", display:"flex", }}>
                                            {div.secondNumber} {div.textThree}
                                        </div>
                                       </> 
                                      }
                                    </div>
                                    <div style={{ margin: "0.5rem", marginTop: "0.938rem", width: "20%", transition: "all 0.3s ease-in-out"}} className="ml-auto">
                                        <img src={div.image} alt="" />
                                    </div>
                                </div>
                                {/* <div style={{ width: "100%", height: "135px", borderRadius: "12px", margin: ".9125rem",  transition: "all 0.3s ease-in-out", }}>
                                    <div style={{ display: "flex" }}>
                                        <div>
                                            <div style={{ display: "flex", flexDirection: "column", margin: "0.5rem", justifyContent: "flex-start", transition: "all 0.3s ease-in-out" }}>
                                                <div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textOne}</div>
                                                <div style={{ color: "#212b36", fontSize: "16px", transition: "all 0.3s ease-in-out" }}>{div.textTwo}</div>
                                            </div>
                                        </div>
                                        <div className="ml-auto" style={{marginLeft:"auto"}}>
                                            <img src={div.image} alt="" />
                                        </div>
                                        
                                    </div>
                                    

                                </div> */}
                            </Link>

                        </div>


                    ))}
                    {currentIndex < divs.length - 5 && (
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center"

                        }}>
                            <KeyboardArrowRight onClick={handleNext} />
                        </div>
                    )}
                </div>

                <div style={{ display: "flex", width: "100%" }}>
                    <div style={{
                        width: "60%",
                        margin: "1.125rem"
                    }}>
                        {/* <Calendar
                            onChange={calendarOnChangeHandler}
                            value={calendarDateRef.current}
                            next2Label=""
                            prev2Label=""
                            tileContent={({ date, view }) => {
                                if (calenderData.find((x) => x._id.Time === moment(date).format("YYYY-MM-DD"))) {
                                    const totalDailySale = calenderData.map((y) => {
                                        if (y._id.Time === moment(date).format("YYYY-MM-DD")) {
                                            return numberFormat(y.amount);
                                        }
                                    });
                                    return (
                                        <div>
                        <span
                            style={{
                                height: "9px",
                                fontFamily: "Poppins",
                                fontSize: "12px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "2",
                                letterSpacing: "normal",
                                textAlign: "center",
                                color: "#dc3545",
                            }}
                        >
                          {totalDailySale}
                        </span>
                                        </div>
                                    );
                                }
                            }}
                        /> */}
                        <div style={{ width: "95%", margin: "1.125rem", borderRadius: "12px", boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)" }}>
                                <Grid direction={'row'} display={'flex'} justifyContent={'space-between'}>
						        	<span style={{ fontSize: "17px", color: "#dc3545", fontWeight: 600, margin: "1.125rem" }}> Calender</span>
						        	<div style={{display:"flex" ,cursor:'pointer' ,alignItems:"center"}}>
						        		<ArrowBackIosNewIcon onClick={handlePrevClick}/>
						        				<span>{year}</span>
						        		<ArrowForwardIosIcon onClick={handleNextClick}/>
						        	</div>
						        </Grid>
                            {/*Head Container*/}
                            <Grid container direction={'row'} spacing={2} justifyContent={'space-between'} style={{ marginLeft: '-8px', marginTop: '-8px' }}>
                                {monthData.map((month, index) => (
                                    <Grid onClick={() => navigate(`/school/transcations/${month.month}/${year}`)} item xs={2.7} key={month.id} style={{ cursor: 'pointer', width: "100%", height: "135px", borderRadius: "12px", border: " solid 1px rgba(3, 37, 65, 0.2)", margin: "10px", display: "flex", transition: "all 0.3s ease-in-out" }}>
                                        <Grid container direction={'row'} justifyContent={'space-between'} style={{ paddingRight: "16px" }}>

                                            {/*Months and Total Amount Label*/}
                                            <Grid item style={{ color: '#6e7074', fontSize: '12px', width: '5px' }} >Total Amount</Grid>
                                            <Grid item style={{ color: '#032541', fontSize: '15px', fontWeight: 'bold' }} >{month.month}</Grid>

                                            {/*Amount*/}
                                            <Grid container direction={'column'} >
                                                <Grid item style={{ color: '#032541', fontSize: '14px', fontWeight: '500' }} >{numberFormat(month.amount)}</Grid>
                                            </Grid>

                                        </Grid>
                                    </Grid>
                                ))}
                            </Grid>

                        </div>

                    </div>

                    <div style={{ display: "flex", flexDirection: "column", width: "40%", margin: "1.125rem", }}>

                        <div style={{
                            width: "100%",
                            minHeight: "11.875rem",
                            borderRadius: "12px",
                            boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)"
                        }}>
                            <div style={{ color: "#dc3545", fontSize: "18px", fontWeight: "600", margin: "1.125rem", display: "flex", justifyContent: "space-between" }}>
                                <div>Transaction Summary</div>
                                <div>
                                    <Form.Select
                                        size="sm"
                                        aria-label="Default select example"
                                        style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold" }}
                                        value={dropDown}
                                        onChange={dropChange}
                                    >
                                        <option value="Today">Today</option>
                                        <option value="YesterDay">Yesterday</option>
                                        <option value="7days">Last 7 Days</option>
                                        <option value="this Month">This Month</option>
                                        <option value="last Month">Last Month</option>
                                        {/*<option value="range">Custom range</option>*/}
                                    </Form.Select>
                                </div>

                            </div>
                            <div style={{
                                margin: "1.125rem"
                            }}>
                                {chartData.length < 1 ? (
                                    <div>No Payment Data</div>
                                ) : (
                                    chartData.map((lvalues, index) => (
                                        <Grid container key={index} justifyContent={"space-between"} direction={"row"}>
                                            <Grid item>
                                                <FiberManualRecordIcon style={{ fontSize: "10px" }} />
                                                {lvalues._id}
                                            </Grid>
                                            <Grid item>{numberFormat(lvalues.amount)}</Grid>
                                        </Grid>
                                    ))
                                )}

                            </div>

                        </div>

                        <div style={{
                            width: "100%",
                            height: "11.875rem",
                            borderRadius: "12px",
                            // marginTop: "1rem",
                            boxShadow: "0 3px 6px 0 rgba(120, 90, 236, 0.16)",
                            paddingBottom: "30px"
                        }}>

                            <div style={{ color: "#dc3545", fontSize: "18px", padding: "0PX 10px 0px 10px", fontWeight: "600", margin: "1.125rem", display: "flex", justifyContent: "start" }}>
                                <div>Recent Invoices</div>
                                {/*<div>*/}
                                {/*    <Form.Select*/}
                                {/*        size="sm"*/}
                                {/*        aria-label="Default select example"*/}
                                {/*        style={{ borderWidth: "0px", backgroundColor: "#fff", color: "#032541", fontSize: "14px", fontWeight: "bold" }}*/}
                                {/*        value={dropDown}*/}
                                {/*    >*/}
                                {/*        <option value="Today">Today</option>*/}
                                {/*        <option value="YesterDay">Yesterday</option>*/}
                                {/*        <option value="7days">Last 7 Days</option>*/}
                                {/*        <option value="this Month">This Month</option>*/}
                                {/*        <option value="last Month">Last Month</option>*/}
                                {/*    </Form.Select>*/}
                                {/*</div>*/}
                            </div>

                            {batchData.map((batch) => (
                                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 3, marginTop: 3, padding: "0PX 10px 0px 10px", }}>
                                    <Typography sx={{ color: "#687c8d", fontSize: "0.875rem", marginLeft: 2, marginRight: 1 }}>
                                        Batch No {batch.batchNo}
                                    </Typography>
                                    <Typography sx={{ color: "#687c8d", fontSize: "0.875rem", marginX: 1 }}>
                                        {batch.totalInvoices} Invoices
                                    </Typography>
                                    <Typography sx={{ marginX: 1, cursor: "pointer", color: "#33699d", fontSize: "0.875rem", fontWeight: 500, textDecoration: "underline" }}
                                        onClick={() => navigate(`/school/invoices/batch/${batch._id}`)}
                                    >
                                        View
                                    </Typography>
                                </div>
                            ))}

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}

export { StudentDash }
