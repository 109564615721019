import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { FormControl, Grid, Input, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import jwt from "jwt-decode";
import { handleBusinessUnitConfigId, handleUpdateBusinessData } from "../../features/tenantBusinessSlice";
import loginLogo from "../../images/zedLogo.png";
import "./phoneBootstrap.css";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import Button from "@mui/material/Button";
import { logInUser, logoutUser, changeToken } from "../../features/userSlice";
import { handleBusinessCategory } from "../../features/businessCategorySlice";
import { handleBranchDetails } from "../../features/branchSlice";
import { handleBusinessBranches } from "../../features/businessBranchesSlice";
import loginImage from "../../common/images/login_image.svg";
import { ErrorAlert } from "../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../snackBar Alerts/successAlert";
import HttpComponent from "../School/MakeRequest";
import { handleSchoolTypeName } from "../../features/schoolType";
import StockFunctions from "../products/stockComponents/stockEndpoints";
import { switchWarehouseState } from "../../features/sideBarSlice";
const baseUrl = process.env.REACT_APP_BASE_URL;

const useStyles = makeStyles((theme) => ({
  imgRes: {
    [theme.breakpoints.down("md")]: {
      alignItems: "center",
      width: "100%",
      objectFit: "contain",
      height: "100%",
      display: "inline-block",
    },
    [theme.breakpoints.up("md")]: {
      alignItems: "center",
      justifyContent: "center",
      width: "40rem",
      paddingLeft: "5rem",
      objectFit: "contain",
      height: "100%",
      display: "inline-block",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
      alignItems: "center",
      width: "100%",
      objectFit: "contain",
      height: "100%",
    },
  },
  pinsty: {
    [theme.breakpoints.up("sm")]: {
      width: "min-content",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  gridLayout: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "10rem",
    },
    [theme.breakpoints.down("md")]: {
      paddingLeft: "1rem",
      paddingRight: "1rem",
    },
  },
}));

const EmailSignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = useStyles();
    const stockfunctions = new StockFunctions();

  // Notification popup
  const handleClose = () => {
    dispatch(logoutUser());
    setSuccessLogin(false);
    setInvalidPassword(false);
    setUserSuspended(false);
    setNonExistingUser(false);
  };

  // Toggle eye
  const [values, setValues] = React.useState({
    newPassword: "",
    confirmPassword: "",
    showNewPassword: false,
    showConfirmPassword: false,
  });

  const handleMouseNewDownPassword = (event) => {
    event.preventDefault();
  };

  //handle email change

  // Eye toggle stuff
  const handleClickNewShowPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };

  // setters and Getters
  const [userEmail, setuserEmail] = useState("");
  const [userPin, setUserPin] = useState("")

  // Alerts Setters and Getters
  const [openSuccess, setSuccessLogin] = useState(false);
  const [openInvalid, setInvalidPassword] = useState(false);
  const [openSuspended, setUserSuspended] = useState(false);
  const [openNotFound, setNonExistingUser] = useState(false);
  const [schoolTypeName, setSchoolTypeName] = useState("");
  const [schoolTypeId, setSchoolTypeId] = useState("");
  const [successShow, setSuccessShow] = useState({ state: false, message: "" })
  const [errorShow, setErrorShow] = useState({ state: false, message: "" })


  // BarMan Setters and Getters
  const [barManState, setBarManState] = useState();

  // Get Bar Man Status
  const fetchBarManConfigs = async () => {
    try {
      const response = await fetch(baseUrl + "/api/get_enable_bar_and_kitchen", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          "X-Authorization": localStorage.getItem("X-Authorization"),
        },
      });
      await response
        .json()
        .then((data) => {
          setBarManState(data.status);
          // localStorage.setItem('barMan', data.status)
          localStorage.setItem("barMan", "undefined");
        })
        .catch((err) => {
          console.log("error getting stocks", err.message);
        });
    } catch (e) {
      console.log(e.message);
    }
  };

  function getBusinessUnitConfig(token, businessName) {
    HttpComponent({
      method: "GET",
      url: "/api/get_business_unit_config_list",
      token: token,
    })
      .then((data) => {
        console.log();
        if (data.status === 201) {
          localStorage.setItem("businessUnitConfigId", data.response.data[0]?._id);
          console.log(data.response.data[0]?._id, `fsbfbdbndfndfndfbn`);
          dispatch(handleBusinessUnitConfigId(data.response.data[0]?._id));
          dispatch(handleUpdateBusinessData(businessName));
        } else {
          console.log(`Error Getting BusinessUnitConfigList!`);
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  function checkSetupStatus(token) {
    HttpComponent({
      method: "GET",
      url: "/api/get_setup_status",
      token: token,
    })
      .then((data) => {
        if (data.status === 201) {
          console.log("state", data.response.data.workflowState);
          let state = data.response.data.workflowState;
          if (state.includes("COMPLETE")) {
            return navigate("/dashboard");
          }
        } else {
          return navigate("/rentSetup");
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  }

  async function submitHandler(event) {
    event.preventDefault();

    const response = await fetch(baseUrl + "/posLoginVersion2", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userPin: userPin,
        email: userEmail,
        fcmToken: "cLyPjpl0TGSBb-HNCXe-ia:APA91bGRikYPEZvFG5DiLmg0ciO4WQpqAOSyBYiSSU0I2R7YFyqnpNV9Jy6LML_zs9uu1JrRRAtUUC1as45Owbx0Xb5txiQQWlgKw7VJOK2o_en4ZE0dB49kOKeLjhlpnuz8lWNwAELd",
        deviceId: "WEB",
      }),
    });


    const data = await response.json()

    if (response.status === 200) {

      setUserPin('')
      setuserEmail('')
      const switchBranch = async (branch_id, oldToken, business_id) => {
        try {
          const response = await fetch(baseUrl + `/api/get_business_token`, {
            method: "POST",
            headers: {
              "X-Authorization": oldToken,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify({
              branch_id,
              oldToken,
              business_id,
            }),
          });
          if (response.status === 401) return (window.location.href = "/logout");
          const result = await response.json();
          dispatch(changeToken({ token: result.data.newToken }));
          localStorage.removeItem("X-Authorization");
          localStorage.setItem("X-Authorization", result.data.newToken);
          fetchBranches(result.data.newToken);
        } catch (e) {
          console.log("Error Switching branch", e.message);
        }
      };

      const fetchBranches = async (token) => {
        try {
          const response = await fetch(baseUrl + "/api/getBusinessBranchesByBid", {
            method: "GET",
            headers: {
              "X-Authorization": token,
              "Content-Type": "application/json",
              Accept: "application/json",
            },
          });
          const data = await response.json();
          if (response.status === 401) {
            return navigate("/logout");
          }
          if (response.status === 200) {
            dispatch(handleBusinessBranches({ branchesArr: data.data }));
          }
        } catch (e) {
          console.log(e);
        }
      };

      handleClose();
      switch (data.message !== undefined ? data.message : data.error) {
        case "Invalid Password":
          setInvalidPassword(true);
          handleErrorNotificationClick();
          break;
        case "Account Suspended for Numerous Login Attempts":
          setUserSuspended(true);
          handleErrorNotificationClick();
          break;
        case "User not found ...":
          setNonExistingUser(true);
          handleErrorNotificationClick();
          break;
        case "User Logged in Successfully!":
          if (data.businessCategory == "Rental" || localStorage.getItem("businessCategory") == "Rental") {
            getBusinessUnitConfig(data.token, data.businessName);
          }
          if (data.state.toLowerCase().includes("new")) {
            localStorage.setItem("email", data.email);
            return navigate("/forgotReset"); branchIdbranchId
          }

          setSuccessLogin(true);
          handleSuccessNotificationClick();
          const { branchId } = jwt(data.token);
          dispatch(logInUser({ isLoggedIn: true, ...data, branchId: branchId }));
          dispatch(handleBranchDetails({ _id: branchId }));




          switchBranch(branchId, data.token, data.defaultBusinessId);
          localStorage.setItem("X-Authorization", data.token);
          localStorage.setItem("group", data.group);
          localStorage.setItem("zedAccountingId", data.zedAccountingId);
          localStorage.setItem("email", data.email);
          localStorage.setItem("state", data.state);
          localStorage.setItem("businessID", data.defaultBusinessId);
          localStorage.setItem("username", data.username);
          localStorage.setItem("businessName", data.businessName);
          localStorage.setItem("businessId", data.businessNumber);
          localStorage.setItem("userId", data.userId);
          localStorage.setItem("BranchID", branchId);
          localStorage.setItem("customerId", data.customerId);
          localStorage.setItem("businessCategory", data.businessCategory);
          localStorage.setItem('localCurrency', data?.localCurrency)

          await stockfunctions.getWarehouseStatus().then((data) => {
            if (data?.status === 201) {
              console.log("data?.response?.data?.warehouseOn", data?.response?.data?.warehouseOn)
              dispatch(switchWarehouseState(data?.response?.data?.warehouseOn))
            }
          })
          await fetchBarManConfigs();
          await fetchBusinessCategory();
          // checkSetupStatus(data.token)

          if (data.businessCategory) {
            if (data.businessCategory.toLowerCase()?.includes("rental") && localStorage.getItem("workflow")?.toLowerCase()?.includes("complete")) {
              return navigate("/dashboard");
            } else if (data.businessCategory?.toLowerCase()?.includes("rental") && !localStorage.getItem("workflow")?.toLowerCase()?.includes("complete")) {
              return navigate("/rentalSetup");
            }
          }
          if (data.businessCategory?.toLowerCase()?.includes("school")) {
            await getSchoolTypeId(data?.token);
          }

          if (data.group === "Cashier") {
            navigate("/orders");
          }
          if (data.group == "User") {
            navigate("/userDashboard");
            return null;
          }

          navigate("/dashboard", { state: { fromLogin: true } });
          break;
      }
    } else if (response.status === 400) {
      // setNonExistingUser(true);
      // handleErrorNotificationClick();
      setErrorShow({ state: true, message: 'User not found, please provide correct phone or email and pin' })
    } else if (response.status === 505) {
      setErrorShow({ state: true, message: 'Invalid Pin' })
    } else if (response.status === 507) {
      setErrorShow({ state: true, message: 'Account Suspended for Numerous Login Attempts' })
    } else if (response.status === 405) {
      setErrorShow({ state: true, message: 'User Does not Exists!' })
    }

  }
  const getSchoolTypeId = async (token) => {
    try {
      HttpComponent({
        method: 'GET',
        // /api/get_school_type_by_businesss
        url: "/api/get_school_type_by_businesss",
        body: null,
        token: token
      }).then((data) => {
        //  console.log('heeeeeeeeeeeeee', data.response.data.schoolType, data.response.data._id);
        if (data.status === 201) {
          if (data.response.data?.schoolType) {
            setSchoolTypeName(data?.response.data?.schoolType);
            setSchoolTypeId(data?.response.data?._id);

          }
          setSchoolTypeName("NOT SCHOOL");
          setSchoolTypeId("NOT SCHOOL");

          const schoolDetails = {
            schoolTypeName: data?.response?.data?.schoolType,
            schoolTypeId: data?.response?.data?._id
          }

          // console.log("inside if ", schoolDetails);
          dispatch(handleSchoolTypeName(schoolDetails))

        } else {
          console.error("Error setting info")
          // setErrorShow({ state: true, message: data.response.message })
        }

      })
    } catch (error) {
      console.error(error.message);
    }
  }
  const checkFormStatus = (token) => {
    console.log("workflow has been called");
    HttpComponent({
      method: "GET",
      url: "/api/get_setup_status",
      body: null,
      token: token,
    })
      .then((data) => {
        console.log("here store is data", data);
        if (data.status === 201) {
          console.log("state", data.response.data.workflowState);
          let state = data.response.data.workflowState;
          localStorage.setItem("workflow", state);
        } else {
        }
      })
      .catch((error) => {
        console.error(error.message);
      });
  };

  const fetchBusinessCategory = async () => {
    const response = await fetch(baseUrl + "/api/get_business_category", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Authorization": localStorage.getItem("X-Authorization"),
      },
    });
    const { businessCategory } = await response.json();
    dispatch(handleBusinessCategory(businessCategory));
  };

  // Error Notification
  const [errorNotificationOpen, setErrorNotificationOpen] = React.useState(false);
  const handleErrorNotificationClick = () => {
    setErrorNotificationOpen(true);
  };
  const handleErrorNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorNotificationOpen(false);
  };

  // Success Notification
  const [successNotificationOpen, setSuccessNotificationOpen] = React.useState(false);
  const handleSuccessNotificationClick = () => {
    setSuccessNotificationOpen(true);
  };
  const handleSuccessNotificationClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSuccessNotificationOpen(false);
  };

  // Alerts
  let alertType;
  if (openInvalid === true) {
    alertType = <ErrorAlert message={"Invalid Credentials!"} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
  } else if (openNotFound === true) {
    alertType = <ErrorAlert message={`User Doesn't Exist!`} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
  } else if (openSuspended === true) {
    alertType = <ErrorAlert message={"User Suspended!"} open={errorNotificationOpen} onClose={handleErrorNotificationClose} horizontal={"right"} vertical={"top"} />;
  } else if (openSuccess === true) {
    alertType = <SuccessAlert message={"Login Success"} open={successNotificationOpen} onClose={handleSuccessNotificationClose} horizontal={"right"} vertical={"top"} />;
  }
  const handleNavigate = ()=> navigate('/')

  //add media queries

  return (
    <Grid container style={{ fontFamily: "Poppins" }}>
      {/*snack bars*/}
      {alertType}

      <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
      <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />

      {/*Header logo Container*/}
      <Grid container direction={"row"} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        {/*Logo image*/}
        <Grid item>
          <img src={loginLogo} alt="logo" style={{ marginTop: "10%" }} />
        </Grid>
      </Grid>

      {/*login image and Form container*/}
      <Grid container justifyContent={"center"} alignContent={"center"}>
        {/*login image*/}
        <Grid item lg={6} md={6} sm={12} xs={12} xl={6} justifyContent={"center"} alignContent={"center"}>
          <img src={loginImage} className={`img-fluid ${classes.imgRes}`} alt={"login image"} />
        </Grid>

        {/*Login Form*/}
        <Grid item style={{ marginTop: "5%" }} className={classes.gridLayout} lg={6} md={6} sm={12} xs={12} xl={6}>
          {/*Login form*/}
            <Grid item display={'flex'} alignItems={'center'} mb={2}>
              <span onClick={handleNavigate} style={{ fontSize: "20px", cursor:"pointer", color: "#707070", marginLeft:'-10px', fontWeight: "600" }}>  <ArrowBackIosNewIcon style={{marginRight:'5px'}} /> Back</span>
            </Grid>

          <Grid container direction={"column"} justifyContent={"space-between"} spacing={2} alignContent={"flex-start"}>
            {/*login label*/}

            <Grid item>
              <span style={{ fontSize: "20px", color: "#032541", fontWeight: "600" }}>Login</span>
            </Grid>
          </Grid>
          <Grid container display={'flex'} alignItems={'center'}>
            {/*login input label*/}
            <Grid item style={{ marginTop: "2%" }} sm={12} md={6} xs={12} lg={6} xl={6}>
              <span style={{ fontSize: "15px", color: "#032541" }}>
                {" "}
                Email Address
                <span style={{ color: "red" }}>*</span>
              </span>
            </Grid>

            {/*phone number input*/}
            <Grid item style={{ marginTop: "2%" }} xs={12} lg={12} sm={12} md={12}>
              <FormControl autoComplete="off" variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Email Address</InputLabel>
                <OutlinedInput
                  required
                  id="outlined-adornment-password"
                  type="email"
                  name="userEmail"
                  autoComplete='off'
                  value={userEmail}
                  onChange={(e) => setuserEmail(e.target.value)}
                  label="Email Address"
                />
              </FormControl>
            </Grid>

            {/*pin*/}
            {/*pin label*/}
            <Grid item style={{ marginTop: "2%" }} sm={12} md={12} lg={12} xs={12} xl={12}>
              <span style={{ fontSize: "15px", color: "#032541" }}>
                Pin
                <span style={{ color: "red" }}>*</span>
              </span>
            </Grid>
            {/*pin input*/}
            <Grid item mb={3} xs={12} sm={12} lg={12} md={12} style={{ marginTop: "3%" }}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">Enter Pin</InputLabel>
                <OutlinedInput
                  required
                  id="outlined-adornment-password"
                  type={values.showNewPassword ? "number" : "password"}
                  value={userPin}
                  autoComplete="new-password"
                  onChange={(e) => setUserPin(e.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" onClick={handleClickNewShowPassword} onMouseDown={handleMouseNewDownPassword}>
                        {values.showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Enter Pin"
                />
              </FormControl>
            </Grid>
          </Grid>

          {/*Forgot Password link*/}
          <Grid container justifyContent={"flex-end"}>
            <Grid item>
              <Link style={{ textDecoration: "none" }} to={"/forgot"}>
                Forgot Pin?
              </Link>
            </Grid>
          </Grid>

          {/*Login button*/}
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "10%" }}>
            <Button onClick={submitHandler} style={{ color: "white", backgroundColor: "#032541", height: "45px", width: "171px" }}>
              Login
            </Button>
          </Grid>

          {/*New user Signup*/}
          <Grid container justifyContent={"center"} alignContent={"center"} style={{ marginTop: "5%", fontSize: "15px", color: "#032541" }}>
            <span>
              New User?{" "}
              <Link style={{ fontWeight: "bold", textDecoration: "none" }} to={"/signup"}>
                Signup
              </Link>
            </span>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default EmailSignIn;
