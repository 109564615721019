import React, { useState, useEffect } from "react";
import AutocompleteComponent from "../../../common/autoComplete";
import { Button, Grid, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ErrorAlertRightAligned, SuccessAlertRightAligned } from "../../bills/startorderModals/modals";
import ConfigurationFunctions from "../configEndpoints";

const PayModeSetUp = () => {
  // label, styles, setInputValue, setSelectValue, data

  const navigate = useNavigate();
  const configMethods = new ConfigurationFunctions();

  const [selectedValue, setSelectValue] = useState("");
  const [selectInputValue, setInputValue] = useState("");
  const [businessess, setBusinesses] = useState([]);
  const [transMode, setTransMode] = useState("");
  const [numberOfPrints, setNumberOfPrints] = useState(0);
  const [showPrints, setShowPrints] = useState(false);
  const [canTransact, setCanTransact] = useState(false)
  const [transactionModes, setTransactionModes] = useState([
    { id: 1, label: "Bio", name: "bio" },
    { id: 2, label: "Card", name: "card" },
    { id: 2, label: "Card and Bio", name: "card_bio" },
  ]);

  const [success, setSuccess] = useState({ state: false, message: "" });
  const [error, setError] = useState({ state: false, message: "" });
  useEffect(() => {
    if (success.state) {
      setTimeout(() => {
        setSuccess({ state: false, message: "" });
      }, 3000);
    }
  }, [success.state]);

  useEffect(() => {
    if (error.state) {
      setTimeout(() => {
        setError({ state: false, message: "" });
      }, 3000);
    }
  }, [error.state]);

  useEffect(() => {
    if(transMode === "bio"){
      setShowPrints(true)
    }else if(transMode === "card_bio"){
      setShowPrints(true)
    }else{
      setShowPrints(false)
    }
    
  }, [transMode])

  useEffect(() => {
    if(transMode === "card" &&  selectInputValue?.name){
      setCanTransact(true)
    }else if(transMode === "bio" &&  selectInputValue?.name && numberOfPrints ){
      setCanTransact(true)
    }else if(transMode === "card_bio" &&  selectInputValue?.name && numberOfPrints){
      setCanTransact(true)
    }else{
      setCanTransact(false)
    }
    
  }, [numberOfPrints, transMode])

  useEffect(() => {
    configMethods.getAllBusiness(selectedValue).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        const newArray = data?.response?.data?.map((bus) => {
          if (bus.zedPayItStatus === true) {
            // console.log(bus);
            return {
              name: bus.businessName,
              bsNo: bus?.businessNumber,
            };
          } else {
            return {
              name: "default",
              bsNo: 1111,
            };
          }
        });

        setBusinesses(newArray);
      }
    });
  }, [selectedValue]);

  const handleChange = (e) => {
    setTransMode(e.target.value);
  };

  const updateTransMode = () => {
    configMethods?.setupTransactionMode(transMode, numberOfPrints, selectInputValue?.bsNo).then((data) => {
      if (data?.status === 200 || data?.status === 201) {
        setSuccess({ state: true, message: "Configurations set sucessfully!" });

        setTimeout(() => {
          navigate(-1);
        }, 3000);
      } else if (data?.status === 400) {
        setError({ state: true, message: data?.response?.message });
      } else {
        setError({ state: true, message: "Error in setting up configs!" });
      }
    });
  };

  

  return (
    <div style={{ width: "100%", fontFamily: "Poppins" }}>
      <AutocompleteComponent setSelectValue={setSelectValue} setInputValue={setInputValue} label={"Search school.."} data={businessess ? businessess : [{ name: "default", bsNo: 546778 }]} styles={{ width: "40%", margin: "0 0 3% 0" }} />

      <Grid container width={"100%"}>
        <Grid item width={"100%"} margin={"0 0 3% 0"}>
          {transactionModes?.map((bioName) => {
            return (
              <div style={{ display: "flex", width: "40%", justifyContent: "space-between", cursor: "pointer", margin: "0 0 1% 0", padding: "5px" }}>
                <span key={bioName.id}>{bioName.label}</span>
                <input onChange={handleChange} type="radio" value={bioName.name} name="trans" />
              </div>
            );
          })}
        </Grid>

        {showPrints && (
          <Grid item width={"100%"} margin={"0 0 3% 0"}>
            <TextField style={{ width: "40%" }} label={"Enter no of prints"} onChange={(e) => setNumberOfPrints(e.target.value)} />
          </Grid>
        )}

        <Grid item width={"100%"} margin={"0 0 1% 0"}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", width: "40%", gap: "20px" }}>
            <Button onClick={() => navigate(`/payItMode`)} style={canTransact  ? { color: "#dc3545", border: "1px solid #dc3545", width: "30%" } : { color: "#dc3545", border: "1px solid #dc3545", width: "30%", opacity: 0.4 }}>
              Cancel
            </Button>
            <Button style={canTransact ? { backgroundColor: "#17ae7b", color: "#ffffff", width: "30%" } : { backgroundColor: "#17ae7b", color: "#ffffff", width: "30%", opacity: 0.3 }} onClick={() => (canTransact ? updateTransMode() : null)}>
              Done
            </Button>
          </div>
        </Grid>
      </Grid>

      <ErrorAlertRightAligned error={error.state} message={error.message} />
      <SuccessAlertRightAligned sucess={success.state} message={success.message} />
    </div>
  );
};

export default PayModeSetUp;
