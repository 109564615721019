import React, { useState, useEffect } from "react";
import CustomDataGrid from "../../products/stockComponents/customDataGrid";
import Breadcrumbcomponent from "../../products/stockComponents/breadcrumb";
import { Button, Grid } from "@mui/material";
import SearchFilter from "../../customerAccounts/customerComponents/searchFilter";
import { Exports } from "../../customerAccounts/customerComponents/exports";
import PayModeSetUp from "./payModeSetup";
import { useNavigate } from "react-router-dom";
import { ArrowBackIos, Cancel } from "@mui/icons-material";
import ConfigurationFunctions from "../configEndpoints";

const PayModesLanding = () => {
  const navigate = useNavigate();
  const configMethods = new ConfigurationFunctions();

  const breadcrumb = [
    <span style={{ cursor: "pointer" }} key={1}>
      Dashboard
    </span>,
    <span style={{ cursor: "pointer" }} key={2}>
      Configurations
    </span>,
    <span style={{ cursor: "pointer", color: "red" }} key={3}>
      Zed-Payit Modes
    </span>,
  ];

  const column = [
    { field: "businessName", headerName: "BusinessName", flex: 1 },
    { field: "transactionMode", headerName: "Zed-PayIt Mode", flex: 1 },
    {field: 'numberOfFingerPrints', headerName: 'Number Of FingerPrints', flex: 1},
    // { field: "action", headerName: "Action", flex: 1, renderCell:(params) => {
    //   return (
    //     <div style={{display:'flex', gap:'10px', alignItems:'center'}}>
    //       <Cancel style={{color: 'red', cursor:'pointer'}} />
          
    //     </div>
    //   )
    // } },
  ];

  //   search filter;
  const [searchValue, setInputValue] = useState("");

  // DataGrid Pagination
  const [dataGridPageSize, setPageSize] = React.useState(10);
  const [pageState, setPageState] = useState({ isLoading: true, data: [], count: 0, page: 1, pageSize: dataGridPageSize });

  //   query params;
  const queryParams = decodeURIComponent(window.location.search);

  const queryParamValue = queryParams.slice(1);

  useEffect(() => {
    setPageState({...pageState,  isLoading: true })
    configMethods.getTransactionMode(pageState?.page, dataGridPageSize, searchValue).then((data) => {
      if(data?.status === 200 || data?.status === 201){
        setPageState({...pageState, data: data?.response?.data, isLoading: false, count: data?.response?.count })
      }
    })

  }, [queryParamValue, pageState?.page, dataGridPageSize, searchValue])

  const configData = pageState?.data?.map((config) => ({
    id:config._id,
    businessName:config?.businessName,
    transactionMode:config?.transactionMode || 'N/A',
    numberOfFingerPrints:config?.numberOfFingerPrints || 'N/A'
  }))

  return (
    <div style={{ width: "100%", fontFamily: "Poppins" }}>
      <Grid container margin={"0 0 2% 0"}>
        <Grid item>
          <div style={{ display: "flex", alignItems: "center", gap: "10px", cursor: "pointer" }}>
            <ArrowBackIos onClick={() => navigate(-1)} />
            <span style={{ color: "#032541", fontSize: "20px", fontWeight: "bold" }}>Configurations</span>
          </div>
        </Grid>
      </Grid>

      <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"} margin={"0 0 2% 0"}>
        <Grid item>
          <Breadcrumbcomponent breadcrumbs={breadcrumb} />
        </Grid>

        <Grid item>
          {queryParamValue === "payModesSetup" ? null : (
            <Button onClick={() => navigate(`/payItMode?payModesSetup`)} style={{ backgroundColor: "#032541", color: "#ffffff" }}>
              Zed-PayIt Modes
            </Button>
          )}
        </Grid>
      </Grid>

      {queryParamValue === "payModesSetup" ? (
        <PayModeSetUp />
      ) : (
        <>
          <Grid container justifyContent={"space-between"} width={"100%"} direction={"row"} margin={"0 0 2% 0"}>
            <Grid item width={"30%"}>
              <SearchFilter placeholder={"Search"} setInputValue={setInputValue} />
            </Grid>

            <Grid item>{/* <Exports /> */}</Grid>
          </Grid>

          <Grid container>
            <Grid item width={"100%"}>
              <CustomDataGrid customStyles={{ border: "none", height: "450px", width: "100%" }} column={column} setPageSize={setPageSize} setPageState={setPageState} pageState={pageState} dataGridPageSize={dataGridPageSize} row={configData} />
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default PayModesLanding;
