import { Breadcrumbs, Button, Collapse, FormControlLabel, Grid, List, ListItem, ListItemIcon, ListItemText, Radio, TextField, Typography } from "@mui/material";
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import Checkbox from '@mui/material/Checkbox';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from "react";
import HttpComponent from "../MakeRequest";
import { ErrorAlert } from "../../snackBar Alerts/errorAlert";
import { SuccessAlert } from "../../snackBar Alerts/successAlert";
import { makeStyles } from "@mui/styles";

const breadcrumbs = [
    <Typography key={1} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Dashboard</Typography>,
    <Typography key={3} style={{ color: "#707070", fontSize: "14px", fontFamily: "Poppins" }}>Configuration</Typography>,
    <Typography key={4} style={{ color: "#dc3545", fontSize: "14px", fontFamily: "Poppins" }}>Auto Generate Student Number</Typography>
]

const useStyles = makeStyles((theme) => ({
    customCheckbox: {
      '&.Mui-disabled .MuiSvgIcon-root': {
        color: '#dc3545', 
      },
    },
  }));

export default function SchoolGradeAutogenerateConfigSetUp() {
    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false)
    const [openPrefix, setOpenPrefix] = useState(false)
    const [openDiffPrefix, setOpenDiffPrefix] = useState(false)
    const [foundGrades, setFoundGrades] = useState([])
    const [formData, setFormData] = useState({ autoIncrementValue: '', prefixForAllValue: '' })
    const [studentPrefixFound, setStudentPrefixFound] = useState([])
    const [updateGrades, setUpdateGrades] = useState([{ gradeName: "", prefixName: "" ,gradeId: '',  }])
    const [autoIncrementStatus, setAutoIncrementStatus] = useState(false)
    const [allPrefixstatus, setallPrefixstatus] = useState('')
    const [differentLevelsStatus, setDifferentLevelsStatus] = useState(false)
    const [successShow, setSuccessShow] = useState({ state: false, message: "" })
    const [errorShow, setErrorShow] = useState({ state: false, message: "" })
    const [fromdbStatus , setFromDbStatus] = useState(false)
    const [fromdbAllstatus ,setFromdball]= useState(false)
    //update state
    const handleOpenA = () => setIsOpen(!isOpen)
    const handleOpenB = () => {
        if(differentLevelsStatus === false && allPrefixstatus === 'all'){
            setOpenPrefix(!openPrefix)
        }else if(fromdbAllstatus === true){
            setOpenPrefix(!openPrefix)
        }
    }
    const handleOpenC = () => {
        if(fromdbAllstatus === false && allPrefixstatus === 'differebtLevels'){
            setOpenDiffPrefix(!openDiffPrefix)
        }else if (differentLevelsStatus === true){
            setOpenDiffPrefix(!openDiffPrefix)
        }
        setOpenDiffPrefix(!openDiffPrefix)
    }

   //console.log(allPrefixstatus , 'youuuu')




    // get status

    function getGradeConfigStatus() {
        try {
            HttpComponent({
                method: "GET",
                url: '/api/v1/students/setup/getAutogenerateStudentNumberStatus',
                token: localStorage.getItem('X-Authorization'),
            }).then((data) => {
                console.log(data, 'grade set')
                if (data.status === 200) {
                    setAutoIncrementStatus(data?.response?.data?.studentNumberIncrementStartStatus)
                    setallPrefixstatus(data?.response?.data?.samePrefixForSchoolStatus)
                    setDifferentLevelsStatus(data?.response?.data?.prefixPerGradeStatus)
                    setFromDbStatus(data?.response?.data?.prefixPerGradeStatus)
                    setFromdball(data?.response?.data?.samePrefixForSchoolStatus)

                }
            })
        } catch (error) {
        }
    }

    useEffect(() => {
        getGradeConfigStatus()
    }, [])




    //handle on change

    const handleOnChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }


    // get prefixes /api/v1/students/setup/getStudentNumberPrefix

    function getStudentPrefixinSchool() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/students/setup/getStudentNumberPrefix`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'fffffffffffff')
                if (data.status === 200) {
                    setFormData({
                        ...formData,
                        autoIncrementValue: data?.response?.data?.studentNumberIncrementStart,
                        prefixForAllValue: data?.response?.data?.samePrefixForSchool

                    })
                }
            })

        } catch (error) {

        }
    }

    useEffect(() => {
        getStudentPrefixinSchool()
    }, [])

    // if different levels is enabled 

    function getDifferentLevelsEnabled() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/v1/students/setup/getStudentNumberPrefix`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                console.log(data , 'gardes enabled >>>>>>>')
                if (data.status === 200) {
                    setStudentPrefixFound(data?.response?.data?.prefixPerGrade)
                    const OtherGrades = data?.response?.data?.prefixPerGrade

                    if(OtherGrades?.length > 0){
                        setUpdateGrades(OtherGrades?.map(item => ({
                            gradeName: item?.gradeName,
                            gradeId: item?._id,
                            prefixName: item?.prefixName
                        })));
                    }

                }
            })

        } catch (error) {

        }

    }
    useEffect(()=>{
        getDifferentLevelsEnabled()
    },[])


    //get grades in a school

    function getGradesinSchool() {
        try {
            HttpComponent({
                method: 'GET',
                url: `/api/get_school_grades`,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if (data.status === 201) {
                    setFoundGrades(data?.response?.data)
                    const OtherGrades = data?.response?.data
                    setUpdateGrades(OtherGrades?.map(item => ({
                        gradeName: item?.schoolGrades,
                        gradeId: item?._id,
                        prefixName: ''
                    })));

                }
            })

        } catch (error) {

        }
    }

    // array onchnage

    const handleGradeOnChange = (event, index) => {
        const { name, value } = event.target;
        const updatedUpdateGrades = [...updateGrades];
        updatedUpdateGrades[index][name] = value;
        setUpdateGrades(updatedUpdateGrades);
    };

    // submit details

    const submitBody = {
        studentNumberIncrementStart: formData?.autoIncrementValue,
        samePrefixForSchool: formData?.prefixForAllValue
    }

    const submitBody2 = {
        studentNumberIncrementStart: formData?.autoIncrementValue,
        prefixPerGrade: updateGrades
    }

    // useEffect(() => {

    //     if(differentLevelsStatus){
    //         setallPrefixstatus(false)
    //         setDifferentLevelsStatus(true)
    //     }

    //     if(allPrefixstatus){
    //         setDifferentLevelsStatus(false)
    //         setallPrefixstatus(true)
    //     }
    // }, [allPrefixstatus, differentLevelsStatus])



    useEffect(() => {
        if (differentLevelsStatus === true) {
            getDifferentLevelsEnabled()
        } else if (differentLevelsStatus === false) {
            getGradesinSchool()
        }
    }, [differentLevelsStatus])

    function handleSubmitUpdateDetails() {
        try {
            HttpComponent({
                method: 'POST',
                url: '/api/v1/students/setup/setStudentNumberPrefix',
                body: fromdbStatus === true ? submitBody: submitBody2,
                token: localStorage.getItem('X-Authorization')
            }).then((data) => {
                if(data.status === 200){
                    setSuccessShow({state:true , message:'Set Student Number Prefix enabled successfully'})
                    setTimeout(()=>{
                        window.location.reload();
                    },1000)
                    
                }else{
                    setErrorShow({state:true , message:'Error Setting Student Number Prefix'})
                }
            })

        } catch (error) {

        }
    }



    return (
        <Grid container display={'flex'} direction={'column'}>
             <SuccessAlert vertical="top" horizontal="right" onClose={() => setSuccessShow({ ...successShow, state: false })} open={successShow.state} message={successShow.message} />
                <ErrorAlert vertical="top" horizontal="right" onClose={() => setErrorShow({ ...errorShow, state: false })} open={errorShow.state} message={errorShow.message} />
            <Grid item display={'flex'} alignItems={'center'}>
                <ArrowBackIosNewIcon style={{ color: "#707070" }} />
                <Typography style={{ color: "#707070", fontWeight: 'bold', fontSize: "20px", fontFamily: "Poppins" }}>Auto Generate Student Number</Typography>
            </Grid>
            <Grid item display={'flex'} mt={2}>
                <Breadcrumbs style={{ fontFamily: 'Poppins', fontSize: '14px' }} aria-label="breadcrumb" separator={<FiberManualRecordIcon style={{ fontSize: "0.625rem", fontFamily: 'Poppins', color: "#e1e5e8" }} />}>
                    {breadcrumbs}
                </Breadcrumbs>
            </Grid>
            <Grid item mt={2}>
                <Typography style={{ color: "#032541", fontWeight: 'bold', margin: '10px 0px', fontSize: "25px", fontFamily: "Poppins" }}>Select a  Preference</Typography>
            </Grid>
            <Grid item display={'flex'}>
                <Grid container display={'flex'} direction={'column'}>
                    <Grid item display={'flex'} direction={'column'} width={'50%'} mt={2}>
                        <ListItem sx={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'space-between' }}>
                            <ListItemIcon>
                                <Checkbox checked={autoIncrementStatus}  onChange={(e) => setAutoIncrementStatus(e.target.checked)} />
                            </ListItemIcon>
                            <ListItemText primary="Auto-increment numbers" />
                            {isOpen ? <ExpandLess onClick={handleOpenA} /> : <ExpandMore onClick={handleOpenA} />}
                        </ListItem>
                        <Collapse in={isOpen} sx={{ marginTop: '10px', marginBottom: '10px' }} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: "4%", marginRight: '3%' }}>
                                <TextField sx={{ flex: 1 }} value={formData?.autoIncrementValue} onChange={handleOnChange} name="autoIncrementValue" id="outlined-basic" label="Start At" variant="outlined" />
                            </List>
                        </Collapse>
                    </Grid>
                    <Grid item display={'flex'} direction={'column'} width={'50%'} mt={2}>
                        <ListItem sx={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'space-between' }}>
                            <ListItemIcon>
                                {fromdbAllstatus ?  <Checkbox checked={fromdbAllstatus}  classes={{ root: classes.customCheckbox }} disabled={fromdbAllstatus}  /> :<FormControlLabel  value="all"  control={<Radio sx={{display:'flex' , marginLeft:'10px', alignItems:'center'}} />}  checked={allPrefixstatus === 'all'} disabled={differentLevelsStatus} onChange={(e)=>setallPrefixstatus(e.target.value)} />}
                            </ListItemIcon>
                            <ListItemText primary="Prefix for all students in all levels" />
                            {openPrefix && !openDiffPrefix ? <ExpandLess onClick={handleOpenB} /> : <ExpandMore onClick={handleOpenB} />}
                        </ListItem>
                        <Collapse in={openPrefix} sx={{ marginTop: '10px', marginBottom: '10px' }} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding sx={{ display: 'flex', justifyContent: 'space-between', marginLeft: "4%", marginRight: '3%' }}>
                                <TextField sx={{ flex: 1 }} value={formData.prefixForAllValue} onChange={handleOnChange} name="prefixForAllValue" id="outlined-basic"  label="Prefix" variant="outlined" />
                            </List>
                        </Collapse>
                    </Grid>
                    <Grid item display={'flex'} direction={'column'} width={'50%'} mt={2}>
                        <ListItem sx={{ display: 'flex', width: "100%", alignItems: 'center', justifyContent: 'space-between' }}>
                            <ListItemIcon>
                               {differentLevelsStatus ? <Checkbox checked={differentLevelsStatus} classes={{ root: classes.customCheckbox }} disabled={differentLevelsStatus}/>:<FormControlLabel value="differebtLevels" control={<Radio  sx={{display:'flex' , marginLeft:'10px', alignItems:'center'}} />} onChange={(e)=>setallPrefixstatus(e.target.value)}  checked={allPrefixstatus === 'differebtLevels'}/>} 
                            </ListItemIcon>
                            <ListItemText primary="Different prefix in all levels" />
                            {openDiffPrefix ? <ExpandLess onClick={handleOpenC} /> : <ExpandMore onClick={handleOpenC} />}
                        </ListItem>
                        <Collapse in={openDiffPrefix} sx={{ marginTop: '10px', marginBottom: '10px' }} timeout="auto" unmountOnExit>
                            {studentPrefixFound?.length > 0 ?
                                <>
                                    <List component="div" disablePadding sx={{ display: 'flex', width: "100%", marginLeft: "4%", marginRight: '3%' }}>
                                        <Grid item display={'flex'} direction={'column'} width={'100%'} marginRight={'6%'}>
                                            {studentPrefixFound?.map((foundG, index) => {
                                                return (
                                                    <Grid key={foundG?.gradeId} item display={'flex'} width={'100%'} mt={2} justifyContent={'space-between'}>
                                                        <TextField sx={{ width: '100%' }} value={updateGrades[index]?.prefixName} onChange={(event) => handleGradeOnChange(event, index)} name={'prefixName'} id={`outlined-basic-${foundG.gradeId}`} label={foundG?.gradeName} variant="outlined" />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </List>
                                    {/* <Grid item display={'flex'} mt={2} marginRight={'1%'} justifyContent={'flex-end'}>
                                        <Button onClick={handleSubmitUpdateDetails} style={{ width: '141px', marginLeft: '10px', height: "55px", color: '#fff', textTransform: 'inherit', background: 'rgba(3, 37, 65, 0.4)' }}>Save</Button>
                                    </Grid> */}
                                </> :
                                <>
                                    <List component="div" disablePadding sx={{ display: 'flex', width: "100%", marginLeft: "4%", marginRight: '3%' }}>
                                        <Grid item display={'flex'} direction={'column'} width={'100%'} marginRight={'6%'}>
                                            {foundGrades?.map((foundG, index) => {
                                                return (
                                                    <Grid key={foundG?.gradeId} item display={'flex'} width={'100%'} mt={2} justifyContent={'space-between'}>
                                                        <TextField sx={{ width: '100%' }} value={updateGrades[index]?.prefixName || ' '} onChange={(event) => handleGradeOnChange(event, index)} name={'prefixName'} id="outlined-basic" label={foundG?.schoolGrades} variant="outlined" />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                    </List>
                                </>}
                        </Collapse>
                    </Grid>
                    <Grid item display={'flex'} width={'50%'} mt={2} marginRight={'1%'} justifyContent={'flex-end'}>
                        <Button onClick={handleSubmitUpdateDetails} style={formData?.autoIncrementValue ? {background:'#032541' ,  width: '141px', marginLeft: '10px', height: "55px", color: '#fff', textTransform: 'inherit',}:{ width: '141px', marginLeft: '10px', height: "55px", color: '#fff', textTransform: 'inherit', background: 'rgba(3, 37, 65, 0.4)' }}>Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}